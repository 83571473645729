<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-container">
      <div class="modal-header">
        <span class="modal-title"
          >{{ $t("language.messageTitle") }} {{ racer }}</span
        >
      </div>

      <div class="modal-content">
        <div class="form-group" style="margin-top: 40px" id="form-container">
          <label for="textArea1">{{ $t("language.messageLabel") }} </label>
          <textarea class="form-control" id="textArea1" rows="3" v-model="message"></textarea>
        </div>
        <div class="action-buttons" style="">
          <GrayActionButton
            style="padding-inline: 20px"
            @actionClick="dataPush()"
            :actionImage="actionSaveImage"
            :text="saveText"
          />
          <GrayActionButton
            @actionClick="closeModal"
            :actionImage="actionCancel"
            :text="cancelText"
          />
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="formBody"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
</template>

<script>
import GrayActionButton from "./GrayActionButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "MessageModal",
  emits: ["close"],
  data() {
    return {
      actionSaveImage: "fa-solid fa-paper-plane",
      saveText: "Send",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "Cancel",
      message: "",
      showAlert: false,
      formBody: "",
    };
  },
  props: {
    showModal: Boolean,
    racer: String,
    racerId: [String, Number],
    deviceId: String,
  },
  mounted() {
    this.saveText = this.$t("language.messageSend");
    this.cancelText = this.$t("language.cancelText");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setMessageText(value) {
      this.message = value;
    },
    async dataPush() {
      try {
        console.log(this.message);
        if (this.message.length > 0) {
          this.closeModal();
          //Alert
          this.formBody = "Message Sent Successfully!";
          this.showAlert = true;
          await dp.postMessage(this.racerId, this.deviceId, this.message);
        } else {
          this.formBody = "Message cannot be empty!";
          this.showAlert = true;
        }
      } catch (error) {
        this.formBody = "Failed to send message!";
        this.showAlert = true;
      }
    },
    handleEnterKey() {
      if (event.key === "Enter") {
        this.dataPush();
      }
    },
  },
  components: {
    GrayActionButton,
    CustomAlert,
  },
};
</script>

<style scoped>
/* Add your modal styling here */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-container {
  background: #202528;
  padding-inline: 20px;
  border-radius: 10px;
  width: 30%;
  height: 50%;
  z-index: 1002;
  box-shadow: #dd9c44 0px 0px 10px;
}

h2 {
  color: #555556;
  margin-bottom: 20px;
}

#textArea1 {
  background-color: #202528;
  border: 1px solid #555556;
  color: white;
  resize: none;
  outline: none;
  box-shadow: none;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

#form-container {
  text-align: left;
  color: #555556;
}

.inputfields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 20px;
}

.close-modal {
  cursor: pointer;
  font-size: 3rem;
  color: white;
  opacity: 0.6;
}

.modal-title {
  color: white;
  opacity: 0.6;
  font-size: 1.2rem;
  font-family: "Lexend";
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 20px;
}

.modal-content {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .modal-container {
    width: 80%;
    height: 50%;
  }

  .inputfields {
    transform: scale(1.2);
    height: 60%;
  }

  .action-buttons {
    padding-left: 0px;
  }
}
</style>
