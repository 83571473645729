<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.home") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="devicelist">{{ $t("language.navDeviceList") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/device">{{ $t("language.navDevice") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.navEditDevice") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-inline: 3vw;
      "
    >
      <div class="header-text">
        <GoldCircleButton
          :circleText="back"
          :circleImage="backImage"
          @circleClick="navigateBack()"
          title="Device list"
        />
        <div class="header-title">
          <span class="title"
          >{{$t('language.editDeviceTitle')}} {{ this.editDevice.device_serial_num }}</span
          >
        </div>
      </div>

      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{$t('language.editDeviceSerialNumber')}}
              <GrayInputField
                type="number"
                :value="this.editDevice.device_serial_num"
                :setter="setPID"
                @input="validateNumericInput($event)"
              />
            </div>
            <div class="edit-row">
              {{$t('language.editDeviceImei')}}
              <GrayInputField
                type="number"
                :value="this.editDevice.device_imei"
                :setter="setIMEI"
                @input="validateNumericInput($event)"
              />
            </div>
            <!--
                    <div style="border: 1px solid white; border-radius: 10px; margin: 10px; padding: 10px; width: 30vw;">
            <div class="edit-row">
              Simcard:
              <GrayInputField
                :value="this.editDevice.simcard"
                :setter="setICC"
              />
            </div>-->
            <div class="edit-row">
              {{$t('language.editDeviceSimCard')}}
              <GrayInputField
                type="number"
                :value="this.editDevice.simcard_icc"
                :setter="setMessageFrequency"
                @input="validateNumericInput($event)"
              />
            </div>
            <div class="edit-row">
              {{$t('language.editDeviceFV')}}
              <GrayInputField
                :value="this.editDevice.firmware_version"
                :setter="setFw"
              />
            </div>
            <div class="edit-row">
              {{$t('language.editDevicePhysicalID')}}
              <GrayInputField
                :value="this.editDevice.device_physical_id"
                :setter="setPHI"
              />
            </div>
          </div>
          <div class="action-buttons">
            <div>
              <GrayActionButton
                @actionClick="showAlertModal = true"
                :actionImage="actionDeleteImage"
                :text="deleteText"
              />
            </div>
            <div style="display: flex">
              <GrayActionButton
                style="padding-inline: 20px"
                @actionClick="handleSaveClick"
                :actionImage="actionSaveImage"
                :text="saveText"
              />
              <GrayActionButton
                @actionClick="handleCancelClick"
                :actionImage="actionCancel"
                :text="cancelText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="deleteModalText"
    @confirm="handleDeleteClick"
    @cancel="showAlertModal = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "EditDevice",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCancel: "fa-solid fa-xmark",
      createText: "Cancel",
      rallyName: "Nyíregyháza Rally",
      rallyLocation: "Nyíregyháza",
      userPhoneNumber: "+36 30 123 4567",
      deviceSorszam: "",
      devicePID: "",
      deviceIMEI: "",
      cancelText: "",
      back: "",
      deviceICC: "",
      deviceMessageFrequency: "",
      deviceSound: "",
      backImage: "fa-solid fa-arrow-left",
      editDevice: [],
      device_name: undefined,
      device_serial_num: undefined,
      device_imei: undefined,
      simcard: undefined,
      simcard_icc: undefined,
      firmware_version: undefined,
      device_physical_id: undefined,
      device_updated_by: undefined,
      wordDocumentUrl: "../adatvedelem.docx",
      showAlertModal: false,
    };
  },
  created() {
    this.deleteText = this.$t("language.deleteText");
    this.deleteModalText = this.$t("language.deleteDevice");
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.cancelText = this.$t("language.cancelText");
  },
  props: [],
  async mounted() {
    this.device_id = localStorage.getItem("device_id");
    this.editDevice = await dp.getDeviceById(this.device_id);
    this.device_name = this.editDevice.device_name;
    this.device_serial_num = this.editDevice.device_serial_num;
    this.device_imei = this.editDevice.device_imei;
    this.simcard = this.editDevice.simcard;
    this.simcard_icc = this.editDevice.simcard_icc;
    this.firmware_version = this.editDevice.firmware_version;
    this.device_physical_id = this.editDevice.device_physical_id;
  },
  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
      console.log(inputValue);
    },
    async dataPush() {
      await dp.updateDeviceById(
        undefined,
        this.device_serial_num,
        this.device_imei,
        undefined,
        this.simcard_icc,
        this.firmware_version,
        this.device_physical_id,
        this.device_updated_by,
        this.device_id
      );
      this.$router.push("/deviceList");
    },

    async remove() {
      await dp.deleteDeviceById(this.device_id);
      this.$router.push("/deviceList");
    },
    navigateBack() {
      this.$router.push("/device");
    },
    handleDeleteClick() {
      this.remove();
      this.showAlertModal = false;
    },
    handleCancelClick() {
      this.$router.push("/deviceList");
    },
    handleSaveClick() {
      this.dataPush();
    },
    handleCreateClick() {},
    setSorszam(value) {
      this.device_name = value;
    },
    setPID(value) {
      this.device_serial_num = value;
    },
    setIMEI(value) {
      this.device_imei = value;
    },
    setICC(value) {
      this.simcard = value;
    },
    setMessageFrequency(value) {
      this.simcard_icc = value;
    },
    setFw(value) {
      this.firmware_version = value;
    },
    setPHI(value) {
      this.device_physical_id = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 99vw;
  height: 98vh;
  position: absolute !important;
  top: 10px;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 30px;
}

.title {
  color: white;
  font-size: 40px;
  font-family: "Lexend";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
}

.title2 {
  color: white;
  font-size: 20px;
  font-family: "Lexend";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center vertically */
  width: 100%;
  padding-right: 60px;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-right: 60px;
  flex-grow: 1; /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  text-align: left;
}

@media (max-width: 1000px) {
  .header-text {
    padding: 0;
  }
  .edit-row {
    font-size: 14px;
  }
  h1 {
    font-size: 26px;
  }
  .title {
    font-size: 20px;
    padding: 0;
  }
  .title2 {
    font-size: 14px;
    padding: 0;
  }
  .edit-block {
    width: 90vw;
  }
  .action-buttons {
    width: 98%;
  }
}
</style>
