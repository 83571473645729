<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.eventsTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="races-background">
    <div style="flex: 1">
      <span class="large-title-small-device">{{
        $t("language.eventsTitle")
      }}</span>
    </div>
    <div
      v-if="this.role"
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
      "
    >
      <div style="flex: 1">
        <GoldCircleButton
          :circleText="back"
          :circleImage="navigateBack"
          @circleClick="navigateHome()"
        />
      </div>
      <div style="flex: 1">
        <span class="large-title">{{ $t("language.eventsTitle") }}</span>
      </div>
      <div style="flex: 1; display: flex; justify-content: right">
        <GoldCircleButton
          :circleText="newEvent"
          v-if="this.role == 'admin' || this.role == 'staff'"
          :circleImage="newEventImage"
          @circleClick="navigateAddEventPage()"
        />
      </div>
    </div>
    <div class="search-div">
      <input
        class="search-bar"
        v-model="searchQuery"
        :placeholder="searchBar"
      />
      <i class="search-icon" style="color: white" :class="searchImage"></i>
    </div>
    <div id="table-container">
      <div class="col-12 outer-table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="width: 5%;" colspan="1"></th>
              <th
                style="width: 10%"
                colspan="6"
                @click="sortTable('event_name')"
              >
                {{ $t("language.eventName") }}
              </th>
              <th
                style="width: 17%"
                colspan="6"
                @click="sortTable('event_type')"
              >
                {{ $t("language.eventListType") }}
              </th>
              <th
                style="width: 17%"
                colspan="6"
                @click="sortTable('event_start_date')"
              >
                {{ $t("language.eventStart") }}
              </th>
              <th
                style="width: 17%"
                colspan="6"
                @click="sortTable('event_end_date')"
              >
                {{ $t("language.eventEnd") }}
              </th>
              <th
                style="width: 17%"
                colspan="6"
                @click="sortTable('event_created_at')"
              >
                {{ $t("language.eventCreated") }}
              </th>
              <th
                style="width: 17%"
                colspan="6"
                @click="sortTable('event_updated_at')"
              >
                {{ $t("language.eventUpdated") }}
              </th>
              <!-- <th style="width: 17%;" colspan="6">Active</th> -->
            </tr>
          </thead>
          <tbody v-if="this.filteredItems.length > 0">
            <tr
              v-for="(item, index) in this.filteredItems"
              :key="item.event_id"
             
              class="hover-row"
            >
            <td :class="{ 'active-row': item.event_active }" colspan="1">
              <input type="checkbox" v-model="item.checked" :id="'customCheckbox' + index">
              <label :for="'customCheckbox' + index" class="checkbox-label"></label>
            </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ item.event_name }}
              </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ item.event_type }}
              </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ formatDate(item.event_start_date) }}
              </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ formatDate(item.event_end_date) }}
              </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ formatTimeDate(item.event_created_at) }}
              </td>
              <td :class="{ 'active-row': item.event_active }" colspan="6"
              @click="HandleEventClick(item.event_id, item.event_name)">
                {{ formatTimeDate(item.event_updated_at) }}
              </td>
              <!-- <td :class="{'active-row': item.event_active}" colspan="6"> {{ item.event_active ? 'Aktív' : 'Nem Aktív' }}</td> -->
              <!-- <td colspan="6">{{ item.event_active }}</td> -->
              <!--               <td>
                <label class="switch " >
                  <input type="checkbox" id="activeTick" v-model="item.event_active" @change="handleSwitchChange" />
                  <span class="slider"></span>
                </label>
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <td colspan="36" style="text-align: center">
              {{ $t("language.eventSearch") }}
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import dataProvider from "../utils/dataProvider";
import GoldCircleButton from "./GoldCircleButton.vue";
import jwt_decode from "jwt-decode";

const dp = dataProvider();

export default {
  name: "RaceList",
  props: {
    msg: String,
  },
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      eventsArray: [],
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      searchQuery: "",
      racerlistImage: "fa-solid fa-user-astronaut",
      deviceImage: "fa-solid fa-mobile-android",
      usersListImage: "fa-solid fa-user",
      newEventImage: "fa-solid fa-plus",
      racerImage: "fa-solid fa-flag-checkered",
      navigateBack: "fa-solid fa-arrow-left",
      newEvent: "",
      searchBar: "",
      back: "",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      //------------------------------------------sorting-------------------------------------------
      reverseBool: false,
      currentType: "",
      lastSortedType: "",
      // checkbox
      selectedEventRow: [],
    };
  },
  created() {
    this.newEvent = this.$t("language.newEvent");
    this.searchBar = this.$t("language.searchBar");
    this.back = this.$t("language.back");
  },
  computed: {
    filteredItems: function () {
      const searchQuery3 = this.searchQuery.toLowerCase().trim();
      let items = this.eventsArray.filter((item) => {
        if (item.event_name.toLowerCase().includes(searchQuery3)) {
          return true;
        }
        if (item.event_type.toLowerCase().includes(searchQuery3)) {
          return true;
        }

        if (
          new Date(item.event_start_date)
            .toLocaleString()
            .includes(searchQuery3)
        ) {
          return true;
        }
        if (
          new Date(item.event_end_date).toLocaleString().includes(searchQuery3)
        ) {
          return true;
        }
        if (
          new Date(item.event_created_at)
            .toLocaleString()
            .includes(searchQuery3)
        ) {
          return true;
        }
        if (
          new Date(item.event_updated_at)
            .toLocaleString()
            .includes(searchQuery3)
        ) {
          return true;
        }
        return false;
      });
      if (items.length > 1) {
        if (this.lastSortedType === this.currentType) {
          if (this.reverseBool) {
            items = [...items].sort((a, b) => {
              if (isNaN(b[this.currentType] - a[this.currentType])) {
                if (b[this.currentType] > a[this.currentType]) {
                  return 1;
                }
                if (b[this.currentType] < a[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return b[this.currentType] - a[this.currentType];
            });
          } else {
            items = [...items].sort((a, b) => {
              if (isNaN(a[this.currentType] - b[this.currentType])) {
                if (a[this.currentType] > b[this.currentType]) {
                  return 1;
                }
                if (a[this.currentType] < b[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return a[this.currentType] - b[this.currentType];
            });
          }
        } else {
          items.sort((a, b) => {
            if (isNaN(b[this.currentType] - a[this.currentType])) {
              if (a[this.currentType] > b[this.currentType]) {
                return 1;
              }
              if (a[this.currentType] < b[this.currentType]) {
                return -1;
              }
              return 0;
            }
            return a[this.currentType] - b[this.currentType];
          });
        }
      }
      return items;
    },
  },
  async mounted() {
    this.eventsArray = await dp.getEvents();
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  methods: {
    sortTable(type) {
      this.lastSortedType = this.currentType;
      this.currentType = type;

      if (this.lastSortedType === this.currentType) {
        this.reverseBool = !this.reverseBool;
      } else {
        this.reverseBool = false;
      }
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString();
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    HandleEventClick(id, name) {
      localStorage.setItem("event_id", id);
      localStorage.setItem("event_name", name);
      this.$router.push("/legs");
    },
    navigateHome() {
      this.$router.push("/home");
    },
    navigateAddEventPage() {
      this.$router.push("/addevent");
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    /* components: {
      GoldCircleButton,
    }, */
  },
  components: { GoldCircleButton },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.races-background {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

td {
  cursor: pointer;
}
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
.event-button {
  margin-right: 20px;
}

.right-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switch-container {
  flex-shrink: 0;
  margin-left: 0px;
  align-items: center;
  margin-block: 6px;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.table-striped .active-row {
  background-color: #ff9a41;
  opacity: 0.8;
  color: black;
  width: 7%;
}
.table-striped td{
  vertical-align: middle;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.large-title {
  color: white;
  font-size: 50px;
  font-family: "Lexend";
  text-align: center;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  left: 0;
  margin-left: 3.2px;
  margin-top: 3px;
}

input:checked + .slider {
  background-color: #dd9c44;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.outer-table {
  width: 100%;
  height: 65vh;
  background-color: transparent;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}
.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

/* .outer-table::-webkit-scrollbar {
  width: 0;
} */

/* checkbox */
.checkbox-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #7f494900;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkbox-label {
  background-color: #dd9c44;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.708a1 1 0 0 0-1.414-1.414L9 15.165 5.707 11.872a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10z"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
}
.races-title {
  color: white;
  font-size: 30px;
  font-family: "Lexend";
  display: none;
}

.top-tr {
  background-color: red;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";

  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  background-color: #2c3033;
  padding-left: 2%;
  padding-right: 2%;
}

#table-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.search-div {
  width: 100%;
  margin-bottom: 4vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}

.hover-row:hover {
  background-color: #dd9c44;
  color: #2c3033;
}

.hover-row:hover td {
  background-color: #dd9c44;
  color: #2c3033;
}
.hover-row:hover i{
  color: #ffffff;
}

.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}

.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}
#centered-button {
  padding-inline: 10px;
}

.large-title-small-device {
  display: none;
}

@font-face {
  font-family: "Lexend";
  src: url("~@/Fonts/Lexend/static/Lexend-Regular.ttf") format("truetype");
  /* Adjust the path and format as needed */
  font-weight: normal;
  font-style: normal;
}

@media (max-width: 768px) {
  .races-title {
    font-size: 30px;
    padding-left: 0px;
  }

  .large-title-small-device {
    display: block;
    font-size: 20px;
    text-align: center;
    color: white;
    font-family: "Lexend";
  }
  .large-title {
    display: none;
  }

  .event-button {
    margin-right: 0px;
  }

  td {
    font-size: 10px;
  }

  th {
    font-size: 10px;
  }

  .search-bar {
    width: 150px;
  }

  .rally-name {
    font-size: 16px;
  }

  .search-bar {
    border-radius: 10px;
    width: 120px;
    height: 30px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.1em;
  }
}
@media (max-width: 550px) {
  .large-title {
    font-size: 20px;
  }
  .races-title {
    display: block;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    width: 100vw;
    bottom: 0;
  }
}
</style>
