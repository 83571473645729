<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.events") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.addLegTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        padding-inline: 3vw;
      "
    >
      <div class="header-text">
        <div style="flex: 1;">

          <GoldCircleButton
          
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
          />
        </div>

        <div class="header-title">
          <h1>{{ $t("language.addLegTitle") }}</h1>
        </div>
        <div style="display: flex; flex: 1; "></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.legsName") }}
              <GrayInputField :setter="setName" />
            </div>
            <div class="edit-row">
              {{ $t("language.legDate") }}
              <DateInputField :setter="setDate" />
            </div>
            <div class="edit-row">
              {{ $t("language.mapName") }}
              <GrayInputField :setter="setMap" />
            </div>
            <div class="edit-row">
              {{ $t("language.mapFile") }}
              <input
                :setter="setFile"
                class="form-control form-control-sm"
                type="file"
                id="formFile"
                accept=".gpx"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.mapImage") }}
              <input
                :setter="setImg"
                class="form-control form-control-sm"
                type="file"
                id="formImg"
              />
            </div>
          </div>
          <div class="action-buttons">
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
            <GrayActionButton
              @actionClick="navigateBack"
              :actionImage="actionCancel"
              :text="cancelText"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomAlert
      :show="showAlert"
      :body="alertBody"
      @confirm="showAlert = false"
      :showCancelButton="false"
    />
  </div>
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import DateInputField from "@/components/DateInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "AddLeg",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      actionCancel: "fa-solid fa-xmark",
      saveText: "",
      cancelText: "",
      back: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      rallyName: "Nyíregyháza Rally",
      rallyLocation: "Nyíregyháza",
      userPhoneNumber: "+36 30 123 4567",
      backImage: "fa-solid fa-arrow-left",
      leg_name: undefined,
      leg_date: undefined,
      event_id: undefined,
      map_name: undefined,
      map_File: undefined,
      map_Image: undefined,
      wordDocumentUrl: "../adatvedelem.docx",
      showAlert: false,
      alertBody: "",
      legsArray: [],
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.back = this.$t("language.back");
  },
  props: [],

  async mounted() {
    this.eventId = localStorage.getItem("event_id");
    this.legsArray = await dp.getLegByEventId(this.eventId);
  },
  methods: {
    testMethod() {
      if (
        this.legsArray.filter(
          (leg) => leg.leg_date.slice(0, -14) === this.leg_date
        ).length === 0
      ) {
        const slicedArray = this.legsArray.map((leg) =>
          leg.leg_date.slice(0, -14)
        );
        console.log(
          "nincs ilyen dátumú leg",
          this.leg_date,
          this.legsArray,
          slicedArray
        );
      } else {
        const slicedArray = this.legsArray.map((leg) =>
          leg.leg_date.slice(0, -14)
        );
        console.log(
          "van ilyen dátumú leg",
          this.leg_date,
          this.legsArray,
          slicedArray
        );
      }
    },
    async dataPush() {
      if (
        this.leg_name &&
        this.leg_date &&
        this.map_name &&
        document.getElementById("formFile").files[0] &&
        document.getElementById("formImg").files[0]
      ) {
        const formFile = document.getElementById("formFile").files[0];
        if (formFile && !formFile.name.endsWith(".gpx")) {
          this.alertBody = "A fájl nem .gpx kiterjesztésű!";
          this.showAlert = true;
          return;
        } else {
          if (
            this.legsArray.filter(
              (leg) => leg.leg_date.slice(0, -14) === this.leg_date
            ).length === 0
          ) {
            this.map_File = document.getElementById("formFile").files[0];
            this.map_Image = document.getElementById("formImg").files[0];
            try {
              console.log(this.leg_date);
              await dp.addLeg(
                this.leg_name,
                this.leg_date,
                this.eventId,
                this.map_name,
                this.map_File,
                this.map_Image
              );
              this.handleBackClick();
            } catch (error) {
              //valamiért belefut amikor jó akkor is
              console.log(error);
              this.alertBody = error;
              this.showAlert = true;
            }
          } else {
            this.alertBody = "Ilyen dátummal már létezik leg";
            this.showAlert = true;
          }
        }
      } else {
        this.alertBody = "Nincs minden szükséges mező kitöltve!";
        this.showAlert = true;
      }
    },
    handleDeleteClick() {},
    handleSaveClick() {
      this.dataPush();
    },
    navigateBack() {
      this.$router.back();
    },
    handleCreateClick() {},
    handleBackClick() {
      this.$router.push("/legs");
    },
    setName(value) {
      this.leg_name = value;
    },
    setDate(value) {
      this.leg_date = value;
    },
    setMap(value) {
      this.map_name = value;
    },
    setFile(value) {
      this.map_File = value;
    },
    setImg(value) {
      this.map_Image = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    DateInputField,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  height: 0vh;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}

.form-control-sm {
  max-width: 300px;
  min-width: 200px;
}

.costum-input {
  max-width: 100px !important;
  min-width: 100px !important;
  margin-left: 20px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  height: 95vh;
  position: absolute !important;
  top: 20;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 15px;
}

#editleg-row td {
  max-width: 10px !important;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}
h1 {
    font-size: 50px;
  }

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  padding-bottom: 20px;
  text-align: left;
}

@media (max-width: 800px) {
  .edit-block {
    width: 90vw;
  }
}

@media (max-width: 568px) {
  .edit-row {
    font-size: 14px;
  }
  h1 {
    font-size: 26px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 70px;
    width: 100vw;
  }

  .breadcrumb-item {
    font-size: 0.8rem;
  }

  .costum-input {
    max-width: 50px;
  }
}
</style>
