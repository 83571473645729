<template>
  <transition name="slide">
    <div v-if="show" class="toast-alert" @mouseover="pauseTimer" @mouseleave="resumeTimer" style="cursor: pointer;">
      <div class="toast-header">
        <button @click="closeToast" class="close-button"><i class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="toast-body">
        <i class="fa-solid fa-circle-exclamation" style="color: red; font-size: 18px;"></i>
        <p>{{ message }}</p>
      </div>
      <div class="progress-bar-container">
        <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    message: {
      type: String,
      required: true
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    autoCloseTime: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      timer: null,
      progressBarWidth: 100,
      remainingTime: this.autoCloseTime,
      lastUpdateTime: null,
      isPaused: false
    };
  },
  watch: {
    show(newVal) {
      if (newVal && this.autoClose) {
        this.startTimer();
      } else {
        this.resetTimer();
      }
    }
  },
  methods: {
    startTimer() {
      this.lastUpdateTime = Date.now();
      this.timer = setInterval(() => {
        if (!this.isPaused) {
          const currentTime = Date.now();
          const elapsedTime = currentTime - this.lastUpdateTime;
          this.remainingTime -= elapsedTime;
          this.progressBarWidth = (this.remainingTime / this.autoCloseTime) * 100;
          this.lastUpdateTime = currentTime;
          if (this.remainingTime <= 0) {
            this.closeToast();
          }
        }
      }, 100);
    },
    pauseTimer() {
      this.isPaused = true;
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    resumeTimer() {
      this.isPaused = false;
      this.lastUpdateTime = Date.now();
    },
    resetTimer() {
      clearInterval(this.timer);
      this.progressBarWidth = 100;
      this.remainingTime = this.autoCloseTime;
      this.isPaused = false;
    },
    closeToast() {
      this.$emit('update:show', false);
    }
  },
  mounted() {
    if (this.show && this.autoClose) {
      this.startTimer();
    }
  },
  beforeUnmount() {
    this.clearTimer();
  }
}
</script>

<style scoped>
.toast-alert {
  background-color: #2c3033;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(221, 156, 68, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 400px;
  height: 150px;
}

.toast-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.toast-body {
  display: flex;
  align-items: center;
}

.toast-body p {
  margin: 0;
  padding-left: 10px;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.close-button:hover {
  color: red;
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  background-color: #ddd;
  margin-top: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #dd9c44;
  transition: width 0.1s linear;
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease; /* Az animáció időtartama 0.5 másodperc */
}

.slide-enter, .slide-leave-to {
  transform: translateX(130%); /* Kezdő pozíció jobbról */
}

.slide-enter-to, .slide-leave {
  transform: translateX(0%); /* Végső pozíció középen */
}
</style>