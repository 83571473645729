<template>
  <div class="sidebarBtns" id="blackCircleButtons">
    <BlackCircleButton
      id="bc9"
      :blackCircleImage="homeimg"
      @blackCircleClick="navBack(); this.isMapSetting = false"
    />
  </div>

  <div class="spinner-container-big" v-if="this.load">
    <radar-spinner
      :animation-duration="2000"
      :size="100"
      color="#dd9c44"
      id="big-spinner"
    />

    <h1 class="loading-title">{{ $t("language.loadingTitle") }}</h1>
  </div>

  <div class="selected-racer-modal" v-if="this.isSelected">
    <div class="racer-title-row" v-if="this.selectedRacer">
      <h1 class="racer-title">
        {{
          this.selectedRacer.racer_start_num ||
          this.selectedRacer.staff_name.slice(0, 3)
        }}.
        {{
          this.selectedRacer.racer_pilot_name || this.selectedRacer.staff_name
        }}
      </h1>
      <button class="btn btn-primary" id="close-info" @click="closeRacer()">
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
    <div class="actual-racer-table" v-if="this.selectedRacer">
      <table class="table" v-if="this.selectedRacer">
        <thead v-if="this.selectedRacer.racer_id">
          <tr>
            <th scope="col" class="racer-data">Status</th>
            <th scope="col" class="racer-data">Nationality</th>
            <th scope="col" class="racer-data">Co-Pilot</th>
            <th scope="col" class="racer-data">Device ID</th>
          </tr>
        </thead>
        <thead v-if="this.selectedRacer.staff_id">
          <tr>
            <th scope="col" class="racer-data">Name</th>
            <th scope="col" class="racer-data">Phone Number</th>
            <th scope="col" class="racer-data">email</th>
            <th scope="col" class="racer-data">Device ID</th>
          </tr>
        </thead>
        <tbody v-if="this.selectedRacer.racer_id">
          <tr>
            <td class="racer-data">
              {{
                this.selectedRacer.racer_status ||
                this.selectedRacer.staff_status
              }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_pilot_nationality }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_copilot_name }}
            </td>
            <td class="racer-data">{{ this.selectedRacer.device_id }}</td>
          </tr>
        </tbody>
        <tbody v-if="this.selectedRacer.staff_id">
          <tr>
            <td class="racer-data">{{ this.selectedRacer.staff_name }}</td>
            <td class="racer-data">
              {{ this.selectedRacer.staff_phonenum }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.staff_email }}
            </td>
            <td class="racer-data">{{ this.selectedRacer.device_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="spinner-container" v-if="this.loading">
      <radar-spinner
        :animation-duration="2000"
        :size="80"
        color="#dd9c44"
        id="spinner"
      />
    </div>

    <div class="chartDiv1">
      <canvas ref="lineChart" id="chart1"></canvas>
    </div>
    <div class="chartDiv2">
      <canvas ref="lineChart2" id="chart2"></canvas>
    </div>
    <br />
    <div id="buttonWrapper" v-if="this.selectedRacer">
      <button
        v-if="!this.selectedRacer.staff_id"
        class="btn btn-primary"
        id="more-info-button"
        @click="navToRacer()"
      >
        <i class="fa-solid fa-circle-info"></i> More info
      </button>
    </div>
  </div>

  <div class="mapContainerAll">
    <div class="grid-container">
      <div id="projectorMapContainer"></div>
      <div id="projectorMapContainer1"></div>
      <div id="projectorMapContainer2"></div>
      <div id="projectorMapContainer3"></div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LControlZoom } from "leaflet";
import BlackCircleButton from "./BlackCircleButton.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();
const range = process.env.VUE_APP_SNAPPING_RANGE || 15;
import "leaflet/dist/leaflet.css";
import Chart from "chart.js/auto";
import { RadarSpinner } from "epic-spinners";
import { DateTime /* ,Interval  */ } from "luxon";

import mqtt from "mqtt/dist/mqtt";
import "leaflet-rotatedmarker";

const signaling_topic = process.env.VUE_APP_SIGNALING_TOPIC || "signalingLog";
// const frontend_raw_data_topic=process.env.FRONTENDRAWDATA_TOPIC ||"rawData"
// const server_data_topic=process.env.SERVERTODEVICE_TOPIC ||"ddc-rally/serverData"
const frontend_topic = process.env.VUE_APP_FRONTEND_TOPIC || "deviceData";
const topic_prefix = process.env.VUE_APP_TOPIC_PREFIX || ""; //demo/
// const device_data_topic=process.env.DEVICEDATA_TOPIC|| "ddc-rally/deviceData"

export default {
  name: "Map",
  L,
  LMap,
  LTileLayer,
  LControlZoom,
  data() {
    return {
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      logsImg: "fa-solid fa-list",
      stagesImg: "fa-solid fa-route",
      poiImg: "fa-solid fa-location-dot",
      projectModeImg: "fa-solid fa-table-cells-large",
      replayImg: "fa-solid fa-play",
      settingsImg: "fa-solid fa-gears",
      homeimg: "fa-solid fa-arrow-left",
      center: [47.6875, 17.6504],
      mapDiv: undefined,
      mapDiv1: undefined,
      mapDiv2: undefined,
      mapDiv3: undefined,
      baseLayer: undefined,
      switchLayer: 0,
      isDropdownVisible: false,
      legName: undefined,
      legDate: undefined,
      popUpId: 0,
      stages: [],
      pois: [],
      markers: [],
      stageCoords: [],
      loading: true,
      poiTypes: [],
      poiTypeCounter: 0,
      newCenter: undefined,
      polyline: undefined,
      stageLogs: [],
      stageColor: [],
      usersArray: [],
      markerElements: [],
      isMapSetting: false,
      SSSTARTShow: true,
      SSSTOPShow: true,
      FINISHShow: true,
      TMShow: true,
      TFShow: true,
      TCShow: true,
      SZShow: true,
      ServiceShow: true,
      RZShow: true,
      RNEPShow: true,
      RadioShow: true,
      MarshallShow: true,
      HLZShow: true,
      FiretruckShow: true,
      MedicalShow: true,
      WaypointShow: true,
      legId: undefined,
      legMap: [],
      lastDeviceArray: [],

      carMarkerArrayTR: [],
      carMarkerArray2TR: [],

      carMarkerArrayTL: [],
      carMarkerArray2TL: [],

      carMarkerArrayBR: [],
      carMarkerArray2BR: [],

      carMarkerArrayBL: [],
      carMarkerArray2BL: [],

      measureMarker: undefined,
      measureMarkers: [],
      measure: false,
      measureLines: [],
      totalDistance: 0,
      totalDistanceString: "0 m",
      distance: 0,
      distanceArray: [],
      dwell_time: undefined,
      uniqueDistances: [],
      highlightBorder: undefined,
      polyLineArray: [],
      polyLineArray1: [],
      polyLineArray2: [],
      polyLineArray3: [],
      //logArray: [],
      isSelected: false,

      isSearch: false,

      racers: [],
      //filteredRacers: [],

      actualRacerMarker: undefined,
      ActualRacerSpeeds: [],
      ActualRacerGForces: [],
      actualLastData: [],
      modalChartLabels: [],
      selectedRacer: undefined,
      chart: undefined,
      chart2: undefined,
      ///-------------------------------------------------------MQTT-----------------------------------------------------
      connection: {
        protocol: "wss",
        host: "rally.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "", // "/mqtt"
        rejectUnauthorized: false,

        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "rally_frontend_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "",
        password: "",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,

      load: true,

      //------------------------------------------------------------MQTT END------------------------------------------------------------------------------------------
    };
  },
  components: {
    BlackCircleButton,
    RadarSpinner,
  },

  computed: {
    filteredRacers: function () {
      return this.racers.filter((item) => {
        if (item.racer_pilot_name) {
          let racer_pilot_name = item.racer_pilot_name;
          return racer_pilot_name
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        }
        if (item.racer_start_num) {
          let racer_start_num = item.racer_start_num.toString();
          return racer_start_num
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },

  async mounted() {
    this.legId = localStorage.getItem("leg_id");
    this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
    this.stages = await dp.getStageByLegId(this.legId);
    this.pois = await dp.getPOIsByLegId(this.legId);
    this.legMap = await dp.getLegById(this.legId);
    this.legName = this.legMap.leg_name;
    this.legDate = this.legMap.leg_date;
    this.racers = await dp.getRacersByEventId(localStorage.getItem("event_id"));
    this.lastDeviceArray = await dp.getAllLastDeviceLog(this.legId);
    ///this.logArray = await dp.getEveryLogByLegId(this.legId);
    this.initMQTTData();
    this.createMQTTConnection();
    this.doMQTTSubscribe(`${topic_prefix}${frontend_topic}/#`, 0);
    this.doMQTTSubscribe(`${topic_prefix}${signaling_topic}/#`, 0);

    this.setupLeafletMap();
    if (!this.mapDiv) {
      return;
    }
    this.mapDiv.createPane("highlightPane");
    this.mapDiv1.createPane("highlightPane");
    this.mapDiv2.createPane("highlightPane");
    this.mapDiv3.createPane("highlightPane");
    this.mapDiv.getPane("highlightPane").style.zIndex = 399;
    this.mapDiv1.getPane("highlightPane").style.zIndex = 399;
    this.mapDiv2.getPane("highlightPane").style.zIndex = 399;
    this.mapDiv3.getPane("highlightPane").style.zIndex = 399;

    this.load = false;
  },

  methods: {
    async reloadChartData(parsedMessage) {
      if (this.selectedRacer != undefined) {
        let dummyArraySpeed = [...this.actualRacerSpeeds];
        let dummyArrayGforce = [...this.actualRacerGForces];
        let dummyArrayLabel = [...this.modalChartLabels];
        dummyArraySpeed.shift();
        dummyArrayGforce.shift();
        dummyArrayLabel.shift();
        dummyArraySpeed.push(parsedMessage.velocity);
        dummyArrayGforce.push(
          parsedMessage.g_force ? parsedMessage.g_force : 0
        );
        dummyArrayLabel.push(
          DateTime.fromISO(parsedMessage.recorded_at).toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
          })
        );
        await this.renderChart(
          dummyArrayGforce,
          dummyArrayLabel,
          dummyArraySpeed
        );
      }
    },
    activateSearch() {
      this.isSearch = !this.isSearch;
    },

    navToProjector() {
      this.$router.push("/projectorPage");
    },

    async showRacer(item) {
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (item.racer_id == this.lastDeviceArray[i].racer_id) {
          this.mapDiv.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
          this.mapDiv1.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
          this.mapDiv2.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
          this.mapDiv3.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
        }

        this.isSearch = false;
        this.searchValue = "";
      }
    },

    // getDwellTime(stage_id) {
    //   return document.getElementById("dwellTimeInput" + stage_id).value;
    // },
    // getSpeedLimit(stage_id) {
    //   return document.getElementById("speedLimitInput" + stage_id).value;
    // },
    setupLeafletMap: function () {
      const mapParams = (L.MapOptions = {
        zoomControl: false,
        doubleClickZoom: false,
        scrollWheelZoom: true,
        attributionControl: false,
      });
      if (!document.getElementById("projectorMapContainer")) {
        return;
      }
      this.mapDiv = L.map("projectorMapContainer", mapParams).setView(
        this.center,
        13
      );
      this.mapDiv1 = L.map("projectorMapContainer1", mapParams).setView(
        this.center,
        13
      );

      this.mapDiv2 = L.map("projectorMapContainer2", mapParams).setView(
        this.center,
        13
      );
      this.mapDiv3 = L.map("projectorMapContainer3", mapParams).setView(
        this.center,
        13
      );
      const mapContainer = document.getElementById('projectorMapContainer');
      const resizeObserver = new ResizeObserver(() => {
        this.mapDiv.invalidateSize();  
      });
      resizeObserver.observe(mapContainer);

      const mapContainer1 = document.getElementById('projectorMapContainer1');
      const resizeObserver1 = new ResizeObserver(() => {
        this.mapDiv1.invalidateSize();  
      });
      resizeObserver1.observe(mapContainer1);

      const mapContainer2 = document.getElementById('projectorMapContainer2');
      const resizeObserver2 = new ResizeObserver(() => {
        this.mapDiv2.invalidateSize();  
      });
      resizeObserver2.observe(mapContainer2);

      const mapContainer3 = document.getElementById('projectorMapContainer3');
      const resizeObserver3 = new ResizeObserver(() => {
        this.mapDiv3.invalidateSize();  
      });
      resizeObserver3.observe(mapContainer3);
      this.setLightLayer();

      //-----------------------------------STAGE HANDLER------------------------------------------
      for (let i = 0; i < this.stages.length; i++) {
        let stageCoordData = JSON.parse(this.stages[i].stage_line);

        let latlngs = stageCoordData.coordinates;

        this.newCenter = latlngs[1];
        let color = undefined;
        if (this.stageLogs[i].stage_status == "normal") {
          color = "green";
        }
        if (this.stageLogs[i].stage_status == "red flag") {
          color = "red";
        }
        if (this.stageLogs[i].stage_status.includes("limit")) {
          color = "yellow";
        } else if (this.stageLogs[i].stage_status == "transport") {
          color = "blue";
        }
        let polyline = L.polyline(latlngs, {
          color: color,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv);
        this.polyLineArray.push(polyline);

        let polyline1 = L.polyline(latlngs, {
          color: color,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv1);
        this.polyLineArray1.push(polyline1);

        let polyline2 = L.polyline(latlngs, {
          color: color,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv2);
        this.polyLineArray2.push(polyline2);

        let polyline3 = L.polyline(latlngs, {
          color: color,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv3);
        this.polyLineArray3.push(polyline3);
      }
      this.mapDiv.setView(this.newCenter);
      this.mapDiv1.setView(this.newCenter);
      this.mapDiv2.setView(this.newCenter);
      this.mapDiv3.setView(this.newCenter);

      this.poiHandler();
      this.carMarkerHandler();

      //---------------------------------MAP CONTROLS-----------------------------------------------
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv);

      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv1);

      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv2);

      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv3);
    },
    /* changeMeasure() {
      event.stopPropagation();
      this.measure = !this.measure;
      if (this.measure == true) {
        this.mapDiv.on("click", this.measureDistance);
        this.mapDiv.on("click", this.calculateTotalDistance);
        var currentZoom = this.mapDiv.getZoom();
        this.mapDiv.setMaxZoom(currentZoom);
        this.mapDiv.setMinZoom(currentZoom);
      } else if (this.measure == false) {
        this.mapDiv.off("click", this.calculateTotalDistance);
        this.mapDiv.off("click", this.measureDistance);

        for (let i = 0; i < this.measureMarkers.length; i++) {
          this.mapDiv.removeLayer(this.measureMarkers[i]);
        }
        for (let i = 0; i < this.measureLines.length; i++) {
          this.mapDiv.removeLayer(this.measureLines[i]);
        }

        this.measureLines = [];
        this.measureMarkers = [];
        this.totalDistance = 0;
        this.totalDistanceString = "0 m";
        this.uniqueDistances = [];
        this.distanceArray = [];
        this.mapDiv.setMaxZoom(22);
        this.mapDiv.setMinZoom(1);
      }
    }, */

    // async searchRacer() {

    //   this.isSearch = !this.isSearch;

    //   this.racers = await dp.getRacersByEventId(
    //     localStorage.getItem("event_id")
    //   );

    //   for(let i = 0; i < this.racers.length; i++){
    //     if((this.racers[i].racer_pilot_name).includes(this.searchValue)){
    //       this.filteredRacers.push(this.racers[i]);
    //     }
    //     else if(this.searchValue == ""){
    //       this.filteredRacers = this.racers;
    //     }
    //   }
    // },

   /*  measureDistance(e) {
      const haversine = require("haversine");
      const { lat, lng } = e.latlng;
      let measureIcon = L.icon({
        iconUrl: "measureDot.png",
        iconSize: [15, 15],
      });
      this.measureMarker = L.marker([lat, lng], { icon: measureIcon }).addTo(
        this.mapDiv
      );
      this.measureMarkers.push(this.measureMarker);

      for (let i = 1; i < this.measureMarkers.length; i++) {
        let start = {
          latitude: this.measureMarkers[i - 1]._latlng.lat,
          longitude: this.measureMarkers[i - 1]._latlng.lng,
        };
        let end = {
          latitude: this.measureMarkers[i]._latlng.lat,
          longitude: this.measureMarkers[i]._latlng.lng,
        };
        this.distance = 0;
        this.distance = haversine(start, end, { unit: "meter" });

        this.distanceArray.push(this.distance);

        let distanceString = this.distance.toString().slice(0, 8) + " m";
        this.measureMarker.bindTooltip(distanceString).openTooltip();

        let polyLine = L.polyline([
          [
            this.measureMarkers[i - 1]._latlng.lat,
            this.measureMarkers[i - 1]._latlng.lng,
          ],
          [
            this.measureMarkers[i]._latlng.lat,
            this.measureMarkers[i]._latlng.lng,
          ],
        ])
          .setStyle({ color: "#dd9c44" })
          .addTo(this.mapDiv);

        this.measureLines.push(polyLine);
      }
    }, */

    /* async calculateTotalDistance() {
      let uniqueSet = new Set(this.distanceArray);
      this.uniqueDistances = Array.from(uniqueSet);

      let length = this.uniqueDistances.length;

      if (length > 0) {
        this.totalDistance += this.uniqueDistances[length - 1];
      }

      this.totalDistanceString =
        this.totalDistance.toString().slice(0, 8) + " m";
    },
 */
    poiHandler() {
      for (let i = 0; i < this.pois.length; i++) {
        let marker = [
          Number(this.pois[i].poi_latitude),
          Number(this.pois[i].poi_longitude),
        ];
        this.markers.push(marker);
      }
      for (let i = 0; i < this.markers.length; i++) {
        let poiIcon = L.icon({
          iconUrl: "icons/" + this.pois[i].poi_type.toLowerCase() + ".png",
          iconSize: [25, 25],
        });
        this.poiTypeCounter = 0;
        for (let j = 0; j < this.poiTypes.length; j++) {
          if (
            this.poiTypes[j].toLowerCase() ==
            this.pois[i].poi_type.toLowerCase()
          ) {
            this.poiTypeCounter++;
          }
        }
        if (this.poiTypeCounter == 0) {
          this.poiTypes.push(this.pois[i].poi_type.toLowerCase());
        }
        let markerElement = L.marker(this.markers[i], {
          icon: poiIcon,
          style: "border-radius= 25px",
          title: `name: ${this.pois[i].poi_name}, \ntype: ${this.pois[
            i
          ].poi_type.toLowerCase()}, \ndescription: ${
            this.pois[i].poi_description
          },`,
        }).addTo(this.mapDiv);

        L.marker(this.markers[i], {
          icon: poiIcon,
          style: "border-radius= 25px",
          title: `name: ${this.pois[i].poi_name}, \ntype: ${this.pois[
            i
          ].poi_type.toLowerCase()}, \ndescription: ${
            this.pois[i].poi_description
          },`,
        }).addTo(this.mapDiv1);

        L.marker(this.markers[i], {
          icon: poiIcon,
          style: "border-radius= 25px",
          title: `name: ${this.pois[i].poi_name}, \ntype: ${this.pois[
            i
          ].poi_type.toLowerCase()}, \ndescription: ${
            this.pois[i].poi_description
          },`,
        }).addTo(this.mapDiv2);

        L.marker(this.markers[i], {
          icon: poiIcon,
          style: "border-radius= 25px",
          title: `name: ${this.pois[i].poi_name}, \ntype: ${this.pois[
            i
          ].poi_type.toLowerCase()}, \ndescription: ${
            this.pois[i].poi_description
          },`,
        }).addTo(this.mapDiv3);
        this.markerElements.push(markerElement);
      }
    },

    async carMarkerHandler() {
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        let marker = [
          Number(this.lastDeviceArray[i].latitude),
          Number(this.lastDeviceArray[i].longitude),
        ];

        let status = this.lastDeviceArray[i].racer_status;
        let isTransporting = false;
        if (this.lastDeviceArray[i].racer_status === "SOS") {
          status = "SOS";
        }else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                }  else {
          for (let index = 0; index < this.stages.length; index++) {
            if (
              this.stageLogs[index]?.stage_id ==
                this.lastDeviceArray[i].closest_stage &&
              this.stageLogs[index].stage_status == "transport"
            ) {
              isTransporting = true;
              if (
                this.lastDeviceArray[i].velocity >= 0 &&
                this.lastDeviceArray[i].velocity <= 10
              ) {
                status = "stoppedTransport";
              } else {
                status = "movingTransport";
              }
              break;
            }
          }
          if (!isTransporting) {
            status = this.lastDeviceArray[i].racer_status;
          }
        }
        let iconColor;
        switch (status) {
          case "normal":
            iconColor = "black";
            break;
          case "SOS":
            iconColor = "red";
            break;
          case "OK":
            iconColor = "green";
            break;
          case "stoppedTransport":
            iconColor = "grey";
            break;
          case "movingTransport":
            iconColor = "white";
            break;
          case "stopped":
            iconColor = "yellow";
            break;
          default:
            iconColor = "black";
            break;
        }
        let carIcon = L.icon({
          iconUrl: "icons/carIcons/" + iconColor + ".webp",
          iconAnchor: [15, 15],
          iconSize: [30, 30],
          rotationAngle: this.lastDeviceArray[i].bearing,
          rotationOrigin: "center",
        });
        let carIcon2;
        if (this.lastDeviceArray[i].staff_id) {
          carIcon2 = L.divIcon({
            iconAnchor: [8, 10],
            iconSize: [20],
            startnum: this.lastDeviceArray[i].staff_start_num.slice(0, 3),
            staff_id: this.lastDeviceArray[i].staff_id,

            className: "my-div-icon",
            html:
              '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
              "</b>",
          });
        } else {
          carIcon2 = L.divIcon({
            iconAnchor: [8, 10],
            iconSize: [20],
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,

            className: "my-div-icon",
            html:
              '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          });
        }

        let Marker = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon,
        }).addTo(this.mapDiv);
        let Marker01 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon,
        }).addTo(this.mapDiv1);
        let Marker02 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon,
        }).addTo(this.mapDiv2);
        let Marker03 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon,
        }).addTo(this.mapDiv3);

        let Marker2 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon2,
        })
          .addTo(this.mapDiv)
          .on("click", this.getActualRacerData);

        let Marker21 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon2,
        })
          .addTo(this.mapDiv1)
          .on("click", this.getActualRacerData);

        let Marker22 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon2,
        })
          .addTo(this.mapDiv2)
          .on("click", this.getActualRacerData);

        let Marker23 = L.marker(marker, {
          autoPanOnFocus: false,
          icon: carIcon2,
        })
          .addTo(this.mapDiv3)
          .on("click", this.getActualRacerData);

        this.carMarkerArrayTL.push(Marker);
        this.carMarkerArray2TL.push(Marker2);

        this.carMarkerArrayTR.push(Marker01);
        this.carMarkerArray2TR.push(Marker21);

        this.carMarkerArrayBL.push(Marker02);
        this.carMarkerArray2BL.push(Marker22);

        this.carMarkerArrayBR.push(Marker03);
        this.carMarkerArray2BR.push(Marker23);
      }
    },

    /* async renderChart(dummyArrayGforce, dummyArrayLabel, dummyArraySpeed) {
      console.log("renderchart");
      this.actualRacerGForces = [...dummyArrayGforce];
      this.actualRacerSpeeds = [...dummyArraySpeed];
      this.modalChartLabels = [...dummyArrayLabel];

      if (this.chart != undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      if (this.chart2 != undefined) {
        this.chart2.destroy();
        this.chart2 = undefined;
      }
      if (this.$refs.lineChart != undefined) {
        const ctx1 = await this.$refs.lineChart.getContext("2d");
        this.chart = new Chart(ctx1, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: "Speed",
                data: this.actualRacerSpeeds,
                backgroundColor: "rgb(90,145,101, 0.5)",
                borderColor: "#5A9165",
                fill: true,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            labels: {
              fontColor: "white",
            },
            animation: {
              duration: 0,
            },
          },
        });
      }

      if (this.$refs.lineChart2 != undefined) {
        const ctx2 = await this.$refs.lineChart2.getContext("2d");
        this.chart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: "G-force",
                data: this.actualRacerGForces,
                backgroundColor: "rgb(255, 217, 100, 0.5)",
                borderColor: "#DD9C44",
                fill: true,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
          },
        });
      }
    }, */
    async renderChart(dummyArrayGforce, dummyArrayLabel, dummyArraySpeed) {
      let combinedData = dummyArrayLabel.map((label, index) => ({
          label: DateTime.fromFormat(label, "HH:mm:ss"), 
          gforce: dummyArrayGforce[index],
          speed: dummyArraySpeed[index],
      }));

      combinedData.sort((a, b) => a.label - b.label);

      this.actualRacerGForces = combinedData.map(item => item.gforce);
      this.actualRacerSpeeds = combinedData.map(item => item.speed);
      this.modalChartLabels = combinedData.map(item => item.label.toFormat("HH:mm:ss"));

      if (this.chart != undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      if (this.chart2 != undefined) {
        this.chart2.destroy();
        this.chart2 = undefined;
      }

      if (this.$refs.lineChart != undefined) {
        const ctx1 = await this.$refs.lineChart.getContext("2d");

        let translatedChartSpeed = this.$t("language.chartSpeed");

        this.chart = new Chart(ctx1, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartSpeed,
                fill: true,
                data: this.actualRacerSpeeds,
                backgroundColor: "rgb(90,145,101, 0.5)",
                borderColor: "#5A9165",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            labels: {
              fontColor: "white",
            },
            animation: {
              duration: 0,
            },
          },
        });
      }

      if (this.$refs.lineChart2 != undefined) {
        const ctx2 = await this.$refs.lineChart2.getContext("2d");
        let translatedChartG = this.$t("language.chartG");
        this.chart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartG,
                data: this.actualRacerGForces,
                backgroundColor: "rgb(255, 217, 100, 0.5)",
                borderColor: "#DD9C44",
                fill: true,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
          },
        });
      }
    },


    closeRacer() {
      this.isSelected = false;
    },

    navToRacer() {
      localStorage.setItem("racer_id", this.selectedRacer.racer_id);
      this.$router.push("/racerinfo");
    },

    async getActualRacerData(marker) {
      this.loading = true;
      this.isSelected = !this.isSelected;
      this.isSelected = true;
      this.actualRacerGForces = [];
      this.actualRacerSpeeds = [];
      this.modalChartLabels = [];
      console.log("MARKER");
      try {
        //TODO le kell kezelnin azt is ha staff
        // console.log(this.lastDeviceArray);
        for (let i = 0; i < this.lastDeviceArray.length; i++) {
          if (this.lastDeviceArray[i].racer_start_num) {
            if (
              marker.sourceTarget.options.icon.options.startnum ==
              this.lastDeviceArray[i].racer_start_num
            ) {
              console.log(
                this.lastDeviceArray[i].racer_id,
                10,
                this.lastDeviceArray[i].staff_id
              );
              this.actualLastData = await dp.getDeviceLogByRacerIdLimit(
                this.lastDeviceArray[i].racer_id,
                10,
                this.lastDeviceArray[i].staff_id
              );
              this.selectedRacer = await dp.getRacersById(
                this.lastDeviceArray[i].racer_id
              );
            }
          } else if (this.lastDeviceArray[i].staff_start_num) {
            if (
              marker.sourceTarget.options.icon.options.startnum ==
              this.lastDeviceArray[i].staff_start_num.slice(0, 3)
            ) {
              this.actualLastData = await dp.getDeviceLogByRacerIdLimit(
                this.lastDeviceArray[i].racer_id,
                10,
                this.lastDeviceArray[i].staff_id
              );
              this.selectedRacer = await dp.getStaffById(
                this.lastDeviceArray[i].staff_id
              );
            }
          }
        }
        this.loading = false;
        let dummyArrayGforce = [];
        let dummyArrayLabel = [];
        let dummyArraySpeed = [];
        console.log(this.actualLastData.length);
        for (let i = 0; i < this.actualLastData.length; i++) {
          dummyArraySpeed.push(this.actualLastData[i].velocity);
          dummyArrayGforce.push(this.actualLastData[i].g_force);
          dummyArrayLabel.push(
            DateTime.fromISO(this.actualLastData[i].recorded_at).toLocaleString(
              {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hourCycle: "h23",
              }
            )
          );
        }
        //console.log(dummyArrayGforce, dummyArrayLabel, dummyArraySpeed);
        await this.renderChart(
          dummyArrayGforce,
          dummyArrayLabel,
          dummyArraySpeed
        );
      } catch (error) {
        console.log(error);
      }
    },

    poiFilter(item) {
      for (let i = 0; i < this.markerElements.length; i++) {
        let itemString = "icons/" + item + ".png";
        let variableString = item.toString().replace("-", "") + "Show";
        if (this.markerElements[i].options.icon.options.iconUrl == itemString) {
          if (this[variableString] == true) {
            this.mapDiv.removeLayer(this.markerElements[i]);
            this[variableString] = false;
          } else {
            this.markerElements[i].addTo(this.mapDiv);
            this[variableString] = true;
          }
        }
      }
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    navBack() {
      localStorage.setItem("racer_id", undefined);
      this.$router.go(-1);
    },
    toggleDropDown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    logUserOut() {
      localStorage.removeItem("user_id");
      this.$router.push("/");
    },
    setLightLayer() {
      document.getElementById("projectorMapContainer").style.backgroundColor =
        "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
      document.getElementById("projectorMapContainer1").style.backgroundColor =
        "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv1);
      document.getElementById("projectorMapContainer2").style.backgroundColor =
        "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv2);
      document.getElementById("projectorMapContainer3").style.backgroundColor =
        "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv3);
      // this.baseLayer.addTo(this.mapDiv2);
      // this.baseLayer.addTo(this.mapDiv3);
    },

    /* setMapView(index) {
      let stageCoordData = JSON.parse(this.stages[index].stage_line);

      let latlngs = stageCoordData.coordinates;

      if (this.highlightBorder != undefined) {
        this.mapDiv.removeLayer(this.highlightBorder);
        this.mapDiv1.removeLayer(this.highlightBorder);
        this.mapDiv2.removeLayer(this.highlightBorder);
        this.mapDiv3.removeLayer(this.highlightBorder);
      }

      let middleCoord = latlngs.length / 2;
      middleCoord = parseInt(middleCoord);

      this.mapDiv.setView(
        [latlngs[middleCoord][0], latlngs[middleCoord][1]],
        15
      );
      this.mapDiv1.setView(
        [latlngs[middleCoord][0], latlngs[middleCoord][1]],
        15
      );
      this.mapDiv2.setView(
        [latlngs[middleCoord][0], latlngs[middleCoord][1]],
        15
      );
      // this.mapDiv3.setView(
      //   [latlngs[middleCoord][0], latlngs[middleCoord][1]],
      //   15
      // );

      this.highlightBorder = L.polyline(latlngs, {
        color: "black",
        weight: "7",
        pane: "highlightPane",
      }).addTo(this.mapDiv);
    }, */

    //--------------------------------MQTT FUNCTIONS----------------------------------
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleMQTTOnReConnect() {
      console.log("Mqtt reconnecting");
      /* 
      if (this.retryTimes > 10) {
        try {
          this.client.end();
          this.initMQTTData();
          this.client.reconnect()

        } catch (error) {
          console.log(error);
        }
      } */
    },
    createMQTTConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleMQTTOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
          });
          this.client.on("message", (topic, message) => {
            if (topic.includes(frontend_topic)) {
              let parsedMessage = JSON.parse(message);
              parsedMessage.snapped_coord = parsedMessage.snapped_coord.slice(
                6,
                -1
              );
              let coordinates = parsedMessage.snapped_coord.split(" ");
              parsedMessage.snapped_coord = {};
              parsedMessage.snapped_coord.coordinates = [];
              parsedMessage.snapped_coord.coordinates[0] = parseFloat(
                coordinates[0]
              );
              parsedMessage.snapped_coord.coordinates[1] = parseFloat(
                coordinates[1]
              );
              let racerIndex = -1;
              for (
                let index = 0;
                index < this.lastDeviceArray.length;
                index++
              ) {
                if (
                  this.lastDeviceArray[index].racer_id ==
                    parsedMessage.racer_id ||
                  this.lastDeviceArray[index].staff_id == parsedMessage.staff_id
                ) {
                  racerIndex = index;
                  break;
                }
              }
              if (racerIndex != -1) {
                // Updating Velocity
                this.lastDeviceArray[racerIndex] = {
                  ...this.lastDeviceArray[racerIndex],
                  ...parsedMessage,
                };
                // Updating Status and changing color
                let status = parsedMessage.racer_status;
                let isTransporting = false;
                if (parsedMessage.racer_status === "SOS") {
                  status = "SOS";
                } else if (parsedMessage.racer_status === "stopped") {
                  status = "stopped";
                }else {
                  for (let index = 0; index < this.stages.length; index++) {
                    if (
                      this.stageLogs[index]?.stage_id ==
                        parsedMessage.closest_stage &&
                      this.stageLogs[index].stage_status == "transport"
                    ) {
                      isTransporting = true;
                      if (
                        parsedMessage.velocity >= 0 &&
                        parsedMessage.velocity <= 10
                      ) {
                        status = "stoppedTransport";
                      } else {
                        status = "movingTransport";
                      }
                      break;
                    }
                  }
                  if (!isTransporting) {
                    status = parsedMessage.racer_status;
                  }
                }
                let iconColor;
                switch (status) {
                  case "normal":
                    iconColor = "black";
                    break;
                  case "SOS":
                    iconColor = "red";
                    break;
                  case "OK":
                    iconColor = "green";
                    break;
                  case "stoppedTransport":
                    iconColor = "grey";
                    break;
                  case "movingTransport":
                    iconColor = "white";
                    break;
                  case "stopped":
                    iconColor = "yellow";
                    break;
                  default:
                    iconColor = "black";
                    break;
                }

                let vehicleIcon = L.icon({
                  iconUrl: `icons/carIcons/${iconColor}.webp`,
                  racerStatus: parsedMessage.racer_status,
                  iconAnchor: this.toggleIconSize ? [20, 20] : [15, 15],
                  iconSize: [
                    this.toggleIconSize ? 45 : 30,
                    this.toggleIconSize ? 45 : 30,
                  ],
                  rotationAngle: parsedMessage.bearing,
                  rotationOrigin: "center",
                });
                let vehicleIcon2;
                if (this.lastDeviceArray[racerIndex].staff_id) {
                  vehicleIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    startnum: this.lastDeviceArray[
                      racerIndex
                    ].staff_start_num.slice(0, 3),
                    staff_id: this.lastDeviceArray[racerIndex].staff_id,

                    className: "my-div-icon",
                    html:
                      '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[racerIndex].staff_start_num.slice(
                        0,
                        3
                      ) +
                      "</b>",
                  });
                } else {
                  vehicleIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    startnum: this.lastDeviceArray[racerIndex].racer_start_num,
                    racer_id: this.lastDeviceArray[racerIndex].racer_id,

                    className: "my-div-icon",
                    html:
                      '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[racerIndex].racer_start_num +
                      "</b>",
                  });
                }
                // Refrehing Markers if they change in ssome attribute, like RotationAngle, ID, Coordinates, Status
                if (
                  this.carMarkerArrayTR[racerIndex].options.rotationAngle !=
                    parsedMessage.bearing ||
                  this.carMarkerArrayTR[racerIndex].options.id !=
                    parsedMessage.racer_id ||
                  (this.carMarkerArrayTR[racerIndex]._latlng.lat !=
                    parsedMessage.latitude &&
                    this.carMarkerArrayTR[racerIndex]._latlng.lat !=
                      parsedMessage.snapped_coord.coordinates[0]) ||
                  (this.carMarkerArrayTR[racerIndex]._latlng.lng !=
                    parsedMessage.longitude &&
                    this.carMarkerArrayTR[racerIndex]._latlng.lng !=
                      parsedMessage.snapped_coord.coordinates[1])
                ) {
                  //Updating RotationAngle on marker
                  this.carMarkerArrayTR[racerIndex].options.rotationAngle =
                    parsedMessage.bearing;

                  this.carMarkerArrayTL[racerIndex].options.rotationAngle =
                    parsedMessage.bearing;

                  this.carMarkerArrayBL[racerIndex].options.rotationAngle =
                    parsedMessage.bearing;

                  this.carMarkerArrayBR[racerIndex].options.rotationAngle =
                    parsedMessage.bearing;
                  // Updating LatLong on marker
                  this.carMarkerArrayTR[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArrayTL[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArrayBR[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArrayBL[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  // Updating Icon on marker
                  this.carMarkerArrayTR[racerIndex].setIcon(vehicleIcon);
                  this.carMarkerArrayTL[racerIndex].setIcon(vehicleIcon);
                  this.carMarkerArrayBR[racerIndex].setIcon(vehicleIcon);
                  this.carMarkerArrayBL[racerIndex].setIcon(vehicleIcon);

                  // Updating id on marker
                  this.carMarkerArrayTR[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArrayTL[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArrayBR[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArrayBL[racerIndex].options.id =
                    parsedMessage.racer_id;

                  // Updating marker LATLNG on number marker
                  this.carMarkerArray2TR[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArray2TL[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArray2BR[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  this.carMarkerArray2BL[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  //updating Icon on number marker
                  this.carMarkerArray2TR[racerIndex].setIcon(vehicleIcon2);
                  this.carMarkerArray2TL[racerIndex].setIcon(vehicleIcon2);
                  this.carMarkerArray2BR[racerIndex].setIcon(vehicleIcon2);
                  this.carMarkerArray2BL[racerIndex].setIcon(vehicleIcon2);

                  //updating ID on number marker
                  this.carMarkerArray2TR[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArray2TL[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArray2BR[racerIndex].options.id =
                    parsedMessage.racer_id;
                  this.carMarkerArray2BL[racerIndex].options.id =
                    parsedMessage.racer_id;
                  if (this.chart) {
                    // ha rossz kell bele ez: && this.selectedRacer
                    if (
                      (this.selectedRacer.racer_id ==
                        this.lastDeviceArray[racerIndex].racer_id &&
                        this.selectedRacer.racer_id != undefined) ||
                      (this.selectedRacer.staff_id ==
                        this.lastDeviceArray[racerIndex].staff_id &&
                        this.selectedRacer.staff_id != undefined)
                    ) {
                      this.reloadChartData(parsedMessage);
                    }
                  }
                }
              } else {
                //ha nincs még marker
                this.lastDeviceArray.push(parsedMessage);
                let i = this.lastDeviceArray.length - 1;
                let marker = [
                  parseFloat(this.lastDeviceArray[i].latitude),
                  parseFloat(this.lastDeviceArray[i].longitude),
                ];
                let status =
                  this.lastDeviceArray[i].racer_status ||
                  this.lastDeviceArray[i].staff_status;
                let isTransporting = false;
                if (this.lastDeviceArray[i].racer_status === "SOS") {
                  status = "SOS";
                } else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                }else {
                  for (let index = 0; index < this.stages.length; index++) {
                    if (
                      this.stageLogs[index]?.stage_id ==
                        this.lastDeviceArray[i].closest_stage &&
                      this.stageLogs[index].stage_status == "transport"
                    ) {
                      isTransporting = true;
                      if (
                        this.lastDeviceArray[i].velocity >= 0 &&
                        this.lastDeviceArray[i].velocity <= 10
                      ) {
                        status = "stoppedTransport";
                      } else {
                        status = "movingTransport";
                      }
                      break;
                    }
                  }
                  if (!isTransporting) {
                    status =
                      this.lastDeviceArray[i].racer_status ||
                      this.lastDeviceArray[i].staff_status;
                  }
                }
                let iconColor;
                switch (status) {
                  case "normal":
                    iconColor = "black";
                    break;
                  case "SOS":
                    iconColor = "red";
                    break;
                  case "OK":
                    iconColor = "green";
                    break;
                  case "stoppedTransport":
                    iconColor = "grey";
                    break;
                  case "movingTransport":
                    iconColor = "white";
                    break;
                  case "stopped":
                    iconColor = "yellow";
                    break;
                  default:
                    iconColor = "black";
                    break;
                }
                let carIcon = L.icon({
                  iconUrl: `icons/carIcons/${iconColor}.webp`,
                  iconAnchor: this.toggleIconSize ? [20, 20] : [15, 15],
                  iconSize: this.toggleIconSize ? [45, 45] : [30, 30],
                  rotationAngle: this.lastDeviceArray[i].bearing,
                  rotationOrigin: "center",
                  startnum: this.lastDeviceArray[i].racer_start_num,
                  racer_id: this.lastDeviceArray[i].racer_id,
                  racerStatus: this.lastDeviceArray[i].racer_status,
                });

                let carIcon2;
                if (this.lastDeviceArray[i].staff_id) {
                  carIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    startnum: this.lastDeviceArray[i].staff_start_num.slice(
                      0,
                      3
                    ),
                    staff_id: this.lastDeviceArray[i].staff_id,

                    className: "my-div-icon",
                    html:
                      '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[i].racer_start_num +
                      "</b>",
                  });
                } else {
                  carIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    startnum: this.lastDeviceArray[i].racer_start_num,
                    racer_id: this.lastDeviceArray[i].racer_id,

                    className: "my-div-icon",
                    html:
                      '<b  style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[i].racer_start_num +
                      "</b>",
                  });
                }

                let Marker = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon,
                }).addTo(this.mapDiv);
                let Marker01 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon,
                }).addTo(this.mapDiv1);
                let Marker02 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon,
                }).addTo(this.mapDiv2);
                let Marker03 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon,
                }).addTo(this.mapDiv3);

                let Marker2 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon2,
                })
                  .addTo(this.mapDiv)
                  .on("click", this.getActualRacerData);

                let Marker21 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon2,
                })
                  .addTo(this.mapDiv1)
                  .on("click", this.getActualRacerData);

                let Marker22 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon2,
                })
                  .addTo(this.mapDiv2)
                  .on("click", this.getActualRacerData);

                let Marker23 = L.marker(marker, {
                  autoPanOnFocus: false,
                  icon: carIcon2,
                })
                  .addTo(this.mapDiv3)
                  .on("click", this.getActualRacerData);

                this.carMarkerArrayTL.push(Marker);
                this.carMarkerArray2TL.push(Marker2);

                this.carMarkerArrayTR.push(Marker01);
                this.carMarkerArray2TR.push(Marker21);

                this.carMarkerArrayBL.push(Marker02);
                this.carMarkerArray2BL.push(Marker22);

                this.carMarkerArrayBR.push(Marker03);
                this.carMarkerArray2BR.push(Marker23);
              }
            }
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
      }
    },

    doMQTTSubscribe(topic, qos) {
      //const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
      });
    },
    doMQTTUnSubscribe(topic) {
      //const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        }
      });
    },
    doMQTTPublish(topic, payload, qos) {
      //const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    //---------------------------------------------------------MQTT ends here----------------------------------------
  },

  beforeUnmounted() {
    /* if (this.mapDiv && this.mapDiv1 && this.mapDiv2 && this.mapDiv3) {
      this.mapDiv1.remove();
      this.mapDiv2.remove();
      this.mapDiv3.remove();
      this.mapDiv.remove();
    } */
    if (this.mapDiv1 && this.mapDiv1.remove) {
      this.mapDiv1.off();
      this.mapDiv1.remove();
    }
    if (this.mapDiv2 && this.mapDiv2.remove) {
      this.mapDiv2.off();
      this.mapDiv2.remove();
    }
    if (this.mapDiv3 && this.mapDiv3.remove) {
      this.mapDiv3.off();
      this.mapDiv3.remove();
    }
    if (this.mapDiv && this.mapDiv.remove) {
      this.mapDiv.off();
      this.mapDiv.remove();
    }

    this.mapDiv = undefined;
  },
};
</script>

<style lang="scss" scoped>
.fa-arrow-left,
.fa-2xl {
  font-size: 2rem;
}
.form-check-input {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.form-check-input:checked {
  background-color: orange;
  border-color: orange;
}

.spinner-container-big {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(32, 37, 40, 0.1);
}

#big-spinner {
  margin: 0 auto;
  height: auto;
  position: relative;
  width: auto;
  top: 40%;
}

.mapOption-popup {
  height: 15vh;
  // min-height: 135px;
  width: 30vw;
  // min-width: 490px;
  background-color: #202528;
  border-radius: 10px;
  float: left;
  margin-top: 1%;
  margin-left: 2vw;
  z-index: 1100;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
}

#projectorPage {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  bottom: -10vh;
}
#liveMapPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
}
#livemappage::-webkit-scrollbar {
  display: none; /* Chrome, Safari, és Edge */
}

.mapOptionFlexBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backgroundOptionImg {
  display: flex;
  width: 8vw;
  height: 14vh;
  border-radius: 7.5%;
  // min-width: 153px;
  // min-height: 125px;
  margin-top: 4px;
  // margin-bottom: 10px;
}

#top-bar {
  position: relative;
  display: flex;
  margin: auto;
  z-index: 1010;
  visibility: visible;
  margin-top: 0;
  width: auto;
  height: 10.5%;
  background-color: rgb(73, 72, 72);
  color: #fff;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 2px solid white;
  padding: 8px;
  text-align: center;
  font-family: "Lexend";
  border-bottom: 1px solid white;
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid white;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
  cursor: pointer;
}

.checkboxRow {
  width: 100%;
  text-align: left;
}

.cross {
  background-color: transparent;
  border: 0;
  height: 30px;
}

.cross:hover {
  color: #dd9c44;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vh;
  padding: 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.outer-table {
  width: 94%;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* Enable vertical scrolling */
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

h1 {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;
}

h2 {
  font-size: 15px;
  font-family: "Lexend";
  color: #fff;
}

.back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dd9c44;
  border-radius: 50%;
  border-color: transparent;
  height: 35px;
  width: 35px;
}

.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dd9c44;
  border-radius: 10px;
  border-color: transparent;
  height: 3vh;
  width: auto;
}

.chartDiv2 {
  position: relative;
  width: 100%;
  height: 300px;
}

.chartDiv1 {
  width: 100%;
  height: 300px;
  position: relative;
}

#more-info-button {
  background-color: #dd9c44;
  border: 0;
  width: 100%;
  color: #202528;
}

#more-info-button:hover {
  color: #fff;
}
.data-popup {
  min-height: 80vh;
  width: 30vw;
  background-color: #202528;
  border-radius: 10px;
  float: left;
  top: 10%;
  z-index: 9999;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 80px;
}

.dropdown {
  top: 100%;
  margin-top: 5px;
  position: absolute;
  background-color: rgb(73, 72, 72);
  box-shadow: 0px 0px 8px #dd9c44;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.racer-title-row {
  display: flex;
  width: 100%;
}

.racer-title {
  justify-content: center;
  align-items: center;
  width: 95%;
}

#close-info {
  float: right;
  right: 0;
  position: relative;
  width: 8%;
  background-color: transparent;
  border: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #dd9c44;
}

/* Show the dropdown when isDropdownVisible is true */

#top-icon2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

#top-icon2:hover {
  color: #dd9c44;
}

#top-icon:hover {
  color: #dd9c44;
}

#right-icons {
  position: relative;
  float: left;
  display: flex;
}

#flag {
  background-image: url(../assets/flag.png);
  background-repeat: no-repeat;
  background-size: 120px 70px;
  position: relative;
  margin-top: 7.5%;
  margin-left: 7.5%;
  margin-right: 5%;
  height: 70px;
  width: 125px;
}

#trackInfo {
  max-width: 100%;
  padding-left: 20px;
}

#top-icons {
  float: right;
  position: relative;
  display: flex;
}

.fa-bell {
  margin-top: 10%;
  font-size: 500%;
}

.fa-circle-user {
  margin-left: -40%;
  font-size: 600%;
}

.selected-racer-modal {
  max-height: 500px;
  width: 400px;
  position: absolute;
  right: 80px;
  top: 10vh;
  background-color: #202528;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.racer-data {
  text-align: left;
  width: auto;
  font-size: 12px;
}

.sidebar {
  position: absolute;
  z-index: 1100;
  float: left;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-ites: center;
  height: 100vh;
  overflow-y: hidden;
  top: 0;
  background-color: #202528;
  padding-right: 1vw;
  padding-left: 0.7vw;
  box-shadow: 0 0 10px #dd9c44;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(61, 57, 57, 0.2);
  border-radius: 10px;
}
#blackCircleButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1100;
  left: 0.7vw;
  top: 0;
  position: absolute;
}

#bc1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc4 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc5 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc6 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc9 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-top: 2vh;
}

.spinner-container {
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

#spinner {
  margin: auto;
}

.poi-filter-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60%;
  color: white;
}

.poi-filter-title {
  position: relative;
  float: left;
  left: 0;
  text-align: left;
}

.poi-filter-icon {
  height: 35px;
  width: 35px;
  padding-left: 5px;
}

#measure-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999999;
  background-color: black;
  border: 0;
  border-radius: 5px;
  color: white;
}

.loading-title {
  margin-top: 100px;
  color: #dd9c44;
}

#measure-btn:hover {
  background-color: #dd9d45;
  color: black;
  border: 0;
}

#total-distance {
  position: absolute;
  top: 10px;
  height: 50px;
  right: 100px;
  z-index: 9999999;
}

#search-field {
  position: absolute;
  z-index: 1000;
  top: 10px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  display: block;
}

.search-bar {
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  max-width: 30vw;
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
}

.search-result-tr {
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.search-result-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 25vw;
  border-radius: 15px !important;
  overflow: hidden;
  border: 0;
  height: auto;
}

#result-table {
  width: 100%;
  border-radius: 15px;
}

.search-result-container {
  background-color: rgba(44, 48, 51, 0.85);
}

::placeholder {
  color: rgb(190, 184, 184);
  opacity: none;
}

.search-icon {
  margin-left: -30px;
}

#projectorMapContainer.leaflet-container {
  display: flex;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid black;
  resize: both;
  margin: 0;
  padding: 0;
  top: 0;
}

#projectorMapContainer1.leaflet-container {
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid black;
  //resize: both;
  margin: 0;
  padding: 0;
}
#projectorMapContainer2.leaflet-container {
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid black;
  //resize: both;
  margin: 0;
  padding: 0;
}
#projectorMapContainer3.leaflet-container {
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid black;
  // resize: both;
  margin: 0;
  padding: 0;
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  height: 100%;
  width: 100%;
  grid-auto-flow: dense;
  position: relative;
}
.mapContainerAll {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1700px) {
  #top-icons {
    margin-right: 5%;
  }

  #top-bar {
    height: auto;
  }

  /*  #bc1 {
    margin-top: 1vh;
  }
  #bc2 {
    margin-top: 1vh;
  }
  #bc3 {
    margin-top: 1vh;
  }
  #bc4 {
    margin-top: 1vh;
  }
  #bc5 {
    margin-top: 1vh;
  }
  #bc6 {
    margin-top: 1vh;
  }
  #bc7 {
    margin-top: 1vh;
  }
  #bc8 {
    margin-top: 1vh;
  }
  #bc9 {
    margin-top: 1vh;
  } */
}

@media only screen and (max-height: 950px) {
  #top-bar {
    height: auto;
  }

  #flag {
    display: none;
  }

  #top-icons {
    float: right;
    margin-right: 10vh;
  }
}

@media only screen and (max-width: 1550px) {
  .mapOption-popup {
    height: 13vh;
  }

  .backgroundOptionImg {
    height: 12vh;
  }
}

@media only screen and (max-width: 1200px) {
  .mapOption-popup {
    height: 12vh;
  }

  .backgroundOptionImg {
    width: 8.5vw;
    height: 11vh;
  }

  #top-icons {
    margin-right: 7vh;
  }

  .fa-bell {
    margin-top: -20%;
    font-size: 250%;
  }

  .fa-circle-user {
    margin-top: -20%;
    font-size: 250%;
  }

  #flag {
    height: 100%;
    width: 10%;
  }

  .search-bar {
    width: 65%;
  }
}

@media (max-width: 1000px) {
  .backgroundOptionImg {
    height: 8vh;
  }

  .mapOption-popup {
    height: 9vh;
  }
}

@media (max-width: 768px) {
  #search-field {
    display: none;
  }

  .data-popup {
    width: 75vw;
  }

  .mapOption-popup {
    width: 75vw;
    height: 17vh;
  }

  .backgroundOptionImg {
    width: 22vw;
    height: 16vh;
  }

  .backgroundOption .back-button {
    height: 30px;
    width: 30px;
  }
}
@media (max-width: 550px) {
  .sidebar {
    position: absolute;
    z-index: 1100;
    float: left;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-ites: center;
    height: 100vh;
    overflow-y: hidden;
    top: 0;
    background-color: transparent;
    padding-right: 1vw;
    padding-left: 0.7vw;
    box-shadow: none;
  }
}

@media (max-width: 650px) {
  .mapOption-popup {
    height: 11vh;
  }

  .backgroundOptionImg {
    height: 10vh;
  }
}

@media (max-width: 1100px) {
  th {
    font-size: 10px;
  }

  td {
    font-size: 10px;
  }
}

@media (max-width: 920px) {
  th {
    font-size: 9px;
  }

  td {
    font-size: 9px;
  }
}
</style>
