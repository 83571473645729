<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          {{ $t("language.userTitle") }}
        </li>
      </ol>
    </nav>
  </header>

  <div class="row" id="users-row">
    <div class="title-container-small">
      <h1>{{ $t("language.userTitle") }}</h1>
    </div>
    <div class="center-alignment">
      <div style="flex: 1">
        <GoldCircleButton
          :circleText="back"
          :circleImage="backImage"
          @circleClick="navigateBack()"
        />
      </div>
      <div class="title-container">
        <h1>{{ $t("language.userTitle") }}</h1>
      </div>
      <div style="flex: 1; display: flex; justify-content: right">
        <GoldCircleButton
          :circleText="userAdd"
          :circleImage="plusImage"
          @circleClick="navigateAddUser()"
        />
      </div>
    </div>
    <div class="search-div">
      <input
        class="search-bar"
        v-model="searchValue"
        :placeholder="searchBar"
      />
      <i class="search-icon" :class="searchImage"></i>
    </div>
    <div
      sm="12"
      md="6"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div class="outer-table">
        <table class="table table-striped">
          <thead v-if="sortedAndFilteredItems.length > 0">
            <tr>
              <th colspan="1"></th>
              <th
                style=""
                colspan="4"
                @click="sortTable(`first_name`)" 
              ><!-- TODO CSAK FISTNAMEBEN SZŰR EMIATT -->
                <span class="table-order-title">{{
                  $t("language.tableUser")
                }}</span>
              </th>
              <th style="" colspan="4" @click="sortTable('phone_number')">
                <span class="table-order-title">{{
                  $t("language.tablePhone")
                }}</span>
              </th>
              <th style="" colspan="4" @click="sortTable('email')">
                <span class="table-order-title">{{
                  $t("language.tableEmail")
                }}</span>
              </th>
              <th
                colspan="4"
                style="padding-right: 10px"
                @click="sortTable('user_id')"
              >
                <span class="table-order-title">
                  {{ $t("language.tableID") }}
                </span>
              </th>
            </tr>
          </thead>
          <thead v-else>
            <th>Találat</th>
          </thead>
          <tbody v-if="this.sortedAndFilteredItems.length > 0 && this.tableCount === 2">
            <!-- Sample rows, replace with your dynamic data -->
            <tr
              v-for="(item, index) in this.sortedAndFilteredItems"
              :key="item.user_id"
              class="hover-row"
            >
              <td colspan="1">
                <input type="checkbox" v-model="item.selectedUserRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label"></label>
              </td>  
            <td colspan="4" @click="navigateEditUser(item.user_id)">
                {{ item.title + " " + item.first_name + " " + item.last_name }}
              </td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">
                {{ item.phone_number }}
              </td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">{{ item.email }}</td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">{{ item.user_id }}</td>
            </tr>
          </tbody>
          <tbody v-if="this.sortedAndFilteredItems.length > 0 && this.tableCount === 1">
            <!-- Sample rows, replace with your dynamic data -->
            <tr
              v-for="(item, index) in this.sortedAndFilteredItems"
              :key="item.user_id"
              class="hover-row"
            >
            <td colspan="1">
                <input type="checkbox" v-model="item.selectedUserRow" :id="'customCheckbox' + index">
                <label :for="'customCheckbox' + index" class="checkbox-label">
                   <i v-if="item.checked" class="fa-solid fa-check"></i>
                 </label>
              </td> 
              <td colspan="4" @click="navigateEditUser(item.user_id)">
                {{ item.title + " " + item.first_name + " " + item.last_name }}
              </td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">
                {{ item.phone_number }}
              </td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">{{ item.email }}</td>
              <td colspan="4" style="padding-right: 8px" @click="navigateEditUser(item.user_id)">{{ item.user_id }}</td>
            </tr>
          </tbody>
          <tbody v-if="sortedAndFilteredItems.length < 1">
            <td colspan="36" style="text-align: center">
              {{ $t("language.searchText") }}
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import GoldCircleButton from "./GoldCircleButton.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "UserList",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      usersArray: [],
      backImage: "fa-solid fa-arrow-left",
      plusImage: "fa-solid fa-plus",
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      searchBar: "",
      userAdd: "",
      back: "",
      sortedItems: [],
      tableCount: 1,
      lastSortedType: null,
      reverseBool:false,
      // checkbox
      selectedUserRow:[],
    };
  },
  created() {
    this.userAdd = this.$t("language.userAdd");
    this.searchBar = this.$t("language.searchBar");
    this.back = this.$t("language.back");
  },
  async mounted() {
    this.usersArray = await dp.getUsers();
  },
  components: {
    GoldCircleButton,
  },
  computed: {

    sortedAndFilteredItems() {
    let items = [...this.usersArray];

    // Apply filtering based on search query
    items = items.filter((item) => {
      
      return (
        (item.first_name + " " + item.last_name + " " + item.title)
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          item.phone_number
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          item.email.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.user_id.toString().includes(this.searchValue.toLowerCase())
      );
    });

    // Apply sorting based on the selected column
    if (items.length > 1) {
        if (this.lastSortedType === this.currentType) {
          if (this.reverseBool) {
            items = [...items].sort((a, b) => {
              if (isNaN(b[this.currentType] - a[this.currentType])) {
                if (b[this.currentType] > a[this.currentType]) {
                  return 1;
                }
                if (b[this.currentType] < a[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return b[this.currentType] - a[this.currentType];
            });
          } else {
            items = [...items].sort((a, b) => {
              if (isNaN(a[this.currentType] - b[this.currentType])) {

                if (a[this.currentType] > b[this.currentType]) {
                  return 1;
                }
                if (a[this.currentType] < b[this.currentType]) {
                  return -1;
                }
                return 0;
              }
              return a[this.currentType] - b[this.currentType];
            });
          }
        } else {
          items.sort((a, b) => {
            if (isNaN(b[this.currentType] - a[this.currentType])) {
              if (a[this.currentType] > b[this.currentType]) {
                return 1;
              }
              if (a[this.currentType] < b[this.currentType]) {
                return -1;
              }
              return 0;
            }
            return a[this.currentType] - b[this.currentType];
          });
        }
      }

    return items;
  },
  },
  methods: {
    sortTable(type) {
      this.lastSortedType = this.currentType;
      this.currentType = type;

      if (this.lastSortedType === this.currentType) {
        this.reverseBool = !this.reverseBool;
      } else {
        this.reverseBool = false;
      }
    },
    navigateBack() {
      this.$router.push("/home");
    },
    navigateAddUser() {
      this.$router.push("/addUser");
    },
    navigateEditUser(id) {
      localStorage.setItem("user_id", id);
      this.$router.push("/editUser");
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
.table-order-title:hover {
  cursor: pointer;
  transition: 0.2s;
  color: white;
}
.title-container-small {
  justify-content: center;
  font-family: "Lexend";
  color: #dd9c44;
  font-size: 25px;
  display: none;
}
.title-container {
  color: white;
  font-size: 40px;
  font-family: "Lexend";
  text-align: center;
}
#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.search-div {
  width: 100%;
  margin-bottom: 4vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 25px;
}

.hover-row:hover {
  background-color: #dd9c44;
  color: #2c3033;
}

.hover-row:hover td {
  background-color: #dd9c44;
  color: #2c3033;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.outer-table {
  width: 100%;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* Enable vertical scrolling */
  background-color: transparent;
  border-radius: 10px;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}


.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}
/* checkbox */
.checkbox-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #7f494900;
  border: 1px solid #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkbox-label {
  background-color: #dd9c44;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.708a1 1 0 0 0-1.414-1.414L9 15.165 5.707 11.872a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10z"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
}

.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid #202528 !important;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
  cursor: pointer;
}

h1 {
  color: white;
  font-size: 50px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4vh;
}

@media (max-width: 768px) {
  h1 {
    font-size: 30px;
  }

  th {
    font-size: 11px;
  }

  td {
    font-size: 11px;
  }

  .outer-table {
    width: 100%;
  }

  .search-bar {
    width: 150px;
    height: 30px;
    padding-right: 35px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.2em;
  }

  .search-div {
    margin-left: 1.5vw;
  }
  .title-container {
    display: none;
  }
  .title-container-small {
    justify-content: center;
    font-family: "Lexend";
    color: #ffffff;
    font-size: 25px;
    display: block;
    margin-bottom: 2vh;
  }
}
@media (max-width: 768px) {
  .breadcrumb li {
    font-size: 12px;
  }
  .title-container {
    display: none;
  }
  .title-container-small {
    justify-content: center;
    font-family: "Lexend";
    color: #ffffff;
    font-size: 25px;
    display: block;
    margin-bottom: 2vh;
  }
}
@media (max-width: 430px) {
  .title-container {
    display: none;
  }
  .title-container-small {
    justify-content: center;
    font-family: "Lexend";
    color: #ffffff;
    font-size: 25px;
    display: block;
    margin-bottom: 2vh;
  }
}
@media (max-height: 350px) {
  .breadcrumb li {
    font-size: 12px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 60px;
    width: 100vw;
  }
  .title-container {
    display: none;
  }
  .title-container-small {
    justify-content: center;
    font-family: "Lexend";
    color: #ffffff;
    font-size: 25px;
    display: block;
    margin-bottom: 2vh;
  }
}
</style>
