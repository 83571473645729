<template>
  <div id="liveMapPage">
    <div class="spinner-container-big" v-if="this.load">
      <radar-spinner
        :animation-duration="2000"
        :size="100"
        color="#dd9c44"
        id="big-spinner"
      />

      <h1 class="loading-title">{{ $t("language.loadingTitle") }}</h1>
    </div>
    <!-- <button
      class="btn btn-secondary"
      id="measure-btn"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-title="This top tooltip is themed via CSS variables."
      @click="this.changeMeasure()"
    >
      <i v-if="!this.measure" class="fa-solid fa-ruler"></i>
      <i v-if="this.measure" class="fa-solid fa-xmark"></i>
    </button> -->

    <button
      class="btn btn-secondary"
      id="measure-hide-btn"
      data-bs-toggle="tooltip"
      v-if="this.measure"
      data-bs-placement="left"
      data-bs-title="This top tooltip is themed via CSS variables."
      @click="this.hideMeasure()"
    >
      <i v-if="this.measureShow" class="fa-solid fa-eye-slash"></i>
      <i v-if="!this.measureShow" class="fa-solid fa-eye"></i>
    </button>
    <div
      class="alert alert-light"
      id="total-distance"
      role="alert"
      v-if="this.measure"
    >
      <text class="total-distance-text">
        {{ $t("language.totDis") }}&nbsp; {{ this.totalDistanceString }}
      </text>
    </div>
    <div id=""></div>
    <BlackCircleButton
      id="bc1"
      :blackCircleImage="menuClose"
      @click="openSidebar()"
      class="openButton"
      style="
        cursor: pointer;
        z-index: 9999;
        margin-right: 1vw;
        margin-left: 0.7vw;
        margin-bottom: 7vh;
        margin-top: 2vh;
        position: absolute;
      "
      :style="{
        transform: `rotate(${rotationAngle}deg)`,
        transition: 'transform 0.5s',
      }"
      v-if="
        this.role == 'admin' || this.role == 'staff' || this.role == 'clerk'
      "
    />
    <div
      class="sidebar"
      id="side-bar"
      :class="[
        sidebarAnimationClass,
        { 'sidebar-hidden': SidebarIsOpen && !this.isAnimating },
      ]"
    >
      <div class="sidebarBtns" id="blackCircleButtons">
        <!-- <BlackCircleButton
            id="bc1"
            :blackCircleImage="menuClose"
            @click="closeSidebar()"
            style="margin-bottom: 7vh; margin-top: 2vh; position:absolute"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          /> -->
        <div
          class="button-margin"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
          "
        >
          <BlackCircleButton
            id="bc9"
            :blackCircleImage="homeImg"
            @blackCircleClick="
              navigateBack();
              this.isMapSetting = false;
            "
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc1"
            :blackCircleImage="logsImg"
            @blackCircleClick="
              this.checkPopupId(1);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc5"
            :blackCircleImage="alertImage"
            :numberOfAlerts="numberOfUnhandledEventLogs"
            @blackCircleClick="
              this.checkPopupId(5);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 5 ? '#DD9C44' : '' }"
            v-if="this.role == 'admin' || this.role == 'clerk'"
          />
          <BlackCircleButton
            id="bc2"
            :blackCircleImage="stagesImg"
            @blackCircleClick="
              this.checkPopupId(2);
              this.isMapSetting = false;
              this.isOpen = !this.isOpen;
            "
            :imageColor="{ color: popUpId == 2 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="poiImg"
            @blackCircleClick="
              this.checkPopupId(3);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 3 ? '#DD9C44' : '' }"
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="racerImg"
            @blackCircleClick="
              this.checkPopupId(7);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 7 ? '#DD9C44' : '' }"
          />
          <div class="mapOptionFlexBox">
            <BlackCircleButton
              id="bc6"
              :blackCircleImage="settingsImg"
              @blackCircleClick="
                this.isMapSetting = !this.isMapSetting;
                this.checkPopupId(6);
              "
              :imageColor="{
                color: popUpId == 6 ? '#DD9C44' : '',
              }"
            />
            <div v-show="this.isMapSetting === true" class="mapOption-popup">
              <div class="mapOption-popup-content" style="">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <!-- <label style="color: white;">Világos mód</label> -->
                  <div
                    @click="changeLayer(0), (this.switchLayer = 0)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/lightmode_background.png"
                      alt="Light Background"
                      :style="{
                        border:
                          this.switchLayer == 0 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: black;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.defMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(1), (this.switchLayer = 1)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/darkmode_background.png"
                      alt="Dark Background"
                      :style="{
                        border:
                          this.switchLayer == 1 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.darkMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(2), (this.switchLayer = 2)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/satellite_background.png"
                      alt="Satellite Background"
                      :style="{
                        border:
                          this.switchLayer == 2 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.satView") }}</label
                    >
                  </div>
                </div>
                <div
                  style="
                    background-color: #202528;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    margin-top: 0;
                    padding-top: 2%;
                    padding-bottom: 2%;
                  "
                >
                  <div
                    id="iconSizer"
                    style="font-weight: bolder; color: white; margin: auto"
                    v-show="false"
                  >
                    {{ $t("language.racerIcon") }}
                    <button
                      class="smallBtn"
                      id="iconSizerBtn"
                      @click="smallIcons()"
                      style="margin-right: 2%"
                    >
                      {{ $t("language.smText") }}
                    </button>
                    <button
                      class="largeBtn"
                      id="iconSizerBtn"
                      @click="bigIcons()"
                    >
                      {{ $t("language.lgText") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BlackCircleButton
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
            id="bc4"
            :blackCircleImage="projectModeImg"
            @blackCircleClick="
              this.navToProjector();
              this.checkPopupId(4);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 4 ? '#DD9C44' : '' }"
          />
        </div>
        <!--           <BlackCircleButton id="bc7" :blackCircleImage="usersimg" @blackCircleClick="navigateEventUsersPage()" />
            <BlackCircleButton id="bc8" :blackCircleImage="mapimg" @blackCircleClick="changeLayer()"/> -->
        <div
          style="
            position: fixed;
            bottom: 0;
            left: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          "
        ></div>
      </div>
    </div>
    <BlackCircleButton
      v-show="!displaySidebar"
      id="bc1"
      class="burgerOpenButton"
      style="z-index: 1100; margin-right: 1vw; margin-left: 0.7vw"
      :blackCircleImage="menuOpen"
      @blackCircleClick="this.displaySidebar = true"
      :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
      v-if="
        this.role == 'admin' || this.role == 'staff' || this.role == 'clerk'
      "
    />
    <div v-show="displaySidebar" class="sidebarBurger" id="side-bar">
      <div class="sidebarBtns" id="blackCircleButtons">
        <BlackCircleButton
          id="bc1"
          :blackCircleImage="menuClose"
          style="margin-bottom: 5vh"
          @blackCircleClick="this.displaySidebar = false"
          :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
          v-if="
            this.role == 'admin' || this.role == 'staff' || this.role == 'clerk'
          "
        />
        <div
          class="button-margin"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
          "
        >
          <BlackCircleButton
            id="bc9"
            :blackCircleImage="homeImg"
            style=""
            @blackCircleClick="
              navigateBack();
              this.isMapSetting = false;
            "
          />
          <BlackCircleButton
            id="bc1"
            :blackCircleImage="logsImg"
            @blackCircleClick="
              this.checkPopupId(1);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc5"
            :blackCircleImage="alertImage"
            :numberOfAlerts="numberOfUnhandledEventLogs"
            @blackCircleClick="
              this.checkPopupId(5);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 5 ? '#DD9C44' : '' }"
          />
          <BlackCircleButton
            id="bc2"
            :blackCircleImage="stagesImg"
            @blackCircleClick="
              this.checkPopupId(2);
              this.isMapSetting = false;
              this.isOpen = !this.isOpen;
            "
            :imageColor="{ color: popUpId == 2 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="poiImg"
            @blackCircleClick="
              this.checkPopupId(3);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 3 ? '#DD9C44' : '' }"
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="racerImg"
            @blackCircleClick="
              this.checkPopupId(7);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 7 ? '#DD9C44' : '' }"
          />
          <div class="mapOptionFlexBox">
            <BlackCircleButton
              id="bc6"
              :blackCircleImage="settingsImg"
              @blackCircleClick="
                this.isMapSetting = !this.isMapSetting;
                this.checkPopupId(6);
              "
              :imageColor="{
                color: popUpId == 6 ? '#DD9C44' : '',
              }"
            />
            <div v-show="this.isMapSetting === true" class="mapOption-popup">
              <div class="mapOption-popup-content" style="">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <!-- <label style="color: white;">Világos mód</label> -->
                  <div
                    @click="changeLayer(0), (this.switchLayer = 0)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/lightmode_background.png"
                      alt="Light Background"
                      :style="{
                        border:
                          this.switchLayer == 0 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: black;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.defMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(1), (this.switchLayer = 1)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/darkmode_background.png"
                      alt="Dark Background"
                      :style="{
                        border:
                          this.switchLayer == 1 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.darkMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(2), (this.switchLayer = 2)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/satellite_background.png"
                      alt="Satellite Background"
                      :style="{
                        border:
                          this.switchLayer == 2 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.satView") }}</label
                    >
                  </div>
                </div>
                <div
                  style="
                    background-color: #202528;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    margin-top: 0;
                    padding-top: 2%;
                    padding-bottom: 2%;
                  "
                >
                  <div
                    id="iconSizer"
                    style="font-weight: bolder; color: white; margin: auto"
                    v-show="false"
                  >
                    {{ $t("language.racerIcon") }}
                    <button
                      class="smallBtn"
                      id="iconSizerBtn"
                      @click="smallIcons()"
                      style="margin-right: 2%"
                    >
                      {{ $t("language.smText") }}
                    </button>
                    <button
                      class="largeBtn"
                      id="iconSizerBtn"
                      @click="bigIcons()"
                    >
                      {{ $t("language.lgText") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BlackCircleButton
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
            id="bc4"
            :blackCircleImage="projectModeImg"
            @blackCircleClick="
              this.navToProjector();
              this.checkPopupId(4);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 4 ? '#DD9C44' : '' }"
          />
        </div>
        <!--           <BlackCircleButton id="bc7" :blackCircleImage="usersimg" @blackCircleClick="navigateEventUsersPage()" />
            <BlackCircleButton id="bc8" :blackCircleImage="mapimg" @blackCircleClick="changeLayer()"/> -->
        <div
          style="
            position: fixed;
            bottom: 0;
            left: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          "
        ></div>
      </div>
    </div>
    <div v-show="popUpId === 1" class="data-popup">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <div class="search-bar-container">
            <input
              class="search-bar2"
              v-model="searchQuery2"
              :placeholder="searchBar"
            />
            <i class="search-icon2" :class="searchImage"></i>
          </div>
          <div style="display: flex; flex: 1; justify-content: center">
            <span class="logs-text">{{ $t("language.logs") }}</span>
          </div>
          <div style="display: flex; flex: 1; justify-content: right">
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
          "
        ></div>
      </div>
      <div sm="12" md="6" class="center-alignment">
        <div class="outer-table" @scroll="handleScroll" ref="tableContainer">
          <div class="spinner-container-logs" id="scl">
            <radar-spinner
              :animation-duration="2000"
              :size="80"
              color="#dd9c44"
              id="spinner-logs"
            />
          </div>
          <div class="button-container">
            <button
              class="filter-button"
              :class="{ active: activeButton === 'StageLogs' }"
              id="filter-button-stage"
              @click="showStageLogs()"
            >
              {{ $t("language.changeLogs") }}
            </button>
            <button
              class="filter-button"
              :class="{ active: activeButton === 'StatusLogs' }"
              id="filter-button-status"
              @click="showStatusLogs()"
            >
              {{ $t("language.statusLogs") }}
            </button>
            <button
              class="filter-button"
              :class="{ active: activeButton === 'EventLogs' }"
              id="filter-button-event"
              @click="showEventLogs()"
            >
              {{ $t("language.eventLogs") }}
            </button>
            <button
              class="filter-button"
              :class="{ active: activeButton === 'SpeedingLogs' }"
              id="filter-button-speeding"
              @click="showSpeedingLogs()"
            >
              {{ $t("language.speedingLogs") }}
            </button>
          </div>

          <table class="custom-table">
            <thead v-if="this.actualLogs == 1">
              <tr>
                <th style="width: 8%; text-align: left" colspan="4">
                  {{ $t("language.startnum") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.carStatusLabel") }}
                </th>
                <th style="width: 15%" colspan="4">
                  {{ $t("language.recordedAt") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.prevStage") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.currStage") }}
                </th>
              </tr>
            </thead>

            <thead v-if="this.actualLogs == 3">
              <tr>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.statusLabel") }}
                </th>
                <th style="width: 15%" colspan="4">
                  {{ $t("language.recordedAt") }}
                </th>
                <th style="width: 20%" colspan="4">
                  {{ $t("language.stage") }}
                </th>
              </tr>
            </thead>
            <thead v-if="this.actualLogs == 2">
              <th style="width: 8%; text-align: center" colspan="4">
                {{ $t("language.startNum") }}
              </th>
              <th style="width: 10%" colspan="4">
                {{ $t("language.raceriStatus") }}
              </th>
              <th style="width: 15%" colspan="4">
                {{ $t("language.recordedAt") }}
              </th>
              <th style="width: 20%" colspan="4">{{ $t("language.stage") }}</th>
            </thead>
            <thead v-if="this.actualLogs == 4">
              <th style="width: 15%; text-align: center" colspan="4">
                {{ $t("language.startNum") }}
              </th>
              <th style="width: 35%" colspan="4">
                {{ $t("language.recordedAt") }}
              </th>
              <th style="width: 50%" colspan="4">{{ $t("language.stage") }}</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.filteredLogs" :key="index">
                <td v-if="item.racer_id || item.racer_start_num" colspan="4">
                  {{ item.racer_start_num }}
                </td>
                <td
                  v-if="
                    this.actualLogs == 3 ||
                    this.actualLogs == 2 ||
                    this.actualLogs == 1
                  "
                  colspan="4"
                >
                  {{ item.stage_status }} {{ item.type }}
                  {{ item.stage_change_log_current_stage_status }}
                </td>
                <td colspan="4" v-if="item.stage_change_log_created_at">
                  {{ formatTimeDate(item.stage_change_log_created_at) }}
                </td>
                <td colspan="4" v-if="item.stage_status_log_created_at">
                  {{ formatTimeDate(item.stage_status_log_created_at) }}
                </td>
                <td colspan="4" v-if="item.event_log_created_at">
                  {{ formatTimeDate(item.event_log_created_at) }}
                </td>
                <td colspan="4" v-if="item.speeding_log_created_at">
                  {{ formatTimeDate(item.speeding_log_created_at) }}
                </td>
                <td
                  v-if="
                    item.stage_id ||
                    item.stage_change_log_current_stage ||
                    item.prev_stage_name
                  "
                  colspan="4"
                >
                  {{ item.stage_name || item.prev_stage_name || "offstage" }}
                </td>
                <td
                  v-if="!item.stage_id && !item.stage_change_log_current_stage"
                  colspan="4"
                >
                  offstage
                </td>
                <td
                  v-if="
                    item.current_stage_name &&
                    item.stage_change_log_current_stage_status != 'offstage'
                  "
                  colspan="4"
                >
                  {{ item.current_stage_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="stageModal" v-show="popUpId === 2" class="data-popup">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <span class="alert-text">{{ $t("language.stagesLabel") }}</span>

          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <button
              class="btn btn-secondary"
              id="checkInfo-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasStages"
              aria-controls="offcanvasStages"
              data-bs-title="This top tooltip is themed via CSS variables."
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasStages"
          aria-labelledby="offcanvasStagesLabel"
          style="background-color: #202528; color: white"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">
              Stages Info
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            {{ $t("language.welcomeText") }}<br />
            <br />
            {{ $t("language.closeText") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="
                background-color: orange;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                color: black;
              "
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <br /><br /><br />
            {{ $t("language.singleStageText") }}
            <br />
            <br />
            {{ $t("language.stageSelect") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="background-color: grey; width: 45px"
              type="button"
            >
              <i class="fa-solid fa-crosshairs"></i>
            </button>
            <br /><br /><br />
            {{ $t("language.toChageDwellTime") }}
            <br />
            <br />
            {{ $t("language.firstDwell") }}
            <br />
            {{ $t("language.secondDwell") }}
            <br />
            {{ $t("language.thirdDwell") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="background-color: grey; width: auto"
            >
              <i class="fa-regular fa-floppy-disk"></i>
            </button>
            <br />
            <br />
            <br />
            {{ $t("language.toChangeLimit") }}
            <br />
            <br />
            {{ $t("language.toChangeLimit1") }}
            <br />
            {{ $t("language.toChangeLimit2") }}
            <br />
            {{ $t("language.thirdDwell") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="background-color: grey; width: auto"
            >
              <i class="fa-regular fa-floppy-disk"></i>
            </button>
            <br />
            <br />
            <br />
            {{ $t("language.toViewChange") }} <br />
            <br />
            {{ $t("language.toViewChangeText") }}
            <br />
            <br />
            <div style="display: flex; justify-content: center">
              <i
                class="fa-regular fa-flag"
                style="color: green; font-size: 24px"
              ></i>
              <i
                class="fa-regular fa-flag"
                style="color: yellow; padding-inline: 20px; font-size: 24px"
              ></i>
              <i
                class="fa-regular fa-flag"
                style="color: red; font-size: 24px"
              ></i>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
          "
        >
          <!--           <button class="filter-button">
              <i style="margin-right: 3px" class="fa-solid fa-filter"></i>
              Filter
            </button> -->
        </div>
      </div>
      <div sm="12" md="6" class="center-alignment" v-if="this.role">
        <div class="outer-table">
          <table class="custom-table">
            <thead>
              <tr>
                <th style="width: 8%" colspan="5"></th>
                <th style="width: 15%" colspan="5">
                  {{ $t("language.stageName") }}
                </th>
                <th
                  style="width: 25%"
                  colspan="5"
                  v-if="this.role == 'admin' || this.role == 'clerk'"
                >
                  {{ $t("language.dwelltime") }}
                </th>
                <th
                  style="width: 18%"
                  colspan="5"
                  v-if="this.role == 'admin' || this.role == 'clerk'"
                >
                  {{ $t("language.speedLimit") }}
                </th>
                <th
                  style="width: 25%"
                  colspan="5"
                  v-if="this.role == 'admin' || this.role == 'clerk'"
                >
                  {{ $t("language.statusLabel") }}
                </th>
                <th style="width: 10%" colspan="5" v-else>
                  {{ $t("language.statusLabel") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) of this.stages"
                :key="item.stage_id"
                :id="'table-tr' + item.stage_id"
              >
                <td colspan="5" style="text-align: center">
                  <button
                    class="btn btn-secondary btn-sm cross"
                    id="crosshairButton"
                    style="
                      --bs-btn-padding-y: 0.25rem;
                      --bs-btn-padding-x: 0.5rem;
                      --bs-btn-font-size: 0.8rem;
                    "
                    @click="
                      setMapViewButtonClick(index, 'table-tr' + item.stage_id)
                    "
                  >
                    <i class="fa-solid fa-crosshairs"></i>
                  </button>
                </td>
                <td colspan="5" style="text-align: left">
                  {{ item.stage_name }}
                </td>
                <td
                  colspan="5"
                  style="text-align: left"
                  v-if="this.role == 'admin' || this.role == 'clerk'"
                >
                  <input
                    type="number"
                    @input="validateNumericInputDwelltime($event)"
                    min="1"
                    max="600000"
                    style="
                      margin: auto;
                      width: 50%;
                      margin-right: 4%;
                      background-color: #202528;
                      color: white;
                      border: 0;
                    "
                    :id="'dwellTimeInput' + item.stage_id"
                    :value="item.dwell_time"
                    v-if="this.stageLogs[index].stage_status != 'transport'"
                  />
                  <button
                    v-if="this.stageLogs[index].stage_status != 'transport'"
                    class="btn btn-secondary btn-sm mb-1 cross"
                    id="dwellTimeButton"
                    style="
                      --bs-btn-padding-y: 0.25rem;
                      --bs-btn-padding-x: 0.5rem;
                      --bs-btn-font-size: 0.8rem;
                      margin-left: auto;
                    "
                    type="button"
                    @click="dwellTime(item.stage_id)"
                  >
                    <i class="fa-regular fa-floppy-disk"></i>
                  </button>
                </td>
                <td
                  colspan="5"
                  style="text-align: left"
                  v-if="this.role == 'admin' || this.role == 'clerk'"
                >
                  <input
                    v-if="this.stageLogs[index].stage_status != 'transport'"
                    type="text"
                    @input="validateNumericInput($event)"
                    min="0"
                    style="
                      margin: auto;
                      width: 50%;
                      margin-right: 4%;
                      background-color: #202528;
                      color: white;
                      border: 0;
                    "
                    :id="'speedLimitInput' + item.stage_id"
                    :value="
                      this.stageLogs[index].stage_status.includes('limit')
                        ? this.stageLogs[index].stage_status.split(' ')[1]
                        : 'No Limit'
                    "
                  />
                  <button
                    class="btn btn-secondary btn-sm mb-1 cross"
                    id="dwellTimeButton"
                    style="
                      --bs-btn-padding-x: 0.5rem;
                      --bs-btn-font-size: 0.8rem;
                    "
                    type="button"
                    @click="speedLimit(item.stage_id, value)"
                    v-if="this.stageLogs[index].stage_status != 'transport'"
                  >
                    <i class="fa-regular fa-floppy-disk"></i>
                  </button>
                </td>
                <td colspan="5" style="font-size: x-small; text-align: center">
                  <div style="display: flex; align-items: center">
                    <!--                     <button
                        class="btn btn-success btn-sm mb-1"
                        id="statusButton"
                        type="button"
                        style="
                          /* --bs-btn-padding-y: 0.25rem;
                          --bs-btn-padding-x: 0.25rem;
                          --bs-btn-font-size: 0.6rem; */
                          font-size: xx-small;
                        "
                        @click="setNormal(item.stage_id)"
                        v-if="this.role == 'admin' || this.role == 'clerk'"
                      >
                        Normal
                      </button> -->
                    <p
                      class="status-text"
                      style="margin-top: 10%"
                      v-if="this.role == 'admin' || this.role == 'clerk'"
                    >
                      {{ this.stageLogs[index].stage_status }}
                    </p>
                    <p class="status-text" style="margin: auto" v-else>
                      {{ this.stageLogs[index].stage_status }}
                    </p>
                    <div
                      v-if="this.stageLogs[index].stage_status != 'transport'"
                    >
                      <button
                        style="color: white; padding-left: 10px"
                        class="btn dropdown-toggle flag-class"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        v-if="this.role == 'admin' || this.role == 'clerk'"
                      >
                        <i
                          class="fa-regular fa-flag"
                          :class="{
                            'green-flag':
                              this.stageLogs[index].stage_status === 'normal',
                            'red-flag':
                              this.stageLogs[index].stage_status === 'red flag',
                            'yellow-flag': this.stageLogs[index].stage_status
                              .toLowerCase()
                              .includes('limit'),
                          }"
                        ></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li
                          style="display: flex"
                          @click="setNormal(item.stage_id)"
                          class="dropdown-flag-class"
                        >
                          <button
                            class="btn"
                            type="button"
                            @click="setNormal(item.stage_id)"
                          >
                            <i
                              class="fa-regular fa-flag"
                              style="color: green"
                            ></i></button
                          ><span
                            style="
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              cursor: pointer;
                            "
                            class=""
                            href="#"
                            >{{ $t("language.normal") }}</span
                          >
                        </li>
                        <li style="display: flex" class="dropdown-flag-class">
                          <button
                            class="btn"
                            type="button"
                            @click="setRedFlag(item.stage_id)"
                          >
                            <i
                              class="fa-regular fa-flag"
                              style="color: red"
                            ></i></button
                          ><span
                            style="
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              cursor: pointer;
                            "
                            class=""
                            href="#"
                            @click="setRedFlag(item.stage_id)"
                            >{{ $t("language.redFlag") }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <!--                <button
                      class="btn btn-danger btn-sm mb-1"
                      id="statusButton"
                      style="
                        --bs-btn-padding-y: 0.25rem;
                        --bs-btn-padding-x: 0.25rem;
                        --bs-btn-font-size: 0.6rem;
                        font-size: xx-small;
                      "
                      v-if="this.role == 'admin' || this.role == 'clerk'"
                      type="button"
                      @click="setRedFlag(item.stage_id)"
                    >
                      Red Flag
                    </button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="popUpId === 3" class="data-popup" style="width: 25%">
      <div style="display: flex; flex-direction: column; overflow-y: auto">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <span class="alert-text">{{ $t("language.poiSettings") }}</span>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div>
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                id="checkInfo-btn"
              >
                <i class="fa-solid fa-info"></i>
              </button>
            </div>
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          @hook:updated="checkOffcanvasState"
          style="background-color: #202528; color: white"
        >
          <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">POI Info</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            {{ $t("language.poiTitle") }}
            <br />
            {{ $t("language.poiClose") }}
            <br />
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="
                background-color: orange;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                color: black;
              "
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <br /><br /><br />
            <br />
            {{ $t("language.poiView") }}
            <br />
            <br />

            <img :src="tooltipPoi" alt="poi tooltip" style="width: 15vw" />

            <br /><br /><br />
            {{ $t("language.poiLimit") }}
            <br />
            <br />
            <img :src="checkboxPoi" alt="poi tooltip" style="width: 12vw" />
            <br /><br /><br />
            {{ $t("language.poiTypes") }}
            <br />
            <br />
            <div style="display: flex; flex-direction: column">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/finish.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.finish_POI")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/ss-start.png"
                  class="poi-img"
                  alt="start icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Stage_start")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/ss-stop.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Stage_Stop")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/firetruck.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Firetruck")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/hlz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Helicopter_Landing_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/marshall.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Marshall")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/medical.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Medical")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/waypoint.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Waypoint")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/radio.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                &emsp;
                <span style="text-align: center">{{
                  $t("language.Radio")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/rnep.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Route_Note_Exchange_Point")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/rz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Refuel_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/service.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Service")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/sz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Spectator_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tc.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Time_Control")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tf.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Tire_Fitting")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tm.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Tire_Marking")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/mp.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Media_Point")
                }}</span>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
            height: 80%;
            overflow-x: auto;
          "
          class="poi-filter-container"
        >
          <br />
          <div class="poi-filter-container">
            <div
              class="form-check checkboxRow"
              v-for="item in this.poiTypes"
              v-bind:key="item"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style="margin-bottom: 10px"
                checked="true"
                @click="this.poiFilter(item)"
              />
              <img :src="'icons/' + item + '.png'" class="poi-filter-icon" />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                id="poi-text"
              >
                &nbsp; {{ item }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- racer list popup -->
    <div v-show="popUpId === 7" class="data-popup" style="width: 40%">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <div class="search-bar-container">
            <input
              class="search-bar5"
              v-model="searchQuery4"
              :placeholder="searchBar"
            />
            <i class="search-icon2" :class="searchImage"></i>
          </div>
          <div style="display: flex; flex: 1; justify-content: center">
            <span class="logs-text">{{ $t("language.racerList") }}</span>
          </div>
          <div style="display: flex; flex: 1; justify-content: right">
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
          "
        ></div>
      </div>
      <div sm="12" md="6" class="center-alignment">
        <div class="outer-table">
          <div class="spinner-container-logs" id="scl">
            <radar-spinner
              :animation-duration="2000"
              :size="80"
              color="#dd9c44"
              id="spinner-logs"
            />
          </div>

          <table class="custom-table">
            <thead>
              <tr>
                <th style="width: 8%; text-align: left" colspan="4">
                  {{ $t("language.startnum") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.deviceSpeed") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.deviceG") }}
                </th>
                <th style="width: 15%" colspan="4">
                  {{ $t("language.recordedAt") }}
                </th>
                <th style="width: 10%" colspan="4">
                  {{ $t("language.carStatusLabel") }}
                </th>
                <th colspan="2" style="width: 5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in this.filteredRacerList" :key="item.racer_id">
                <td colspan="4">
                  {{ item.racer_start_num }}
                </td>
                <td colspan="4">
                  {{ item.velocity }}
                </td>
                <td colspan="4">
                  {{ item.g_force }}
                </td>
                <td colspan="4">
                  {{ formatTimeDate(item.received_at) }}
                </td>
                <td colspan="4" :class="getStatusClass(item.racer_status)">
                  {{ item.racer_status }}
                </td>
                <td colspan="2">
                  <button
                    type="button"
                    style="background: none; border: none; color: white"
                    @click="showRacer(item)"
                  >
                    <i class="fa-solid fa-crosshairs fa-1xl"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="popUpId === 5" class="data-popup" style="width: 30%">
      <div style="display: flex; flex-direction: column;">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
            "
          >
            <span class="alert-text" style="margin-right: 10px;">
              {{ $t('language.alertTitle') }}
            </span>
            <span class="alert-number">
              {{ numberOfUnhandledEventLogs }}
            </span>
          </div>
          <button @click="closePopup" class="back-button" style="display: flex; align-items: center;">
            <i class="fa-solid fa-arrow-left fa-2xl"></i>
          </button>
        </div>
      </div>
      <div sm="12" md="6" class="center-alignment">
        <div
          class="outer-table"
          style="border-radius: 0; justify-content: center; align-items: center"
        >
          <div
            v-for="item in unhandledEventLogs"
            :key="item.event_log_id"
            class="alertbox-outer"
            style="display: flex"
          >
            <div
              class="alert-top-row"
              style="
                display: flex;
                flex-direction: column;
                width: 80%;
                height: 100%;
              "
            >
              <div class="alertbox-header">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;
                  "
                >
                  <span class="alertbox-title" style="margin-left: 10px">{{
                    `${this.$t("language.startNum")}: ${
                      item.racer_start_num
                        ? item.racer_start_num
                        : item.staff_start_num.slice(0, 3)
                    } ${this.$t("language.stageName")}: ${
                      item.stage_name ? item.stage_name : "offstage"
                    } 
                    `
                  }}</span>
                </div>

                <div
                  style="
                    display: flex;
                    min-height: 5vh;
                    padding: 10px;
                    width: 80%;
                  "
                >
                  <span
                    v-if="item.sub_type == 'EXTREME_ACCEL_WARNING'"
                    class="alertbox-text"
                    style="justify-content: left"
                    >{{
                      item.type +
                      " EXTREME ACCEL" +
                      " " +
                      formatTime(item.event_log_created_at)
                    }}</span
                  >
                  <span
                    v-else-if="item.sub_type == 'EXTREME_TEMP_WARNING'"
                    class="alertbox-text"
                    >{{
                      item.type +
                      " EXTREME TEMP" +
                      " " +
                      formatTime(item.event_log_created_at)
                    }}</span
                  >
                  <span v-else class="alertbox-text">{{
                    item.type +
                    " " +
                    item.sub_type +
                    " " +
                    formatTime(item.event_log_created_at)
                  }}</span>
                </div>
              </div>
            </div>

            <div class="alert-btn-div">
              <button
                type="button"
                class="alertbox-button"
                @click="triggerAlert(item)"
              >
                <i class="fa-solid fa-check"></i>
              </button>
              <button
                type="button"
                class="alertbox-button2"
                @click="showRacer(item)"
              >
                <i class="fa-solid fa-crosshairs fa-1xl"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-content" id="search-field">
      <input
        class="search-bar"
        id="racerSearchBar"
        v-model="searchValue"
        :placeholder="SearchRacerText"
        @click="activateSearch"
      />
      <i
        class="search-icon"
        style="font-size: 1.2em; color: white"
        :class="searchImage"
      ></i>
      <div class="search-result-container" v-if="this.isSearch">
        <table class="table" id="result-table">
          <tbody>
            <tr
              class="search-result-tr"
              v-for="item in this.filteredRacers"
              :key="item.racer_id"
              @click="this.showRacer(item)"
            >
              <td class="num-td">
                <p>{{ item.racer_start_num }}</p>
              </td>
              <td class="name-td">
                <p class="result-name">{{ item.racer_pilot_name }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="selected-racer-modal" v-if="this.isSelected">
    <div class="racer-title-row" v-if="this.selectedRacer">
      <h1 class="racer-title">
        {{
          this.selectedRacer.racer_start_num ||
          this.selectedRacer.staff_name.slice(0, 3)
        }}
        |
        {{
          this.selectedRacer.racer_pilot_name || this.selectedRacer.staff_name
        }}
      </h1>
      <button class="btn btn-primary" id="close-info" @click="closeRacer()">
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
    <div class="actual-racer-table" v-if="this.selectedRacer">
      <table class="table" v-if="this.selectedRacer">
        <thead v-if="this.selectedRacer.racer_id">
          <tr>
            <th scope="col" class="racer-data" style="width: 10%">
              {{ $t("language.racerModalStatus") }}
            </th>
            <th scope="col" class="racer-data" style="width: 12%">
              {{ $t("language.racerModalNationality") }}
            </th>
            <th scope="col" class="racer-data" style="width: 12%">
              {{ $t("language.racerModalCoPilot") }}
            </th>
            <th scope="col" class="racer-data" style="width: 12%">
              {{ $t("language.racerModalDevId") }}
            </th>
            <th scope="col" class="racer-data" style="width: 17%">
              {{ $t("language.mainTitle") }}
            </th>
            <th scope="col" class="racer-data" style="width: 11%">
              {{ $t("language.deviceSpeed") }}
            </th>
            <th scope="col" class="racer-data" style="width: 11%">
              {{ $t("language.deviceG") }}
            </th>
          </tr>
        </thead>
        <thead v-if="this.selectedRacer.staff_id">
          <tr>
            <th scope="col" class="racer-data">
              {{ $t("language.racerModalName") }}
            </th>
            <th scope="col" class="racer-data">
              {{ $t("language.racerModalPhone") }}
            </th>
            <th scope="col" class="racer-data">
              {{ $t("language.racerModalEmail") }}
            </th>
            <th scope="col" class="racer-data">
              {{ $t("language.racerModalDevId") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="this.selectedRacer.racer_id">
          <tr>
            <td class="racer-data">
              {{
                this.selectedRacer.racer_status ||
                this.selectedRacer.staff_status
              }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_pilot_nationality }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_copilot_name }}
            </td>
            <td class="racer-data">{{ this.selectedRacer.device_id }}</td>
            <td class="racer-data">
              {{ formatTimeDate(this.actualLastData[0].received_at) }}
            </td>
            <td class="racer-data">
              {{ this.actualLastData[0].velocity }} km/h
            </td>
            <td class="racer-data">{{ this.actualLastData[0].g_force }}</td>
          </tr>
        </tbody>
        <tbody v-if="this.selectedRacer.staff_id">
          <tr>
            <td class="racer-data">{{ this.selectedRacer.staff_name }}</td>
            <td class="racer-data">
              {{ this.selectedRacer.staff_phonenum }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.staff_email }}
            </td>
            <td class="racer-data">{{ this.selectedRacer.device_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="spinner-container" v-if="this.loading">
      <radar-spinner
        :animation-duration="2000"
        :size="80"
        color="#dd9c44"
        id="spinner"
      />
    </div>
    <div class="select-container">
      <label
        for="chart-select"
        class="col-form-label"
        id="chart-select-label"
        >{{ $t("language.raceriLengthChart") }}</label
      >
      <select v-model="this.selectedNumber" id="chart-select">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
    <div class="chartDiv1">
      <canvas ref="lineChart" id="chart1"></canvas>
    </div>
    <div class="chartDiv2">
      <canvas ref="lineChart2" id="chart2"></canvas>
    </div>
    <br />
    <div id="buttonWrapper" v-if="this.selectedRacer">
      <button
        v-if="
          !this.selectedRacer.staff_id &&
          (this.role == 'admin' || this.role == 'clerk' || this.role == 'staff')
        "
        class="btn btn-primary"
        id="more-info-button"
        @click="navToRacer()"
      >
        <i class="fa-solid fa-circle-info"></i>
        {{ $t("language.racerModalMoreInfo") }}
      </button>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="$t('language.notificationConfirm')"
    :confirmText="confirmText"
    :cancelText="cancelText"
    @confirm="
      handleAlert(currentEventLogId, currentHandled), (showAlertModal = false)
    "
    @cancel="showAlertModal = false"
  />
  <CustomAlert
    :show="showAlert2"
    :body="this.bodyText"
    @confirm="showAlert2 = false"
    :showCancelButton="false"
  />
  <div class="toast-container">
    <ToastAlert
      v-for="item in unhandledEventLogs"
      :key="item.event_log_id"
      :id="item.event_log_id"
      :message="
        item.type +
        ' ' +
        item.sub_type +
        ' ' +
        formatTime(item.event_log_created_at)
      "
      :autoClose="true"
      :autoCloseTime="4000"
      :show="item.show"
      @update:show="updateShow(item.event_log_id, $event)"
      style="z-index: 9999"
    />
  </div>

  <div id="mapContainer"></div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LControlZoom } from "leaflet";
import BlackCircleButton from "./BlackCircleButton.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();
const range = process.env.VUE_APP_SNAPPING_RANGE || 15;
import "leaflet/dist/leaflet.css";
import Chart from "chart.js/auto";
import { RadarSpinner } from "epic-spinners";

import mqtt from "mqtt/dist/mqtt";
import "leaflet-rotatedmarker";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";

import CustomAlert from "./CustomAlert.vue";
import ToastAlert from "./ToastAlert.vue";

const signaling_topic = process.env.VUE_APP_SIGNALING_TOPIC || "signalingLog";
// const frontend_raw_data_topic=process.env.FRONTENDRAWDATA_TOPIC ||"rawData"
// const server_data_topic=process.env.SERVERTODEVICE_TOPIC ||"ddc-rally/serverData"
const frontend_topic = process.env.VUE_APP_FRONTEND_TOPIC || "deviceData";
const topic_prefix = process.env.VUE_APP_TOPIC_PREFIX || ""; //demo/
// const device_data_topic=process.env.DEVICEDATA_TOPIC|| "ddc-rally/deviceData"

export default {
  name: "Map",
  L,
  LMap,
  LTileLayer,
  LControlZoom,
  data() {
    return {
      confirmText:undefined,
      cancelText:undefined,
      bodyText:undefined,
      selectedNumber: 10,
      SidebarIsOpen: true,
      isAnimating: true,
      openButton: false,
      followRacerMap: undefined,
      alertImage:
        "fa-solid fa-triangle-exclamation" /* fa-triangle-exclamation */,
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      logsImg: "fa-solid fa-list",
      menuClose: "fa-solid fa-caret-left",
      menuOpen: "fa-solid fa-caret-right",
      burgerImg: "fa-solid fa-bars",
      stagesImg: "fa-solid fa-route",
      poiImg: "fa-solid fa-location-dot",
      racerImg: "fa-solid fa-user-astronaut",
      projectModeImg: "fa-solid fa-table-cells-large",
      replayImg: "fa-solid fa-play",
      settingsImg: "fa-solid fa-gears",
      homeImg: "fa-solid fa-arrow-left",
      center: [47.6875, 17.6504],
      mapDiv: undefined,
      baseLayer: undefined,
      switchLayer: 0,
      isDropdownVisible: false,
      legName: undefined,
      legDate: undefined,
      popUpId: 0,
      stages: [],
      pois: [],
      markers: [],
      stageCoords: [],
      loading: true,
      poiTypes: [],
      eventLogs: [],
      unhandledEventLogs: [],
      numberOfUnhandledEventLogs: 0,
      poiTypeCounter: 0,
      newCenter: undefined,
      centerCoord: [],
      polyline: undefined,
      stageLogs: [],
      stageColor: [],
      usersArray: [],
      markerElements: [],
      isMapSetting: false,
      ssstartShow: true,
      ssstopShow: true,
      finishShow: true,
      tmShow: true,
      tfShow: true,
      tcShow: true,
      szShow: true,
      serviceShow: true,
      rzShow: true,
      rnepShow: true,
      radioShow: true,
      marshallShow: true,
      hlzShow: true,
      firetruckShow: true,
      medicalShow: true,
      waypointShow: true,
      legId: undefined,
      legMap: [],
      lastDeviceArray: [],
      carMarkerArray: [],
      carMarkerArray2: [],
      measureMarker: undefined,
      measureMarkers: [],
      measure: false,
      measureLines: [],
      totalDistance: 0,
      totalDistanceString: "0 m",
      distance: 0,
      distanceArray: [],
      dwell_time: undefined,
      uniqueDistances: [],
      highlightBorder: undefined,
      polyLineArray: [],
      allCoords: [],
      logArray: [],
      isSelected: false,
      isSearch: false,
      racersArray: [],
      actualRacerMarker: undefined,
      ActualRacerSpeeds: [],
      ActualRacerGForces: [],
      actualLastData: [],
      modalChartLabels: [],
      selectedRacer: undefined,
      chart: undefined,
      chart2: undefined,
      polyLine: undefined,
      tooltipPoi: "/poi_tooltip.png",
      checkboxPoi: "/poi_check.png",
      SearchRacerText: "",
      load: true,
      offcanvasIsOn: false,
      storedID: "",
      displaySidebar: false,

      actualRacerGForcesRe: [],
      actualRacerSpeedsRe: [],
      modalChartLabelsRe: [],
      searchQuery2: "",
      searchQuery4: "",

      measureShow: true,

      islogsLoaded: false,

      draggableLines: [],

      measureArrows: [],
      bearing: undefined,

      halfwayCoord: undefined,
      toggleIconSize: false,
      isOpen: false,
      searchBar: "",
      //-------------------------------------Alert-------------------------------------
      showAlertModal: false,
      
      currentEventLogId: null,
      currentHandled: null,
      showAlert2: false,
      errorMessage: "",
      //-------------------------------------Logs sorter-------------------------------------
      actualLogs: 1,
      title: "",
      activeButton: "StageLogs",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      ///-------------------------------------------------------MQTT-----------------------------------------------------
      connection: {
        protocol: "wss",
        host: "rally.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "", // "/mqtt"
        rejectUnauthorized: false,

        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "rally_frontend_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "",
        password: "",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,

      totalDistances: [],
      userScrolled: false,
      previousScrollHeight: 0,
      isRotating: false,
      rotationAngle: 0,
      //------------------------------------------------------------MQTT END------------------------------------------------------------------------------------------
      lineWeight: 4,
      prevZoomLevel: 12,
    };
  },
  created() {
    this.SearchRacerText = this.$t("language.SearchRacerText");
    this.searchBar = this.$t("language.searchBar");
  },
  components: {
    BlackCircleButton,
    RadarSpinner,
    CustomAlert,
    ToastAlert,
  },

  computed: {
    sidebarAnimationClass() {
      return this.SidebarIsOpen ? "slide-in-animation" : "slide-out-animation";
    },
    filteredLogs: function () {
      console.log("LOGOKRENDEZÉSE");
      if (this.popUpId == 1) {
        const searchQuery3 = this.searchQuery2.toLowerCase().trim();
        return this.logArray.filter((item) => {
          // Check if any property of the item contains the search query
          //StageChange
          if (this.actualLogs == 1) {
            if (item.racer_start_num) {
              if (item.racer_start_num.toString().includes(searchQuery3)) {
                return true;
              }
            }
            if (item.staff_name) {
              if (item.staff_name.toLowerCase().includes(searchQuery3)) {
                return true;
              }
            }
            if (item.current_stage_name.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (item.prev_stage_name.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (
              item.stage_change_log_current_stage_status
                .toLowerCase()
                .includes(searchQuery3)
            ) {
              return true;
            }
            if (
              new Date(item.stage_change_log_created_at)
                .toLocaleString()
                .includes(searchQuery3)
            ) {
              return true;
            }
          }
          //StageStatus
          if (this.actualLogs == 3 && item.stage_name != undefined) {
            if (item.stage_name.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (item.stage_status.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (
              new Date(item.stage_status_log_created_at)
                .toLocaleString()
                .includes(searchQuery3)
            ) {
              return true;
            }
          }
          //Event
          if (this.actualLogs == 2) {
            if (item.racer_start_num) {
              if (item.racer_start_num.toString().includes(searchQuery3)) {
                return true;
              }
            }
            if (item.staff_name) {
              if (item.staff_name.toLowerCase().includes(searchQuery3)) {
                return true;
              }
            }

            if (item.type.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (item.stage_name.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (
              new Date(item.event_log_created_at)
                .toLocaleString()
                .includes(searchQuery3)
            ) {
              return true;
            }
          }
          //speeding
          if (this.actualLogs == 4) {
            if (item.racer_start_num) {
              if (item.racer_start_num.toString().includes(searchQuery3)) {
                return true;
              }
            }
            if (item.staff_name) {
              if (item.staff_name.toLowerCase().includes(searchQuery3)) {
                return true;
              }
            }

            if (item.stage_name.toLowerCase().includes(searchQuery3)) {
              return true;
            }
            if (
              new Date(item.speeding_log_created_at)
                .toLocaleString()
                .includes(searchQuery3)
            ) {
              return true;
            }
          }

          return false; // If none of the item's properties match the search query
        });
      } else {
        return [];
      }
    },
    filteredRacers: function () {
      return this.racersArray
        .filter((item) => {
          if (item.racer_id) {
            if (
              item.racer_pilot_name
                .toLowerCase()
                .includes(this.searchValue.toLowerCase()) ||
              item.racer_start_num.toString().includes(this.searchValue)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
        .sort((a, b) => a.racer_start_num - b.racer_start_num);
    },
    filteredRacerList: function () {
      return this.lastDeviceArray
        .filter((item) => {
          if (item.racer_id) {
            if (
              item.racer_start_num.toString().includes(this.searchQuery4) ||
              item.racer_status.toLowerCase().includes(this.searchQuery4) ||
              item.received_at.toLowerCase().includes(this.searchQuery4)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
        .sort((a, b) => a.racer_start_num - b.racer_start_num);
    },
  },

  async mounted() {
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
    this.legId = localStorage.getItem("leg_id");
    this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
    this.stages = await dp.getStageByLegId(this.legId);
    this.pois = await dp.getPOIsByLegId(this.legId);
    this.legMap = await dp.getLegById(this.legId);
    this.legName = this.legMap.leg_name;
    this.legDate = this.legMap.leg_date;
    if (this.role != "spectator") {
      this.unhandledEventLogs = await dp.getUnhandledEventLogs(this.legId);
    }

    this.racersArray = await dp.getRacersByEventId(
      localStorage.getItem("event_id")
    );
    this.lastDeviceArray = await dp.getAllLastDeviceLog(this.legId);
    this.logArray = await dp.getStageChangeLogById(this.legId);
    this.setupLeafletMap();

    this.followRacerMap = localStorage.getItem("racer_id");
    if (
      this.followRacerMap != undefined ||
      this.followRacerMap != "undefined"
    ) {
      this.showRacerZoom();
    }

    this.mapDiv.createPane("highlightPane");
    this.mapDiv.getPane("highlightPane").style.zIndex = 399;

    this.load = false;
    document.addEventListener("click", this.outsideClick);
    this.initMQTTData();
    this.createMQTTConnection();
    this.doMQTTSubscribe(`${topic_prefix}${frontend_topic}/#`, 0);
    this.doMQTTSubscribe(`${topic_prefix}${signaling_topic}/#`, 0);
  },

  beforeUnmounted() {
    document.removeEventListener("click", this.outsideClick);
  },
  watch: {
    selectedNumber() {
      if (this.currentMarker) {
        this.getActualRacerData(this.currentMarker);
      }
    },
    filteredLogs() {
      this.$nextTick(() => {
        const container = this.$refs.tableContainer;
        if (!this.userScrolled) {
          container.scrollTop = 0;
        } else {
          const newHeight = container.scrollHeight;
          const scrollDifference = newHeight - this.previousScrollHeight;
          container.scrollTop += scrollDifference;
          this.previousScrollHeight = newHeight;
        }
      });
    },
  },
  methods: {
    handleScroll() {
      const container = this.$refs.tableContainer;
      this.userScrolled = container.scrollTop !== 0;
      if (this.userScrolled) {
        this.previousScrollHeight = container.scrollHeight;
      }
    },
    getStatusClass(racer_status) {
      return {
        "bg-red": racer_status === "SOS",
        "bg-green": racer_status === "normal",
      };
    },
    openSidebar() {
      this.isRotating = true;
      setTimeout(() => {
        this.rotationAngle += 180;
        this.SidebarIsOpen = !this.SidebarIsOpen;
        this.isRotated = !this.isRotated;
        this.isRotating = false;
      }, 500);
    },
    triggerAlert(item) {
      this.currentEventLogId = item.event_log_id;
      this.currentHandled = item.handled;
      this.showAlertModal = true;
      this.confirmText =  this.$t('language.yes'),
      this.cancelText = this.$t('language.cancelText'),
      this.bodyText = this.$t('language.alertOk')
    },
    // Egy pulzáló hatás létrehozása egy időzített függvény segítségével
    pulseEffect(polylineColor, layer) {
      var count = 0;
      var originalColor = polylineColor;
      var pulseTimer = setInterval(function () {
        if (count % 2 === 0) {
          layer.setStyle({ color: "black" }); // Fekete szín
        } else {
          layer.setStyle({ color: originalColor }); // Vagy más szín, amit szeretnél
        }
        count++;
        if (count > 8) {
          // 10-szer pulzál, 3 másodperc alatt
          clearInterval(pulseTimer); // Időzítő leállítása
          layer.setStyle({ color: "black" }); // Visszaállítjuk a fekete színt
        }
      }, 300); // 300ms-es intervallum (0.3 másodperc)
    },

    checkPopupId(id) {
      if (this.popUpId != id) {
        this.popUpId = id;
      } else {
        this.popUpId = 0;
      }
    },

    outsideClick(e) {
      if (e.target.id == "racerSearchBar") {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
    },
    validateNumericInputDwelltime(event) {
      let inputValue = event.target.value;
    inputValue = inputValue.replace(/^0+/, "");
    inputValue = inputValue.replace(/\D/g, "");
    let numericValue = parseInt(inputValue, 10);
    const min = 1;
    const max = 600000;
    if (numericValue > max) {
        numericValue = max;
    } else if (numericValue < min || isNaN(numericValue)) {
        numericValue = min;
    }
    event.target.value = numericValue;
    },
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    // deactivateSearch() {
    //   this.isSearch = false;
    // },
    setActiveButton(buttonId) {
      this.activeButton = buttonId;
    },
    async showStageLogs() {
      document.getElementById("scl").style.display = "block";
      this.logArray = [];
      this.actualLogs = 1;
      this.logArray = await dp.getStageChangeLogById(this.legId);
      this.setActiveButton("StageLogs");
      document.getElementById("scl").style.display = "none";
    },
    async showEventLogs() {
      document.getElementById("scl").style.display = "block";
      this.logArray = [];
      this.actualLogs = 2;
      this.logArray = await dp.getEventLogById(this.legId);
      this.setActiveButton("EventLogs");
      document.getElementById("scl").style.display = "none";
    },
    async showStatusLogs() {
      document.getElementById("scl").style.display = "block";
      this.logArray = [];
      this.actualLogs = 3;
      this.logArray = await dp.getStageStatusLogById(this.legId);
      this.setActiveButton("StatusLogs");
      document.getElementById("scl").style.display = "none";
    },
    async showSpeedingLogs() {
      document.getElementById("scl").style.display = "block";
      this.logArray = [];
      this.actualLogs = 4;
      this.logArray = await dp.getSpeedingLogById(this.legId);
      this.setActiveButton("SpeedingLogs");
      document.getElementById("scl").style.display = "none";
    },
    bigIcons() {
      this.toggleIconSize = true;
      for (let i = 0; i < this.carMarkerArray.length; i++) {
        let marker = this.carMarkerArray[i];
        marker.setIcon(
          L.icon({
            iconUrl: marker.options.icon.options.iconUrl,
            iconAnchor: [20, 20],
            iconSize: [40],
            rotationAngle: marker.options.icon.options.rotationAngle,
            rotationOrigin: "center",
          })
        );
      }
      for (let i = 0; i < this.carMarkerArray2.length; i++) {
        let marker = this.carMarkerArray2[i];

        if (this.lastDeviceArray[i].staff_id) {
          marker.setIcon(
            L.divIcon({
              iconAnchor: [17.5, 17.5],
              iconSize: [35],
              className: "my-div-icon",
              startnum: this.lastDeviceArray[i].staff_start_num.slice(0, 3),
              staff_id: this.lastDeviceArray[i].staff_id,

              html:
                '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
                "</b>",
            })
          );
        } else {
          marker.setIcon(
            L.divIcon({
              iconAnchor: [17.5, 17.5],
              iconSize: [35],
              className: "my-div-icon",
              startnum: this.lastDeviceArray[i].racer_start_num,
              staff_id: this.lastDeviceArray[i].racer_id,
              html:
                '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                this.lastDeviceArray[i].racer_start_num +
                "</b>",
            })
          );
        }
      }
      document.getElementsByClassName("smallBtn")[0].style.backgroundColor =
        "#dd9c44";
      document.getElementsByClassName("largeBtn")[0].style.backgroundColor =
        "#FEC31A";
    },

    smallIcons() {
      this.toggleIconSize = false;
      for (let i = 0; i < this.carMarkerArray.length; i++) {
        let marker = this.carMarkerArray[i];
        marker.setIcon(
          L.icon({
            iconUrl: marker.options.icon.options.iconUrl,
            iconAnchor: [20, 20],
            iconSize: [40],
            rotationAngle: marker.options.icon.options.rotationAngle,
            rotationOrigin: "center",
          })
        );
      }
      for (let i = 0; i < this.carMarkerArray2.length; i++) {
        let marker = this.carMarkerArray2[i];
        if (this.lastDeviceArray[i].staff_id) {
          marker.setIcon(
            L.divIcon({
              iconAnchor: [22.5, 11],
              iconSize: [20],
              className: "my-div-icon",
              startnum: this.lastDeviceArray[i].staff_start_num.slice(0, 3),
              staff_id: this.lastDeviceArray[i].staff_id,
              html:
                '<b style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:45px;height:45px;margin-top:5px; margin-right:2000px; position: relative">' +
                this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
                "</b>",
            })
          );
        } else {
          marker.setIcon(
            L.divIcon({
              iconAnchor: [22.5, 11],
              iconSize: [20],
              className: "my-div-icon",
              html:
                '<b style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:45px;height:45px;margin-top:5px; margin-right:2000px; position: relative">' +
                this.lastDeviceArray[i].racer_start_num +
                "</b>",
            })
          );
        }
        /* marker.setIcon(
            L.divIcon({
              iconAnchor: [22.5, 11],
              iconSize: [30, 30],
              className: "my-div-icon",
              html:
                '<b style="font-size:8.5px;display:inline-block;text-align:center;vertical-align:middle;width:45px;height:45px;margin-top:5px; margin-right:2000px; position: relative">' +
                this.lastDeviceArray[i].racer_start_num +
                "</b>",
            })
          ); */
      }
      document.getElementsByClassName("largeBtn")[0].style.backgroundColor =
        "#dd9c44";
      document.getElementsByClassName("smallBtn")[0].style.backgroundColor =
        "#FEC31A";
    },

    activateSearch() {
      this.isSearch = true;
      this.searchValue = "";
    },

    navToProjector() {
      this.$router.push("/projectorPage");
    },

    async showRacer(item) {
      /* for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (item.racer_id == this.lastDeviceArray[i].racer_id) {
          this.mapDiv.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ], 20);
          this.searchValue = item.racer_pilot_name;
        }
      } */

      let racerIndex = this.carMarkerArray.findIndex(
        (marker) => marker.options.racer_id == item.racer_id
      );

      if (racerIndex !== -1) {
        let centerZoom = [
          this.carMarkerArray[racerIndex]._latlng.lat,
          this.carMarkerArray[racerIndex]._latlng.lng,
        ];
        this.mapDiv.setView(centerZoom, 25);
        let markerBorder = this.carMarkerArray[racerIndex]._icon;

        const originalBorder = markerBorder.style.border;
        const originalBorderRadius = markerBorder.style.borderRadius;
        const originalBackgroundColor = markerBorder.style.backgroundColor;

        markerBorder.style.border = "3px solid #dd9c44";
        markerBorder.style.borderRadius = "60px";
        markerBorder.style.backgroundColor = "rgba(0,0,0,0.2)";

        setTimeout(() => {
          markerBorder.style.border = originalBorder;
          markerBorder.style.borderRadius = originalBorderRadius;
          markerBorder.style.backgroundColor = originalBackgroundColor;
        }, 500);
      }
    },

    setupLeafletMap: function () {
      const mapParams = (L.MapOptions = {
        zoomControl: false,
        doubleClickZoom: false,
        scrollWheelZoom: true,
        attributionControl: false,
        zoomAnimation: false,
      });
      this.mapDiv = L.map("mapContainer", mapParams).setView(this.center, 17);
      this.mapDiv.on("zoom", () => {
    if (window.innerWidth > 500) return;

    let zoomLevel = this.mapDiv.getZoom();
    let thicknessChange = 2;
    let maxThickness = 10;
    let minThickness = 2;

    if (zoomLevel <= 16 && zoomLevel >= 12) {
        if (zoomLevel > this.prevZoomLevel) {
            this.lineWeight = Math.min(this.lineWeight + thicknessChange, maxThickness);
        } else {
            this.lineWeight = Math.max(this.lineWeight - thicknessChange, minThickness);
        }

        // Explicitly set the line weight at the boundary levels
        if (zoomLevel === 12) {
            this.lineWeight = minThickness;
        } else if (zoomLevel === 16) {
            this.lineWeight = maxThickness;
        }

        for (const polyLine of this.polyLineArray) {
            polyLine.setStyle({ weight: this.lineWeight });
        }

        if (this.highlightBorder) {
            this.highlightBorder.setStyle({
                weight: Math.max(this.lineWeight + 5, 5),
            });
        }
    }

    this.prevZoomLevel = zoomLevel;
});
      this.setLightLayer();

      //-----------------------------------Alerts-----------------------------------------------

      this.numberOfUnhandledEventLogs = this.unhandledEventLogs.length;
      //-----------------------------------Alerts End-----------------------------------------------

      //-----------------------------------STAGE HANDLER------------------------------------------
      for (let i = 0; i < this.stages.length; i++) {
        let stageCoordData = JSON.parse(this.stages[i].stage_line);

        let latlngs = stageCoordData.coordinates;

        //track centring
        this.allCoords = this.allCoords.concat(latlngs);
        //console.log(this.allCoords)
        //----------------

        this.newCenter = latlngs[1];
        let color = undefined;
        if (this.stageLogs[i].stage_status == "normal") {
          color = "green";
        }
        if (this.stageLogs[i].stage_status == "red flag") {
          color = "red";
        }
        if (this.stageLogs[i].stage_status.includes("limit")) {
          color = "yellow";
        } else if (this.stageLogs[i].stage_status == "transport") {
          color = "blue";
        }
        this.polyLine = undefined;
        this.polyLine = L.polyline(latlngs, {
          color: color,
          weight: this.lineWeight,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv);

        this.polyLine.on("click", (e) => {
          let highlightedElements =
            document.querySelectorAll(".highlighted-tr");

          highlightedElements.forEach(function (element) {
            element.classList.remove("highlighted-tr");
          });

          if (!this.isOpen) {
            if (document.getElementById("bc2")) {
              document.getElementById("bc2").click();
            }

            setTimeout(() => {
              let idString = e.target.options.id.toString().slice(9);

              if (this.highlightBorder != undefined) {
                this.mapDiv.removeLayer(this.highlightBorder);
              }

              let combinedString = ("table-tr" + idString).toString();

              document
                .getElementById(combinedString)
                .scrollIntoView({ behavior: "smooth", block: "center" });
              document
                .getElementById(combinedString)
                .classList.add("highlighted-tr");

              this.highlightBorder = L.polyline(latlngs, {
                color: "black",
                weight: this.lineWeight + 5,
                pane: "highlightPane",
              }).addTo(this.mapDiv);

              document
                .getElementById(combinedString)
                .scrollIntoView({ behavior: "smooth", block: "center" });
            }, 100);
          } else {
            let idString = e.target.options.id.toString().slice(9);

            if (this.highlightBorder != undefined) {
              this.mapDiv.removeLayer(this.highlightBorder);
            }

            let combinedString = ("table-tr" + idString).toString();

            document
              .getElementById(combinedString)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            document
              .getElementById(combinedString)
              .classList.add("highlighted-tr");

              console.log("lájnweight",this.lineWeight);
              
            this.highlightBorder = L.polyline(latlngs, {
              color: "black",
              weight: this.lineWeight + 5,
              pane: "highlightPane",
            }).addTo(this.mapDiv);

            document
              .getElementById(combinedString)
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
        this.polyLineArray.push(this.polyLine);
      }
      //this.mapDiv.setView(this.newCenter);

      //track centring
      let bounds = L.latLngBounds(this.allCoords);
      this.mapDiv.fitBounds(bounds);
      //---------------

      this.poiHandler();
      this.carMarkerHandler();

      //---------------------------------MAP CONTROLS-----------------------------------------------
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv);
    },

    setMapViewButtonClick(index, id) {
      let highlightedElements = document.querySelectorAll(".highlighted-tr");
      let trID = id.toString();
      if (this.storedID != trID) {
        document.getElementById(trID).classList.remove("highlighted-tr");
      }
      document.getElementById(trID).classList.add("highlighted-tr");
      this.storedID = trID;
      highlightedElements.forEach(function (element) {
        element.classList.remove("highlighted-tr");
      });
      this.setMapView(index);
    },

    changeMeasure() {
      event.stopPropagation();
      this.measure = !this.measure;
      if (this.measure == true) {
        document.getElementById("mapContainer").style.cursor = "crosshair";
        this.mapDiv.on("click", this.measureDistance);
        // this.mapDiv.on("click", this.calculateTotalDistance);
        var currentZoom = this.mapDiv.getZoom();
        this.mapDiv.setMaxZoom(currentZoom);
        this.mapDiv.setMinZoom(currentZoom);
      } else if (this.measure == false) {
        document.getElementById("mapContainer").style.cursor = "auto";

        this.mapDiv.off("click", this.measureDistance);

        for (let i = 0; i < this.measureMarkers.length; i++) {
          this.mapDiv.removeLayer(this.measureMarkers[i]);
        }
        for (let i = 0; i < this.measureLines.length; i++) {
          this.mapDiv.removeLayer(this.measureLines[i]);
        }
        for (let i = 0; i < this.measureArrows.length; i++) {
          this.mapDiv.removeLayer(this.measureArrows[i]);
        }

        this.measureLines = [];
        this.measureMarkers = [];
        this.measureArrows = [];
        this.totalDistance = 0;
        this.totalDistanceString = "0 m";
        this.uniqueDistances = [];
        this.distanceArray = [];
        this.mapDiv.setMaxZoom(22);
        this.mapDiv.setMinZoom(1);
        this.totalDistances = [];
      }
    },

    hideMeasure() {
      this.measureShow = !this.measureShow;

      if (this.measureShow == false) {
        this.mapDiv.off("click", this.measureDistance);
        document.getElementById("mapContainer").style.cursor = "auto";
        for (let i = 0; i < this.measureMarkers.length; i++) {
          this.mapDiv.removeLayer(this.measureMarkers[i]);
        }
        for (let i = 0; i < this.measureLines.length; i++) {
          this.mapDiv.removeLayer(this.measureLines[i]);
        }
        for (let i = 0; i < this.measureArrows.length; i++) {
          this.mapDiv.removeLayer(this.measureArrows[i]);
        }

        this.mapDiv.setMaxZoom(22);
        this.mapDiv.setMinZoom(1);
      }
      if (this.measureShow == true) {
        this.mapDiv.on("click", this.measureDistance);
        document.getElementById("mapContainer").style.cursor = "crosshair";
        for (let i = 0; i < this.measureMarkers.length; i++) {
          this.measureMarkers[i].addTo(this.mapDiv);
        }
        for (let i = 0; i < this.measureLines.length; i++) {
          this.measureLines[i].addTo(this.mapDiv);
        }
        for (let i = 0; i < this.measureArrows.length; i++) {
          this.measureArrows[i].addTo(this.mapDiv);
        }
        var currentZoom = this.mapDiv.getZoom();
        this.mapDiv.setMaxZoom(currentZoom);
        this.mapDiv.setMinZoom(currentZoom);
      }
    },

    calculateBearing(coord1, coord2) {
      const y = Math.sin(coord2[1] - coord1[1]) * Math.cos(coord2[0]);
      const x =
        Math.cos(coord1[0]) * Math.sin(coord2[0]) -
        Math.sin(coord1[0]) *
          Math.cos(coord2[0]) *
          Math.cos(coord2[1] - coord1[1]);
      const bearing = (Math.atan2(y, x) * 180) / Math.PI;
      this.bearing = 360 - ((bearing + 360) % 360);
    },
    calculateHalfwayPoint(coord1, coord2) {
      const lat1 = coord1[0];
      const lon1 = coord1[1];
      const lat2 = coord2[0];
      const lon2 = coord2[1];

      const dLon = this.toRadians(lon2 - lon1);
      const lat1Rad = this.toRadians(lat1);
      const lat2Rad = this.toRadians(lat2);
      const lon1Rad = this.toRadians(lon1);

      const Bx = Math.cos(lat2Rad) * Math.cos(dLon);
      const By = Math.cos(lat2Rad) * Math.sin(dLon);
      const lat3 = Math.atan2(
        Math.sin(lat1Rad) + Math.sin(lat2Rad),
        Math.sqrt((Math.cos(lat1Rad) + Bx) * (Math.cos(lat1Rad) + Bx) + By * By)
      );
      const lon3 = lon1Rad + Math.atan2(By, Math.cos(lat1Rad) + Bx);

      this.halfwayCoord = [this.toDegrees(lat3), this.toDegrees(lon3)];
    },
    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },
    toDegrees(radians) {
      return radians * (180 / Math.PI);
    },

    measureDistance(e) {
      const haversine = require("haversine");
      const { lat, lng } = e.latlng;
      let measureIcon = L.icon({
        iconUrl: "measureDot.png",
        iconSize: [15, 15],
      });

      this.measureMarker = L.marker([lat, lng], {
        icon: measureIcon,
      }).addTo(this.mapDiv);
      // this.measureMarker.on('drag', () =>{
      //   this.dragLines();
      // })

      this.measureMarkers.push(this.measureMarker);
      // for (let i = 1; i < this.measureMarkers.length; i++) {

      let i = this.measureMarkers.length - 1;

      if (i > 0) {
        let start = {
          latitude: this.measureMarkers[i - 1]._latlng.lat,
          longitude: this.measureMarkers[i - 1]._latlng.lng,
        };
        let end = {
          latitude: this.measureMarkers[i]._latlng.lat,
          longitude: this.measureMarkers[i]._latlng.lng,
        };

        this.calculateHalfwayPoint(
          [start.latitude, start.longitude],
          [end.latitude, end.longitude]
        );

        this.bearing = 0;

        this.calculateBearing(
          [start.latitude, start.longitude],
          [end.latitude, end.longitude]
        );

        let measureArrow = L.icon({
          iconUrl: "arrow.png",
          iconSize: [14, 14],
          iconAnchor: [7, 7],
        });

        let measureArrowIcon = L.marker(this.halfwayCoord, {
          icon: measureArrow,
          rotationAngle: this.bearing,
        }).addTo(this.mapDiv);

        this.measureArrows.push(measureArrowIcon);
        this.distance = 0;
        this.distance = haversine(start, end, { unit: "meter" });

        this.distanceArray.push(this.distance);

        this.calculateTotalDistance();

        //let distanceString = this.distance.toString().slice(0, 8) + " m";
        let num = Number(this.totalDistances.length) - 1;

        let lastTotalDistance = this.totalDistances[num];
        this.measureMarker
          .bindTooltip(lastTotalDistance.toString().slice(0, 7) + " m")
          .openTooltip();

        let polyLine = L.polyline([
          [
            this.measureMarkers[i - 1]._latlng.lat,
            this.measureMarkers[i - 1]._latlng.lng,
          ],
          [
            this.measureMarkers[i]._latlng.lat,
            this.measureMarkers[i]._latlng.lng,
          ],
        ])
          .setStyle({ color: "#dd9c44" })
          .addTo(this.mapDiv);

        this.measureLines.push(polyLine);
      }

      // }
    },

    // dragLines(){
    // },

    async calculateTotalDistance() {
      let uniqueSet = new Set(this.distanceArray);
      this.uniqueDistances = Array.from(uniqueSet);

      let length = this.uniqueDistances.length;

      if (length > 0) {
        this.totalDistance += this.uniqueDistances[length - 1];
        this.totalDistances.push(this.totalDistance);
      }

      this.totalDistanceString =
        this.totalDistance.toString().slice(0, 7) + " m";
    },

    poiHandler() {
      for (let i = 0; i < this.pois.length; i++) {
        let marker = [
          Number(this.pois[i].poi_latitude),
          Number(this.pois[i].poi_longitude),
        ];
        this.markers.push(marker);
      }
      for (let i = 0; i < this.markers.length; i++) {
        let poiIcon;

        if (this.pois[i].poi_type.toLowerCase() == "radio") {
          poiIcon = L.divIcon({
            className: "radio-marker-icon",
            html: `<div style="position: relative;"><img src="icons/radio.png" alt="Radio Icon" style="width: 38px; height: 20px;"><span style="position: absolute; top: 50%; left: 230%; transform: translate(-50%, -50%);">${this.pois[
              i
            ].poi_name
              .split("|")[0]
              .replace("#", "")
              .trim()}</span></div>`,
            iconUrl: "icons/radio.png",
          });
        } else {
          poiIcon = L.icon({
            iconUrl: "icons/" + this.pois[i].poi_type.toLowerCase() + ".png",
            iconSize: [25, 25],
          });
        }

        this.poiTypeCounter = 0;

        for (let j = 0; j < this.poiTypes.length; j++) {
          if (
            this.poiTypes[j].toLowerCase() ==
            this.pois[i].poi_type.toLowerCase()
          ) {
            this.poiTypeCounter++;
          }
        }

        if (this.poiTypeCounter == 0) {
          this.poiTypes.push(this.pois[i].poi_type.toLowerCase());
        }

        let markerElement = L.marker(this.markers[i], {
          icon: poiIcon,
        }).addTo(this.mapDiv);

        //let bounds = L.latLngBounds(this.markers);
        //this.mapDiv.fitBounds(bounds);

        const popupContent = `
        <table>
          <tr>
            <th>Name &ensp;</th>
            <td>${this.pois[i].poi_name}</td>
          </tr>
          <tr>
            <th>Type &ensp;</th>
            <td>${this.pois[i].poi_type.toLowerCase()}</td>
          </tr>
          <tr>
            <th>Description &ensp;</th>
            <td>${this.pois[i].poi_description}</td></tr>
        </table>
      `;

        markerElement.bindPopup(popupContent);

        markerElement.on("mouseover", function () {
          this.openPopup();
        });
        markerElement.on("mouseout", function () {
          this.closePopup();
        });

        this.markerElements.push(markerElement);
      }
    },

    async carMarkerHandler() {
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        let marker = [
          Number(this.lastDeviceArray[i].latitude),
          Number(this.lastDeviceArray[i].longitude),
        ];
        let status = this.lastDeviceArray[i].racer_status;
        let isTransporting = false;
        if (this.lastDeviceArray[i].racer_status === "SOS") {
          status = "SOS";
        }else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                } else {
          for (let index = 0; index < this.stages.length; index++) {
            if (
              this.stageLogs[index]?.stage_id ==
                this.lastDeviceArray[i].closest_stage &&
              this.stageLogs[index].stage_status == "transport"
            ) {
              isTransporting = true;
              if (
                this.lastDeviceArray[i].velocity >= 0 &&
                this.lastDeviceArray[i].velocity <= 10
              ) {
                status = "stoppedTransport";
              } else {
                status = "movingTransport";
              }
              break;
            }
          }
          if (!isTransporting) {
            status = this.lastDeviceArray[i].racer_status;
          }
        }
        let iconColor;
        switch (status) {
          case "normal":
            iconColor = "black";
            break;
          case "SOS":
            iconColor = "red";
            break;
          case "OK":
            iconColor = "green";
            break;
          case "stoppedTransport":
            iconColor = "grey";
            break;
          case "movingTransport":
            iconColor = "white";
            break;
          case "stopped":
            iconColor = "yellow";
            break;
          default:
            iconColor = "black";
            break;
        }
        let carIcon = L.icon({
          iconUrl: "icons/carIcons/" + iconColor + ".webp",
          iconAnchor: this.toggleIconSize ? [30, 30] : [20, 20],
          iconSize: this.toggleIconSize ? [40] : [40],
          rotationAngle: this.lastDeviceArray[i].bearing,
          rotationOrigin: "center",
        });
        let carIcon2;
        if (this.lastDeviceArray[i].staff_id) {
          carIcon2 = L.divIcon({
            iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
            iconSize: this.toggleIconSize ? [35] : [20],
            startnum: this.lastDeviceArray[i].staff_start_num.slice(0, 3),
            staff_id: this.lastDeviceArray[i].staff_id,

            className: "my-div-icon",
            html:
              '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
              "</b>",
          });
        } else {
          carIcon2 = L.divIcon({
            iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
            iconSize: this.toggleIconSize ? [35] : [20],
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,

            className: "my-div-icon",
            html:
              '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          });
        }

        let Marker = L.marker(marker, {
          icon: carIcon,
          autoPanOnFocus: false,
          rotationAngle: this.lastDeviceArray[i].bearing,
          racer_id: this.lastDeviceArray[i].racer_id,
        }).addTo(this.mapDiv);

        let Marker2 = L.marker(marker, {
          icon: carIcon2,
          autoPanOnFocus: false,
          racer_id: this.lastDeviceArray[i].racer_id,
        })
          .addTo(this.mapDiv)
          .on("click", this.getActualRacerData);

        this.carMarkerArray.push(Marker);
        this.carMarkerArray2.push(Marker2);
      }
    },

   /*  async renderChart(dummyArrayGforce, dummyArrayLabel, dummyArraySpeed) {
      this.actualRacerGForces = [...dummyArrayGforce];
      this.actualRacerSpeeds = [...dummyArraySpeed];
      this.modalChartLabels = [...dummyArrayLabel];

      if (this.chart != undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      if (this.chart2 != undefined) {
        this.chart2.destroy();
        this.chart2 = undefined;
      }
      if (this.$refs.lineChart != undefined) {
        const ctx1 = await this.$refs.lineChart.getContext("2d");

        let translatedChartSpeed = this.$t("language.chartSpeed");

        this.chart = new Chart(ctx1, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartSpeed,
                fill: true,
                data: this.actualRacerSpeeds,
                backgroundColor: "rgb(90,145,101, 0.5)",
                borderColor: "#5A9165",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            labels: {
              fontColor: "white",
            },
            animation: {
              duration: 0,
            },
          },
        });
      }

      if (this.$refs.lineChart2 != undefined) {
        const ctx2 = await this.$refs.lineChart2.getContext("2d");
        let translatedChartG = this.$t("language.chartG");
        this.chart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartG,
                data: this.actualRacerGForces,
                backgroundColor: "rgb(255, 217, 100, 0.5)",
                borderColor: "#DD9C44",
                fill: true,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
          },
        });
      }
    }, */
    async renderChart(dummyArrayGforce, dummyArrayLabel, dummyArraySpeed) {
      let combinedData = dummyArrayLabel.map((label, index) => ({
          label: DateTime.fromFormat(label, "HH:mm:ss"), 
          gforce: dummyArrayGforce[index],
          speed: dummyArraySpeed[index],
      }));

      combinedData.sort((a, b) => a.label - b.label);

      this.actualRacerGForces = combinedData.map(item => item.gforce);
      this.actualRacerSpeeds = combinedData.map(item => item.speed);
      this.modalChartLabels = combinedData.map(item => item.label.toFormat("HH:mm:ss"));

      if (this.chart != undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      if (this.chart2 != undefined) {
        this.chart2.destroy();
        this.chart2 = undefined;
      }

      if (this.$refs.lineChart != undefined) {
        const ctx1 = await this.$refs.lineChart.getContext("2d");

        let translatedChartSpeed = this.$t("language.chartSpeed");

        this.chart = new Chart(ctx1, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartSpeed,
                fill: true,
                data: this.actualRacerSpeeds,
                backgroundColor: "rgb(90,145,101, 0.5)",
                borderColor: "#5A9165",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            labels: {
              fontColor: "white",
            },
            animation: {
              duration: 0,
            },
          },
        });
      }

      if (this.$refs.lineChart2 != undefined) {
        const ctx2 = await this.$refs.lineChart2.getContext("2d");
        let translatedChartG = this.$t("language.chartG");
        this.chart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: this.modalChartLabels,
            datasets: [
              {
                label: translatedChartG,
                data: this.actualRacerGForces,
                backgroundColor: "rgb(255, 217, 100, 0.5)",
                borderColor: "#DD9C44",
                fill: true,
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
          },
        });
      }
    },

    closeRacer() {
      this.isSelected = false;
    },

    navToRacer() {
      localStorage.setItem("racer_id", this.selectedRacer.racer_id);
      localStorage.setItem("prevPage", "LiveMap");
      this.$router.push("/racerinfo");
    },
    showRacerZoom() {
      let racerIndex = this.carMarkerArray.findIndex(
        (marker) => marker.options.racer_id == this.followRacerMap
      );
      if (racerIndex !== -1) {
        let centerZoom = [
          this.carMarkerArray[racerIndex]._latlng.lat,
          this.carMarkerArray[racerIndex]._latlng.lng,
        ];
        this.mapDiv.setView(centerZoom, 25);
      }
      localStorage.setItem("racer_id", undefined);
    },
    async getActualRacerData(marker) {
      this.currentMarker = marker;
      this.loading = true;
      this.isSelected = !this.isSelected;
      this.isSelected = true;
      this.actualRacerGForces = [];
      this.actualRacerSpeeds = [];
      this.modalChartLabels = [];
      try {
        for (let i = 0; i < this.lastDeviceArray.length; i++) {
          if (this.lastDeviceArray[i].racer_start_num) {
            if (
              marker.sourceTarget.options.icon.options.startnum ==
              this.lastDeviceArray[i].racer_start_num
            ) {
              this.actualLastData = await dp.getDeviceLogByRacerIdLimit(
                this.lastDeviceArray[i].racer_id,
                this.selectedNumber,
                this.lastDeviceArray[i].staff_id
              );
              this.selectedRacer = await dp.getRacersById(
                this.lastDeviceArray[i].racer_id
              );
            }
          } else if (this.lastDeviceArray[i].staff_start_num) {
            if (
              marker.sourceTarget.options.icon.options.startnum ==
              this.lastDeviceArray[i].staff_start_num.slice(0, 3)
            ) {
              this.actualLastData = await dp.getDeviceLogByRacerIdLimit(
                this.lastDeviceArray[i].racer_id,
                this.selectedNumber,
                this.lastDeviceArray[i].staff_id
              );
              this.selectedRacer = await dp.getStaffById(
                this.lastDeviceArray[i].staff_id
              );
            }
          }
        }

        this.loading = false;
        let dummyArrayGforce = [];
        let dummyArrayLabel = [];
        let dummyArraySpeed = [];

        for (let i = 0; i < this.actualLastData.length; i++) {
          dummyArraySpeed.push(this.actualLastData[i].velocity);
          dummyArrayGforce.push(this.actualLastData[i].g_force);
          dummyArrayLabel.push(
            DateTime.fromISO(this.actualLastData[i].recorded_at).toLocaleString(
              {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hourCycle: "h23",
              }
            )
          );
        }

        await this.renderChart(
          dummyArrayGforce,
          dummyArrayLabel,
          dummyArraySpeed
        );
      } catch (error) {
        console.log(error);
      }
    },

    poiFilter(item) {
      for (let i = 0; i < this.markerElements.length; i++) {
        let itemString = "icons/" + item + ".png";
        let variableString =
          item.toString().replace("-", "").toLowerCase() + "Show";
        if (this.markerElements[i].options.icon.options.iconUrl == itemString) {
          if (this[variableString] == true) {
            this.mapDiv.removeLayer(this.markerElements[i]);
            this[variableString] = false;
          } else if (this[variableString] == false) {
            this.markerElements[i].addTo(this.mapDiv);
            this[variableString] = true;
          }
        }
      }
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    formatTime(timeStr) {
      const eventTime = new Date(timeStr);
      const hours = eventTime.getHours();
      const minutes = ("0" + eventTime.getMinutes()).slice(-2);
      return hours + ":" + minutes;
    },
    navigateBack() {
      localStorage.setItem("racer_id", undefined);
      if (localStorage.getItem("prevPageFromLive") == "Home") {
        this.$router.push("/home");
      } else if (localStorage.getItem("prevPageFromLive") == "EventList") {
        this.$router.push("/legs");
      }
    },
    toggleDropDown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    logUserOut() {
      localStorage.removeItem("user_id");
      this.$router.push("/");
    },
    async handleAlert(event_log_id, handled) {
      await dp.updateEventLog(event_log_id, !handled);
      // saját tömbből töröljük a logot
      this.numberOfUnhandledEventLogs -= 1;
      let index = this.unhandledEventLogs.findIndex(
        (element) => element.event_log_id == event_log_id
      );
      if (index != -1) {
        this.unhandledEventLogs.splice(index, 1);
      }
      /* this.eventLogs = this.eventLogs.filter(
        (item) => item.event_log_id != event_log_id
      );
      this.unhandledEventLogs = this.eventLogs.filter(
        (item) => item.handled == false
      ); */
    },
    setLightLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    setDarkLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#101010";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    setSatelliteLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#71705c";
      this.baseLayer = L.tileLayer(
        "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    changeLayer(layer) {
      if (this.switchLayer != 0 && layer == 0) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setLightLayer();
      }
      if (this.switchLayer != 1 && layer == 1) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setDarkLayer();
      }
      if (this.switchLayer != 2 && layer == 2) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setSatelliteLayer();
      }
    },
    closePopup() {
      this.popUpId = 0;
      this.isOpen = false;
    },
    async dwellTime(stage_id) {
      let inputElement = document.getElementById("dwellTimeInput" + stage_id);
      let inputValue = parseInt(inputElement.value, 10);

      if (isNaN(inputValue) || inputValue < 0) {
          this.showAlert2 = true;
          this.confirmText = "Ok";
          this.cancelText = this.$t('language.cancelText');
          this.bodyText = "Dwell Time must be a valid number";
          inputElement.value = 6000000;
      } else {
          await dp.changeDwellTime(inputValue, stage_id);
          this.stages = await dp.getStageByLegId(this.legId);
          return inputValue;
      }
    },

    setMapView(index) {
      let stageCoordData = JSON.parse(this.stages[index].stage_line);
      let latlngs = stageCoordData.coordinates;

      if (this.highlightBorder != undefined) {
        this.mapDiv.removeLayer(this.highlightBorder);
      }

      let middleCoord = latlngs.length / 2;
      middleCoord = parseInt(middleCoord);

      // Az eltolás mértéke
      const offsetX = -0.008; // Az X irányú eltolás mértéke (pozitív érték jobbra, negatív balra)
      const offsetY = -0.015; // Az Y irányú eltolás mértéke (pozitív érték lefelé, negatív felfelé)

      // A térkép új középpontjának beállítása az eltolás hozzáadásával
      this.mapDiv.setView(
        [latlngs[middleCoord][0] + offsetX, latlngs[middleCoord][1] + offsetY],
        15
      );

      // A pulzáló hatás hozzáadása a borderhez
      this.highlightBorder = L.polyline(latlngs, {
        color: "black",
        weight: "7",
        pane: "highlightPane",
      }).addTo(this.mapDiv);

      this.pulseEffect(L.polyline, this.highlightBorder);
    },

    // set map status
    async setNormal(id) {
      await dp.addStageLog(undefined, id, "normal");
      this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
      for (const [index, polyline] of this.polyLineArray.entries()) {
        // for of with index
        if (polyline.options.id == "stageLine" + id) {
          let element = document.getElementsByTagName(`path`)[index];
          element.setAttribute("stroke", "green");
          break;
        }
      }
    },
    async setRedFlag(id) {
      await dp.addStageLog(undefined, id, "red flag");
      this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
      for (const [index, polyline] of this.polyLineArray.entries()) {
        // for of with index
        if (polyline.options.id == "stageLine" + id) {
          let element = document.getElementsByTagName(`path`)[index];
          element.setAttribute("stroke", "red");
          break;
        }
      }
    },
    async speedLimit(stage_id) {
      if (document.getElementById("speedLimitInput" + stage_id).value < 0) {
        /* alert("Speed Limit must be a valid number"); */
        this.showAlert2 = true;
        
        this.bodyText = "Speed Limit must be a valid number";
        document.getElementById("speedLimitInput" + stage_id).value = 0;
      } else {
        let value = document.getElementById("speedLimitInput" + stage_id).value;
        await dp.addStageLog(undefined, stage_id, "limit " + value);
        this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
        for (const [index, polyline] of this.polyLineArray.entries()) {
          // for of with index
          if (polyline.options.id == "stageLine" + stage_id) {
            let element = document.getElementsByTagName(`path`)[index];
            element.setAttribute("stroke", "yellow");
            break;
          }
        }
      }
    },
    //--------------------------------MQTT FUNCTIONS----------------------------------
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleMQTTOnReConnect() {
      /* 
      this.retryTimes += 1; */
      console.log("Mqtt reconnecting");
      /* 
      if (this.retryTimes > 10) {
        try {
          this.client.end();
          this.initMQTTData();
          this.client.reconnect()

        } catch (error) {
          console.log(error);
        }
      } */
    },
    createMQTTConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleMQTTOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
          });
          this.client.on("message", (topic, message) => {
            if (topic.includes(frontend_topic)) {
              let parsedMessage = JSON.parse(message);
              parsedMessage.snapped_coord = parsedMessage.snapped_coord?.slice(
                6,
                -1
              );
              let coordinates = parsedMessage.snapped_coord?.split(" ");
              parsedMessage.snapped_coord = {};
              parsedMessage.snapped_coord.coordinates = [];
              /*  if(coordinates){
              parsedMessage.snapped_coord.coordinates[0] = parseFloat(
                coordinates[0]
              );
              
              parsedMessage.snapped_coord.coordinates[1] = parseFloat(
                coordinates[1]
              );
              } */
              if (coordinates && coordinates.length === 2) {
                parsedMessage.snapped_coord = {
                  coordinates: [
                    parseFloat(coordinates[0]),
                    parseFloat(coordinates[1]),
                  ],
                };
              } else {
                console.warn("Invalid snapped coordinates:", coordinates);
              }
              let racerIndex = -1;
              racerIndex = this.lastDeviceArray.findIndex((item) => {
                if (
                  item.racer_id == parsedMessage.racer_id ||
                  item.staff_id == parsedMessage.staff_id
                ) {
                  return true;
                }
              });
              if (racerIndex != -1) {
                // Updating Velocity
                this.lastDeviceArray[racerIndex] = {
                  ...this.lastDeviceArray[racerIndex],
                  ...parsedMessage,
                };
                // Updating Status and changing color
                let status = parsedMessage.racer_status;
                let isTransporting = false;
                if (parsedMessage.racer_status === "SOS") {
                  status = "SOS";
                }
               else if (parsedMessage.racer_status === "stopped") {
                  status = "stopped";
                } else {
                  for (let index = 0; index < this.stages.length; index++) {
                    if (
                      this.stageLogs[index]?.stage_id ==
                        parsedMessage.closest_stage &&
                      this.stageLogs[index].stage_status == "transport"
                    ) {
                      isTransporting = true;
                      if (
                        parsedMessage.velocity >= 0 &&
                        parsedMessage.velocity <= 10
                      ) {
                        status = "stoppedTransport";
                      } else {
                        status = "movingTransport";
                      }
                      break;
                    }
                  }
                  if (!isTransporting) {
                    status = parsedMessage.racer_status;
                  }
                }
                let iconColor;
                switch (status) {
                  case "normal":
                    iconColor = "black";
                    break;
                  case "SOS":
                    iconColor = "red";
                    break;
                  case "OK":
                    iconColor = "green";
                    break;
                  case "stoppedTransport":
                    iconColor = "grey";
                    break;
                  case "movingTransport":
                    iconColor = "white";
                    break;
                  case "stopped":
                    iconColor = "yellow";
                    break;
                  default:
                    iconColor = "black";
                    break;
                }
                let vehicleIcon = L.icon({
                  iconUrl: `icons/carIcons/${iconColor}.webp`,
                  racerStatus: parsedMessage.racer_status,
                  iconAnchor: this.toggleIconSize ? [30, 30] : [20, 20],
                  iconSize: this.toggleIconSize ? [40] : [40],
                  rotationAngle: parsedMessage.bearing,
                  rotationOrigin: "center",
                });
                let vehicleIcon2;
                if (this.lastDeviceArray[racerIndex].staff_id) {
                  vehicleIcon2 = L.divIcon({
                    iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
                    iconSize: this.toggleIconSize ? [35] : [20],
                    startnum: this.lastDeviceArray[
                      racerIndex
                    ].staff_start_num.slice(0, 3),
                    staff_id: this.lastDeviceArray[racerIndex].staff_id,

                    className: "my-div-icon",
                    html: this.toggleIconSize
                      ? '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[racerIndex].staff_start_num.slice(
                          0,
                          3
                        ) +
                        "</b>"
                      : '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[racerIndex].staff_start_num.slice(
                          0,
                          3
                        ) +
                        "</b>",
                  });
                } else {
                  vehicleIcon2 = L.divIcon({
                    iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
                    iconSize: this.toggleIconSize ? [35] : [20],
                    startnum: this.lastDeviceArray[racerIndex].racer_start_num,
                    racer_id: this.lastDeviceArray[racerIndex].racer_id,

                    className: "my-div-icon",
                    html: this.toggleIconSize
                      ? '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[racerIndex].racer_start_num +
                        "</b>"
                      : '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[racerIndex].racer_start_num +
                        "</b>",
                  });
                }

                // Refrehing Markers if they change in ssome attribute, like RotationAngle, ID, Coordinates, Status
                if (
                  this.carMarkerArray[racerIndex]?.options.rotationAngle !=
                    parsedMessage.bearing ||
                  this.carMarkerArray[racerIndex]?.options.id !=
                    parsedMessage.racer_id ||
                  (this.carMarkerArray[racerIndex]?._latlng.lat !=
                    parsedMessage.latitude &&
                    this.carMarkerArray[racerIndex]?._latlng.lat !=
                      parsedMessage.snapped_coord.coordinates[0]) ||
                  (this.carMarkerArray[racerIndex]?._latlng.lng !=
                    parsedMessage.longitude &&
                    this.carMarkerArray[racerIndex]?._latlng.lng !=
                      parsedMessage.snapped_coord.coordinates[1])
                ) {
                  //Updating RotationAngle on marker
                  this.carMarkerArray[racerIndex].options.rotationAngle =
                    parsedMessage.bearing;
                  // Updating LatLong on marker
                  this.carMarkerArray[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  // Updating Icon on marker
                  this.carMarkerArray[racerIndex].setIcon(vehicleIcon);
                  // Updating id on marker
                  this.carMarkerArray[racerIndex].options.id =
                    parsedMessage.racer_id;

                  // Updating marker LATLNG on number marker
                  this.carMarkerArray2[racerIndex].setLatLng([
                    parsedMessage.stage_distance > range
                      ? parsedMessage.latitude
                      : parsedMessage.snapped_coord.coordinates[0],
                    parsedMessage.stage_distance > range
                      ? parsedMessage.longitude
                      : parsedMessage.snapped_coord.coordinates[1],
                  ]);
                  //updating Icon on number marker
                  this.carMarkerArray2[racerIndex].setIcon(vehicleIcon2);
                  //updating ID on number marker
                  this.carMarkerArray2[racerIndex].options.id =
                    parsedMessage.racer_id || parsedMessage.staff_id;
                }
                if (this.chart) {
                  // ha rossz kell bele ez: && this.selectedRacer
                  if (
                    (this.selectedRacer.racer_id ==
                      this.lastDeviceArray[racerIndex].racer_id &&
                      this.selectedRacer.racer_id != undefined) ||
                    (this.selectedRacer.staff_id ==
                      this.lastDeviceArray[racerIndex].staff_id &&
                      this.selectedRacer.staff_id != undefined)
                  ) {
                    this.reloadChartData(parsedMessage);
                  }
                }
              } else {
                //ha nincs még marker
                this.lastDeviceArray.push(parsedMessage);
                let i = this.lastDeviceArray.length - 1;
                let marker = [
                  parseFloat(this.lastDeviceArray[i].latitude),
                  parseFloat(this.lastDeviceArray[i].longitude),
                ];
                let status =
                  this.lastDeviceArray[i].racer_status ||
                  this.lastDeviceArray[i].staff_status;
                let isTransporting = false;
                if (this.lastDeviceArray[i].racer_status === "SOS") {
                  status = "SOS";
                }else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                } else {
                  for (let index = 0; index < this.stages.length; index++) {
                    if (
                      this.stageLogs[index]?.stage_id ==
                        this.lastDeviceArray[i].closest_stage &&
                      this.stageLogs[index].stage_status == "transport"
                    ) {
                      isTransporting = true;
                      if (
                        this.lastDeviceArray[i].velocity >= 0 &&
                        this.lastDeviceArray[i].velocity <= 10
                      ) {
                        status = "stoppedTransport";
                      } else {
                        status = "movingTransport";
                      }
                      break;
                    }
                  }
                  if (!isTransporting) {
                    status =
                      this.lastDeviceArray[i].racer_status ||
                      this.lastDeviceArray[i].staff_status;
                  }
                }
                let iconColor;
                switch (status) {
                  case "normal":
                    iconColor = "black";
                    break;
                  case "SOS":
                    iconColor = "red";
                    break;
                  case "OK":
                    iconColor = "green";
                    break;
                  case "stoppedTransport":
                    iconColor = "grey";
                    break;
                  case "movingTransport":
                    iconColor = "white";
                    break;
                  case "stopped":
                    iconColor = "yellow";
                    break;
                  default:
                    iconColor = "black";
                    break;
                }
                let carIcon = L.icon({
                  iconUrl: `icons/carIcons/${iconColor}.webp`,
                  iconAnchor: this.toggleIconSize ? [30, 30] : [20, 20],
                  iconSize: this.toggleIconSize ? [40] : [40],
                  rotationAngle: this.lastDeviceArray[i].bearing,
                  rotationOrigin: "center",
                  startnum: this.lastDeviceArray[i].racer_start_num,
                  racer_id: this.lastDeviceArray[i].racer_id,
                  racerStatus: this.lastDeviceArray[i].racer_status,
                });

                let carIcon2;
                if (this.lastDeviceArray[i].staff_id) {
                  carIcon2 = L.divIcon({
                    iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
                    iconSize: this.toggleIconSize ? [35] : [20],
                    startnum: this.lastDeviceArray[i].staff_start_num.slice(
                      0,
                      3
                    ),
                    staff_id: this.lastDeviceArray[i].staff_id,

                    className: "my-div-icon",
                    html: this.toggleIconSize
                      ? '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
                        "</b>"
                      : '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[i].staff_start_num.slice(0, 3) +
                        "</b>",
                  });
                } else {
                  carIcon2 = L.divIcon({
                    iconAnchor: this.toggleIconSize ? [17.5, 17.5] : [8, 10],
                    iconSize: this.toggleIconSize ? [35] : [20],
                    startnum: this.lastDeviceArray[i].racer_start_num,
                    racer_id: this.lastDeviceArray[i].racer_id,

                    className: "my-div-icon",
                    html: this.toggleIconSize
                      ? '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[i].racer_start_num +
                        "</b>"
                      : '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                        this.lastDeviceArray[i].racer_start_num +
                        "</b>",
                  });
                }

                let Marker = L.marker(marker, {
                  icon: carIcon,
                  autoPanOnFocus: false,
                  rotationAngle: this.lastDeviceArray[i].bearing
                }).addTo(this.mapDiv);

                let Marker2 = L.marker(marker, {
                  icon: carIcon2,
                  autoPanOnFocus: false,
                })
                  .addTo(this.mapDiv)
                  .on("click", this.getActualRacerData);

                this.carMarkerArray.push(Marker);
                this.carMarkerArray2.push(Marker2);
              }
            }

            // if a message arrives to the signalinglog/warning topic from the backend it is pushed to the alerts array
            if (topic.includes(signaling_topic) && this.role != "spectator") {
              let parsedMessage = JSON.parse(message);

              this.handleLogs(parsedMessage, topic);
            }
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
      }
    },
    updateShow(id, show) {
      const log = this.unhandledEventLogs.find(
        (log) => log.event_log_id === id
      );
      if (log) {
        log.show = show;
      }
    },
    async handleLogs(parsedMessage, topic) {
      if (topic.includes("stageChange")) {
        if (this.actualLogs == 1) {
          if (this.logArray.length >= 1000) {
            this.logArray.unshift(parsedMessage);
            this.logArray.pop();
          } else {
            this.logArray.unshift(parsedMessage);
          }
        }
      }
      if (topic.includes("warning")) {
        //TODO ZET MEGNÉZNI
        this.unhandledEventLogs.push({
          ...parsedMessage,
          show: true,
        });
        if (this.actualLogs == 2) {
          if (this.logArray.length >= 1000) {
            this.logArray.unshift(parsedMessage);
            this.logArray.pop();
          } else {
            this.logArray.unshift(parsedMessage);
          }
        }
        this.numberOfUnhandledEventLogs++;
        //this.unhandledEventLogs.push(parsedMessage);
      }
      if (topic.includes("ok")) {
        //TODO ZET MEGNÉZNI
        if (this.actualLogs == 2) {
          if (this.logArray.length >= 1000) {
            this.logArray.unshift(parsedMessage);
            this.logArray.pop();
          } else {
            this.logArray.unshift(parsedMessage);
          }
        }
      }
      if (topic.includes("stageStatus")) {
        //todo mikor kell ez?
        if (this.actualLogs == 3) {
          if (this.logArray.length >= 1000) {
            this.logArray.unshift(parsedMessage);
            this.logArray.pop();
          } else {
            this.logArray.unshift(parsedMessage);
          }
        }
      }

      if (topic.includes("speeding")) {
        if (this.actualLogs == 4) {
          if (this.logArray.length >= 1000) {
            this.logArray.unshift(parsedMessage);
            this.logArray.pop();
          } else {
            this.logArray.unshift(parsedMessage);
          }
        }
      }
    },

    async reloadChartData(parsedMessage) {
      if (this.selectedRacer != undefined) {
        let dummyArraySpeed = [...this.actualRacerSpeeds];
        let dummyArrayGforce = [...this.actualRacerGForces];
        let dummyArrayLabel = [...this.modalChartLabels];
        dummyArraySpeed.shift();
        dummyArrayGforce.shift();
        dummyArrayLabel.shift();
        dummyArraySpeed.push(parsedMessage.velocity);
        dummyArrayGforce.push(
          parsedMessage.g_force ? parsedMessage.g_force : 0
        );
        dummyArrayLabel.push(
          DateTime.fromISO(parsedMessage.recorded_at).toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
          })
        );
        await this.renderChart(
          dummyArrayGforce,
          dummyArrayLabel,
          dummyArraySpeed
        );
      }
    },
    doMQTTSubscribe(topic, qos) {
      //const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
      });
    },
    doMQTTUnSubscribe(topic) {
      //const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        }
      });
    },
    doMQTTPublish(topic, payload, qos) {
      //const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    //---------------------------------------------------------MQTT ends here----------------------------------------
  },

  unmounted() {
    this.mapDiv.remove();
    this.destroyMQTTConnection();
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  background-color: orange;
}
#chart-select {
  width: 20%;
  background-color: #2c3033;
  color: white;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.toast-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  height: 100vh;
}
.toast-container > * {
  pointer-events: auto;
}

.bg-red {
  background-color: red;
}
.bg-green {
  background-color: green;
}

#chart-select option:hover {
  background-color: #dd9c44 !important;
  color: #2c3033 !important;
}
#chart-select-label {
  color: white;
  margin-right: 10px;
}
.fa-arrow-left,
.fa-2xl {
  font-size: 2rem;
}

.form-check-input {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #202528;
  border: 1px solid #dd9c44;
}

.form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.spinner-container-big {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(32, 37, 40, 0.1);
}

#big-spinner {
  margin: 0 auto;
  height: auto;
  position: relative;
  width: auto;
  top: 40%;
}

.sidebarBtns {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#iconSizer {
  margin-top: 4%;
  margin-left: -35%;
  border-width: none;
}

.btn-close-white {
  background-color: white;
}

#iconSizerBtn {
  background-color: #dd9c44;
  border-radius: 15px;
  border-style: none;
}

#iconSizerBtn:hover {
  transform: matrix(1.1);
  box-shadow: 0 0 10px #dd9c44;
  transition: 0.3s;
}

.form-check-input:checked {
  background-color: orange;
  border-color: orange;
}

.mapOption-popup {
  // min-height: 135px;
  width: 0vw;
  // min-width: 490px;
  background-color: #202528;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 30px;
  float: left;
  z-index: 999999;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: 0;
}
.mapOption-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #202528;
  width: 30vw;
  border-radius: 20px;
  padding-block: 5px;
  padding-inline: 0;
}

#liveMapPage {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100vw;
  min-height: 100vh;
}

//Alert

.alertbox-title {
  font-family: "Lexend";
  font-size: 15px;
  opacity: 0.8;
  font-weight: bold;
  color: white;
  text-align: left;
}

.alert-btn-div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 20%;
}

.alertbox-text {
  font-family: "Lexend";
  font-size: 15px;
  color: #dd9c44;
}

.alertbox-outer {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 80px;
  background-color: #2c3033;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 18px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.alertbox-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  padding-block: 5px;
}

.alertbox-button {
  font-family: "Lexend";
  font-size: 15px;
  color: #2c3033;
  background-color: #dd9c44;
  border-radius: 0px 10px 0px 0px;
  border: 1px solid #2c3033;
  min-height: 50% !important;
}

.alertbox-button2 {
  font-family: "Lexend";
  font-size: 15px;
  color: #2c3033;
  background-color: #dd9c44;
  border-radius: 0px 0px 10px 0px;
  border: 1px solid #2c3033;
  min-height: 50% !important;
}

.alertbox-button:hover {
  transform: scale(1.2);
  transition: 0.3s;
  cursor: pointer;
  z-index: 100000;
}

.alertbox-button2:hover {
  transform: scale(1.2);
  transition: 0.3s;
  cursor: pointer;
  z-index: 100000;
}

#liveMapPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
}

#livemappage::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, és Edge */
}

.mapOptionFlexBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
  padding-inline: 0;
}

.button-margin {
  margin-top: 14vh;
}

.backgroundOptionImg {
  display: flex;
  width: 8vw;
  height: 14vh;
  border-radius: 7.5%;
  // min-width: 153px;
  // min-height: 125px;
  margin-top: 4px;
  // margin-bottom: 10px;
}

.dropdown-menu {
  background-color: #2c3033 !important;
  color: white !important;
}

.dropdown-menu li:hover {
  background-color: #dd9c44;
  color: white;
}

#top-bar {
  position: relative;
  display: flex;
  margin: auto;
  z-index: 1010;
  visibility: visible;
  margin-top: 0;
  width: auto;
  height: 10.5%;
  background-color: rgb(73, 72, 72);
  color: #fff;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
  border-bottom: none;
}

.burgerOpenButton {
  display: none;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
  cursor: auto;
}

.highlighted-tr td {
  background-color: #ffd062 !important;
  /* Set background color for td elements */
}

.checkboxRow {
  width: 100%;
  text-align: left;
}

.cross {
  background-color: transparent;
  border: 0;
  height: 30px;
}

.cross:active {
  background-color: transparent !important;
}

.cross:focus {
  outline: none;
}

.cross:hover {
  color: #dd9c44;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vh;
  padding: 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}
.custom-table-min {
  width: 100%;
  border-collapse: collapse;
  min-width: 570px;
}

.custom-table td {
  font-size: 11px;
}

.custom-table th {
  font-size: 11px;
}

.outer-table {
  width: 94%;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* Enable vertical scrolling */
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
}

.outer-table::-webkit-scrollbar {
  width: 10px;
}

.flag-class {
  border: 0;
}

.blury-background {
  //ne töröld ki Tibi
  top: 0;
}

.green-flag {
  color: green;
}

.red-flag {
  color: red;
}

.yellow-flag {
  color: yellow;
}

.flag-class:hover {
  transform: scale(1.1);
  transition: 0.1s;
}

.loading-title {
  color: #dd9c44;
  margin-top: 100px;
}

.dropdown-flag-class:hover {
  background-color: rgb(186, 184, 184);
}

.dropdown-flag-class {
  display: flex;
  flex-direction: row;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.alert-text {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;

}

.logstext {
  margin-right: 20%;
  margin-left: 3%;
}

.search-bar-container {
  position: relative;
  display: inline-block;
  /* Ensures the container only takes up the necessary width */
  margin-left: 1%;
  flex: 1;
}

.logs-text {
  font-size: 25px;
  font-family: "Lexend";
  margin-right: 20%;
  color: white;
}

.search-icon2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  /* Adjust as necessary */
  font-size: 1.2em;
  color: white;
}

.spinner-container-logs {
  position: absolute;
  background-color: #202528;
  left: 0;
  top: 10%;
  border-radius: 15px;
  z-index: 1000000;
  width: 100%;
  height: 90%;
  display: none;
}

#spinner-logs {
  margin: 0 auto;
  height: auto;
  position: relative;
  width: auto;
  top: 40%;
}

.search-bar2 {
  position: absolute;
  justify-content: left;
  background-color: rgb(44, 48, 51);
  color: white;
  max-width: 25vw;
  border-radius: 15px;
  padding-left: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
  top: -18px;
  left: 0px;
}

.search-bar5 {
  position: absolute;
  justify-content: left;
  background-color: rgb(44, 48, 51);
  color: white;
  max-width: 25vw;
  border-radius: 15px;
  padding-left: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
  top: -18px;
  left: 0px;
}
h1 {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;
}

.alert-number {
  font-size: 20px;
  font-family: "Lexend";
  color: red;
}

h2 {
  font-size: 15px;
  font-family: "Lexend";
  color: #fff;
}

.back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border-color: transparent;
  height: 35px;
  width: 35px;
  margin-left: 10px;
  margin-right: 10px;
  color: #dd9c44;
}

.button-container {
  display: flex;
}

.filter-button {
  font-family: "Lexend";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #202528;
  color: white;
  border-color: transparent;
  padding: 10px 2px;
  border: 0.5px solid #202528;
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filter-button:hover {
  background-color: #ffd062;
  color: #202528;
}

.filter-button.active {
  background-color: #ffd062;
  color: rgb(0, 0, 0);
  animation: glow 1s infinite alternate;
}

.chartDiv2 {
  position: relative;
  width: 100%;
  height: 300px;
}

.chartDiv1 {
  width: 100%;
  height: 300px;
  position: relative;
}

#more-info-button {
  background-color: #dd9c44;
  border: 0;
  width: 100%;
  color: #202528;
}

#more-info-button:hover {
  color: #fff;
}

.data-popup {
  max-height: 80vh;
  width: 50vw;
  background-color: #202528;
  border-radius: 10px;
  float: left;
  top: 10%;
  z-index: 9999;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 100px;
  margin-top: 8vh;
}

.poi-filter-container {
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.dropdown {
  top: 100%;
  margin-top: 5px;
  position: absolute;
  background-color: rgb(73, 72, 72);
  box-shadow: 0px 0px 8px #dd9c44;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.racer-title-row {
  display: flex;
  width: 100%;
}

.racer-title {
  justify-content: center;
  align-items: center;
  width: 95%;
}

#close-info {
  float: right;
  right: 0;
  position: relative;
  width: 8%;
  background-color: transparent;
  border: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #dd9c44;
}

/* Show the dropdown when isDropdownVisible is true */

#top-icon2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

#top-icon2:hover {
  color: #dd9c44;
}

#top-icon:hover {
  color: #dd9c44;
}

#right-icons {
  position: relative;
  float: left;
  display: flex;
}

#flag {
  background-image: url(../assets/flag.png);
  background-repeat: no-repeat;
  background-size: 120px 70px;
  position: relative;
  margin-top: 7.5%;
  margin-left: 7.5%;
  margin-right: 5%;
  height: 70px;
  width: 125px;
}

#trackInfo {
  max-width: 100%;
  padding-left: 20px;
}

#top-icons {
  float: right;
  position: relative;
  display: flex;
}

.fa-bell {
  margin-top: 10%;
  font-size: 500%;
}

.fa-circle-user {
  margin-left: -40%;
  font-size: 600%;
}

.selected-racer-modal {
  max-height: 550px;
  width: 700px;
  position: absolute;
  right: 80px;
  top: 10vh;
  background-color: #202528;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.racer-data {
  text-align: left;
  width: auto;
  font-size: 11px;
}

.sidebar {
  position: absolute;
  z-index: 1100;
  float: left;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-ites: center;
  height: 100vh;
  background-color: #202528;
  padding-right: 1vw;
  padding-left: 0.7vw;
  box-shadow: 0 0 10px #dd9c44;
}
.sidebarBurger {
  display: none;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(61, 57, 57, 0.2);
  border-radius: 10px;
}

#blackCircleButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  // align-items: center;
}

#bc1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc4 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc5 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc6 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc9 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.spinner-container {
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

#spinner {
  margin: auto;
}

.poi-filter-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: white;
}

.poi-filter-title {
  position: relative;
  float: left;
  left: 0;
  text-align: left;
}

.poi-filter-icon {
  height: 35px;
  width: 40px;
  padding-left: 5px;
}

.offcanvasRight {
  z-index: 999999999;
  background-color: #202528 !important;
}

#measure-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1500;
  background-color: black;
  border: 0;
  border-radius: 5px;
  color: white;
  border: 3px solid white;
}

#measure-hide-btn {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 10100;
  background-color: black;
  border: 0;
  border-radius: 5px;
  color: white;
}

#measure-hide-btn:hover {
  background-color: #dd9c44;
  color: #202528;
}

#measure-btn:hover {
  background-color: #dd9d45;
  color: black;
}

#total-distance {
  position: absolute;
  top: 10px;
  max-height: 30px;
  right: 100px;
  z-index: 9999999;
  background-color: #202528;
  color: white;

  text-align: center;
}

.total-distance-text {
  position: relative;
  bottom: 10px;
}

#search-field {
  position: absolute;
  z-index: 1000;
  top: 10px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  display: block;
}

.search-bar {
  background-color: rgba(44, 48, 51);
  color: white;
  max-width: 300px;
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
}

.search-result-tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: auto;
}
.search-result-tr:hover td {
  background-color: #dd9c44;
  cursor: pointer;
  color: #2c3033;
}

.search-result-container {
  position: absolute;
  left: 10px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  top: 6vh;

  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;

  border-radius: 10px !important;
  overflow-y: scroll;
  border: 0;
  height: auto;
  max-height: 30vh;
  cursor: auto;
}

#result-table {
  width: 100%;
  border-radius: 15px;
  height: 100%;
  cursor: pointer;
  margin-bottom: 0;
}

.num-td {
  width: 30%;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  vertical-align: middle;
}

p {
  height: auto;
  width: auto;
  text-align: left;
}

.name-td {
  width: 70%;
  align-items: right;
  justify-content: right;
  max-height: 30px;
  vertical-align: middle;
}

#result-table th {
  min-width: 50px !important;
  max-width: 50px !important;
}

.search-result-container {
  background-color: rgba(44, 48, 51, 0.85);
}

::placeholder {
  color: rgb(190, 184, 184);
  opacity: none;
}

.search-icon {
  margin-left: -30px;
}

#mapContainer {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
}

#CheckInfoData-btn {
  position: absolute;
  margin-top: 10px;
  right: 80px;
  width: 35px;
  height: 35px;
  background-color: #dd9c44;
  border: 0;
  border-radius: 50%;
  color: black;
}

.poi-img {
  transform: scale(0.8);
}

#checkInfo-btn {
  width: 35px;
  height: 35px;
  background-color: #dd9c44;
  border: 0;
  border-radius: 50%;
  color: black;
  margin-right: 10px;
}

.back-button:hover {
  background-color: #e38e16;
  color: #2c3033;
}

#checkInfo-btn:hover {
  background-color: #e38e16;
}

#CheckInfoData-btn:hover {
  background-color: #e38e16;
  color: black;
  border: 0;
}

.status-text {
  text-align: left;
  width: 40px;
}

@media only screen and (max-width: 1700px) {
  .filter-button {
    font-size: 12px;
    font-weight: bold;
  }

  #top-icons {
    margin-right: 5%;
  }

  #top-bar {
    height: auto;
  }
  .search-bar5 {
    max-width: 12vw;
  }

  /*  #bc1 {
      margin-top: 1vh;
    }
    #bc2 {
      margin-top: 1vh;
    }
    #bc3 {
      margin-top: 1vh;
    }
    #bc4 {
      margin-top: 1vh;
    }
    #bc5 {
      margin-top: 1vh;
    }
    #bc6 {
      margin-top: 1vh;
    }
    #bc7 {
      margin-top: 1vh;
    }
    #bc8 {
      margin-top: 1vh;
    }
    #bc9 {
      margin-top: 1vh;
    } */
}

@media only screen and (max-height: 950px) {
  #top-bar {
    height: auto;
  }

  #flag {
    display: none;
  }

  #top-icons {
    float: right;
    margin-right: 10vh;
  }
}

@media only screen and (max-width: 1550px) {
  .search-bar2 {
    max-width: 15vw;
  }
  .backgroundOptionImg {
    height: 12vh;
  }
  .data-popup {
    left: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .filter-button {
    font-size: 9px;
  }

  .backgroundOptionImg {
    width: 8.5vw;
    height: 11vh;
  }

  #top-icons {
    margin-right: 7vh;
  }

  .fa-bell {
    margin-top: -20%;
    font-size: 250%;
  }

  .fa-circle-user {
    margin-top: -20%;
    font-size: 250%;
  }

  #flag {
    height: 100%;
    width: 10%;
  }

  .search-bar {
    width: 65%;
  }
}

@media (max-width: 1000px) {
  .logs-text {
    margin-right: -20%;
  }
  .mapOption-popup {
    left: 10px;
  }
  .data-popup {
    left: 100px;
  }
  .mapOption-popup-content {
    width: 78vw;
  }
}

@media (max-width: 768px) {
  .openButton {
    display: none;
  }
  .filter-button {
    font-size: 8px;
    font-weight: bolder;
  }
  .sidebar {
    display: none;
  }
  .sidebarBurger {
    position: absolute;
    z-index: 1100;
    float: left;
    flex-direction: column;
    // justify-content: center;
    // align-ites: center;
    height: 100vh;
    background-color: #202528;
    padding-right: 1vw;
    padding-left: 0.7vw;
    box-shadow: 0 0 10px #dd9c44;
    display: flex;
    transition: 0.4s;
  }
  .burgerOpenButton {
    display: flex;
  }

  .data-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    max-height: 100vh !important;
    z-index: 99999999;
    margin-top: 0vh;
    border-radius: 0px;
    overflow-y: hidden;
  }

  #search-field {
    display: none;
  }

  .status-text {
    font-size: 8px;
    margin-inline: 0px;
    text-align: left;
    display: none;
  }

  #crosshairButton {
    transform: scale(0.8);
    margin-right: 10px;
  }

  #statusButton {
    transform: scale(0.7);
  }

  #dwellTimeButton {
    transform: scale(0.8);
  }

  #poi-text {
    font-size: 16px;
  }

  .poi-filter-icon {
    height: 30px;
    width: 30px;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
  }

  .back-button {
    transform: scale(0.7);
  }

  #CheckInfoData-btn {
    transform: scale(0.7);
  }

  .backgroundOptionImg {
    width: 22vw;
    height: 16vh;
  }

  .backgroundOption .back-button {
    height: 30px;
    width: 30px;
  }

  .alert-text {
    font-size: 20px;
  }

  .custom-table th {
    font-size: 8px;
  }

  .custom-table td {
    font-size: 8px;
  }
  .selected-racer-modal {
    width: 95vw;
    right: 2.5vw;
  }
}

@media (max-width: 650px) {
  .backgroundOptionImg {
    height: 10vh;
  }
  .search-bar2 {
    max-width: 30vw;
  }
  .search-bar5 {
    max-width: 30vw;
  }
  .status-text {
    font-size: 8px;
    margin-inline: 0px;
    text-align: left;
    display: none;
  }
  .outer-table {
    position: relative;
    margin-top: -15vh;
    height: 60vh;
  }
  .center-alignment {
    margin-bottom: 2vh;
    padding: 0;
    height: 100vh;
    top: 0;
  }
  .poi-filter-container {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    color: white;
    border-radius: 0;
  }
}

@media (max-width: 1100px) {
  th {
    font-size: 10px;
  }

  td {
    font-size: 10px;
  }
}

@media (max-width: 920px) {
  th {
    font-size: 9px;
  }

  td {
    font-size: 9px;
  }
}
@media (max-width: 400px) {
  .custom-table {
    overflow-x: scroll;
  }
  .outer-table {
    overflow-x: scroll;
  }
}

@media (max-height: 750px) {
  .button-margin {
    margin-top: 12vh;
    transition: 0.5s;
  }
  .status-text {
    font-size: 8px;
    margin-inline: 0px;
    text-align: left;
    display: none;
  }
}
// @keyframes rotate {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(180deg);
//   }
// }

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.rotate-animation {
  animation: rotate 0.5s forwards;
}

.slide-out-animation {
  animation: slideOut 0.5s forwards;
}

.slide-in-animation {
  animation: slideIn 0.5s forwards;
}

// .rotated {
//   transform: rotate(180deg);
// }
</style>
