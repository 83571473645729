<template>
  <div class="modal-overlay" v-if="showAddRacerModal">
    <div class="edit-block" id="edit-block">
      <div class="header-title">
        <span class="modal-title mb-4">{{
          $t("language.addDevtoRacerTitle")
        }}</span>
      </div>
      <div>
        <div class="modal-content">
          <div class="inputfields">
            <div class="custom-input-div">
              <input
                list="freeDevices"
                aria-label="Default select example"
                placeholder="Serial number"
                id="device-selector"
                type="number"
                v-model="this.device_serial_num"
                @input="validateNumericInput($event)"
              />
            </div>
            <datalist id="freeDevices">
              <option
                v-for="item in availableDevices"
                :value="item.device_serial_num"
                :key="item.device_id"
              >
                {{ item.device_serial_num }}
              </option>
            </datalist>
            <div class="custom-input-div">
              <input
                :placeholder="$t('language.startNum')"
                id="device-selector"
                type="number"
                v-model="this.startNumber"
                @input="validateNumericInput($event)"
              />
            </div>
            <!--             <GrayInputField
              type="number"
              :customPlaceholder="$t('language.startNum')"
              :setter="setStartNumber"
              @input="validateNumericInput($event)"
            /> -->
          </div>

          <div class="mt-3 pb-3">
            <GrayActionButton
              @actionClick="handleConfigLeg()"
              :actionImage="isConfigLeg ? actionSpecImageUp : actionSpecImage"
              :text="specText"
            />
          </div>
          <div v-if="isExtendDivVisible" id="extendDiv" class="extendDiv">
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.pilotName')"
                :setter="setStartNumber"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.pilotNationality')"
                :setter="setPilotNationality"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.pilotPhone')"
                :setter="setPilotPhoneNumber"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.pilotEmail')"
                :setter="setPilotEmail"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.coPilotName')"
                :setter="setCoPilotName"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.coPilotNationality')"
                :setter="setCoPilotNationality"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.coPiloPhone')"
                :setter="setCoPilotPhoneNumber"
              />
            </div>
            <div class="optionClass">
              <GrayInputField
                :customPlaceholder="$t('language.coPilotEmail')"
                :setter="setCoPilotEmail"
              />
            </div>
          </div>
          <div
            class="action-buttons"
            :style="isExtendDivVisible ? 'padding-bottom: 20px' : ''"
            style="display: flex; flex-direction: row; padding-right: 20px"
          >
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
            <GrayActionButton
              @actionClick="closeModal"
              :actionImage="actionCancel"
              :text="cancelText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="deviceAlertSuccess"
    confirmText="Add another device"
    :cancelText="back"
    @confirm="
      showAlertModal = false;
      getAvailableDevices();
    "
    @cancel="
      showAlertModal = false;
      closeModal();
    "
  />
  <CustomAlert
    :show="showAlertModal2"
    :body="deviceValidSerial"
    confirmText="OK"
    @confirm="
      showAlertModal2 = false;
      setFieldsDefault();
    "
    :showCancelButton="false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import CustomAlert from "./CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "AddDeviceToRacer",
  emits: ["close"],
  data() {
    return {
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      startNumber: undefined,
      userNationality: undefined,
      userEmail: undefined,
      userName: undefined,
      device_serial_num: undefined,
      userPhoneNumber: undefined,
      device_id: undefined,
      racer_pilot_name: undefined,
      racer_pilot_nationality: undefined,
      racer_pilot_phonenum: undefined,
      racer_pilot_email: undefined,
      racer_copilot_name: undefined,
      racer_copilot_nationality: undefined,
      racer_copilot_phonenum: undefined,
      racer_copilot_email: undefined,
      backImage: "fa-solid fa-arrow-left",
      deviceArray: [],
      staffArray: [],
      racerArray: [],
      event_id: undefined,
      devices: [],
      wordDocumentUrl: "../adatvedelem.docx",
      actionSpecImage: "fa-solid fa-chevron-down",
      actionSpecImageUp: "fa-solid fa-chevron-up",
      specText: "",
      isConfigLeg: false,
      isExtendDivVisible: false,
      showAlertModal: false,
      showAlertModal2: false,
      back: "",
      filteredDeviceArray: [],

      availableDevices: [],
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.specText = this.$t("language.specText");
    this.deviceAlertSuccess = this.$t("language.deviceAlertSuccess");
    this.deviceValidSerial = this.$t("language.deviceValidSerial");
    this.back = this.$t("language.back");
  },
  props: { showAddRacerModal: Boolean },
  computed: {
    /* filteredDevices: function () {
      return this.deviceArray.filter((item) => {
        if (item.device_serial_num) {
          let device_serial_num = item.device_serial_num;
          return device_serial_num
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        }
      });
    }, */
  },
  async mounted() {
    this.deviceArray = await dp.getDevice();
    let activeEvent = await dp.getActiveEvents();
    this.event_id = activeEvent[0].event_id;
    //this.staffArray = await dp.getStaff();
    //this.racerArray = await dp.getRacers();

    //this.availableDevices = await this.getAvailableDevices();
    //console.log(this.availableDevice);
  },
  watch: {
    showAddRacerModal: function () {
      // If "showAddRacerModal" ever changes, then we will console log its new value.
      this.getAvailableDevices();
    },
  },
  methods: {
    async getAvailableDevices() {
      this.deviceArray = await dp.getDevice();
      let devices = [...this.deviceArray];
      let deviceStatus = await dp.getLastDeviceLogs();
      this.reclaimedStatus = [];
      for (const status of deviceStatus) {
        if (status.device_status == "reclaimed") {
          this.reclaimedStatus.push(status.device_id);
        }
      }
      this.availableDevices = devices.filter((item) =>
        this.reclaimedStatus.includes(item.device_id)
      );
    },
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    closeModal() {
      this.$emit("close");
      this.isExtendDivVisible = false;
      this.isConfigLeg = false;
      this.device_serial_num = undefined;
    },

    handleBackClick() {
      this.$router.push("/racerlist");
    },
    setSerialNumber(value) {
      this.device_serial_num = value;
    },
    setPilotName(value) {
      this.racer_pilot_name = value;
    },
    setPilotNationality(value) {
      this.racer_pilot_nationality = value;
    },
    setPilotPhoneNumber(value) {
      this.racer_pilot_phonenum = value;
    },
    setPilotEmail(value) {
      this.racer_pilot_email = value;
    },
    setCoPilotName(value) {
      this.racer_copilot_name = value;
    },
    setCoPilotNationality(value) {
      this.racer_copilot_nationality = value;
    },
    setCoPilotPhoneNumber(value) {
      this.racer_copilot_phonenum = value;
    },
    setCoPilotEmail(value) {
      this.racer_copilot_email = value;
    },
    setStartNumber(value) {
      this.startNumber = value;
    },
    // Set the values back to empty field
    setFieldsDefault() {
      this.device_serial_num = "";
      this.startNumber = "";
      this.racer_pilot_name = "";
      this.racer_pilot_national = "";
      this.racer_pilot_phonenum = "";
      this.racer_pilot_email = "";
      this.racer_copilot_name = "";
      this.racer_copilot_national = "";
      this.racer_copilot_phonenum = "";
      this.racer_copilot_email = "";
      this.setStartNumber = "";
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleSaveClick() {
      //this.getAvailableDevices();
      let DeviceIdFinder = this.deviceArray.find(
        (DeviceIdFinder) =>
          DeviceIdFinder.device_serial_num == this.device_serial_num
      );
      if (DeviceIdFinder) {
        this.device_id = DeviceIdFinder.device_id;
      }
      //console.log(this.deviceArray);

      console.log(this.device_serial_num, this.startNumber, this.device_id);
      if (this.device_serial_num && this.startNumber && this.device_id) {
        try {
          let racers = await dp.getRacersByEventId(this.event_id);

          let racer = racers.find(
            (racer) => racer.racer_start_num == this.startNumber
          );

          if (racer) {
            await dp.updateRacersById(
              racer.racer_id,
              this.device_id,
              this.racer_pilot_name
                ? this.racer_pilot_name
                : racer.racer_pilot_name,
              this.racer_pilot_nationality
                ? this.racer_pilot_nationality
                : racer.racer_pilot_nationality,
              this.racer_pilot_phonenum
                ? this.racer_pilot_phonenum
                : racer.racer_pilot_phonenum,
              this.racer_pilot_email
                ? this.racer_pilot_email
                : racer.racer_pilot_email,
              this.racer_copilot_name
                ? this.racer_copilot_name
                : racer.racer_copilot_name,
              this.racer_copilot_nationality
                ? this.racer_copilot_nationality
                : racer.racer_copilot_nationality,
              this.racer_copilot_phonenum
                ? this.racer_copilot_phonenum
                : racer.racer_copilot_phonenum,
              this.racer_copilot_email
                ? this.racer_copilot_email
                : racer.racer_copilot_email,
              this.startNumber ? this.startNumber : racer.racer_start_num
            );
            this.device_serial_num = undefined;
            this.startNumber = undefined;
            this.showAlertModal = true;
          } else {
            await dp.addRacers(
              this.event_id,
              this.device_id,
              this.racer_pilot_name,
              this.racer_pilot_nationality,
              this.racer_pilot_phonenum,
              this.racer_pilot_email,
              this.racer_copilot_name,
              this.racer_copilot_nationality,
              this.racer_copilot_phonenum,
              this.racer_copilot_email,
              this.startNumber,
              this.racer_status,
              this.racer_current_stage
            );
            this.device_serial_num = undefined;
            this.startNumber = undefined;
            this.showAlertModal = true;
          }
        } catch (error) {
          this.showAlertModal2 = true;
        }
      } else {
        this.showAlertModal2 = true;
      }
    },
    toggleExtendDivWithDelay() {
      // Set isExtendDivVisible to true after a delay
      setTimeout(() => {
        this.isExtendDivVisible = true;
      }, 500); // 0.5 second delay
    },
    handleTransitionEnd(event) {
      // Check if the transition property is 'height' and the event target is the edit-block element
      if (event.propertyName === "height" && event.target.id === "edit-block") {
        // Call the toggleExtendDivWithDelay method
        this.toggleExtendDivWithDelay();
      }
    },
    handleConfigLeg() {
      this.isConfigLeg = !this.isConfigLeg;
      if (this.isConfigLeg) {
        this.toggleExtendDivWithDelay();
        document.getElementById("edit-block").style.height = "95vh";
        document.getElementById("edit-block").style.transition = "height 0.5s";
      } else {
        document.getElementById("edit-block").style.height = "50%";
        this.isExtendDivVisible = false;
      }
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    CustomAlert,
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

#device-selector {
  width: 20vw;
  height: 5vh;
  border: 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  color: white;
  background-color: #555556;
  font-family: "Lexend";
  margin-bottom: 20px;
}

.edit-block {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.custom-input-div {
  padding-left: 5vw;
}

#device-selector:focus {
  outline-color: #dd9c44;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  margin-bottom: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}
.optionClass {
  margin-bottom: 20px;
}

.header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.extendDiv {
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  /* transition-delay: 0.5s; */ /* Added delay */
  transition: 0.4s;
}

.edit-block {
  background: #202528;
  padding-inline: 20px;
  border-radius: 10px;
  width: 30%;
  height: 50%;
  z-index: 1002;
  box-shadow: #dd9c44 0px 0px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-content {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-title {
  color: white;
  opacity: 0.6;
  font-size: 1.5rem;
  font-family: "Lexend";
}

.inputfields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 75px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 768px) {
  .edit-block {
    width: 90%;
    height: 50%;
  }
  .inputfields {
    height: 60%;
    width: 100%;
    padding-right: 10px;
  }
  #device-selector {
    height: 4vh;
    width: 100%;
    font-size: small;
  }
  .action-buttons {
    padding-left: 0px;
  }
  .extendDiv {
    padding-right: 10px;
  }
  .modal-title {
    font-size: 1rem;
  }
}
</style>
