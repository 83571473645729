<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.home") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/users">{{ $t("language.navUsers") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.navEditUser") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="this.back"
            :circleImage="backImage"
            @circleClick="navigateBack()"
            title="Back"
          />
        </div>
        <div class="header-title">
          <span class="leg-title">{{ $t("language.navEditUser") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div class="edit-row">
            {{ $t("language.addUserEmail") }}
            <GrayInputField
              :value="this.editEmail"
              :setter="setEmail"
            />
          </div>
          <div class="edit-row">
            {{ $t("language.userPass") }}
            <GrayInputField
              customPlaceholder="New password"
              v-model="this.editPassword"
            />
          </div>
          <div class="edit-row">
            {{ $t("language.userPhone") }}
            <GrayInputField
              :value="this.editPhoneNumber"
              :setter="setPhoneNumber"
              @input="validatePhoneInput($event)"
            />
          </div>
          <div class="edit-row">
            {{ $t("language.userFirstName") }}
            <GrayInputField
              @input="capitalizeFirstName()"
              :value="this.editFirstName"
              :setter="setFirstName"
            />
          </div>
          <div class="edit-row">
            {{ $t("language.userLastName") }}
            <GrayInputField
              @input="capitalizeLastName()"
              :value="this.editLastName"
              :setter="setLastName"
            />
          </div>
          <div class="action-buttons">
            <div>
              <GrayActionButton
                @actionClick="showAlertModal2 = true"
                :actionImage="actionDeleteImage"
                :text="deleteText"
              />
            </div>
            <div style="display: flex">
              <GrayActionButton
              style="padding-inline: 20px"
                @actionClick="
                  showAlertModal = true;
                    alertBody = $t('language.sureToChange')
                "
                :actionImage="actionSaveImage"
                :text="saveText"
              />
              <GrayActionButton
                @actionClick="cancelAction"
                :actionImage="actionCancel"
                :text="cancelText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="alertBody"
    confirmText="OK"
    @confirm="dataPush()"
    @cancel="showAlertModal = false"
  />
  <CustomAlert
    :show="showAlertModal2"
    :body= "$t('language.deleteDevice')"
    @confirm="deleteUser(), (showAlertModal2 = false)"
    @cancel="showAlertModal2 = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "./CustomAlert.vue";

const dp = dataProvider();

export default {
  name: "EditUser",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "Save",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "Cancel",
      rallyName: "Nyíregyháza Rally",
      rallyLocation: "Nyíregyháza",
      rallyMap: "Upload Image",
      legName: "",
      legLocation: "",
      backImage: "fa-solid fa-arrow-left",
      editUser: [],
      editFirstName: undefined,
      editLastName: undefined,
      editEmail: undefined,
      editPassword: undefined,
      editPhoneNumber: undefined,
      editTitle: undefined,
      isValidEmail: false,
      showAlertModal: false,
      alertBody: "",
      showAlertModal2: false,
    };
  },
  props: [],

  async mounted() {
    this.user_id = localStorage.getItem("user_id");
    this.editUser = await dp.getUserById(this.user_id);
    this.editFirstName = this.editUser.first_name;
    this.editLastName = this.editUser.last_name;
    this.editEmail = this.editUser.email;
    this.editPhoneNumber = this.editUser.phone_number;
    this.editTitle = this.editUser.title;
    this.deleteText = this.$t("language.deleteText");
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.cancelText = this.$t("language.cancelText");
  },
  methods: {
    capitalizeFirstName() {
      // Capitalize only the first letter of the first name
      this.editFirstName =
        this.editFirstName.charAt(0).toUpperCase() +
        this.editFirstName.slice(1).toLowerCase();
    },
    capitalizeLastName() {
      // Capitalize only the first letter of the last name
      this.editLastName =
        this.editLastName.charAt(0).toUpperCase() +
        this.editLastName.slice(1).toLowerCase();
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36 20 323 4211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.editEmail);
    },
    async dataPush() {
      this.validateEmail();
      console.log(this.isValidEmail); //do not delete this log!!!
      if (!this.isValidEmail) {
        this.alertBody = this.$t("language.validEmail");
        this.showAlertModal2 = true;
        return;
      } else {
        await dp.updateUserById(
          this.editEmail,
          this.editPassword,
          undefined,
          this.editPhoneNumber,
          this.editFirstName,
          this.editLastName,
          this.user_id
        );
        this.$router.push("/users");
      }
    },
    navigateBack() {
      this.$router.go(-1);
    },
    handleDeleteClick() {},
    handleSaveClick() {
      this.dataPush();
    },
    handleCreateClick() {},
    setEmail(value) {
      this.editEmail = value;
    },
    setPassword(value) {
      this.editPassword = value;
    },
    setTitle(value) {
      this.editTitle = value;
    },
    setPhoneNumber(value) {
      this.editPhoneNumber = value;
    },
    setFirstName(value) {
      this.editFirstName = value;
    },
    setLastName(value) {
      this.editLastName = value;
    },
    cancelAction() {
      this.$router.push("/users");
    },
    async deleteUser() {
      await dp.deleteUserById(this.user_id);
      this.$router.push("/users");
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 32px;
  padding-inline: 3vw;
}

.leg-title {
  color: white;
  font-size: 45px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}
.leg-date {
  color: white;
  font-size: 20px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}
.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.edit-block {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  padding-bottom: 20px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

@media (max-width: 1400px) {
  .edit-row {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .leg-title {
    font-size: 25px;
  }

  .leg-date {
    font-size: 13px;
  }

  .edit-row {
    font-size: 12px;
  }
  .edit-block {
    width: 90vw;
  }

}

@media (max-width: 568px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 16px;
  }

  .edit-row {
    font-size: 12px;
  }

}
@media (max-height: 550px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 16px;
  }

  .edit-row {
    font-size: 12px;
  }
  .privacyText {
    position: absolute;
  }

}
</style>
