<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/users">{{ $t("language.userTitle") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.addUserTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
            title="Users"
          />
        </div>
        <div class="header-title">
          <span class="title">{{ $t("language.addUserTitle") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.userFirstName") }}
              <GrayInputField
                :value="firstName"
                :setter="setFirstName"
                @input="capitalizeFirstName()"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.userLastName") }}
              <GrayInputField
                :value="lastName"
                :setter="setLastName"
                @input="capitalizeLastName()"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.userEmail") }}
              <GrayInputField
                class="edit-row"
                :value="email"
                :setter="setEmail"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.userPhone") }}
              <GrayInputField
                :value="phoneNumber"
                :setter="setPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.userPass") }}
              <GrayInputField
                :value="password"
                :setter="setPass"
                :type="'password'"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.userPassAgain") }}
              <GrayInputField
                :value="passwordAgain"
                :setter="setPassAgain"
                :type="'password'"
              />
            </div>
          </div>
          <div class="action-buttons">
            <GrayActionButton
            style="padding-inline: 20px"
              @actionClick="handleCancelClick"
              :actionImage="actionCancel"
              :text="cancelText"
            />
            <GrayActionButton
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="alertBody"
    confirmText="OK"
    @confirm="showAlertModal = false"
    :showCancelButton="false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "@/components/CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "AddUser",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      backImage: "fa-solid fa-arrow-left",
      wordDocumentUrl: "../adatvedelem.docx",
      firstName: undefined,
      lastName: undefined,
      title: undefined,
      email: undefined,
      phoneNumber: undefined,
      password: undefined,
      passwordAgain: undefined,
      back: "",
      cancelText: "",
      actionCancel: "fa-solid fa-xmark",
      isValidEmail: false,
      alertBody: "",
      showAlertModal: false,
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.cancelText = this.$t("language.cancelText");
  },
  props: [],
  methods: {
    capitalizeFirstName() {
      // Capitalize only the first letter of the first name
      this.firstName =
        this.firstName.charAt(0).toUpperCase() +
        this.firstName.slice(1).toLowerCase();
    },
    capitalizeLastName() {
      // Capitalize only the first letter of the last name
      this.lastName =
        this.lastName.charAt(0).toUpperCase() +
        this.lastName.slice(1).toLowerCase();
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36 20 323 4211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.email);
    },
    async handleSaveClick() {
      this.validateEmail();
      console.log(this.isValidEmail); //do not delete this log!!!
      if (this.lastName === undefined || this.lastName === undefined) {
        this.alertBody = this.$t("language.addValidName");
        this.showAlertModal = true;
        return;
      } else if (
        this.password === undefined ||
        this.password !== this.passwordAgain
      ) {
        this.alertBody =  this.$t("language.wrongPass");
        this.showAlertModal = true;
        return;
      } else if (!this.isValidEmail) {
        this.alertBody = this.$t("language.validEmail");
        this.showAlertModal = true;
        return;
      } else {
        await dp.addNewUser(
          this.firstName,
          this.lastName,
          undefined,
          this.email,
          this.phoneNumber,
          this.password
        );
        this.$router.push("/users");
      }
    },
    handleBackClick() {
      this.$router.push("/users");
    },
    setFirstName(value) {
      this.firstName = value;
    },
    handleCancelClick() {
      this.$router.push("/users");
    },
    setLastName(value) {
      this.lastName = value;
    },
    setEmail(value) {
      this.email = value;
    },
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    setPass(value) {
      this.password = value;
    },
    setPassAgain(value) {
      this.passwordAgain = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 32px;
  padding-inline: 3vw;
}

.title {
  color: white;
  font-size: 45px;
  font-family: "Lexend";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}
.small-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 10px;
  display: none;
}

.edit-row {
  padding-bottom: 20px;
}

.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 800px) {
  .edit-block {
    width: 90vw;
  }
}

@media (max-width: 568px) {
  .edit-row {
    font-size: 12px;
  }

  h1 {
    font-size: 26px;
  }

  .title {
    font-size: 16px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 70px;
    width: 100vw;
  }
  .small-title {
    display: block;
    font-size: 20px;
  }
  .breadcrumb-item {
    font-size: 10px;
  }
}

@media (max-height: 350px) {
  .edit-row {
    font-size: 12px;
  }

  h1 {
    font-size: 26px;
  }

  .title {
    font-size: 22px;
  }
  .privacyText {
    position: absolute;
  }
}
</style>
