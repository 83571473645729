<template>
    <button @click="handleBlackCircleClick" class="blackCircle" :style="style">
        <i :style="imageColor" class="black-icon" :class="blackCircleImage">
          <span v-if="numberOfAlerts > 0" class="circle-number">{{ numberOfAlerts }}</span> 
        </i>
    </button>
</template>

<script>
export default {
    name: 'BlackCircleButton',
    props: ["blackCircleImage", "style", "imageColor", "numberOfAlerts"],
    methods: {
        handleBlackCircleClick() {
            this.$emit('blackCircleClick');
        },
    },
};
</script>

<style scoped>
.blackCircle {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #202528;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.circle-number {
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;  /* Match the color of the button for consistency */
  color: #fff;  /* Text color */
  border-radius: 50%;  /* Make it a circle */
  padding: 5px;  /* Adjust the padding as needed */
  font-size: 12px;
}
.black-icon {
  color: #ffffff;
  font-size: 2.2em;

}
.black-icon:hover {
  color: #DD9C44
}
@media (max-height: 650px){
  .blackCircle {
    transform: scale(0.7);
  }
  .black-icon {
     transform: scale(0.7);
  }
}
@media (max-height: 600px){
  .blackCircle {
    transform: scale(0.5);
  }
  .black-icon {
     transform: scale(0.5);
  }
}
</style>