<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.legsTitle") }}
        </li>
      </ol>
    </nav>
  </header>

  <div class="row" id="users-row">
    <!-- <div class="small-title">{{ this.eventName }}</div> -->

    <div
      style="
        display: flex;
        padding:0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      "
    >
      <div class="back-button-title" style="flex: 1">
        <GoldCircleButton
          :circleText="back"
          :circleImage="backImage"
          @circleClick="navigateHome()"
        />
      </div>
      <span class="large-title" style="display: flex">{{
        this.currentEvent.event_name
      }}</span>
      <div style="flex: 1" class="title-spacer-div"></div>
    </div>
    <div class="page-title">
      <div class="big-info">
        <table class="header-table bigscreen-table">
          <tr>
            <th>{{ $t("language.eventListType") }}</th>
            <th>{{ $t("language.eventStartDate") }}</th>
            <th>{{ $t("language.eventEndDate") }}</th>
            <th>{{ $t("language.eventCreatedAt") }}</th>
            <th>{{ $t("language.eventUpdatedAt") }}</th>
            <th class="active-th"  v-show="this.currentEvent.event_active">
              {{ $t("language.evetnActive") }}
            </th>
            <th class="active-th" v-show="!this.currentEvent.event_active">
              {{ $t("language.eventInactive") }}
            </th>
          </tr>
          <tr class="racer-info-data">
            <td>{{ this.currentEvent.event_type }}</td>
            <td>{{ formatDate(this.currentEvent.event_start_date) }}</td>
            <td>{{ formatDate(this.currentEvent.event_end_date) }}</td>
            <td>{{ formatTimeDate(this.currentEvent.event_created_at) }}</td>
            <td>{{ formatTimeDate(this.currentEvent.event_updated_at) }}</td>
            <td>
              <label v-if="this.role" class="switch">
                <input
                  v-if="this.role == 'admin'"
                  type="checkbox"
                  id="activeTick"
                  v-model="this.currentEvent.event_active"
                  @change="handleSwitchChange()"
                />
                <input
                  v-else
                  disabled
                  type="checkbox"
                  id="activeTick"
                  v-model="this.currentEvent.event_active"
                  @change="handleSwitchChange()"
                />
                <span class="slider"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div class="small-info">
        <table class="header-table">
          <tr>
            <th>{{ $t("language.legsName") }}</th>
            <th>{{ $t("language.eventStartDate") }}</th>
            <th>{{ $t("language.eventEndDate") }}</th>
          </tr>
          <tr class="racer-info-data">
            <td>{{ this.currentEvent.event_name }}</td>
            <td>{{ formatDate(this.currentEvent.event_start_date) }}</td>
            <td>{{ formatDate(this.currentEvent.event_end_date) }}</td>
          </tr>
          <tr>
            <th>{{ $t("language.eventStartDate") }}</th>
            <th>{{ $t("language.eventUpdatedAt") }}</th>
            <th>{{ $t("language.evetnActive") }}</th>
          </tr>
          <tr class="racer-info-data">
            <td>{{ formatTimeDate(this.currentEvent.event_created_at) }}</td>
            <td>{{ formatTimeDate(this.currentEvent.event_updated_at) }}</td>
            <td>
              <label class="switch">
                <input
                  type="checkbox"
                  id="activeTick"
                  v-model="this.currentEvent.event_active"
                  @change="handleSwitchChange()"
                />
                <span class="slider"></span>
              </label>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="center-alignment2" v-if="this.role">
      <table style="width: 105%; text-align: center; background-color: #dd9c44; border-radius: 10px;">
        <tr>
          <td v-if="this.role == 'admin'">
            <span class="action-buttons" @click="editEvents">
              <i class="fa-solid fa-pen-to-square"></i>
               {{ $t('language.eventEditBtn') }}
            </span>
          </td>
          <td v-if="this.role == 'admin'">
            <span class="action-buttons" @click="navigateUserList">
              <i class="fa-solid fa-user"></i>
               {{ $t('language.usersBtn') }}
            </span>
          </td>
        </tr>
        <tr>
          <td
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          >
            <span class="action-buttons" @click="navigateRacerList">
              <i class="fa-solid fa-user-astronaut"></i>
               {{ $t('language.racerStaffBtn') }}
            </span>
          </td>
          <td v-if="this.role == 'admin' || this.role == 'staff'">
            <span class="action-buttons" @click="navigateToDeviceManagment">
              <i class="fa-solid fa-mobile-android"></i>
               {{ $t('language.deviceHandlerBtn') }}
            </span>
          </td>
        </tr>
      </table>
      <ChangeDeviceModal :showModal="showModal" @close="showModal = false" />
    </div>
    <div
      class="center-alignment"
      v-if="this.role"
      style="
        background-color: #dd9c44;
        border-radius: 10px;
        height: 5vh;
        width: 99%;
        margin-left: 0.5vw;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          width: 100%;
        "
      >
        <div>
          <span
            class="action-buttons"
            @click="editEvents"
            v-if="this.role == 'admin' "
          >
            <i class="fa-solid fa-pen-to-square"></i>
            {{ $t('language.eventEditBtn') }}
          </span>
        </div>
        <div>
          <span
            class="action-buttons"
            @click="navigateUserList"
            v-if="this.role == 'admin'"
          >
            <i class="fa-solid fa-user"></i>
            {{ $t('language.usersBtn') }}
          </span>
        </div>
        <div>
          <span
            class="action-buttons"
            @click="navigateRacerList"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          >
            <i class="fa-solid fa-user-astronaut"></i>
            {{ $t('language.racerStaffBtn') }}
          </span>
        </div>
        <div>
          <span
            class="action-buttons"
            @click="navigateToDeviceManagment"
            v-if="this.role == 'admin' || this.role == 'staff'"
          >
            <i class="fa-solid fa-mobile-android"></i>
            {{ $t('language.deviceHandlerBtn') }}
          </span>
        </div>
        <ChangeDeviceModal :showModal="showModal" @close="showModal = false" />
      </div>
    </div>

    <div
      :class="{
        'scroll-container': filteredItems.length > 0,
        'empty-leg': filteredItems.length === 0,
      }"
    >
      <div v-if="filteredItems.length > 0" class="cards-container">
        <LegsCard
          v-for="item in this.filteredItems"
          :key="item.leg_id"
          :rallyName="item.leg_name"
          :Created="formatDate(item.leg_date)"
          :Updated="formatTimeDate(item.leg_updated_at)"
          :Active="item.leg_active"
          :mapSource="`${this.imageApiUrl}/${item.leg_id}`"
          :flagSource="flagSource"
          :eventActive="this.currentEvent.event_active"
          :legId="item.leg_id"
          @refreshTime="refreshTimeHandler()"
          @editClick="HandleLegEditClick(item.leg_id)"
          @openClick="handleLegOpenClick(item.leg_id)"
          @replyClick="navigateReplyPage(item.leg_id)"
        />
        <div
          class="leg-card-container"
          style="padding-inline: 1%"
          v-if="this.role"
        >
          <div
            class="square"
            @click="navigateAddLeg"
            v-if="this.role == 'admin' || this.role == 'staff'"
          >
            <i
              class="fa-solid fa-plus"
              style="color: #dd9c44; font-size: 10em"
            ></i>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          v-if="legsArray.length > 0"
          style="
            font-size: 40px;
            font-family: Lexend;
            color: white;
            padding-top: 20vh;
          "
        >
          No leg avaliable with this name
        </div>
        <div v-else>
          <div
            class="leg-card-container"
            style="padding-bottom: 1vh"
            v-if="this.role"
          >
            <div
              class="square"
              @click="navigateAddLeg"
              style=""
              v-if="this.role == 'admin' || this.role == 'staff'"
            >
              <i
                class="fa-solid fa-plus"
                style="color: #dd9c44; font-size: 10em"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert :show="showAlert" body="Biztosan törölni akarja ezt az elemet?" @confirm="handleDeleteLeg(deletItemId)" @cancel="showAlert = false" />
  <CustomAlert
    :show="showAlert2"
    :body="errorMessage"
    @confirm="showAlert2 = false"
    :showCancelButton="false"
  />

</template>

<script>
import LegsCard from "@/components/LegsCard.vue";
import ChangeDeviceModal from "@/components/ChangeDeviceModal.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "@/components/CustomAlert.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "LegsPage",
  data() {
    return {
      mapSource: "map2.png",
      flagSource: "hungarianFlag.png",
      backImage: "fa-solid fa-arrow-left",
      userImage: "fa-solid fa-user",
      plusImage: "fa-solid fa-plus",
      flagImage: "fa-solid fa-user-astronaut",
      editEvent: "fa-solid fa-pen-to-square",
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      legsArray: [],
      currentEvent: [],
      leg_active: false,
      searchQuery: "",
      imageApiUrl: `${process.env.VUE_APP_API_URL || 'https://testrally.ddc.sze.hu/api'}/legImageById/`,
      staffImage: "fa-solid fa-user-tie",
      deviceChange: "fa-solid fa-mobile-android",
      eventName: undefined,
      showModal: false,
      wordDocumentUrl: "../adatvedelem.docx",
      EditEvent: "",
      back: "",
      userList: "",
      racerStaff: "",
      deletItemId: undefined,
      deviceManagement: "",
      showAlert: false,

      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------Alert------------------------------------------
      showAlert2: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.eventId = localStorage.getItem("event_id");
    if (!this.eventId) {
      this.eventId = 1;
    }
    this.legsArray = await dp.getLegByEventId(this.eventId);
    this.currentEvent = await dp.getEventsById(this.eventId);
    this.eventName = this.currentEvent.event_name;
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  created() {
    localStorage.setItem("tableType", "1");
    this.EditEvent = this.$t("language.EditEvent");
    this.back = this.$t("language.back");
    this.userList = this.$t("language.userList");
    this.racerStaff = this.$t("language.racerStaff");
    this.deviceManagement = this.$t("language.deviceManagement");
  },
  computed: {
    filteredItems: function () {
      return this.legsArray
        .filter((item) => {
          let searchQueryLowerCase = this.searchQuery.toLowerCase();
          return (
            item.leg_name.toLowerCase().includes(searchQueryLowerCase) ||
            item.leg_date.toLowerCase().includes(searchQueryLowerCase) ||
            item.leg_updated_at.toLowerCase().includes(searchQueryLowerCase) ||
            item.leg_active.toString().includes(searchQueryLowerCase)
          );
        })
        .sort((a, b) => {
          return new Date(a.leg_date) - new Date(b.leg_date);
        });
    },
  },
  methods: {

    handleLegOpenClick(id) {
      localStorage.setItem("racer_id", undefined);
      localStorage.setItem("leg_id", id);
      localStorage.setItem("prevPageFromLive", "EventList");
      this.$router.push("/livemapview");
    },
    navigateReplyPage(id) {
      localStorage.setItem("leg_id", id);
      this.$router.push("/ReplayPage");
    },
    HandleLegEditClick(id) {
      localStorage.setItem("leg_id", id);
      this.$router.push("/editleg");
    },

    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString();
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    navigateHome() {
      this.$router.push("/events");
    },
    navigateUserList() {
      localStorage.setItem("event_id", this.eventId);
      this.$router.push("/addUserToEvent");
    },
    navigateAddLeg() {
      this.$router.push("/addleg");
      localStorage.setItem("event_id", this.eventId);
    },
    navigateToDeviceManagment() {
      this.$router.push("/DeviceManagement");
    },
    async refreshTimeHandler(){
    this.currentEvent = await dp.getEventsById(this.eventId);
    this.currentEvent = await dp.getEventsById(this.eventId);
    },
    async handleSwitchChange() {
  try {
    let result = await dp.updateEventsById(
      undefined,
      undefined,
      undefined,
      undefined,
      this.currentEvent.event_id,
      this.currentEvent.event_active
    );

    if (result.status === 400) {
      this.currentEvent.event_active = false;
      this.showAlert2 = true;
      this.errorMessage = result.response.message;
    } else {
      if (!this.currentEvent.event_active) {
        // Azonnali frissítés a legsArray-ben
        for (let leg of this.legsArray) {
          leg.leg_active = false;
          await dp.updateLegById(
            leg.leg_name,
            leg.leg_date,
            false,
            leg.leg_id
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
    this.showAlert2 = true;
    this.errorMessage = error.message;
  }

  this.currentEvent = await dp.getEventsById(this.eventId);
  this.legsArray = await dp.getLegByEventId(this.eventId);
},
    navigateRacerList() {
      this.$router.push("/racerlist");
    },
    editEvents() {
      this.$router.push("/editevent");
    },
    navigateStaffPage() {
      this.$router.push("/stafflist");
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    LegsCard,
    GoldCircleButton,
    ChangeDeviceModal,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: absolute;
  left: 20px;
  top: 0;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.page-title {
  width: 99%;
  background-color: #2c3033;
  height: 60px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0.5%;
  margin-bottom: 2vh;
  /* Adjusted to space between */
  /* Center the title within its parent container */
}

.active-th{
  min-width: 67px;
}

.header-table th,
td {
  text-align: left;
}

.header-table th {
  color: #dd9c44;
  font-size: 16px;
  font-family: "Lexend";
  white-space: nowrap;
}

.header-table td {
  color: white;
  font-size: 16px;
  font-family: "Lexend";
  white-space: nowrap;
}

.header-table {
  width: 90vw;
  border-collapse: collapse;
}

.square {
  width: 320px;
  height: auto;
  height: 445px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #2c3033;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1em;
}

.square:hover {
  box-shadow: 0 0 10px 5px rgba(221, 156, 68, 0.5);
  /* Adjust the color and size as needed */
}

.cards-container {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.both-margin {
  margin-inline: 20px;
}

.fixed-container {
  max-width: 100%;
  /* Set a fixed maximum width for the container */
  margin: 0 auto;
  /* Center the container on the page */
  padding: 0;
}

.scroll-container {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.cards-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 98vw;
  white-space: nowrap;
  padding-bottom: 16px;
  /* Enable horizontal scrolling within cards-container */
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
  width: 100%;
}

.center-alignment2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
  display: none;
}

.small-title {
  color: white;
  font-family: Lexend;
  font-size: 30px;
  display: none;
}

h3 {
  font-size: 24px;
  margin: 40px 0 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.large-title {
  color: white;
  font-size: 50px;
  font-family: "Lexend";
}

.circle-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 50px;
  /* Gap between the circles */
}

.circle-position {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: -20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

a {
  color: #42b983;
}

.first-circle {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.left-margin {
  margin-left: 20px;
}

.right-margin {
  margin-right: 20px;
}

.search-div {
  width: 100%;
  margin-bottom: 0vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-bar {
  border: 0px solid white;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 8px 12px;
  padding-right: 45px;
  border-radius: 10px;
  width: 200px;
  /* ::placeholder {
    color: white;
  } */
}

.search-icon {
  margin-left: -40px;
  font-size: 1.6em;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  left: 0;
  margin-left: 3.2px;
  margin-top: 3px;
}

.smallscreen-table {
  display: none;
}

input:checked + .slider {
  background-color: green;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.small-info {
  display: none;
}
.action-buttons {
  color: #202528;
  background-color: #dd9c44;
  font-family: "Lexend";
}
.action-buttons:hover {
  cursor: pointer;
  color: white;
} .breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
@media (max-width: 600px) {
  .both-margin {
    margin-inline: 0px;
  }
}

@media (max-width: 1200px) {
  .small-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .large-title {
    font-size: 35px;
  }
}

@media (max-width: 1011px) {
  h1 {
    font-size: 15px;
  }
  .header-table th {
    font-size: 14px;
  }
  .header-table td {
    font-size: 14px;
  }
  .large-title {
    font-size: 30px;
  }
}

@media (max-width: 875px) {
  .large-title {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  h1 {
    display: none;
  }

  .action-buttons {
    font-size: 12px;
    margin-left: 15px;
  }
  .large-title {
    font-size: 18px;
    margin-right: 2vw;
  }
  .back-button-title {
    flex: 0;
  }

  .title-spacer-div {
    display: none;
  }

  .center-alignment {
    display: none;
  }
  .center-alignment2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .switch {
    scale: 0.5;
  }

  .big-info {
    display: none;
  }

  .small-info {
    display: block;
  }
  .page-title {
    height: auto;
  }

  .small-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .circle-container {
    padding-right: 0px;
  }

  .first-circle {
    margin-left: 0px;
  }

  .left-margin {
    margin-left: 0px;
  }

  .right-margin {
    margin-right: 0px;
  }

  .hello {
    margin-bottom: 50px;
  }

  .search-bar {
    width: 150px;
    height: 30px;
    padding-right: 35px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.2em;
  }

  .search-div {
    margin-left: 1.5vw;
  }

  .header-table th {
    font-size: 12px;
  }

  .header-table td {
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  .search-div {
    margin-bottom: 5px;
  }

  .both-margin {
    margin-inline: 0px;
  }
}

@media (max-height: 730px) {
  .square {
    height: 400px;
    max-width: 290px;
  }
}

@media (max-height: 550px) {
  .square {
    height: 400px;
    max-width: 290px;
  }
  .privacyText {
    position: absolute;
    top: 130px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 70px;
    width: 100vw;
  }
}
</style>
