<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-container" v-if="this.role">
      <div class="modal-header">
        <span class="modal-title">{{ $t("language.changeDevTitle") }}</span>
      </div>

      <div class="modal-content">
        <div class="inputfields">
          <div class="custom-input-div">
            <input
              list="freeDevices"
              aria-label="Default select example"
              :placeholder="$t('language.pilotDevSN')"
              id="device-selector"
              type="number"
              v-model="this.device_serial_from"
              @input="validateNumericInput($event)"
            />
          </div>
          <datalist id="freeDevices">
            <option
              v-for="item in this.filteredDeviceArray"
              :value="item.device_serial_num"
              :key="item.device_id"
            >
              {{ item.device_serial_num }}
            </option>
          </datalist>
          <div class="custom-input-div">
            <input
              list="freeDevices2"
              aria-label="Default select example"
              :placeholder="$t('language.pilotDevSN')"
              id="device-selector"
              type="number"
              v-model="device_serial_to"
              @input="validateNumericInput($event)"
            />
          </div>
          <datalist id="freeDevices2">
            <option
              v-for="item in this.oppositeFilteredDeviceArray"
              :value="item.device_serial_num"
              :key="item.device_id"
            >
              {{ item.device_serial_num }}
            </option>
          </datalist>
        </div>
        <div class="action-buttons">
          <GrayActionButton

            @actionClick="dataPush()"
            :actionImage="actionSaveImage"
            :text="saveText"
          />
          <GrayActionButton
           style="padding-left: 20px;"
            @actionClick="closeModal"
            :actionImage="actionCancel"
            :text="cancelText"
          />
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="body"
    :showCancelButton="false"
    @confirm="showAlert = false"
  />
</template>

<script>
//import GrayInputField from "./GrayInputField.vue";
import GrayActionButton from "./GrayActionButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "ChangeDeviceModal",
  emits: ["close"],
  data() {
    return {
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      device_serial_from: undefined,
      device_serial_to: undefined,

      trim_device_serial_from: undefined,
      trim_device_serial_to: undefined,
      showAlert: false,
      body: "",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      deviceArray: [],
      racerArray: [],
      staffArray: [],
      filteredDeviceArray: [],
      oppositeFilteredDeviceArray: [],
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.oldDevSN = this.$t("language.oldDevSN");
    this.newDevSN = this.$t("language.newDevSN");
  },
  async mounted() {
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
    await this.getAvailableDevices();
  },
  props: {
    showModal: Boolean,
  },
  watch: {
    showModal: function () {
      // If "showAddRacerModal" ever changes, then we will console log its new value.
      this.getAvailableDevices();
    },
  },
  methods: {
    
    async getAvailableDevices() {
      this.deviceArray = await dp.getDevice();
      let devices = [...this.deviceArray];
      let deviceStatus = await dp.getLastDeviceLogs();
      this.reclaimedStatus = [];
      for (const status of deviceStatus) {
        if (status.device_status == "reclaimed") {
          this.reclaimedStatus.push(status.device_id);
        }
      }
      this.oppositeFilteredDeviceArray = devices.filter((item) =>
        this.reclaimedStatus.includes(item.device_id)
      );
      this.filteredDeviceArray = devices.filter((item) =>
        !this.reclaimedStatus.includes(item.device_id)
      );
      
    },
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    closeModal() {
      this.$emit("close");
      this.device_serial_from = undefined;
      this.device_serial_to = undefined;
    },
    setOldDeviceName(value) {
      this.device_serial_from = value;
    },
    setNewDeviceName(value) {
      this.device_serial_to = value;
    },
    async dataPush() {
      if (!this.device_serial_from || !this.device_serial_to) {
        this.body = this.$t("language.changeFailed");
        this.showAlert = true;
      } else {
        this.trim_device_serial_from = this.device_serial_from.toString().trim();
        this.trim_device_serial_to = this.device_serial_to.toString().trim();
      }
      try {
        if (this.device_serial_from && this.device_serial_to) {
          await dp.changeDevice(
            this.trim_device_serial_from,
            this.trim_device_serial_to
          );
          this.closeModal();
          this.device_serial_from = undefined;
          this.device_serial_to = undefined;
          //alert
          this.body = this.$t("language.changeOk");
          this.showAlert = true;
        }else{
            this.body = this.$t("language.changeFailed");
          this.showAlert = true;
        }

      } catch (error) {
        this.body = this.$t("language.changeFailed");
        this.showAlert = true;
      }
      /*
        if (!this.device_serial_from && !this.device_serial_to) {
          this.body = "Device Change Failed";
          this.showAlert = true;
        } else {
          throw new Error("Select device to change");
        }
       catch (error) {
        this.body = "Device Change Failed";
        this.showAlert = true;
      } */
    },
  },
  components: {
    //GrayInputField,
    GrayActionButton,
    CustomAlert,
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
.custom-input-div {
  padding-left: 5vw;
}
#device-selector {
  width: 20vw;
  height: 5vh;
  border: 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  color: white;
  background-color: #555556;
  font-family: "Lexend";
  margin-bottom: 20px;
}
#device-selector:focus {
  outline-color: #dd9c44;
}
/* Add your modal styling here */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-container {
  background: #202528;
  padding-inline: 20px;
  border-radius: 10px;
  width: 30%;
  height: 50%;
  z-index: 1002;
  box-shadow: #dd9c44 0px 0px 10px;
}

h2 {
  color: #555556;
  margin-bottom: 20px;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.inputfields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 20px;
}

.close-modal {
  cursor: pointer;
  font-size: 3rem;
  color: white;
  opacity: 0.6;
}

.modal-title {
  color: white;
  opacity: 0.6;
  font-size: 1.5rem;
  font-family: "Lexend";
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 2vw;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 3vw;
}

@media (max-width: 768px) {
    .modal-content {
  padding-right: 0;
}
  .modal-container {
    width: 90%;
    height: 50%;
  }
  .custom-input {
    width: 40%;
  }
  .inputfields {
    height: 60%;
    width: 60%;
  }
  .action-buttons {
    padding-left: 0px;
  }
  #device-selector {
  width: 50vw;
  height: 4vh;
  border: 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  color: white;
  background-color: #555556;
  font-family: "Lexend";
  margin-bottom: 20px;
}
}
</style>
