<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.home") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.devManagement") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="races-background">
    <div
      class="title-div"
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

      "
    >
    <div style="flex: 1;">

      <GoldCircleButton
        style="z-index: 100"
        :circleText="back"
        :circleImage="backImage"
        @circleClick="navigateBack()"
      />
    </div>
      <div style="display:flex;">
        <span class="rally-title">{{ $t("language.devManagement") }}</span>
      </div>
      <div style="flex: 1;"></div>
    </div>
    <div class="card-container">
      <div class="custom-card card-margin" @click="showAddRacerModal = true">
        <h2 class="boxTitle">{{ $t("language.handOut") }}</h2>
        <i class="fa-solid fa-hand-holding-hand"></i>
      </div>
      <HandOutDeviceModal
        :showAddRacerModal="showAddRacerModal"
        @close="showAddRacerModal = false"
      />
      <div class="custom-card card-margin" @click="showModal = true">
        <h2 class="boxTitle">{{ $t("language.changeDev") }}</h2>
        <i class="fa-solid fa-arrow-right-arrow-left"></i>
      </div>
      <ChangeDeviceModal :showModal="showModal" @close="showModal = false" />
     
      <div
        class="custom-card card-margin"
        id="retCard"
        @click="showAlertModal = true"
      >
        <h2 class="boxTitle">{{ $t("language.returnDev") }}</h2>
        <i class="fa-solid fa-arrow-rotate-left"></i>
      </div>
      <DeviceReclaim
        :showReclaimDeviceModal="showReclaimDeviceModal"
        @close="showReclaimDeviceModal = false"
      />
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="$t('language.reclaimPlsDontBreakIt')"
    @confirm="showAlertModal = false; showReclaimDeviceModal = true"
    :showCancelButton="false"
  />
</template>

<script>
// import axios from "axios";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import ChangeDeviceModal from "@/components/ChangeDeviceModal.vue";
import HandOutDeviceModal from "@/components/AddDeviceToRacer.vue";
import DeviceReclaim from "@/components/DeviceReclaim.vue";
import CustomAlert from "@/components/CustomAlert.vue";

const dp = dataProvider();

export default {
  name: "DeviceManagement",
  props: {
    msg: String,
  },
  data() {
    return {
      backImage: "fa-solid fa-arrow-left",
      singOutIcon: "fa-solid fa-sign-out fa-rotate-180",
      showModal: false,
      showAddRacerModal: false,
      showReclaimDeviceModal: false,
      back: "",
      showAlertModal: false,
      
    };
  },
  created() {
    this.back = this.$t("language.back");
  },
  methods: {
    navigateBack() {
      this.$router.back();
    },
    navigateDeviceListPage() {
      this.$router.push("/devicelist");
    },
    navigateAllUsersPage() {
      this.$router.push("/users");
    },
    navigateEventsPage() {
      this.$router.push("/events");
    },
    async navigateActiveLegLiveMapPage() {
      const activeLeg = await dp.getActiveLeg();
      localStorage.setItem("leg_id", activeLeg.leg_id);
      this.$router.push("/liveMapView");
    },
    signOut() {
      localStorage.removeItem("user_id");
      this.$router.push("/");
    },
  },
  components: {
    GoldCircleButton,
    ChangeDeviceModal,
    HandOutDeviceModal,
    DeviceReclaim,
    CustomAlert,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
.races-background {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding-top: 17vh;
}
.card-margin {
  margin-inline: 2vw;
}
.custom-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 20vw;
  background-color: #2c3133;
  color: #dd9c44;
  border-radius: 10px;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}
footer {
  position: absolute;
  bottom: 0;
}
.custom-card:hover {
  background-color: #dd9c44;
  color: #2c3133;
  width: 21vw;
}
.small-title {
  color: white;
  font-size: 30px;
  font-family: "Lexend";
  display: none;
}
.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.custom-card-man {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
  width: 17vw;
  background-color: #2c3133;
  color: #dd9c44;
  border-radius: 10px;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}
i {
  font-size: 7em;
}
.rally-title {
  font-family: "Lexend";
  color: white;
  font-size: 50px;
}
h2 {
  font-size: 2.3em;
  font-family: "Lexend";
}
.device-man {
  margin-top: -40px;
}

@media (max-width: 1200px) {
  .custom-card {
    width: 25vw;
    height: 40vh;
  }
  i {
    font-size: 6em;
  }
  h2 {
    font-size: 2em;
  }
  .card-margin {
    margin-inline: 4vw;
  }
  .custom-card:hover {
    width: 23vw;
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    padding-top: 10vh;
  }
  .custom-card {
    width: 50vw;
    height: 30vh;
  }
  i {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
  .rally-title {
    font-size: 20px;

  }
  .small-title {
    font-size: 20px;
    padding-left: 0px;
    padding-top: 30px;
  }
  .card-margin {
    margin-block: 4vh;
  }
  .custom-card:hover {
    width: 45vw;
  }
  .title-div {
    padding-bottom: 5vh;
  }
}
@media (max-width: 500px) {
  .card-container {
    flex-direction: column;
  }
  .custom-card {
    width: 50vw;
    height: 30vh;
  }
  i {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
  .rally-title {
    font-size: 20px;

  }
  .card-margin {
    margin-block: 4vh;
  }
  .custom-card {
    width: 35vw;
    height: 20vh;
  }
  .custom-card:hover {
    width: 45vw;
  }
  .title-div {
    padding-bottom: 0;
  }
  .boxTitle {
    font-size: small;
  }
}
@media (max-width: 450px) {
  .small-title {
    display: block;
  }
/*   .rally-title {
    Ha nem kell töröljük mert jelzi és hibát dob rá.
  } */
}
</style>
