import { createRouter, createWebHistory } from "vue-router";

import LoginPage from "../components/LoginPage.vue";
import Events from "../components/Events.vue";
import LegsPage from "../components/LegsPage.vue";
import UserList from "../components/UserList.vue";
import RacerList from "../components/RacerList.vue";
import RacerInfo from "../components/RacerInfo.vue";
import Device from "../components/Device.vue";
import DeviceList from "../components/DeviceList.vue";
import LiveMapView from "../components/LiveMapView.vue";
import StaffList from "../components/StaffList.vue";
import StaffInfo from "../components/StaffInfo.vue";

import EditLeg from "../components/EditLeg.vue";
import EditEvent from "../components/EditEvent.vue";
import EditUser from "../components/EditUser.vue";
import EditDevice from "../components/EditDevice.vue";
import EditRacer from "../components/EditRacer.vue";
import EditStaff from "../components/EditStaff.vue";

import AddLeg from "../components/AddLeg.vue";
import AddEvent from "../components/AddEvent.vue";
import AddRacer from "../components/AddRacer.vue";
import AddUser from "../components/AddUser.vue";
import AddDevice from "../components/AddDevice.vue";
import AddUserToEvent from "../components/AddUserToEvent.vue";
import AddStaff from "../components/AddStaff.vue";

import LandingPage from "../components/LandingPage.vue";
import ProjectorPage from "../components/ProjectorPage.vue";
import ReplayPage from "../components/ReplayPage.vue";
import DeviceManagement from "../components/DeviceManagement.vue";

import dataProvider from "../utils/dataProvider";

const dp = dataProvider();

async function guard() {
  try {
    let event_id = localStorage.getItem("event_id");
    let userEmail = localStorage.getItem("username");
    let user = await dp.getUserByEmail(userEmail);
    let userList = await dp.getEventUsersByEvent(event_id);
    let found = userList.find((item) => item.user_id == user.user_id);
    if (found) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error in guard function:", error);
    throw error;
  }
}

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "LandingPage",
    component: LandingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: { requiresAuth: true },
  },
  {
    path: "/legs",
    name: "LegsPage",
    component: LegsPage,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/users",
    name: "UserList",
    component: UserList,
    meta: { requiresAuth: true },
  },
  {
    path: "/racerlist",
    name: "RacerList",
    component: RacerList,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/staffList",
    name: "StaffList",
    component: StaffList,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/racerinfo",
    name: "RacerInfo",
    component: RacerInfo,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/StaffInfo",
    name: "StaffInfo",
    component: StaffInfo,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/editleg",
    name: "EditLeg",
    component: EditLeg,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/editevent",
    name: "EditEvent",
    component: EditEvent,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/edituser",
    name: "EditUser",
    component: EditUser,
    meta: { requiresAuth: true },
  },
  {
    path: "/editdevice",
    name: "EditDevice",
    component: EditDevice,
    meta: { requiresAuth: true },
  },
  {
    path: "/addracer",
    name: "AddRacer",
    component: AddRacer,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/addStaff",
    name: "AddStaff",
    component: AddStaff,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/device",
    name: "Device",
    component: Device,
    meta: { requiresAuth: true },
  },
  {
    path: "/deviceList",
    name: "DeviceList",
    component: DeviceList,
    meta: { requiresAuth: true },
  },
  {
    path: "/liveMapView",
    name: "LiveMapView",
    component: LiveMapView,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/AddLeg",
    name: "AddLeg",
    component: AddLeg,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/AddEvent",
    name: "AddEvent",
    component: AddEvent,
    meta: { requiresAuth: true },

  },
  {
    path: "/AddUser",
    name: "AddUser",
    component: AddUser,
    meta: { requiresAuth: true },
  },
  {
    path: "/AddDevice",
    name: "AddDevice",
    component: AddDevice,
    meta: { requiresAuth: true },
  },
  {
    path: "/EditRacer",
    name: "EditRacer",
    component: EditRacer,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/EditStaff",
    name: "EditStaff",
    component: EditStaff,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/AddUserToEvent",
    name: "AddUserToEvent",
    component: AddUserToEvent,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/projectorPage",
    name: "ProjectorPage",
    component: ProjectorPage,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/ReplayPage",
    name: "ReplayPage",
    component: ReplayPage,
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (await guard()) {
        next();
      } else {
        next({
          path: "/events",
        });
      }
    },
  },
  {
    path: "/DeviceManagement",
    name: "DeviceManagement",
    component: DeviceManagement,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    //console.log(localStorage.getItem('JWT'))
    //console.log(await dp.checkAuth(localStorage.getItem('JWT')))
    if (!(await dp.checkAuth(localStorage.getItem("JWT")))) {
      next({
        path: "/",
      });
    } else next();
  } else next();
});

export default router;
