<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.events") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legs") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/racerlist">{{ $t("language.staffList") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.addNewStaffSmall") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
            title="Staff list"
          />
        </div>
        <div class="header-title">
          <span>{{ $t("language.addNewStaff") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.devSN") }}
              <input
                list="freeDevices"
                aria-label="Default select example"
                id="device-selector"
                v-model="this.device_serial_num"
                type="number"
              />
              <datalist id="freeDevices">
                <option
                  v-for="item in deviceArray"
                  :value="item.device_serial_num"
                  :key="item.device_id"
                >
                  {{ item.device_serial_num }}
                </option>
              </datalist>
            </div>
            <div class="edit-row">
              {{ $t("language.staffName") }}
              <GrayInputField :setter="setStaffName" />
            </div>
            <div class="edit-row">
              {{ $t("language.staffEmail") }}
              <GrayInputField :setter="setStaffEmail" type="email" />
            </div>
            <div class="edit-row">
              {{ $t("language.staffPhone") }}
              <GrayInputField
                :setter="setStaffPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
          </div>
          <div class="action-buttons">
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleCancelClick"
              :actionImage="actionCancel"
              :text="cancelText"
            />
            <GrayActionButton
              @actionClick="handleSaveClick()"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomAlert
      :show="showAlertModal"
      :body="alertBody"
      @confirm="showAlertModal = false"
      :showCancelButton="showCancelButton"
    />
  </div>
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import CustomAlert from "./CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "AddRacer",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      event_id: undefined,
      device_id: undefined,
      staff_name: undefined,
      staff_email: undefined,
      staff_phonenum: undefined,
      device_serial_num: undefined,
      backImage: "fa-solid fa-arrow-left",
      actionCancel: "fa-solid fa-xmark",
      deviceArray: [],
      staffArray: [],
      racerArray: [],
      back: "",
      cancelText: "",
      isValidEmail: false,
      showAlertModal: false,
      alertBody: "",
      showCancelButton: false,
      validStaff: "",
      validEmail: "",
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.validStaff = this.$t("language.validStaff");
    this.validEmail = this.$t("language.validEmail");
    this.cancelText = this.$t("language.cancelText");
  },
  props: [],
  async mounted() {
    this.deviceArray = await dp.getDevice();
    this.event_id = localStorage.getItem("event_id");

    this.staffArray = await dp.getStaff();
    this.racerArray = await dp.getRacers();

    let comboarray = [];
    for (const i of this.staffArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }
    for (const i of this.racerArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }

    for (const device of comboarray) {
      let foundIndex = this.deviceArray.findIndex(
        (obj) => obj.device_id == device.device_id
      );
      if (foundIndex != -1) {
        this.deviceArray.splice(foundIndex, 1);
      }
    }
  },

  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.staff_email);
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36203234211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    async handleSaveClick() {
      console.log(this.deviceArray);
      this.validateEmail();
      if (!this.isValidEmail) {
        console.log(this.isValidEmail, this.staff_email);
        this.alertBody = this.validEmail;
        this.showAlertModal = true;
        return;
      }
      let DeviceIdFinder = this.deviceArray.find(
        (DeviceIdFinder) =>
          DeviceIdFinder.device_serial_num == this.device_serial_num
      );
      if (DeviceIdFinder) {
        this.device_id = DeviceIdFinder.device_id;
        if (!this.device_serial_num) {
          console.log("error !this.device_serial_num");
          this.alertBody = this.validStaff;
          this.showAlertModal = true;
          return;
        }
        console.log(
          this.event_id,
          this.device_id,
          this.staff_name,
          this.staff_email,
          this.staff_phonenum
        );
        await dp.addStaff(
          this.event_id,
          this.device_id,
          this.staff_name,
          this.staff_email,
          this.staff_phonenum
        );
        this.$router.push("/racerlist");
      } else {
        console.log("error Device not found");
        this.alertBody = "Device not found";
        this.showAlertModal = true;
        return;
      }
    },
    handleCancelClick() {
      this.$router.push("/racerlist");
    },
    handleBackClick() {
      this.$router.push("/racerlist");
    },
    setStaffName(value) {
      // Split the full name into first name and last name
      let names = value.split(" ");

      // Capitalize the first letter of the first name
      let capitalizedFirstName =
        names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();

      // Capitalize the first letter of the last name (if available)
      let capitalizedLastName = "";
      if (names.length > 1) {
        capitalizedLastName =
          names[names.length - 1].charAt(0).toUpperCase() +
          names[names.length - 1].slice(1).toLowerCase();
      }
      // Construct the capitalized full name
      let capitalizedFullName = capitalizedFirstName;
      if (capitalizedLastName !== "") {
        capitalizedFullName += " " + capitalizedLastName;
      }

      // Set the capitalized full name to the racer_pilot_name property
      this.staff_name = capitalizedFullName;
    },
    setStaffEmail(value) {
      this.staff_email = value;
    },
    setStaffPhoneNumber(value) {
      this.staff_phonenum = value;
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
  display: none !important;
}

header {
  position: fixed;
  top: 0;

  left: 20px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#device-selector {
  background-color: #555556;
  font-family: "Lexend";
  color: white;
  border-radius: 10px;
  padding: 5px;
  width: 20vw;
  margin-left: 20px;
  border-style: none;
}

#device-selector:focus {
  border: 2px solid #dd9c44;
}

h1 {
  font-size: 50px;
  font-family: "Lexend";
}

footer {
  position: absolute;
  bottom: 0;
}

#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.edit-block::-webkit-scrollbar {
  width: 5px;
  color: #dd9c44;
}

.edit-block::-webkit-scrollbar-thumb {
  background: #dd9c44;
  border-radius: 5px;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-family: "Lexend";
  color: white;
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
  overflow-y: auto;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  text-align: left;
}

@media (max-width: 1400px) {
  .edit-row {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
  .edit-block {
    width: 90vw;
  }
}

@media (max-height: 800px) {
  .header-title {
    font-size: 20px;
  }
}

@media (max-height: 640px) {
}
</style>
