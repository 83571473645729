<template>
  <div v-show="show" class="modal" style="font-family: Lexend">
    <div class="modal-content">
      <h2>{{ $t('language.alertMsg') }}</h2>
      <div class="modal-body" style="padding-block: 25px">
        <slot>
          {{ body }}
        </slot>
      </div>
      <div class="custom-modal-footer">
        <div>
          <button
            class="modal-button-confirm"
            @click="$emit('confirm')"
          >
            {{ confirmText }}
          </button>
        </div>
        <div v-show="showCancelButton">
          <button class="modal-button-cancel" @click="$emit('cancel')">
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
 <!--  Minta!!!! -->
<!--   <CustomAlert
    :show="showAlertModal" (true nál látszik false nál nem látszik)
    :body="body"  (ez a szöveg jelenik meg a modalban)
    :confirmText="confirmText" (ez a szöveg jelenik meg a confirm gombon)
    :cancelText="cancelText" (ez a szöveg jelenik meg a cancel gombon)
    :showCancelButton="false" (ha true akkor megjelenik a cancel gomb, ha false akkor nem default érték true)
    @confirm="showAlertModal = false" (ha a confirm gombra kattintunk akkor ez a function megy végbe)
    @cancel="showAlertModal = false" (ha a cancel gombra kattintunk akkor ez a function megy végbe)
  /> -->
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    body: {
      type: String,
    },
    confirmText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    translatedConfirmText() {
      return this.$t(this.confirmText);
    },
    translatedCancelText() {
      return this.$t(this.cancelText);
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
}
.modal-button-confirm {
  background: #dd9c44;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  min-width: 100px;
}

.modal-button-confirm:hover {
  box-shadow: #555556 0px 0px 10px;
  transition: 0.3s;
}

.modal-button-cancel {
  background: #555556;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  min-width: 100px;
}

.modal-button-cancel:hover {
  box-shadow: #dd9c44 0px 0px 10px;
  transition: 0.3s;
}

.modal-content {
  background: #202528;
  padding: 20px;
  border-radius: 4px;
  width: 450px;
  box-shadow: #dd9c44 0px 0px 10px;
  color: white;
}

.custom-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}
</style>
