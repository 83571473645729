<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.navHome")}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.navEvents")}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.navLegs")}}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/racerlist">{{ $t("language.racerList")}}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
        {{ $t("language.navRacerInfo")}}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row" v-if="this.role">
    <div class="center-alignment" style="">
      <div style="flex: 1">
        <GoldCircleButton
          :circleText="$t('language.racerList')"
          :circleImage="racerListImg"
          @circleClick="navigateBack()"
        />
      </div>
      <div class="title-container">
        <span class="title-text">
          {{ this.racer.racer_start_num }} | {{ this.racer.racer_pilot_name }}
        </span>
      </div>
      <div
        v-if="screenWidth <= 600 && this.role"
        style="flex: 1; display: flex; justify-content: right"
      >
        <GoldCircleButton
          circleText="Edit"
          :circleImage="addImage"
          @circleClick="navigateEditRacer()"
          v-if="this.role == 'admin' || this.role == 'staff'"
          id="edit-btn"
        />
      </div>
      <div
        v-if="this.role"
        style="flex: 1; display: flex; justify-content: right"
      >
        <GoldCircleButton
          :circleText=" $t('language.racerMessage')"
          :circleImage="messageImage"
          @circleClick="sendMessageToRacer()"
          v-if="this.role == 'admin' || this.role == 'clerk'"
          style="z-index: 6000;"
        />
      </div>
      <MessageModal
        :deviceId="this.deviceArr.device_physical_id"
        :racerId="this.racer.racer_id"
        :showModal="showMessageModal"
        @close="showMessageModal = false"
        :racer="this.racer.racer_pilot_name"
      />
    </div>
    <div class="page-title">
      <div class="racer-info">
        <table v-if="screenWidth <= 768" class="header-table">
          <tr>
            <th>{{ $t("language.raceriPilot") }}</th>
            <th>{{ $t("language.raceriNationality") }}</th>
            <th>{{ $t("language.raceriCoPilot") }}</th>
            <th>{{ $t("language.raceriCoNationality") }}</th>
            <td>{{ this.racer.racer_pilot_name }}</td>
            <td>{{ this.racer.racer_pilot_nationality }}</td>
            <td>{{ this.racer.racer_copilot_name }}</td>
            <td>{{ this.racer.racer_copilot_nationality }}</td>
          </tr>
          <tr class="racer-info-data">
            <th>Start num</th>
            <th>Device SN</th>
            <th>Status</th>
            <th></th>
            <td>{{ this.racer.racer_start_num }}</td>
            <td>{{ this.deviceArr.device_serial_num }}</td>
            <td>{{ this.racer.racer_status.charAt(0).toUpperCase() + this.racer.racer_status.slice(1) }}</td>
          </tr>
        </table>
        <table v-else class="header-table">
          <tr>
            <th>{{ $t("language.raceriPilot") }}</th>
            <th>{{ $t("language.deviceCurrentStage") }}</th>
            <th>{{ $t("language.raceriNationality") }}</th>
            <th>{{ $t("language.raceriCoPilot") }}</th>
            <th>{{ $t("language.raceriCoNationality") }}</th>
            <th>{{ $t("language.raceriPhone") }}</th>
            <th>{{ $t("language.raceriStart") }}</th>
            <th>{{ $t("language.raceriDeviceSN") }}</th>
            <th>{{ $t("language.raceriStatus") }}</th>

          </tr>
          <tr class="racer-info-data">
            <td>{{ this.racer.racer_pilot_name }}</td>
            <td>{{ this.stageName }}</td>
            <td>{{ this.racer.racer_pilot_nationality }}</td>
            <td>{{ this.racer.racer_copilot_name }}</td>
            <td>{{ this.racer.racer_copilot_nationality }}</td>
            <td>{{ this.racer.racer_pilot_phonenum }}</td>
            <td>{{ this.racer.racer_start_num }}</td>
            <td>{{ this.deviceArr.device_serial_num }}</td>
            <td>{{ this.racer.racer_status.charAt(0).toUpperCase() + this.racer.racer_status.slice(1) }}</td>
          </tr>
        </table>
      </div>
      <button
        class="edit-button"
        @click="navigateEditRacer()"
        title="Edit racer"
        v-if="this.role == 'admin' || this.role == 'staff'"
      >
        <i style="font-size: 1.3rem" class="fa-solid fa-pen-to-square"></i>
        <span
          style="padding-left: 5px; display: inline-flex; font-family: Lexend"
          >{{  $t('language.legEdit') }}</span
        >
      </button>
    </div>
    <div class="diagrams">
      <div class="left-diagrams">
        <div class="select-container">
          <label
            for="chart-select"
            class="col-form-label"
            id="chart-select-label"
            >{{ $t("language.raceriLengthChart") }}</label
          >
          <select
            v-model="this.selectedNumber"
            @change="onSelectChange()"
            id="chart-select"
            :disabled="selectDisabled"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>

        <div class="gForce">
          <canvas id="speedChart"></canvas>
        </div>
        <div class="speed">
          <canvas id="gForceChart"></canvas>
        </div>
      </div>
      <div class="right-diagrams">
        <div class="outer-table">
          <table class="vehicle-table">
            <thead>
              <tr>
                <th colspan="4">{{ $t("language.vehicleData") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceLat") }}</td>
                <td>{{ formatLatitude(this.latitude)}} °</td>
                <td>{{ $t("language.deviceLon") }}</td>
                <td>{{ formatLatitude(this.longitude) }} °</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceAlt") }}</td>
                <td v-if="this.altitude">{{ this.altitude.toFixed(2) }} m</td>
                <td>{{ $t("language.deviceBear") }}</td>
                <td>{{ this.bearing }}°</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceSpeed") }}</td>
                <td>{{ this.velocity }} km/h</td>
                <td>{{ $t("language.deviceG") }}</td>
                <td>{{ this.g_force }} g</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceCurrentStage") }}</td>
                <td>{{ this.racer.racer_current_stage }}</td>
                <td>{{ $t("language.devicePhone") }}</td>
                <td>{{ this.racer.racer_pilot_phonenum }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceConn") }}</td>
                <td>{{ this.conn_type }}</td>
                <td>{{ $t("language.deviceSignal") }}</td>
                <td>{{ this.signal_strength }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceIMEI") }}</td>
                <td>{{ this.imei }}</td>
                <td>{{ $t("language.deviceMAC") }}</td>
                <td>{{ this.mac_address }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceIP4") }}</td>
                <td>{{ this.ip_4_address }}</td>
                <td>{{ $t("language.deviceIPV6") }}</td>
                <td>{{ this.ip_6_address }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.carVolt") }}</td>
                <td>{{ this.car_voltage }} V</td>
                <td>{{ $t("language.deviceVolt") }}</td>
                <td>{{ this.device_voltage }} V</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceIntTemp") }}</td>
                <td>{{ this.internal_temp }} °C</td>
                <td>{{ $t("language.deviceExt") }}</td>
                <td>{{ this.external_temp }} °C</td>
              </tr>
              <tr class="racer-info-data">
                <td>{{ $t("language.deviceRecord") }}</td>
                <td>{{ formatTimeDate(this.recorded_at) }}</td>
                <td>{{ $t("language.deviceRec") }}</td>
                <td>{{ formatTimeDate(this.received_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom">
        
           <button  class="followRacerBtn" @click="navigateRacerMap()">
               <a> {{ $t("language.followActiveRacer") }} <i class="fa-solid fa-crosshairs"
              ></i></a>
          </button> 
          <div class="bool-icons">
            <div
              style="display: flex; flex-direction: column"
              :class="{
                'default-background': !this.sos,
                'danger-background': this.sos,
              }"
            >
              <div style="position: relative">
                <i
                  style="font-size: 5rem"
                  class="fa-solid fa-play fa-rotate-270"
                >
                </i>
                <i
                  v-if="this.fire"
                  style="
                    color: #dd9c44;
                    font-size: 2.2rem;
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-fire"
                ></i>
                <i
                  v-if="this.medic"
                  style="
                    color: #dd9c44;
                    font-size: 2rem;
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-suitcase-medical"
                ></i>
                <i
                  v-if="this.sos"
                  style="
                    color: #dd9c44;
                    font-size: 2.2rem;
                    position: absolute;
                    top: 43%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-exclamation"
                ></i>
                <i
                  v-else
                  style="
                    color: black;
                    opacity: 0.7;
                    font-size: 2.2rem;
                    position: absolute;
                    top: 43%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-exclamation"
                ></i>

                <div>
                  <span v-if="this.fire">{{ $t("language.deviceFire")}}</span>
                  <span v-if="this.medic">{{ $t("language.deviceMedic")}}</span>
                  <span v-if="this.sos">{{ $t("language.SOS")}}</span>
                  <span v-else>{{ $t("language.deviceDanger")}}</span>
                </div>
              </div>
            </div>

            <div
              style="display: flex; flex-direction: column"
              :class="{
                'default-background': !this.ok,
                'road-background': this.road_blocked || this.ok || this.road_clear
              }"
            >
              <div style="position: relative">
                <i style="font-size: 4.5rem" class="fa-solid fa-circle"> </i>
                <i
                  v-if="!this.ok"
                  style="
                    color: white;
                    font-size: 2.8rem;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-road"
                ></i>
                <i
                  v-if="this.road_blocked"
                  style="
                    color: #dd9c44;
                    font-size: 2.8rem;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-road-lock"
                ></i>
                <i
                  v-else
                  style="
                    color: black;
                    opacity: 0.7;
                    font-size: 2.8rem;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  class="fa-solid fa-road"
                ></i>
                <div style="padding-top: 1.2vh">
                  <span v-if="this.ok">{{ $t("language.deviceRoadCl") }}</span>
                  <span v-if="this.road_blocked">{{ $t("language.deviceRoadBl") }}</span>
                  <span v-else>OK</span>
                </div>
              </div>
            </div>

            <div
              style="display: flex; flex-direction: column"
              class=""
              :class="{
                'speeding-background': this.speeding,
                'default-background': !this.speeding,
              }"
            >
              <i class="fa-solid fa-gauge-high" style="font-size: 4.5rem"></i>
              <span style="padding-top: 1vh">{{ $t("language.deviceSpeedingA")}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoldCircleButton from "./GoldCircleButton.vue";
import Chart from "chart.js/auto";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();
import mqtt from "mqtt/dist/mqtt";
import MessageModal from "./MessageModal.vue";
import jwt_decode from "jwt-decode";

// const signaling_topic=process.env.SIGNALING_TOPIC || "signalingLog"
// const frontend_raw_data_topic=process.env.FRONTENDRAWDATA_TOPIC ||"rawData"
// const server_data_topic=process.env.SERVERTODEVICE_TOPIC ||"ddc-rally/serverData"
const frontend_topic=process.env.VUE_APP_FRONTEND_TOPIC|| "deviceData"
const topic_prefix=process.env.VUE_APP_TOPIC_PREFIX || "" //demo/
// const device_data_topic=process.env.DEVICEDATA_TOPIC|| "ddc-rally/deviceData"

export default {
  name: "RacerInfo",
  data() {
    return {
      selectedNumber: 10,
      wordDocumentUrl: "../adatvedelem.docx",
      racerListImg: "fa-solid fa-user-astronaut",
      addImage: "fa-solid fa-pen-to-square",
      messageImage: "fa-solid fa-envelope",
      screenWidth: window.innerWidth,
      racer: [],
      racer_id: "",
      myLineChart: undefined,
      myLineChart2: undefined,

      device: [],
      
      deviceLog: undefined,
      deviceData: undefined,
      altitude: undefined,
      bearing: undefined,
      car_voltage: undefined,
      conn_type: undefined,
      device_log_id: undefined,
      device_voltage: undefined,
      external_temp: undefined,
      extreme_accel_warning: undefined,
      extreme_temp_warning: undefined,
      fire: undefined,
      g_force: undefined,
      imei: undefined,
      internal_temp: undefined,
      ip_4_address: undefined,
      ip_6_address: undefined,
      latitude: undefined,
      longitude: undefined,
      mac_address: undefined,
      medic: undefined,
      ok: undefined,
      racerId: undefined,
      received_at: undefined,
      recorded_at: undefined,
      road_blocked: undefined,
      road_clear: undefined,
      signal_strength: undefined,
      sos: undefined,
      speeding: undefined,
      velocity: undefined,
      x_acceleration: undefined,
      y_acceleration: undefined,
      z_acceleration: undefined,
      showMessageModal: false,
      selectDisabled: false,

      chartTimes: [],
      gForceValues: [],
      speedValues: [],
      deviceArr: [],

      gForceValuesRe: [],
      speedValuesRe: [],
      chartTimesRe: [],
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      ///-------------------------------------------------------MQTT-----------------------------------------------------
      connection: {
        protocol: "wss",
        host: "rally.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "", // "/mqtt"
        rejectUnauthorized: false,

        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "rally_frontend_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "",
        password: "",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
      stageName:undefined,

      //------------------------------------------------------------MQTT END------------------------------------------------------------------------------------------
      translatedChartGText: "",
      translatedSpeedText: "",
      translatedChartSpeedText: "",
      translatedGText: "",
    };
  },
  created() {
    this.translatedChartGText = this.$t("language.chartG");
    this.translatedSpeedText = this.$t("language.chartSpeed");
    this.translatedChartSpeedText = this.$t("language.chartSpeed");
    this.translatedGText = this.$t("language.chartG");
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.racer_id = localStorage.getItem("racer_id");
    this.staff_id= localStorage.getItem("staff_id")
    this.racer = await dp.getRacersById(this.racer_id);
    this.deviceArr = await dp.getDeviceById(this.racer.device_id);
    this.deviceLog = await dp.getDeviceLogByRacerIdLimit(
      this.racer.racer_id,
      this.selectedNumber,
      this.staff_id
    );
    this.legId = localStorage.getItem("leg_id");
    this.logArray = await dp.getStageByLegId(this.legId);
    for (let i = 0; i < this.logArray.length; i++) {
      if (this.logArray[i].stage_id == this.racer.racer_current_stage) {
        this.stageName = this.logArray[i].stage_name;
      }
    }
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
    this.initMQTTData();
    this.createMQTTConnection();
    this.doMQTTSubscribe(`${topic_prefix}${frontend_topic}/${this.deviceArr.device_physical_id}`, 0);

    if (this.deviceLog[0]) {
      this.altitude = this.deviceLog[0].altitude;
      this.bearing = this.deviceLog[0].bearing;
      this.car_voltage = this.deviceLog[0].car_voltage;
      this.conn_type = this.deviceLog[0].conn_type;
      this.device_log_id = this.deviceLog[0].device_log_id;
      this.device_voltage = this.deviceLog[0].device_voltage;
      this.external_temp = this.deviceLog[0].external_temp;
      this.extreme_accel_warning = this.deviceLog[0].extreme_accel_warning;
      this.extreme_temp_warning = this.deviceLog[0].extreme_temp_warning;
      this.fire = this.deviceLog[0].fire;
      this.g_force = this.deviceLog[0].g_force;
      this.imei = this.deviceLog[0].imei;
      this.internal_temp = this.deviceLog[0].internal_temp;
      this.ip_4_address = this.deviceLog[0].ip_4_address;
      this.ip_6_address = this.deviceLog[0].ip_6_address;
      this.latitude = this.deviceLog[0].latitude;
      this.latitude = this.deviceLog[0].latitude;
      this.longitude = this.deviceLog[0].longitude;
      this.mac_address = this.deviceLog[0].mac_address;
      this.medic = this.deviceLog[0].medic;
      this.ok = this.deviceLog[0].ok;
      this.racerId = this.deviceLog[0].racerId;
      this.received_at = this.deviceLog[0].received_at;
      this.recorded_at = this.deviceLog[0].recorded_at;
      this.road_blocked = this.deviceLog[0].road_blocked;
      this.signal_strength = this.deviceLog[0].signal_strength;
      this.sos = this.deviceLog[0].sos;
      this.speeding = this.deviceLog[0].speeding;
      this.velocity = this.deviceLog[0].velocity;
      this.x_acceleration = this.deviceLog[0].x_acceleration;
      this.y_acceleration = this.deviceLog[0].y_acceleration;
      this.z_acceleration = this.deviceLog[0].z_acceleration;
      for (let i = 0; i < 10; i++) {
        let timeString = this.deviceLog[i].recorded_at.replace("T", " ");
        timeString = timeString.replace("Z", "");
        timeString = timeString.slice(11, 19);
        this.chartTimes.push(timeString);

        this.speedValues.push(this.deviceLog[i].velocity);
        this.gForceValues.push(this.deviceLog[i].g_force);
      }
    }
    await this.loadadatok();
    this.createCharts();
    // this.updateChart();
    
    this.device = await dp.getDeviceById(this.racer.device_id);

  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    GoldCircleButton,
    MessageModal,
  },
  methods: {
    // updateChart() {
    //   if (this.myLineChart) {
    //     this.myLineChart.data.labels = this.chartTimes;
    //     this.myLineChart.data.datasets[0].data = this.gForceValues;
    //     this.myLineChart.update();
    //   }
    // },
    async loadadatok() {
      this.deviceLog = await dp.getDeviceLogByRacerIdLimit(
        this.racer.racer_id,
        this.selectedNumber,
        this.staff_id

      );
      let gforceArray = [];
      let speedArray = [];
      let timeArray = [];
      for (let i = 0; i < this.deviceLog.length; i++) {
        let timeString = this.deviceLog[i].recorded_at.replace("T", " ");
        timeString = timeString.replace("Z", "");
        timeString = timeString.slice(11, 19);
        timeArray.push(timeString);
        speedArray.push(this.deviceLog[i].velocity);
        gforceArray.push(this.deviceLog[i].g_force);
      }

      this.chartTimes = [...timeArray];
      this.gForceValues = [...gforceArray];
      this.speedValues = [...speedArray];

      if (this.myLineChart) {
        this.myLineChart.config._config.data.labels = [...timeArray];
        this.myLineChart.config._config.data.datasets[0].data = [
          ...gforceArray,
        ];

        this.myLineChart.destroy();
        this.myLineChart2.destroy();
        this.createCharts();

        // this.myLineChart.config._config.data.datasets.hidden = false;

      }

    },
    onSelectChange() {
      this.selectDisabled = true;
      setTimeout(() => {
        this.selectDisabled = false;
      }, 1000);
      this.loadadatok();
    },

    createCharts() {
      this.chartTimesRe = this.chartTimes.reverse();
      this.gForceValuesRe = this.gForceValues.reverse();
      this.speedValuesRe = this.speedValues.reverse();

      let translatedChartG = this.translatedChartGText;

      this.myLineChart = new Chart("gForceChart", {
        type: "line",
        data: {
          labels: this.chartTimesRe,
          datasets: [
            {
              label: translatedChartG,
              fill: true,
              lineTension: 0.3,
              backgroundColor: "rgb(255, 217, 100, 0.5)",
              pointHoverBackgroundColor: "#fff",
              borderColor: "#DD9C44",
              data: this.gForceValuesRe,
            },
          ],
        },
        options: {
          scales: {
            y: {
              min: 0,
              ticks: {
                color: "white",
              },
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            x: {
              ticks: {
                color: "white",
              },
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          animation: {
              duration: 0,
            },
          plugins: {
            legend: {
              labels: {
                color: "white",
              },
            },
            tooltip: {
              /* intersect: false, */
              callbacks: {
                label: function (context) {
                  var label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.parsed.y;

                  var speedValue = this.speedValues[context.dataIndex];
                  let translatedSpeed = this.translatedSpeedText;
                  //var gForceValue = this.gForceValues[context.dataIndex];
                  label += " | " +  translatedSpeed + " "  + speedValue;

                  return label;
                }.bind(this),
              },
            },
          },
        },
      });

      let translatedChartSpeed = this.translatedChartSpeedText;

      this.myLineChart2 = new Chart("speedChart", {
        type: "line",
        data: {
          labels: this.chartTimesRe,
          datasets: [
            {
              label: translatedChartSpeed,
              fill: true,
              lineTension: 0.3,
              backgroundColor: "rgb(90,145,101, 0.5)",
              pointHoverBackgroundColor: "#fff",
              borderColor: "#5A9165",
              data: this.speedValuesRe,
            },
          ],
        },
        options: {
          scales: {
            y: {
              min: 0,
              // ticks: {
              //   color: "white",
              //   callback: function (value) {
              //     return value + " km/h";
              //   },
              // },

              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            x: {
              ticks: {
                color: "white",
              },
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          animation: {
              duration: 0,
            },
          plugins: {
            legend: {
              labels: {
                color: "white",
              },
            },
            tooltip: {
              /* intersect: false, */
              callbacks: {
                label: function (context) {
                  var label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.parsed.y;

                  var gForceValue = this.gForceValues[context.dataIndex];
                  let translatedG =this.translatedGText;
                  label += " | " + translatedG + " " + gForceValue;

                  return label;
                }.bind(this),
              },
            },
          },
        },
      });
    },

    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    navigateBack() {
      localStorage.setItem("tableType", 1);

   
        this.$router.push("/racerlist");
      
     

    },
    navigateEditRacer() {
      this.$router.push("/editracer");
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
   
    navigateRacerMap() {
      localStorage.setItem("racer_id", this.racer_id);
      this.$router.push("/livemapview");
    },
    sendMessageToRacer() {
      this.showMessageModal = true;
    },
    formatLatitude(latitude) {
      return parseFloat(latitude).toFixed(8);
    },
    //--------------------------------MQTT FUNCTIONS----------------------------------
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleMQTTOnReConnect() {/* 
      this.retryTimes += 1; */
      console.log("Mqtt reconnecting");

      /* if (this.retryTimes > 10) {
        try {
          this.client.end();
          this.initMQTTData();
          this.client.reconnect()

        } catch (error) {
          console.log(error);
        }
      } */
    },
    createMQTTConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleMQTTOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
          });
          this.client.on("message", async(topic, message) => {
            let data = JSON.parse(message);
            this.altitude = data.altitude;
            this.bearing = data.bearing;
            this.car_voltage = data.car_voltage;
            this.conn_type = data.conn_type;
            this.device_voltage = data.device_voltage;
            this.external_temp = data.external_temp;
            this.extreme_accel_warning = data.extreme_accel_warning;
            this.extreme_temp_warning = data.extreme_temp_warning;
            this.fire = data.fire;
            this.g_force = data.g_force;
            this.imei = data.imei;
            this.internal_temp = data.internal_temp;
            this.ip_4_address = data.ip_4_address;
            this.ip_6_address = data.ip_6_address;
            this.latitude = data.latitude;
            this.longitude = data.longitude;
            this.mac_address = data.mac_address;
            this.medic = data.medic;
            this.ok = data.ok;
            this.received_at = data.received_at;
            this.recorded_at = data.recorded_at;
            this.road_blocked = data.road_blocked;
            this.signal_strength = data.signal_strength;
            this.sos = data.sos;
            this.speeding = data.speeding;
            this.velocity = data.velocity;
            await this.loadadatok()
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
      }
    },

    doMQTTSubscribe(topic, qos) {
      //const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
      });
    },
    doMQTTUnSubscribe(topic) {
      //const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        }
      });
    },
    doMQTTPublish(topic, payload, qos) {
      //const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    //---------------------------------------------------------MQTT ends here----------------------------------------
  },
};
</script>

<style scoped>
.green-background-check {
  opacity: 1;
  color: green;
}

.danger-background {
  opacity: 1;
  color: red;
}

#chart-select {
  width: 20%;
  background-color: #2c3033;
  color: white;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}

#chart-select option:hover {
  background-color: #dd9c44 !important;
  color: #2c3033 !important;
}

#chart-select-label {
  color: white;
  margin-right: 10px;
}

.road-background {
  opacity: 1;
  color: green;
}


.speeding-background {
  opacity: 1;
  color: #dd9c44;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.default-background {
  opacity: 0.4;
  color: grey;
}

.green-background-medic {
  color: #ff0000;
  opacity: 1;
}

.bool-icon-img {
  background-color: transparent;
  height: 7vh;
  width: auto;
}

.bool-table {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: space-evenly;
}

.bool-table-row {
  text-align: center;
  margin: 10px;
  height: 10vh;
}

header {
  position: fixed;
  top: 0;
  left: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.vehicle-table th {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
}

.map-zoomed {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: flex;
  justify-content: flex-end;
}

.diagrams {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.diagrams::-webkit-scrollbar {
  overflow-y: hidden;
}

.left-diagrams {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  height: 100%;
  width: 100%;
  margin-right: 20px;
  flex: 1;
}

.right-diagrams {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.gForce {
  background-color: #2c3033;
  width: auto;
  max-width: 50vw;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

#gForceChart {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}

#speedChart {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}

.speed {
  background-color: #2c3033;
  width: auto;
  height: auto;
  max-width: 50vw;
  border-radius: 10px;
}

.page-title {
  width: 100%;
  background-color: #2c3033;
  height: 60px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  /* Adjusted to space between */
  /* Center the title within its parent container */
}

.bool-icons {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  padding-inline: 1vw;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.follow-race {
  background-color: #dd9c44;
  color: white;
  font-family: "Lexend";
  width: 45%;
  max-width: 45%;
  height: 8vh;
  bottom: 0;
  position: relative;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  margin:5px;
}

.header-table {
  width: 85vw;
  border-collapse: collapse;
}

.vehicle-table {
  width: 100%;
  border-collapse: collapse;
}

.outer-table {
  width: 100%;
  height: auto;
  overflow-y: auto;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow-x: hidden;
}

.race-text {
  font-size: large;
  font-weight: bold;
  margin-inline: 5px;
  text-align: center;
  color: #2c3133;
}

.followRacerBtn {
  background-color: #dd9c44;
  font-family: "Lexend";
  width: 45%;
  height: 55px;
  bottom: 0;
  position: relative;
  border-radius: 10px;
  text-align: center;
  border: none;
  cursor: pointer;
  padding:0;
  margin:5px;
}
.followRacerBtn:hover {
  opacity:0.7;
}
.fa-map-location-dot{
  color: #2c3133;
}

.vehicle-table th {
  background-color: #dd9c44;
  color: black;
  border-bottom: 1px solid #2c3033;
  font-size: large;
  padding: 10px;
  font-family: "Lexend";
}

.header-table th,
td {
  text-align: left;
}

.header-table th {
  color: #dd9c44;
  font-size: 16px;
  font-family: "Lexend";
  white-space: nowrap;
}

.header-table td {
  color: white;
  font-size: 16px;
  font-family: "Lexend";
  white-space: nowrap;
}

.vehicle-table td {
  border-bottom: 1px solid #2c3033;
  font-size: 15px;
  padding: 4px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  vertical-align: middle;
}

.title-text {
  color: white;
  font-size: 40px;
  font-family: "Lexend";
  white-space: nowrap;
}

h4 {
  color: #dd9c44;
  font-size: x-small;
  margin: 0;
  margin-right: 25%;
  font-family: "Lexend";
  white-space: nowrap;
}

h3 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
  margin-right: 15%;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3vh;
  padding: 0;
}

.row {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.cards-container::-webkit-scrollbar {
  height: 0px;
  width: 0;
}

.cards-container::-webkit-scrollbar-thumb {
  height: 0;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button {
  background: #dd9c44;
  border-radius: 10px;
  border: none;
  color: #202528;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  width: 12vh;
  height: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.edit-button:hover{
  box-shadow: #dd9c44 0px 0px 10px;
  border: 0px solid #dd9c44;
  transform: scale(1.1);
}

.outer-table::-webkit-scrollbar {
  width: 0px;
}

@media (max-width: 1400px) {
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bool-icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-inline: 1vw;
  }

  .follow-race {
    width: 95%;
    height: 8vh;
    margin-top: 10px;
  }

  .outer-table {
    height: auto;
  }

  /*   .left-diagrams {
    margin-bottom: 60px;
  } */
  .vehicle-table th {
    font-size: 16px;
  }

  .vehicle-table td {
    font-size: 14px;
  }

  .bool-icons {
    margin-top: 10px;
  }


}

@media (max-width: 1200px) {
  .diagrams {
    flex-direction: column;
  }
  .left-diagrams {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .gForce {
    width: 50vw;
    height: auto;
    min-width: 400px;
  }

  .speed {
    width: 50vw;
    margin-bottom: 20px;
    height: auto;
    min-width: 400px;

  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 52px;
  }

  #chart-select {
    width: 40%;
  }

  .bool-icons {
    transform: scale(0.8);
    transition: 0.3s;
  }

  .title-text {
    font-size: 12px;
    margin: 0;
  }

  .right-diagrams {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-table {
    width: 100vw;
    margin-left: 170px;
  }

  .header-table th {
    font-size: 10px;
  }

  .header-table td {
    font-size: 10px;
  }

  .header-table tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .page-title {
    height: 80px;
  }

  .edit-button {
    transform: scale(0);
    margin-right: 0px;
    flex-direction: row;
    padding-right: 100px;
  }

  .right-diagrams {
    padding-top: 20px;
  }

  .edit-button {
    background: #dd9c44;
    border-radius: 10px;
    border: none;
    color: #202528;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    width: 12vh;
    height: 30px;
    font-weight: bold;
    margin-right: 20px;
  }

  /* Diagramm design change to Grid */
  .diagrams {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .gForce {
    width: 95vw;
    height: 27vh;
    margin-top: 20px;
    min-width: 400px;

  }

  .speed {
    width: 95vw;
    height: 27vh;
    margin-top: 20px;
    min-width: 400px;

  }

  .vehicle-table {
    width: 100vw;
    margin-bottom: 10px;
  }

  .vehicle-table th {
    font-size: 12px;
  }

  .vehicle-table td {
    font-size: 10px;
  }

  .follow-race {
    width: 95vw;
    margin-bottom: 10px;
  }

  .left-diagrams {
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .race-text {
    font-size: 12px;
  }

  .title-container {
    margin-right: 0px;
  }

  .outer-table {
    width: 95vw;
    height: auto;
  }
}
</style>
