<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legsTitle") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.editEventTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row" v-if="this.role">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
      "
    >
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="navigateBack()"
            title="Back"
          />
        </div>
        <div class="header-title">
          <h1>{{ $t("language.editEventBigTitle") }}</h1>
        </div>
        <div style="flex: 1"></div>
      </div>
      <h1>{{ this.editName }}</h1>
      <h2 v-if="this.role == 'staff'">{{ this.editEventType }}</h2>
      <h2>{{ formatDate(this.editStartDate) }}</h2>
      <h2 v-if="this.role == 'staff'">{{ formatDate(this.editEndDate) }}</h2>
      <div class="edit-block" id="edit-block" v-if="this.role">
        <div v-if="this.role == 'admin'">
          <div class="edit-row">
            {{ $t("language.eventNameBig") }}
            <GrayInputField :value="this.editName" :setter="setName" />
          </div>
          <div class="edit-row">
            {{ $t("language.eventType") }}
            <GrayInputField :value="this.editEventType" :setter="setType" />
          </div>
          <div class="edit-row">
            {{ $t("language.newEventStart") }}
            <DateInputField
              :value="formatDate(this.editStartDate)"
              :setter="setStartDate"
            />
          </div>
          <div class="edit-row">
            {{ $t("language.newEventEnd") }}
            <DateInputField
              :value="formatDate(this.editEndDate)"
              :setter="setEndDate"
            />
          </div>
          <GrayActionButton
            @actionClick="handleConfigEvent()"
            :actionImage="isConfigEvent ? actionSpecImageUp : actionSpecImage"
            :text="specText"
            v-if="this.role == 'admin' || this.role == 'staff'"
          />
          <div v-if="isExtendDivVisible" id="extendDiv" class="extendDiv">
            <div class="edit-row" style="padding-bottom: 0px">
              <span class="name-date">{{ $t("language.snapRange") }}</span>

              <GrayInputField
                type="number"
                :value="this.editSnapRange"
                :setter="setSnapRange"
                class="edit-row"
                style="padding-top: 20px;"
                @input="validateSnapRange"
              />
            </div>
            <div class="edit-row" style="padding-bottom: 0px">
              <span class="name-date">{{ $t("language.dwellTime") }}</span>
              <GrayInputField
                type="number"
                :value="this.editDwellTime"
                :setter="setDwellTime"
                class="edit-row"
                @input="validateDwellTime"
              />
            </div>
            <div class="settings-row">
            <div class="settings-label">{{ $t("language.snapRange") }}:</div>
            <div class="settings-switch-container">
              <div class="settings-switch-text2">
                {{ $t("language.snapRangeOff") }}
              </div>
              <div class="form-check form-switch" id="switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="changeSnapRange"
                  @change="changeSnapRangeSwitch"
                  :checked="this.isSnapRangeOn"
                />
              </div>
              <div class="settings-switch-text2">
                {{ $t("language.snapRangeOn") }}
              </div>
            </div>
          </div>
        </div>

          <div class="action-buttons" v-if="this.role">
            <div>
              <GrayActionButton
                @actionClick="showAlert = true"
                :actionImage="actionDeleteImage"
                :text="deleteText"
                v-if="this.role == 'admin'"
              />
              <GrayActionButton
                @actionClick="showAlert = true"
                class="mx-4"
                :actionImage="actionDeleteImage"
                :text="deleteText"
                v-else
              />
            </div>
            <div style="display: flex">
              <GrayActionButton
                style="padding-inline: 20px"
                @actionClick="handleSaveClick"
                :actionImage="actionSaveImage"
                :text="saveText"
                v-if="this.role == 'admin'"
              />
              <GrayActionButton
                @actionClick="navigateBack"
                :actionImage="actionCancel"
                :text="cancelText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    body="Biztosan törölni akarja ezt az elemet a nyílvántartásból?"
    @confirm="handleDeleteClick"
    @cancel="showAlert = false"
  />
  <CustomAlert
    :show="showAlert2"
    :body="errorMessage"
    @confirm="showAlert2 = false"
    :showCancelButton="false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import DateInputField from "@/components/DateInputField.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import jwt_decode from "jwt-decode";
import CustomAlert from "@/components/CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "EditEvent",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      rallyName: "Nyíregyháza Rally",
      rallyLocation: "Nyíregyháza",
      rallyMap: "Upload Image",
      legName: "",
      legLocation: "",
      backImage: "fa-solid fa-arrow-left",
      editEvent: [],
      editName: undefined,
      editStartDate: undefined,
      editEndDate: undefined,
      editEventType: undefined,
      wordDocumentUrl: "../adatvedelem.docx",
      back: "",
      showAlert: false,
      showAlert2: false,
      errorMessage: "",
      isExtendDivVisible: false,
      editSnapRange: undefined,
      editDwellTime: undefined,
      isConfigEvent: false,
      actionSpecImage: "fa-solid fa-chevron-down",
      actionSpecImageUp: "fa-solid fa-chevron-up",
      specText: "",
      configEventArray: [],
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      isSnapRangeOn: true,
   
    };
  },
  created() {
    this.deleteText = this.$t("language.deleteText");
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.specText = this.$t("language.specText");
    this.back = this.$t("language.back");
  },
  props: {
    setter: {
      type: Function,
      default: null,
    },
  },

  async mounted() {
    this.event_id = localStorage.getItem("event_id");
    this.editEvent = await dp.getEventsById(this.event_id);
    this.editName = this.editEvent.event_name;
    this.editEventType = this.editEvent.event_type;
    this.editStartDate = this.editEvent.event_start_date;
    this.editEndDate = this.editEvent.event_end_date;
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------

    //Config leg get, backend requried
    this.configEventArray = await dp.getConfigEvent(this.event_id);
    this.editSnapRange = this.configEventArray[0]
      ? this.configEventArray[0].config_snap_range
      : undefined;
    this.editDwellTime = this.configEventArray[0]
      ? this.configEventArray[0].config_dwell_time
      : undefined;
    this.config_snap_range = this.editSnapRange;
    this.config_dwell_time = this.editDwellTime;
    if (this.role == "clerk") {
      this.isExtendDivVisible = true;
    }
    //-------------------------
    const snapRangeKey = `isSnapRangeOn_${this.event_id}`;
    let snapRange = localStorage.getItem(snapRangeKey);
    this.isSnapRangeOn = snapRange === null ? true : snapRange === "true";
  },
  methods: {
    async changeSnapRange(boolean) {
    const snapRangeKey = `isSnapRangeOn_${this.event_id}`;
    localStorage.setItem(snapRangeKey, boolean.toString());
    this.isSnapRangeOn = boolean;
    if (!this.isSnapRangeOn) {
        this.config_snap_range = 0;
    } else {
        this.config_snap_range = 10;
    }
    this.editSnapRange = this.config_snap_range;
    await this.$nextTick();
    if (!this.isSnapRangeOn) {
        await dp.configResetByEvent(
            this.event_id,
            this.config_snap_range,
            this.config_dwell_time
        );
    } else {
        await dp.configResetByEvent(
            this.event_id,
            this.config_snap_range,
            this.config_dwell_time
        );
    }
},
    changeSnapRangeSwitch(e) {
        this.changeSnapRange(e.target.checked);
    },
    async dataPush() {
      const snapRangeKey = `isSnapRangeOn_${this.event_id}`;
        let snapRange = localStorage.getItem(snapRangeKey);

        if (this.config_snap_range != 0 && snapRange == "false") {
            this.isSnapRangeOn = true;
            localStorage.setItem(snapRangeKey, "true");
        } else if (snapRange === null) {
            this.isSnapRangeOn = true;
            localStorage.setItem(snapRangeKey, "true");
        } else {
            this.isSnapRangeOn = snapRange === "true";
        }
      try {
        await dp.updateEventsById(
          this.event_name,
          this.event_type,
          this.event_start_date,
          this.event_end_date,
          this.event_id
        );
        await dp.configResetByEvent(
          this.event_id,
          this.config_snap_range,
          this.config_dwell_time
        );
      } catch (error) {
        console.log(error);
        this.showAlert2 = true;
        this.errorMessage = error.message;
      }
    },
    async remove() {
      await dp.deleteEventsById(this.event_id);
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    navigateBack() {
      this.$router.go(-1);
    },
    async handleDeleteClick() {
      await this.remove();
      this.$router.push("/events");
    },
    handleSaveClick() {
      this.dataPush();
      this.$router.go(-1);
    },

    setEndDate(value) {
      this.event_end_date = value;
    },
    setStartDate(value) {
      this.event_start_date = value;
    },
    setName(value) {
      this.event_name = value;
    },
    setType(value) {
      this.event_type = value;
    },
    setMap(value) {
      this.legmap = value;
    },
    setDwellTime(value) {
      this.config_dwell_time = value;
    },
   

    setSnapRange(value) {
      this.editSnapRange = Number(value);
      this.config_snap_range = this.editSnapRange;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toggleExtendDivWithDelay() {
      // Set isExtendDivVisible to true after a delay
      setTimeout(() => {
        this.isExtendDivVisible = true;
      }, 500); // 0.5 second delay
    },

    handleConfigEvent() {
      this.isConfigEvent = !this.isConfigEvent;
      if (this.isConfigEvent) {
        this.toggleExtendDivWithDelay();
        document.getElementById("edit-block").style.height = "75vh";
        document.getElementById("edit-block").style.transition = "height 0.5s";
      } else {
        document.getElementById("edit-block").style.height = "52vh";
        this.isExtendDivVisible = false;
      }
    },

    validateSnapRange(event) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, "");
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = Number(inputValue);
      const min = 0;
      const max = 1000;
      if (inputValue < min) {
        inputValue = min;
      }
      if (inputValue > max) {
        inputValue = max;
      }
      event.target.value = inputValue;
      this.editSnapRange = inputValue;
      this.config_snap_range = inputValue;
    },
    validateDwellTime(event) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, "");
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = Number(inputValue);
      const min = 1;
      const max = 600000;
      if (inputValue < min) {
        inputValue = min;
      }
      if (inputValue > max) {
        inputValue = max;
      }
      event.target.value = inputValue;
      this.config_dwell_time = inputValue;
    },
  },
  components: {
    GrayInputField,
    DateInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
.settings-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  vertical-align: middle;
  color:white
}
.settings-switch-text2 {
  margin: 0 10px;
}
.settings-switch-container {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  width: 50%;
  justify-content: flex-end;
}
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}
.extendDiv {
  transition: 0.4s;
}
.name-date {
  width: 110px;
  font-size: 16px;
  text-align: left;
}
.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 5px;
  height: 52vh;
  transition: height 0.9s ease 0s;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  padding-bottom: 20px;
}

h1 {
  color: white;
  font-size: 32px;
  font-family: "Lexend";
}

h2 {
  color: white;
  font-size: 20px;
  font-family: "Lexend";
}

@media (max-width: 800px) {
  .edit-block {
    width: 90vw;
  }
  h1 {
    font-size: 20px;
  }
}

@media (max-width: 568px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 16px;
  }

  .edit-row {
    font-size: 12px;
    text-align: left;
  }

  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 50px;
    width: 100vw;
  }
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Occupy remaining space */
  }
  h1 {
    font-size: 1rem;
  }
  .breadcrumb-item {
    font-size: 0.8rem;
  }
}
</style>
