<template>
  <div class="races-background">
    <h1 class="rally-title-small">{{ $t("language.title") }}</h1>
    <div class="container-class">
      <div class="signout-btn">
        <GoldCircleButton
          :circleImage="singOutIcon"
          :circleText="signOutText"
          @circleClick="signOut()"
        />
      </div>
      <div style="margin: 0 20px; display: flex;">
        <!-- Módosítás: Már nincs a div szélessége flex: 1 -->
        <h1 class="rally-title">{{ $t("language.title") }}</h1>
      </div>
      <div style="flex: 1">
        <LanguageButton />
      </div>
    </div>

    <div class="card-container" v-if="this.role">
      <div
        v-if="this.role == 'admin'"
        class="custom-card"
        @click="navigateDeviceListPage()"
      >
        <h2>{{ $t("language.devList") }}</h2>
        <i class="fa-solid fa-microchip"></i>
      </div>
      <div
        v-if="this.role == 'staff'"
        class="custom-card card-margin"
        @click="navigateDeviceListPage()"
      >
        <h2>{{ $t("language.devList") }}</h2>
        <i class="fa-solid fa-microchip"></i>
      </div>
      <div
        v-if="this.role == 'admin'"
        class="custom-card card-margin"
        @click="navigateAllUsersPage()"
      >
        <h2>{{ $t("language.allUser") }}</h2>
        <i class="fa-solid fa-solid fa-users"></i>
      </div>
      <div class="custom-card" @click="navigateEventsPage()">
        <h2>{{ $t("language.events") }}</h2>
        <i class="fa-regular fa-clipboard"></i>
      </div>
      <div
        class="custom-card card-margin"
        @click="navigateActiveLegLiveMapPage()"
      >
        <h2>{{ $t("language.activeLeg") }}</h2>
        <i class="fa-solid fa-earth-europe"></i>
      </div>
      <div
        class="custom-card"
        @click="navigateDeviceManagementPage"
        v-if="this.role == 'admin' || this.role == 'staff'"
      >
        <h2>{{ $t("language.deviceHandler") }}</h2>
        <i class="fa-solid fa-wrench"></i>
      </div>
    </div>
    <div class="privacy-btn small-btn">
      <a href="#" class="privacyText" @click="downloadWordFile">{{
        $t("language.privacyDoc")
      }}</a>
    </div>
  </div>
  <div class="privacy-btn big-btn">
    <a href="#" class="privacyText" @click="downloadWordFile">{{
      $t("language.privacyDoc")
    }}</a>
  </div>
  <!--   <button @click="toggleAlert" style="color: red;">
    Modal test
  </button> -->

  <CustomAlert
    :show="alertListener"
    :showCancelButton="false"
    :body="body"
    @confirm="alertListener = false"
  />
  <!-- TODO ÁDÁM NÉZZ RÁ-->
</template>

<script>
// import axios from "axios";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import LanguageButton from "./LanguageButton.vue";
/* import dataProvider from '../utils/dataProvider'; */
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "LandingPage",
  props: {
    msg: String,
  },
  data() {
    return {
      singOutIcon: "fa-solid fa-sign-out fa-rotate-180",
      wordDocumentUrl: "../adatvedelem.docx",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      signOutText: "",

      //------------------------------------------alertstufff-------------------------------------------
      alertListener: false,
      body: "",
    };
  },
  created() {
    this.signOutText = this.$t("language.signOutText");
  },
  async mounted() {
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  methods: {
    //--------------------------------------------------ALERT STUFF----------------------------------------------
    toggleAlert() {
      this.alertListener = !this.alertListener;
    },
    toggleConfirm() {
      console.log("confirm");
      this.alertListener = false;
    },
    toggleCancel() {
      console.log("cancel");
      this.alertListener = false;
    },
    //------------------------------------------------------------------------------------------------------------------
    handleSaveChanges() {
      // Handle the "Save changes" action
      console.log("Save changes clicked");
    },
    navigateDeviceListPage() {
      this.$router.push("/devicelist");
    },
    navigateAllUsersPage() {
      this.$router.push("/users");
    },
    navigateEventsPage() {
      this.$router.push("/events");
    },
    async navigateActiveLegLiveMapPage() {
      localStorage.setItem("racer_id", undefined);
      localStorage.setItem("prevPageFromLive", "Home");

      const activeLeg = await dp.getActiveLeg();
      if (activeLeg) {
        localStorage.setItem("leg_id", activeLeg.leg_id);
        this.$router.push("/liveMapView");
      } else {
        this.alertListener = true;
        this.body = this.$t("language.activeLegError");
      }
      //TODO VALAMIÉRT NEM OKÉZZA LE
    },
    signOut() {
      localStorage.removeItem("user_id");
      this.$router.push("/");
    },
    navigateDeviceManagementPage() {
      this.$router.push("/DeviceManagement");
    },

    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: { GoldCircleButton, LanguageButton, 
    CustomAlert 
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  position: fixed;
  top: 0;
  height: 10vh;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}

.title-div {
  padding-bottom: 17vh;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
.races-background {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}
.privacy-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 20px;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 4vh;
}

.card-margin {
  margin-inline: 2vw;
}
.custom-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48vh;
  width: 16vw;
  background-color: #2c3133;
  color: #dd9c44;
  border-radius: 10px;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}

.custom-card:hover {
  background-color: #dd9c44;
  color: #2c3133;
  width: 21vw;
}
.custom-card-man {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
  width: 17vw;
  background-color: #2c3133;
  color: #dd9c44;
  border-radius: 10px;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}
i {
  font-size: 7em;
}
.rally-title {
  font-family: "Lexend";
  color: white;
  font-size: 50px;
}
.rally-title-small {
  font-family: "Lexend";
  color: white;
  font-size: 20px;
  text-align: center;
  display: none;
}
h2 {
  font-size: 2.3em;
  font-family: "Lexend";
}
.device-man {
  margin-top: -40px;
}
.signout-btn {
  flex: 1;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.container-class {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15vh;
}
.small-btn {
  display: none;
}

@media (max-width: 980px) {
  .rally-title {
    font-size: 40px;
    padding-left: 0;
  }
}
@media (max-width: 1650px) {
  .custom-card {
    font-size: 1.2em;
  }
  i {
    font-size: 6em;
  }
  h2 {
    font-size: 1.2em;
  }
}

@media (max-width: 1200px) {
  .custom-card {
    font-size: 1.2em;
  }
  i {
    font-size: 6em;
  }
  h2 {
    font-size: 1em;
  }

  .card-margin {
    margin-inline: 4vw;
  }
  .custom-card:hover {
    width: 23vw;
  }
  .privacy-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    height: 90vh;
    margin-bottom: 10vh;
  }
  .custom-card {
    width: 50vw;
    height: 20vh;
    font-size: 1.2em;
  }
  i {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.2em;
  }
  .rally-title {
    font-size: 26px;
    padding-left: 0;
    font-family: "Lexend";
    color: #dd9c44;
    display: none;
  }
  .rally-title-small {
    font-family: "Lexend";
    color: white;
    font-size: 30px;
    text-align: center;
    display: block;
  }
  .card-margin {
    margin-block: 4vh;
  }
  .custom-card:hover {
    width: 45vw;
  }
  .big-btn {
    display: none;
  }
  .small-btn {
    display: block;
  }
  .title-div {
    padding-bottom: 10vh;
  }
  .container-class {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
  }

  .signout-btn {
    flex: 1;
  }
  /* .langbtn {
      position: relative;
      top: 0px;
      right: 0px;
    } */
  .privacyText {
    color: grey;
    text-align: center; /* Középre igazítjuk a szöveget a linken belül */
    z-index: 99999999999;
  }
}
@media (max-height: 600px) {
  i {
    font-size: 2em;
  }
  h2 {
    font-size: 1.2em;
  }
  .rally-title {
    font-size: 20px;
    padding-left: 0;
    display: none;
  }
  .rally-title-small {
    font-family: "Lexend";
    color: white;
    font-size: 30px;
    text-align: center;
    display: block;
  }
  .big-btn {
    display: none;
  }
  .small-btn {
    display: block;
  }
  .card-margin {
    margin-block: 4vh;
  }
  .custom-card:hover {
    width: 45vw;
  }
  .container-class {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
  }
  .title-div {
    padding-bottom: 5vh;
  }
  .rally-title {
    font-family: "Lexend";
    color: #dd9c44;
    font-size: 20px;
  }
  .privacyText {
    color: grey;
    text-align: center; /* Középre igazítjuk a szöveget a linken belül */
    z-index: 99999999999;
  }
}
</style>
