<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.eventAddTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
          />
        </div>
        <div class="header-title">
          <span class="title">{{ $t("language.eventAddTitle") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.newEventName") }}
              <GrayInputField
                id="input1"
                :value="this.event_name"
                :setter="setName"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.newEventType") }}
              <GrayInputField
                id="input2"
                :value="this.event_type"
                :setter="setType"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.newEventStart") }}
              <DateInputField
                id="input3"
                :value="this.event_start_date"
                :setter="setStartDate"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.newEventEnd") }}
              <DateInputField
                id="input4"
                :value="this.event_end_date"
                :setter="setEndDate"
              />
            </div>
          </div>
          <div class="action-buttons">
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleCancelClick"
              :actionImage="actionCancel"
              :text="cancelText"
            />
            <GrayActionButton
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    v-show="showAlert"
    :body="body"
    :showCancelButton="false"
    @confirm="showAlert = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import DateInputField from "@/components/DateInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();

export default {
  name: "AddEvent",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      deviceSorszam: "",
      devicePID: "",
      deviceIMEI: "",
      deviceICC: "",
      deviceMessageFrequency: "",
      deviceSound: "",
      backImage: "fa-solid fa-arrow-left",
      event_name: undefined,
      event_type: undefined,
      event_start_date: undefined,
      event_end_date: undefined,
      wordDocumentUrl: "../adatvedelem.docx",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      showAlert: false,
      body: "",
      fillAllInput: "",
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.cancelText = this.$t("language.cancelText");
    this.fillAllInput = this.$t("language.fillAllInput");
  },
  props: [],
  methods: {
    async dataPush() {
      if (
        this.event_name &&
        this.event_type &&
        this.event_start_date &&
        this.event_end_date
      ) {
        await dp.addEvents(
          this.event_name,
          this.event_type,
          this.event_start_date,
          this.event_end_date
        );
        this.handleBackClick();
      } else {
        this.showAlert = true;
        this.body = this.fillAllInput;
      }
    },
    handleDeleteClick() {},
    handleSaveClick() {
      this.dataPush();
    },
    handleCreateClick() {},
    handleBackClick() {
      this.$router.push("events");
    },
    setName(value) {
      this.event_name = value;
    },
    setType(value) {
      this.event_type = value;
    },
    handleCancelClick() {
      this.$router.push("/events");
    },
    setStartDate(value) {
      this.event_start_date = value;
    },
    setEndDate(value) {
      this.event_end_date = value;
    },
    setMessageFrequency(value) {
      this.deviceMessageFrequency = value;
    },
    setSound(value) {
      this.deviceSound = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    DateInputField,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 28px;
  padding-inline: 2.2vw;
}

.edit-row {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}

.title {
  color: white;
  font-size: 50px;
  font-family: "Lexend";
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 10vh;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 568px) {
  .edit-row {
    font-size: 14px;
  }

  .title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .edit-block {
    width: 90vw;
  }
}
</style>
