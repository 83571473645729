<template>
    <div class="custom-action-outer">
        <button @click="handleActionClick" class="circle-button">
            <i style=" color: white;" :class="actionImage" class="button-image"></i>
            <span class="action-text">{{ text }}</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'GrayActionButton',
    props: {
        actionImage: {
            type: String,
        },
        text: {
            type: String,
        },
    },
    methods: {
        handleActionClick() {
            this.$emit('actionClick');
        },
    },
};
</script>

<style scoped>
.circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 35px;
    border: 1px;
    border-radius: 5px;
    font-size: 1rem;
    color: white;
    background-color: #555556;
    font-family: "Lexend";
}

.circle-button:hover {
    background-color: #DD9C44;
    box-shadow: #dd9c44 0px 0px 10px;
}

.button-image {
    margin-right: 10px;
    font-size: 1.5em;
}

.action-text {
    font-family: "Lexend";
    font-size: 14px;
}

.custom-action-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .circle-button {
    }
    .button-image {
        margin-right: 5px;
        font-size: 1em;
    }
    .action-text {
        font-size: 10px;
    }
}
</style>