<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.events") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legs") }}</a>
        </li>

        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/racerlist">{{ $t("language.staffList") }} </a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.editStaff") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-inline: 2vw;
      "
    >
      <div class="header-text">
        <GoldCircleButton
          circleText="Back"
          :circleImage="backImage"
          @circleClick="handleBackClick"
          title="Staff list"
        />
        <div class="header-title">
          <h1>{{$t('language.editStaff')}}</h1>
        </div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              Device Serial Number:
              <input
                list="freeDevices"
                aria-label="Default select example"
                id="device-selector"
                :placeholder="this.device ? this.device.device_serial_num : 0"
                v-model="this.device_serial_num"
                type="number"
                style="border: 0;"
                @change="validateNumericInput($event)"
              />
              <datalist id="freeDevices">
                <option v-for="item in deviceArray" :key="item.device_id">
                  {{ item.device_serial_num }}
                </option>
              </datalist>
            </div>
            <div class="edit-row">
              Staff Name:
              <GrayInputField :value="this.staff_name" :setter="setStaffName" />
            </div>
            <div class="edit-row">
              Staff Email:
              <GrayInputField
                :value="this.staff_email"
                :setter="setStaffEmail"
              />
            </div>
            <div class="edit-row">
              Staff Phone Number:
              <GrayInputField
                :value="this.staff_phonenum"
                :setter="setStaffPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
          </div>
          <div class="action-buttons">
            <div>
              <GrayActionButton
                @actionClick="showAlert = true"
                :actionImage="actionDeleteImage"
                :text="deleteText"
              />
            </div>
            <div style="display: flex;">
              <GrayActionButton
                style="padding-inline: 20px"
                @actionClick="dataPush()"
                :actionImage="actionSaveImage"
                :text="saveText"
              />
              <GrayActionButton
                @actionClick="handleCancelClick"
                :actionImage="actionCancel"
                :text="cancelText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    body="Biztosan törölni akarja ezt az elemet?"
    @confirm="handleDeleteClick"
    @cancel="showAlert = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "@/components/CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "EditStaff",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCancel: "fa-solid fa-xmark",
      event_id: undefined,
      device_id: undefined,
      staff_name: undefined,
      staff_email: undefined,
      staff_phonenum: undefined,
      backImage: "fa-solid fa-arrow-left",
      racer_id: undefined,
      /*       actual_device__id: undefined, */
      editStaffArray: [],
      staffArray: [],
      racerArray: [],
      deviceArray: [],
      isValidEmail: false,
      showAlert: false,
      back:"",
      cancelText: "",

      //-------------------------------------Role Start---------------------------------------
      role: undefined,
    };
  },
  props: [],
  created(){
    this.saveText = this.$t('language.saveText');
    this.back = this.$t('language.back');
    this.cancelText = this.$t('language.cancelText');
    this.deleteText = this.$t('language.deleteText');
  },
  async mounted() {
    this.staff_id = localStorage.getItem("staff_id");
    this.event_id = localStorage.getItem("event_id");
    this.editStaffArray = await dp.getStaffById(this.staff_id);
    this.device = await dp.getDeviceById(this.editStaffArray.device_id);
    this.device_id = this.device.device_id;
    this.deviceArray = await dp.getDevice();
    this.event_id = this.editStaffArray.event_id;
    this.staff_name = this.editStaffArray.staff_name;
    this.staff_email = this.editStaffArray.staff_email;
    this.staff_phonenum = this.editStaffArray.staff_phonenum;
    //filter free device
    this.staffArray = await dp.getStaff();
    this.racerArray = await dp.getRacers();

    let comboarray = [];
    for (const i of this.staffArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }
    for (const i of this.racerArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }

    for (const device of comboarray) {
      let foundIndex = this.deviceArray.findIndex(
        (obj) => obj.device_id == device.device_id
      );
      if (foundIndex != -1) {
        this.deviceArray.splice(foundIndex, 1);
      }
    }
    this.deviceArray.push(this.device);
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.staff_email);
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36 20 323 4211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    async dataPush() {
      this.validateEmail();
      if (!this.isValidEmail) {
        console.log(this.isValidEmail, this.staff_email);
        this.alertBody = "Please provide a valid email address";
        this.showAlertModal = true;
        return;
      }
      let DeviceIdFinder = this.deviceArray.find(item => item.device_serial_num == this.device_serial_num);
      if (DeviceIdFinder) {
        this.device_id = DeviceIdFinder.device_id;
      }

      await dp.updateStaffById(
        this.device_id,
        this.staff_name,
        this.staff_email,
        this.staff_phonenum,
        this.staff_id
      );
      this.$router.push("/racerlist");
    },
    async handleDeleteClick() {
      await dp.deleteStaffById(this.staff_id);
      this.$router.push("/racerlist");
    },
    handleCancelClick() {
      this.$router.push("/racerlist");
    },
    handleBackClick() {
      localStorage.setItem("tableType", 2);
      this.$router.push("/racerlist");
    },
    setStaffName(value) {
      this.staff_name = value;
    },
    setStaffEmail(value) {
      this.staff_email = value;
    },
    setStaffPhoneNumber(value) {
      this.staff_phonenum = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
  display: none !important;
}
header {
  position: fixed;
  top: 0;
  left: 20px;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

footer {
  position: absolute;
  bottom: 0;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

#editLeg-row {
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  top: 15px;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 20px;
}

#device-selector {
  background-color: #555556;
  font-family: "Lexend";
  color: white;
  border-radius: 10px;
  padding: 5px;
  width: 20vw;
  margin-left: 20px;
}

#device-selector::placeholder {
  color: white;
}

#device-selector:focus {
  border: 2px solid #dd9c44;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-right: 60px;
  flex-grow: 1;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 1400px) {
  .edit-row {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .edit-block {
    width: 90%;
  }
}

@media (max-height: 640px) {
}

@media (max-width: 568px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 16px;
  }

  .edit-row {
    font-size: 14px;
  }
}
</style>
