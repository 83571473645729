<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'; " aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color:#dd9c44 ;"><a href="/home">Home</a></li>
        <li class="breadcrumb-item " aria-current="page" style="color:#dd9c44 ;"><a href="/events">Events</a></li>
        <li class="breadcrumb-item " aria-current="page" style="color:#dd9c44 ;"><a href="/legs">Legs</a></li>
        <li class="breadcrumb-item active" aria-current="page" style="color:#dd9c44 ;">Staff list</li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row">
    <div
      style="display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 0; padding-inline: 2vw;">

      <GoldCircleButton circleText="Back" title="Legs" :circleImage="backImage" @circleClick="navigateBack()" />
      <div class="page-title">


        <div class="flag-rally">
          <img class="flag-image" :src="flagSource" alt="Flag" />
          <div class="rally-name">{{ this.event_name }}</div>
        </div>

      </div>
      <GoldCircleButton circleText="Add staff"  :circleImage="addImage" @circleClick="navigateAddStaff()" />
    </div>
    <div class="racer-list">
      <div class="left-content">

        <i class="fa-solid fa-user-astronaut" style="color: #dd9c44; font-size: 23px"></i>
        <span style="
            font-family: Lexend;
            color: white;
            font-size: large;
            margin-left: 5px;
          ">Staff List</span>
      </div>

      <div class="right-content">
        <input class="search-bar" v-model="searchQuery" placeholder="Search..." />
        <i class="search-icon" :class="searchImage"></i>
      </div>
    </div>
    <div class="center-alignment">
      <div class="outer-table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="width: 5%; border-right: 1px solid black;" colspan="5">Staff Id</th>
              <th style="width: 20%; border-right: 1px solid black;" colspan="5">Staff Name</th>
              <th style="width: 20%; border-right: 1px solid black;" colspan="5">Staff Phone Number</th>
              <th style="width: 20%; border-right: 1px solid black;" colspan="5">Staff e-mail</th>
              <th style="width: 15%; border-right: 1px solid black;" colspan="5">Device Id</th>
              <!-- <th style="width: 10%; border-right: 1px solid black;" colspan="5">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in this.filteredItems" :key="item.staff_id" >
              <td @click="handleClickOnStaff(item.staff_id)" colspan="5">{{ item.staff_id }}</td>
              <td @click="handleClickOnStaff(item.staff_id)" colspan="5">{{ item.staff_name }}</td>
              <td @click="handleClickOnStaff(item.staff_id)" colspan="5">{{ item.staff_phonenum }}</td>
              <td @click="handleClickOnStaff(item.staff_id)" colspan="5">{{ item.staff_email }}</td>
              <td @click="handleClickOnStaff(item.staff_id)" colspan="5">{{ item.device_id }}</td>
<!--               <td colspan="5">
                <Button class="btn btn-warning" id="editBtn"
                  @click="handleEditStaff(item.staff_id)">
                  Edit
                </Button></td> -->

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import dataProvider from '../utils/dataProvider';
import GoldCircleButton from "./GoldCircleButton.vue";
const dp = dataProvider();

export default {
  name: "RacerList",
  props: {
    flagSource: {
      type: String,
      default: "hungarianFlag.png",
    },
    rallyName: {
      type: String,
      default: "Nyíregyháza Rally",
    },
  },
  data() {
    return {
      searchValue: '',
      searchQuery: '',
      searchImage: "fa-solid fa-magnifying-glass",
      event_id: '',
      event_name: '',
      staffArray: [],
      backImage: 'fa-solid fa-arrow-left',
      addImage: "fa-solid fa-plus",
    };
  },
  async mounted() {
    this.event_id = localStorage.getItem('event_id'),
      this.event_name = localStorage.getItem('event_name'),
      this.staffArray = await dp.getStaff();
    this.staffArray = this.staffArray.filter((item) => {
      if (item.event_id == this.event_id) {
        return item;
      }
    });
  },
  computed: {
    filteredItems: function () {
      return this.staffArray.filter((item) => {
        if (item.staff_name) {
          let staff_name = item.staff_name;
          return staff_name.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
  components: { GoldCircleButton },
  methods: {
    navigateBack() {
      this.$router.push("/legs");
    },
    navigateAddStaff() {
      this.$router.push('/addstaff');
    },
    handleClickOnStaff(id) {
      localStorage.setItem('staff_id', id);
      this.$router.push("/staffinfo");
    },
    handleEditStaff(id) {
      localStorage.setItem('staff_id', id);
      this.$router.push("/editstaff");
    },
  },
};
</script>

<style scoped>
header{
position: fixed;
top: 0;

left: 20px;
}
ol.breadcrumb li+li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editBtn {
  background-color: #dd9c44;
}

#editBtn:hover {
  background-color: #ec900f;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
}

.racer-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 7vh;
  margin-bottom: 3vh;
  width: 100vw;
}

.left-content {
  display: flex;
  align-items: center;
  margin-left: 5.5vw;
}

.right-content {
  display: flex;
  align-items: center;
  margin-right: 5.5vw;
}

.rally-name {
  color: white;
  font-family: Lexend;
  font-size: 22px;
  margin-left: 10px;
}

h4 {
  color: white;
  font-family: Lexend;
  font-size: 1.5vw;
  margin-left: 10px;
}

.flag-image {
  width: 40px;
  height: 24px;
  border-radius: 10%;
  margin-right: 10px;
}

.flag-rally {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2vw;
}

.vissza-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2vw;
  height: 27px;
}

.page-title {
  width: 75%;
  background-color: #2c3033;
  height: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  /* Center the title within its parent container */
}

.custom-button {
  background-color: #dd9c44;
  border-radius: 40px;
  width: 80px;
  border: 0;
  color: white;
  font-family: Lexend;
  font-size: 15px;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.outer-table {
  width: 90%;
  height: 70vh;
  overflow-y: auto;
  background-color: transparent;
  border-radius: 10px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 2px solid white;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";

  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid white;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  background-color: #2c3033;
  padding-left: 2%;
  padding-right: 2%;
  cursor: pointer;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}
.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}

.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}

@media (max-width: 768px) {
  td {
    font-size: 10px;
  }

  th {
    font-size: 10px;
  }

  .search-bar {
    width: 150px;
  }

  .rally-name {
    font-size: 16px;
  }

  .search-bar {
    border-radius: 10px;
    width: 120px;
    height: 30px;
    font-size: 14px;

  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.1em;
  }

  .right-content {
    display: flex;
    align-items: center;
    margin-right: 8.5vw;
  }

}</style>
