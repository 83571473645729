<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.events") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legs") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/racerlist">{{ $t("language.racerList") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.addRacer") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
            title="Racer list"
          />
        </div>

        <div class="header-title">
          <span>{{ $t("language.addNewRacer") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.devSN") }}
              <input
                list="freeDevices"
                aria-label="Default select example"
                id="device-selector"
                v-model="this.device_serial_num"
              />
              <datalist id="freeDevices">
                <option
                  v-for="item in deviceArray"
                  :value="item.device_serial_num"
                  :key="item.device_id"
                >
                  {{ item.device_serial_num }}
                </option>
              </datalist>
            </div>
            <div class="edit-row">
              {{ $t("language.startNum") }}
              <GrayInputField
                type="number"
                :customPlaceholder="startNumber"
                :setter="setStartNumber"
                @input="validateNumericInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotName") }}
              <GrayInputField
                :customPlaceholder="userName"
                :setter="setPilotName"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotNationality") }}
              <GrayInputField
                :customPlaceholder="userNationality"
                :setter="setPilotNationality"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotPhone") }}
              <GrayInputField
                :customPlaceholder="userPhoneNumber"
                :setter="setPilotPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotEmail") }}
              <GrayInputField
                type="email"
                :customPlaceholder="userEmail"
                :setter="setPilotEmail"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPilotName") }}
              <GrayInputField
                :customPlaceholder="userName"
                :setter="setCoPilotName"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPilotNationality") }}
              <GrayInputField
                :customPlaceholder="userNationality"
                :setter="setCoPilotNationality"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPiloPhone") }}
              <GrayInputField
                :customPlaceholder="userPhoneNumber"
                :setter="setCoPilotPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPilotEmail") }}
              <GrayInputField
                type="email"
                :customPlaceholder="userEmail"
                :setter="setCoPilotEmail"
              />
            </div>
          </div>
          <div class="action-buttons">
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleCancelClick"
              :actionImage="actionCancel"
              :text="cancelText"
            />
            <GrayActionButton
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="alertBody"
    :confirmText="confirmText"
    :showCancelButton="showCancelButton"
    @confirm="handleConfirm"
    @cancel="handleCancel"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "AddRacer",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      back: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      startNumber: undefined,
      userNationality: undefined,
      userEmail: undefined,
      userName: undefined,
      userPhoneNumber: undefined,
      device_id: undefined,
      racer_pilot_name: undefined,
      racer_pilot_nationality: undefined,
      racer_pilot_phonenum: undefined,
      racer_pilot_email: undefined,
      racer_copilot_name: undefined,
      racer_copilot_nationality: undefined,
      racer_copilot_phonenum: undefined,
      racer_copilot_email: undefined,
      racer_start_num: undefined,
      cancelText: "",
      actionCancel: "fa-solid fa-xmark",
      backImage: "fa-solid fa-arrow-left",
      deviceArray: [],
      staffArray: [],
      racerArray: [],
      event_id: undefined,
      devices: [],
      wordDocumentUrl: "../adatvedelem.docx",
      isValidEmail: false,
      isValidEmail2: false,
      showAlert: false,
      alertBody: "",
      confirmText: "OK",
      showCancelButton: false,
      invalidSer: "",
      emailFormat: "",
    };
  },
  created() {
    this.saveText = this.$t("language.saveText");
    this.back = this.$t("language.back");
    this.cancelText = this.$t("language.cancelText");
    this.emailFormat = this.$t("language.emailFormat");
    this.deviceValidSerial = this.$t("language.deviceValidSerial");
    this.invalidSer = this.$t("language.invalidSer");
  },
  props: [],
  computed: {
    filteredDevices: function () {
      return this.deviceArray.filter((item) => {
        if (item.device_serial_num) {
          let device_serial_num = item.device_serial_num;
          return device_serial_num
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        }
      });
    },
  },
  async mounted() {
    this.deviceArray = await dp.getDevice();
    this.event_id = localStorage.getItem("event_id");
    this.staffArray = await dp.getStaff();
    this.racerArray = await dp.getRacers();

    let comboarray = [];
    for (const i of this.staffArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }
    for (const i of this.racerArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }

    for (const device of comboarray) {
      let foundIndex = this.deviceArray.findIndex(
        (obj) => obj.device_id == device.device_id
      );
      if (foundIndex != -1) {
        this.deviceArray.splice(foundIndex, 1);
      }
    }
  },
  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36 20 323 4211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.racer_pilot_email);
    },
    validateEmailCo() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail2 = emailRegex.test(this.racer_copilot_email);
    },
    handleCancelClick() {
      this.$router.push("/racerlist");
    },
    handleConfirm() {
      this.showAlert = false;
    },
    async handleSaveClick() {
      if (this.racer_pilot_email) {
        this.validateEmail();
        console.log(this.isValidEmail); //do not delete this log!!!
        if (!this.isValidEmail) {
          this.showAlert = true;
          this.alertBody = this.emailFormat;
          return;
        }
      }
      if (this.racer_copilot_email) {
        this.validateEmailCo();
        console.log(this.isValidEmail2); //do not delete this log!!!
        if (!this.isValidEmail2) {
          this.showAlert = true;
          this.alertBody = this.emailFormat;
          return;
        }
      }
      let DeviceIdFinder = this.deviceArray.find(
        (DeviceIdFinder) =>
          DeviceIdFinder.device_serial_num === this.device_serial_num
      );
      if (DeviceIdFinder) {
        this.device_id = DeviceIdFinder.device_id;
        if (!this.racer_start_num) {
          this.showAlert = true;
          this.alertBody = this.deviceValidSerial;
          return;
        }
        try {
          await dp.addRacers(
            this.event_id,
            this.device_id,
            this.racer_pilot_name,
            this.racer_pilot_nationality,
            this.racer_pilot_phonenum,
            this.racer_pilot_email,
            this.racer_copilot_name,
            this.racer_copilot_nationality,
            this.racer_copilot_phonenum,
            this.racer_copilot_email,
            this.racer_start_num,
            this.racer_status,
            this.racer_current_stage
          );
          this.$router.push("/racerlist");
        } catch (error) {
          this.showAlert = true;
          this.alertBody = error;
        }
      } else {
        this.showAlert = true;
        this.alertBody = this.invalidSer;
      }
    },
    handleBackClick() {
      this.$router.push("/racerlist");
    },
    setPilotName(value) {
      // Split the full name into first name and last name
      let names = value.split(" ");

      // Capitalize the first letter of the first name
      let capitalizedFirstName =
        names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();

      // Capitalize the first letter of the last name (if available)
      let capitalizedLastName = "";
      if (names.length > 1) {
        capitalizedLastName =
          names[names.length - 1].charAt(0).toUpperCase() +
          names[names.length - 1].slice(1).toLowerCase();
      }

      // Construct the capitalized full name
      let capitalizedFullName = capitalizedFirstName;
      if (capitalizedLastName !== "") {
        capitalizedFullName += " " + capitalizedLastName;
      }

      // Set the capitalized full name to the racer_pilot_name property
      this.racer_pilot_name = capitalizedFullName;
    },
    setPilotNationality(value) {
      this.racer_pilot_nationality = value;
    },
    setPilotPhoneNumber(value) {
      this.racer_pilot_phonenum = value;
    },
    setPilotEmail(value) {
      this.racer_pilot_email = value;
    },
    setCoPilotName(value) {
      // Split the full name into first name and last name
      let names = value.split(" ");

      // Capitalize the first letter of the first name
      let capitalizedFirstName =
        names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();

      // Capitalize the first letter of the last name (if available)
      let capitalizedLastName = "";
      if (names.length > 1) {
        capitalizedLastName =
          names[names.length - 1].charAt(0).toUpperCase() +
          names[names.length - 1].slice(1).toLowerCase();
      }

      // Construct the capitalized full name
      let capitalizedFullName = capitalizedFirstName;
      if (capitalizedLastName !== "") {
        capitalizedFullName += " " + capitalizedLastName;
      }

      // Set the capitalized full name to the racer_copilot_name property
      this.racer_copilot_name = capitalizedFullName;
    },
    setCoPilotNationality(value) {
      this.racer_copilot_nationality = value;
    },
    setCoPilotPhoneNumber(value) {
      this.racer_copilot_phonenum = value;
    },
    setCoPilotEmail(value) {
      this.racer_copilot_email = value;
    },
    setStartNumber(value) {
      this.racer_start_num = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#device-selector {
  background-color: #555556;
  font-family: "Lexend";
  color: white;
  border-radius: 10px;
  padding: 5px;
  width: 20vw;
  margin-left: 20px;
  border-style: none;
}

#device-selector:focus {
  border: 2px solid #dd9c44;
}

#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-family: "Lexend";
  color: white;
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}

.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  text-align: left;
}

@media (max-width: 1400px) {
  .edit-row {
    font-size: 14px;
  }
}

@media (max-height: 800px) {
  .edit-block[data-v-ab979860]  {
    width: 60vw;
  }
  .header-title {
    font-size: 20px;
  }
}

@media (max-height: 640px) {
    .edit-block[data-v-ab979860] {
    height: 400px;
    overflow-y: auto;
    width: 80vw;
  }
}
@media (max-width: 640px) {
    .edit-block[data-v-ab979860] {
    overflow-y: auto;
    width: 80vw;
  }
}
</style>
