<template>
  <header style="z-index: 10000">
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.navHome")}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.navEvents")}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.navLegs")}}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/racerlist">{{ $t("language.racerList")}}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="/racerinfo">{{ $t("language.navRacerInfo")}}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
         {{ $t("language.editRacerTitle")}}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div
      style="
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        padding-inline: 3vw;
        top:31px;
      "
    >
      <div class="header-text">
        <GoldCircleButton
          :circleText="backText"
          :circleImage="backImage"
          @circleClick="handleBackClick"
          title="Racer info"
        />
        <div class="header-title">
          <h1>{{ $t("language.editRacerTitle") }}</h1>
        </div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.raceriDeviceSN") }}
              <input
                list="freeDevices"
                aria-label="Default select example"
                id="device-selector"
                :placeholder="this.device ? this.device.device_serial_num : 0"
                v-model="this.device_serial_num"
              />
              <datalist id="freeDevices">
                <option v-for="item in deviceArray" :key="item.id">
                  {{ item.device_serial_num }}
                </option>
              </datalist>
            </div>
            <div class="edit-row">
              {{ $t("language.pilotName") }}
              <GrayInputField
                :value="this.racer_pilot_name"
                :setter="setPilotName"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotNationality") }}
              <GrayInputField
                :value="this.racer_pilot_nationality"
                :setter="setPilotNationality"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotPhone") }}
              <GrayInputField
                :value="this.racer_pilot_phonenum"
                :setter="setPilotPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.pilotEmail") }}
              <GrayInputField
                :value="this.racer_pilot_email"
                :setter="setPilotEmail"
              />
            </div>
            <!--                     </div>
                    <div style="border: 1px solid white; border-radius: 10px; margin: 10px; padding: 10px; width: 25vw;"> -->

            <div class="edit-row">
              {{ $t("language.coPilotName") }}
              <GrayInputField
                :value="this.racer_copilot_name"
                :setter="setCoPilotName"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPilotNationality") }}
              <GrayInputField
                :value="this.racer_copilot_nationality"
                :setter="setCoPilotNationality"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPiloPhone") }}
              <GrayInputField
                :value="this.racer_copilot_phonenum"
                :setter="setCoPilotPhoneNumber"
                @input="validatePhoneInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.coPilotEmail") }}
              <GrayInputField
                :value="this.racer_copilot_email"
                :setter="setCoPilotEmail"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.startNum") }}
              <GrayInputField
                type="number"
                :value="this.racer_start_num"
                :setter="setStartNumber"
                @input="validateNumericInput($event)"
              />
            </div>
            <!--                         <div class="edit-row">
                            Status:
                            <GrayInputField :customPlaceholder="userPhoneNumber" :value="deviceSound" :setter="setSound"
                                />
                        </div>
                        <div class="edit-row">
                            Current Stage:
                            <GrayInputField :customPlaceholder="userPhoneNumber" :value="deviceSound" :setter="setSound"
                                />
                        </div> -->
          </div>
          <div class="action-buttons">
            <div>
              <GrayActionButton
                @actionClick="showAlert1 = true"
                :actionImage="actionDeleteImage"
                :text="deleteText"
              />
            </div>
            <div style="display: flex">
              <GrayActionButton
                style="padding-inline: 20px"
                @actionClick="dataPush()"
                :actionImage="actionSaveImage"
                :text="saveText"
              />
              <GrayActionButton
                @actionClick="handleCancelClick"
                :actionImage="actionCancel"
                :text="cancelText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="body"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
  <CustomAlert
    :show="showAlert1"
    :body="$t('language.deleteRacer')"
    @confirm="handleDeleteClick"
    @cancel="showAlert1 = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "EditRacer",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "Save",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "Cancel",
      rallyName: undefined,
      rallyLocation: undefined,
      userPhoneNumber: undefined,
      device_id: undefined,
      racer_pilot_name: undefined,
      racer_pilot_nationality: undefined,
      racer_pilot_phonenum: undefined,
      racer_pilot_email: undefined,
      racer_copilot_name: undefined,
      racer_copilot_nationality: undefined,
      racer_copilot_phonenum: undefined,
      racer_copilot_email: undefined,
      racer_start_num: undefined,
      device_serial_num: undefined,
      backImage: "fa-solid fa-arrow-left",
      backText: "Back",
      showAlert: false,
      body: "",
      showAlert1: false,
      emailFormat: "",
      /*       actual_device__id: undefined, */
      editRacer: [],
      staffArray: [],
      racerArray: [],
      deviceArray: [],
      isValidEmail: false,
      isValidEmail2: false,
    };
  },
  props: [],
  created () {
    this.deleteText = this.$t("language.deleteText");
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.backText = this.$t("language.back");
    this.emailFormat = this.$t("language.emailFormat");
  },
  async mounted() {
    this.racer_id = localStorage.getItem("racer_id");
    this.event_id = localStorage.getItem("event_id");
    this.editRacer = await dp.getRacersById(this.racer_id);
    this.device = await dp.getDeviceById(this.editRacer.device_id);
    this.device_id = this.device.device_id;
    this.deviceArray = await dp.getDevice();
    this.racer_pilot_name = this.editRacer.racer_pilot_name;
    this.racer_pilot_nationality = this.editRacer.racer_pilot_nationality;
    this.racer_pilot_phonenum = this.editRacer.racer_pilot_phonenum;
    this.racer_pilot_email = this.editRacer.racer_pilot_email;
    this.racer_copilot_name = this.editRacer.racer_copilot_name;
    this.racer_copilot_nationality = this.editRacer.racer_copilot_nationality;
    this.racer_copilot_phonenum = this.editRacer.racer_copilot_phonenum;
    this.racer_copilot_email = this.editRacer.racer_copilot_email;
    this.racer_start_num = this.editRacer.racer_start_num;

    this.staffArray = await dp.getStaff();
    this.racerArray = await dp.getRacers();

    let comboarray = [];
    for (const i of this.staffArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }
    for (const i of this.racerArray) {
      if (i.event_id == this.event_id) {
        comboarray.push(i);
      }
    }

    for (const device of comboarray) {
      let foundIndex = this.deviceArray.findIndex(
        (obj) => obj.device_id == device.device_id
      );
      if (foundIndex != -1) {
        this.deviceArray.splice(foundIndex, 1);
      }
    }
    this.deviceArray.push(this.device);
  },

  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    validatePhoneInput(event) {
      let inputValue = event.target.value;
      // Remove any non-numeric characters except the plus sign at the beginning
      inputValue = inputValue.replace(/[^\d+]|(?<=\+).*?(?=\+)/g, "");

      // Ensure only one plus sign is present at the beginning
      if (inputValue.indexOf("+") > 0) {
        inputValue = inputValue.replace("+", "");
      }
      // Format the phone number as 36 20 323 4211
      let formattedValue = "";
      for (let i = 0; i < inputValue.length; i++) {
        if (i === 12 || i === 15) {
          formattedValue += " " + inputValue[i];
        } else {
          formattedValue += inputValue[i];
        }
      }
      // Update the input field value with the formatted value
      event.target.value = formattedValue;
    },
    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.racer_pilot_email);
    },
    validateEmailCo() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail2 = emailRegex.test(this.racer_copilot_email);
    },
    async dataPush() {
      if (this.racer_pilot_email) {
        this.validateEmail();
        console.log(this.isValidEmail); //do not delete this log!!!
        if (!this.isValidEmail) {
          this.body = this.emailFormat;
          this.showAlert = true;

          return;
        }
      }
      if (this.racer_copilot_email) {
        this.validateEmailCo();
        console.log(this.isValidEmail2); //do not delete this log!!!
        if (!this.isValidEmail2) {
          this.body = this.emailFormat;
          this.showAlert = true;
          return;
        }
      }

      let DeviceIdFinder = this.deviceArray.find(
        (item) => item.device_serial_num === this.device_serial_num
      );
      if (DeviceIdFinder) {
        this.device_id = DeviceIdFinder.device_id;
      }

      await dp.updateRacersById(
        this.racer_id,
        this.device_id,
        this.racer_pilot_name,
        this.racer_pilot_nationality,
        this.racer_pilot_phonenum,
        this.racer_pilot_email,
        this.racer_copilot_name,
        this.racer_copilot_nationality,
        this.racer_copilot_phonenum,
        this.racer_copilot_email,
        this.racer_start_num
      );
      this.$router.push("/racerinfo");
    },
    async handleDeleteClick() {
      await dp.deleteRacersById(this.racer_id);
      this.$router.push("/racerlist");
    },
    handleCancelClick() {
      this.$router.push("/racerinfo");
    },
    handleBackClick() {
      this.$router.push("/racerinfo");
    },
    setPilotName(value) {
      // Split the full name into first name and last name
      let names = value.split(" ");

      // Capitalize the first letter of the first name
      let capitalizedFirstName =
        names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();

      // Capitalize the first letter of the last name (if available)
      let capitalizedLastName = "";
      if (names.length > 1) {
        capitalizedLastName =
          names[names.length - 1].charAt(0).toUpperCase() +
          names[names.length - 1].slice(1).toLowerCase();
      }

      // Construct the capitalized full name
      let capitalizedFullName = capitalizedFirstName;
      if (capitalizedLastName !== "") {
        capitalizedFullName += " " + capitalizedLastName;
      }

      // Set the capitalized full name to the racer_pilot_name property
      this.racer_pilot_name = capitalizedFullName;
    },
    setPilotNationality(value) {
      this.racer_pilot_nationality = value;
    },
    setPilotPhoneNumber(value) {
      this.racer_pilot_phonenum = value;
    },
    setPilotEmail(value) {
      this.racer_pilot_email = value;
    },
    setCoPilotName(value) {
      // Split the full name into first name and last name
      let names = value.split(" ");

      // Capitalize the first letter of the first name
      let capitalizedFirstName =
        names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();

      // Capitalize the first letter of the last name (if available)
      let capitalizedLastName = "";
      if (names.length > 1) {
        capitalizedLastName =
          names[names.length - 1].charAt(0).toUpperCase() +
          names[names.length - 1].slice(1).toLowerCase();
      }

      // Construct the capitalized full name
      let capitalizedFullName = capitalizedFirstName;
      if (capitalizedLastName !== "") {
        capitalizedFullName += " " + capitalizedLastName;
      }

      // Set the capitalized full name to the racer_copilot_name property
      this.racer_copilot_name = capitalizedFullName;
    },
    setCoPilotNationality(value) {
      this.racer_copilot_nationality = value;
    },
    setCoPilotPhoneNumber(value) {
      this.racer_copilot_phonenum = value;
    },
    setCoPilotEmail(value) {
      this.racer_copilot_email = value;
    },
    setStartNumber(value) {
      this.racer_start_num = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer{
  position: absolute;
  bottom: 0;
}
#editLeg-row {
  width: 100vw;
  height: 100vh;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  padding-top: 21px;

  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}


#device-selector {
  background-color: #555556;
  font-family: "Lexend";
  color: white;
  border-radius: 10px;
  padding: 5px;
  width: 20vw;
  margin-left: 20px;
  border-style: none;
}
#device-selector::placeholder {
  color: white;
}

#device-selector:focus {
  border: 2px solid #dd9c44;
}

.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-right: 60px;
  flex-grow: 1;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 1400px) {
  .edit-row {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .edit-block {
    width: 90%;
  }
}

@media (max-height: 640px) {
}

@media (max-width: 568px) {
  h1 {
    font-size: 26px;
  }
  .breadcrumb-item {
    font-size: 10px;
  }

  h2 {
    font-size: 16px;
  }

  .edit-row {
    font-size: 14px;
  }
}
</style>
