<template>
  <router-view />
</template>

<style>
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;

  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

body {
  background-color: #202528;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  overflow-x: hidden;
}

@media (max-width: 760px) {
  .breadcrumb {
    font-size: 12px;
  }
}

</style>
