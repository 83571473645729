<template>
  <div class="all-container">
    <LanguageButton />
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">


      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('language.resetPass') }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
              <span style="margin-right: 10px;">{{ $t('language.resetEmailLabel') }}</span>
              <input v-model="this.email" type="email" placeholder="e-mail" class="form-control"
                id="email-input-pwreset" />
              <!-- TODO Valaki nézzen rá a CSS-re , ne legyen ugyan az az ID mint a másik email inputnak!!! -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('language.resetClose')
              }}</button>
            <button type="button" id="resetBtn" class="btn btn-primary" @click="MessError()">{{
              $t('language.resetReset') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of the Modal -->
    <div class="row" id="login-row">
      <div class="col-7" id="login-container">
        <div class="login-content" id="login-content">
          <h1 class="main-title">{{ $t('language.mainTitle') }}</h1>
          <h4 class="small-title">
            <span class="yellow-text">{{ $t('language.smallTitle') }}</span> {{ $t('language.smallTitleEnd') }}
          </h4>
          <br />
          <br />
          <br />
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">{{ $t('language.emailLabel') }}</label>
            <input v-model="this.email" type="email" class="form-control" id="email-input" @keyup.enter="login" />
          </div>
          <div class="mb-3">

            <label for="exampleFormControlInput1" class="form-label">{{ $t('language.passwordLabel') }}</label>
            <input v-model="this.password" type="password" class="form-control" id="password-input"
              @keyup.enter="login" />
          </div>
          <!--         <a type="button" id="forgot-text" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
          {{ $t('language.forgotPass') }}</a> -->
          <br />
          <br />
          <br />
        </div>
        <button class="btn btn-success" id="login-btn" @click="login()">
          {{ $t('language.login') }}
        </button>
      </div>
      <div class="col-5" id="login-picture">
        <div class="ddc-logo" id="logo"></div>
      </div>
    </div>
    <CustomAlert :show="showAlert" :body="alertBody" :showCancelButton="false" @confirm="showAlert = false" />

    <footer>
      <div>
        <a href="#" @click="downloadWordFile" style="color: grey; text-align: center; margin-left: 20px;">Adat kezelési
          védelem</a>
      </div>
    </footer>
  </div>
</template>

<script>

import dataProvider from '../utils/dataProvider';
import LanguageButton from "./LanguageButton.vue"
import CustomAlert from './CustomAlert.vue';
const dp = dataProvider();

export default {
  name: "LoginPage",
  props: {
    msg: String,
  },
  components: {
    LanguageButton,
    CustomAlert
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      wordDocumentUrl: '../adatvedelem.docx',
      showAlert: false,
      alertBody: "",
    }
  },


  mounted() {

    document.getElementById("login-content").style.opacity = "1";
    document.getElementById("login-btn").style.opacity = "1";
    document.getElementById("logo").style.opacity = "1";
  },
  methods: {

    async login() {
      const loginStatus = await dp.login(this.email, this.password);
      console.log(loginStatus);
      if (loginStatus.jwt) {
        localStorage.setItem("username", this.email);
        localStorage.setItem("JWT", loginStatus.jwt);
        //localStorage.setItem("userEmail", this.useremail);
        this.$router.push("/home");
      } else {
        if (!this.email) {
          document.getElementById("email-input").style.borderColor = "red";
          document.getElementById('email-input').setAttribute('onclick', 'document.getElementById("email-input").style.borderColor = "#dd9c44"');
        }
        if (!this.password) {

          document.getElementById("password-input").style.borderColor = "red";
          document.getElementById('password-input').setAttribute('onclick', 'document.getElementById("password-input").style.borderColor = "#dd9c44"');
        }
        if (loginStatus) {
          this.alertBody = this.$t("language.wrongEverythingIsWrong");
          this.showAlert = true;
        } else if (loginStatus.message === "Wrong Username or password.") {
          this.alertBody = this.$t("language.wrongEverythingIsWrong");
          this.showAlert = true;
        }
      }
    },
    MessError() {
      console.log(" Kell backend!");
    },
    downloadWordFile() {
      const link = document.createElement('a');

      link.href = this.wordDocumentUrl;
      link.download = 'adatedelem.docx';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#login-row {
  width: 100vw;
  height: 100vh;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
}

.all-container {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  top: 0;
  position: absolute;
  left: 0;
}

footer {
  position: absolute;
  bottom: 0;
}

.modal-content {
  background-color: #202528;
  color: #fff;
}

#login-container {
  background-color: #202528;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: width 1s ease;
}

.form-label {
  color: rgba(255, 255, 255, 0.5);
}

.login-content {
  text-align: left;
  transition: opacity 0.5s ease;
  opacity: 100;
}

#email-input {
  border: 3px solid #dd9c44;
  border-radius: 100px;
}

.ddc-logo {
  background-image: url("../../public/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 220px;
  height: 150px;
  float: right;
  margin: 0;
  position: absolute;
  bottom: 0;
  transition: opacity 0.5s ease;
  right: 0;
  opacity: 100;

}

@media (max-width: 768px) {
  .ddc-logo {
    width: 110px;
    height: 75px;
  }

  .col-5 {
    display: none;
  }

  .col-7 {
    width: 100%;
  }
}

#password-input {
  border: 3px solid #dd9c44;
  border-radius: 100px;
}

#forgot-text {
  color: #dd9c44;
  text-decoration: underline;
  float: right;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  font-family: "Lexend";
}

#resetBtn {
  background-color: #dd9c44;
}

#login-btn {
  background-color: #dd9c44;
  border: 0;
  font-family: "Lexend";
  transition: opacity 0.5s ease;
  opacity: 100;
  width: 130px;
}

#login-btn:hover {
  transition: 0.3s;
  transform: scale(1.1);
  box-shadow: 0 0 10px #dd9c44;
}

.main-title {
  color: white;
  font-family: "Lexend", sans-serif;
}

.small-title {
  color: white;
  font-family: "Lexend", sans-serif;
}

.yellow-text {
  color: #dd9c44;
}

@font-face {
  font-family: "Lexend";
  src: url("~@/Fonts/Lexend/static/Lexend-Regular.ttf") format("truetype");
  /* Adjust the path and format as needed */
  font-weight: normal;
  font-style: normal;
}

#login-picture {
  background-image: url("../../public/car-inside.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: width 1s ease;
  padding: 0;
  min-height: 100vh;
}

label {
  font-family: "Lexend", sans-serif;
}

p {
  font-family: "Lexend", sans-serif;
}
</style>
