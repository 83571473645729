<template>
  <button @click="handleCircleClick" class="circle">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="image-div" style="">
        <i style="color: #2c3033" :class="circleImage"></i>
      </div>
      <span class="circle-text">{{ circleText }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "GoldCircleButton",
  props: ["circleImage", "circleText"],
  methods: {
    handleCircleClick() {
      this.$emit("circleClick");
    },
  },
};
</script>

<style scoped>
.circle {
  z-index: 100000;
  width: auto;
  height: 33px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #dd9c44;
  border: 0px solid transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;
  transition: transform 0.1s;
  transform-origin: center;
}

.image-div {
  display: flex;
  font-size: 1.3rem;
}
.circle:hover {
  box-shadow: #dd9c44 0px 0px 10px;
  border: 0px solid #dd9c44;
  transform: scale(1.1);
}
.circle-text {
  color: #2c3033;
  font-size: 15px;
  font-family: "Lexend";
  margin-left: 10px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .circle {
    height: 20px;
    max-width: auto;
    margin-inline: 5px;
    margin-top: 5px;
  }
  .circle-text {
    font-size: 12px;
  }
  .image-div {
    font-size: 1rem;
  }
}
</style>
