<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'" aria-label="breadcrumb" class="breadcrumb-container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legs") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.addUserToEventBreadTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row">
    <div class="center-alignment">
      <div style="flex: 1">
        <GoldCircleButton
          :circleText="$t('language.back')"
          :circleImage="backImage"
          @circleClick="navigateBack()"
          title="Back"
        />
      </div>
      <div class="title-container">
        <h1>{{ $t("language.addUserToEventTitle") }}</h1>
      </div>
      <div style="flex: 1"></div>
      <!--             <div style="margin-right: 2.5vw;">
                <GoldCircleButton :circleImage="plusImage" @circleClick="navigateAddUser()" />
            </div> -->
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      "
    >
      <div class="search-div">
        <input
          class="search-bar"
          v-model="allUserSearchValue"
          :placeholder="searchBar"
        />
        <i class="search-icon" :class="searchImage"></i>
      </div>
      <div class="search-div">
        <input
          class="search-bar"
          v-model="userOnEventSearchValue"
          :placeholder="searchBar"
          style="margin-left: 2vw;"
        />
        <i class="search-icon" :class="searchImage"></i>
      </div>
    </div>
    <div sm="12" md="6" class="center-alignment">
      <div class="outer-table" v-if="screenWidth > 768">
        <table class="allusers-custom-table" style="margin-right: 4vw">
          <thead>
            <tr>
              <th colspan="2" class="top-title">{{ $t("language.allUsers") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div style="flex: 1; display: flex; justify-content: left;">{{ $t("language.addUserName") }}</div>
                <div style="flex: 1; text-align: left;">{{ $t("language.addUserEmail") }}</div>
                <div style="flex: 1; display: flex; justify-content: right;"></div>
              </th>
            </tr>
          </tbody>

          <tbody>
            <tr v-for="item in filteredItems" :key="item.user_id">
              <td colspan="3" class="user-info">
                <div style="flex: 1;">{{ item.first_name + " " + item.last_name }}</div>
                <div style="flex: 1;text-align: left; ">{{ item.email }}</div>
                <div style="flex:1;text-align: right;">
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="addUserToCurrentEvent(item.user_id)"
                  >
                    <i class="fa-solid fa-user-plus"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="eventusers-custom-table">
          <thead>
            <tr>
              <th colspan="1" class="top-title">{{ $t("language.userOnEvent") }}</th>
            </tr>
          </thead>
          <tbody v-if="filteredUsersOnEvent.length > 0">
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div style="flex: 1; display: flex; justify-content: left;">{{ $t("language.addUserName") }}</div>
                <div style="flex: 1; text-align: left;">{{ $t("language.addUserEmail") }}</div>
                <div style="flex: 1"></div>
              </th>
            </tr>
          </tbody>
          <tbody style="height: 13.6%" v-else>
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div style="flex: 1; display: flex; ">{{ $t("language.addUserName") }}</div>
                <div style="flex: 1; text-align: left;">{{ $t("language.addUserEmail") }}</div>
                <div style="flex: 1;"></div>
              </th>
            </tr>
          </tbody>
          <tbody v-if="usersOnEventArray.length > 0">
            <tr v-for="item in filteredUsersOnEvent" :key="item.user_id">
              <td colspan="2" class="user-info">
                <div style="flex: 1;">{{ item.first_name + " " + item.last_name }}</div>
                <div style="flex: 1;">{{ item.email }}</div>
                <div style="flex:1; text-align: right;">
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="removeUserFromEvent(item.event_user_id)"
                  >
                  <i class="fa-solid fa-user-minus"></i>

                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ $t("language.emptyAddedUserToEvent") }}
              </div>
              <div>
                <button type="button" class="btn btn-light">
                  <i class="fa-solid fa-user-xmark" style="color: #2c3033"> </i>
                </button>
              </div>
            </td>
            <td></td>
          </tbody>
        </table>
      </div>
      <div class="outer-table" v-else>
        <table class="allusers-custom-table" style="margin-right: 4vw">
          <thead>
            <tr>
              <th colspan="2">{{ $t("language.allUsers") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div>{{ $t("language.addUserName") }}, {{ $t("language.addUserEmail") }}</div>
              </th>
            </tr>
          </tbody>

          <tbody>
            <!-- Sample rows, replace with your dynamic data -->

            <tr v-for="item in filteredItems" :key="item.user_id">
              <td colspan="2" class="user-info">
                <div>
                  <div>{{ item.first_name + " " + item.last_name }}</div>
                  <div>{{ item.email }}</div>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="addUserToCurrentEvent(item.user_id)"
                  >
                    <i class="fa-solid fa-user-plus"></i>
                  </button>
                </div>
              </td>
              <!--                             <td style="display: flex; flex-direction: column;">
                            Kecskesajt
                        </td> -->
            </tr>
          </tbody>
        </table>
        <table class="eventusers-custom-table">
          <thead>
            <tr>
              <th colspan="2">{{ $t("language.userOnEvent") }}</th>
            </tr>
          </thead>
          <tbody v-if="filteredUsersOnEvent.length > 0">
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div>{{ $t("language.addUserName") }}, {{ $t("language.addUserEmail") }}</div>
              </th>
            </tr>
          </tbody>
          <tbody style="height: 13.6%" v-else>
            <tr>
              <th
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                "
              >
                <div>{{ $t("language.addUserName") }}</div>
                <div>{{ $t("language.addUserEmail") }}</div>
              </th>
            </tr>
          </tbody>
          <tbody v-if="usersOnEventArray.length > 0">
            <tr v-for="item in filteredUsersOnEvent" :key="item.user_id">
              <td colspan="2" class="user-info">
                <div>
                  <div>{{ item.first_name + " " + item.last_name }}</div>
                  <div>{{ item.email }}</div>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="removeUserFromEvent(item.event_user_id)"
                  >

                  <i class="fa-solid fa-user-minus"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td style="display: flex">
              <div>{{ $t("language.emptyUserOnEvent") }}</div>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import GoldCircleButton from "./GoldCircleButton.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "AddUserToEvent",
  data() {
    return {
      usersArray: [],
      usersOnEventArray: [],
      availableUsersArray: [],
      backImage: "fa-solid fa-arrow-left",
      plusImage: "fa-solid fa-plus",
      allUserSearchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      userOnEventSearchValue: "",
      screenWidth: window.innerWidth,
      wordDocumentUrl: "../adatvedelem.docx",
      searchBar: "",
    };
  },
  created() {
    this.searchBar = this.$t("language.searchBar");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    this.usersArray = await dp.getUsers();
    window.addEventListener("resize", this.handleResize);
    this.event_id = localStorage.getItem("event_id");
    this.usersOnEventArray = await dp.getEventUsersByEvent(this.event_id);
    this.availableUsersArray = await this.usersArray.filter(
      (user) =>
        !this.usersOnEventArray.some(
          (userOnEvent) => userOnEvent.user_id === user.user_id
        )
    );
  },
  components: {
    GoldCircleButton,
  },
  computed: {
    filteredItems: function () {
      return this.availableUsersArray.filter((item) => {
        if (
          (item.first_name + " " + item.last_name + " " + item.title)
            .toLowerCase()
            .includes(this.allUserSearchValue.toLowerCase()) ||
          item.phone_number
            .toLowerCase()
            .includes(this.allUserSearchValue.toLowerCase()) ||
          item.email
            .toLowerCase()
            .includes(this.allUserSearchValue.toLowerCase()) ||
          item.user_id
            .toString()
            .includes(this.allUserSearchValue.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    filteredUsersOnEvent: function () {
      return this.usersOnEventArray.filter((item) => {
        if (
          (item.first_name + " " + item.last_name + " " + item.title)
            .toLowerCase()
            .includes(this.userOnEventSearchValue.toLowerCase()) ||
          item.phone_number
            .toLowerCase()
            .includes(this.userOnEventSearchValue.toLowerCase()) ||
          item.email
            .toLowerCase()
            .includes(this.userOnEventSearchValue.toLowerCase()) ||
          item.user_id
            .toString()
            .includes(this.userOnEventSearchValue.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async addUserToCurrentEvent(user_id) {
      await dp.addUserToEvent(this.event_id, user_id);
      this.usersOnEventArray = await dp.getEventUsersByEvent(this.event_id);
      this.availableUsersArray = await this.usersArray.filter(
        (user) =>
          !this.usersOnEventArray.some(
            (userOnEvent) => userOnEvent.user_id === user.user_id
          )
      );
    },
    async removeUserFromEvent(event_user_id) {
      await dp.deleteUserFromEvent(event_user_id);
      this.usersOnEventArray = await dp.getEventUsersByEvent(this.event_id);
      this.availableUsersArray = await this.usersArray.filter(
        (user) =>
          !this.usersOnEventArray.some(
            (userOnEvent) => userOnEvent.user_id === user.user_id
          )
      );
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}

footer {
  position: absolute;
  bottom: 0;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.user-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-info-small {
  display: flex;
  flex-direction: column;
}

.btn-light{
  background-color: #2c3033;
  color: #dd9c44 !important;
  border: 0;
  justify-content: right;
}

.btn-light:hover{
  background-color: #dd9c44;
  color: #2c3033 !important;
}

.search-div {
  width: 100%;
  margin-bottom: 4vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.top-title{
  font-size: 20px;
}

.outer-table {
  width: 100%;
  height: auto;
  max-height: 70vh;
  overflow-y: auto;
  /* Enable vertical scrolling */
  background-color: transparent;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.search-bar {
  border: 0;
  background-color: rgba(44, 48, 51, 0.85);
  color: white;
  padding: 4px 12px;
  border-radius: 10px;
  width: 250px;

  /* ::placeholder {
    color: white;
  } */
}

.search-icon {
  margin-left: -30px;
  font-size: 1.2em;
  color: white;
}

.allusers-custom-table {
  width: 50%;
  border-collapse: collapse;
  height: 10%;
  overflow-y: auto;
  border-radius: 20px;
}

.eventusers-custom-table {
  width: 50%;
  border-collapse: collapse;
  height: 10%;
  overflow-y: auto;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 2px solid #202528;
  padding: 8px;
  text-align: center;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
}

h1 {
  color: white;
  font-size: 50px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh;
  padding:0;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: none;
}
.breadcrumb-container{
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

@media (max-width: 768px) {
  h1 {
    font-size: 18px;
    padding: 0;
  }

  button {
    transform: scale(1);
  }

  th {
    font-size: 11px;
  }

  td {
    font-size: 11px;
  }

  .outer-table {
    width: 100%;
  }

  .search-bar {
    width: 150px;
    height: 30px;
    padding-right: 35px;
    font-size: 14px;
  }

  .search-icon {
    margin-left: -30px;
    font-size: 1.2em;
  }

}
@media (max-width: 1200px) {
  h1 {
    font-size: 26px;
    padding: 0;
  }
}
@media (max-width: 640px) {
  h1 {
    font-size: 26px;
    padding: 0;
  }
  .title-container {
    font-size: 20px;
  }
  .small-title-container {
    display: block;
  }
  h1 {
    font-size: 20px;
    padding: 0;
  }
}
@media (max-width: 500px) {
  .breadcrumb-item {
    font-size: 0.8rem;
  }
  .small-title-container {
    margin-top: 20px;
  }
}
</style>
