<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/events">{{ $t("language.eventsTitle") }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page" style="color: #dd9c44">
          <a href="/legs">{{ $t("language.legsTitle") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.legBreadTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="background">
    <div class="row" id="editLeg-row">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
        "
      >
        <div class="header-text">
          <div style="flex: 1">
            <GoldCircleButton
              :circleText="back"
              :circleImage="backImage"
              @circleClick="navigateBack()"
              :title="back"
            />
          </div>
          <div class="header-title">
            <div class="leg-title">{{ this.editLeg.leg_name }}</div>
          </div>
          <div style="flex: 1"></div>
        </div>
        <div class="leg-date">{{ formatDate(this.editLeg.leg_date) }}</div>
        <div class="edit-block" id="edit-block">
          <div style="display: flex; flex-direction: column" v-if="this.role">
            <div class="edit-row" v-if="this.role == 'admin'">
              <span class="name-date">{{ $t("language.legNameTitle") }}</span>
              <GrayInputField
                :value="this.editName"
                :setter="setName"
                class="edit-row"
              />
            </div>
            <div class="edit-row" v-if="this.role == 'admin'">
              <span class="name-date">{{ $t("language.legDateTime") }}</span>
              <DateInputField
                :value="formatDate(this.editDate)"
                :setter="setDate"
                class="edit-row"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.mapName") }}
              <GrayInputField :setter="setMap" />
            </div>
            <div class="edit-row">
              {{ $t("language.mapFile") }}
              <input
                :setter="setFile"
                class="form-control form-control-sm"
                type="file"
                id="formFile"
                accept=".gpx"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.mapImage") }}
              <input
                :setter="setImg"
                class="form-control form-control-sm"
                type="file"
                id="formImg"
              />
            </div>
            <GrayActionButton
              @actionClick="handleConfigLeg()"
              :actionImage="isConfigLeg ? actionSpecImageUp : actionSpecImage"
              :text="specText"
              v-if="this.role == 'admin' || this.role == 'staff'"
            />
            <div v-if="isExtendDivVisible" id="extendDiv" class="extendDiv">
              <div class="edit-row" style="padding-bottom: 0px">
                <span class="name-date">{{ $t("language.snapRange") }}</span>
                <GrayInputField
                  type="number"
                  :value="this.editSnapRangeLeg"
                  :setter="setSnapRange"
                  class="edit-row"
                  style="padding-top: 20px"
                  @input="validateSnapRangeLeg"
                />
              </div>
              <div class="edit-row" style="padding-bottom: 0px">
                <span class="name-date">{{ $t("language.dwellTime") }}</span>
                <GrayInputField
                  type="number"
                  :value="this.editDwellTime"
                  :setter="setDwellTime"
                  class="edit-row"
                  @input="validateDwellTime"
                />
              </div>
              <div class="settings-row">
                <div class="settings-label">
                  {{ $t("language.snapRange") }}:
                </div>
                <div class="settings-switch-container">
                  <div class="settings-switch-text2">
                    {{ $t("language.snapRangeOff") }}
                  </div>
                  <div class="form-check form-switch" id="switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="changeSnapRangeLeg"
                      @change="changeSnapRangeLegSwitch"
                      :checked="this.isSnapRangeLegOn"
                    />
                  </div>
                  <div class="settings-switch-text2">
                    {{ $t("language.snapRangeOn") }}
                  </div>
                </div>
              </div>
            </div>
            <!--           <div class="edit-row">
            Térkép:
            <input
              style="margin: 20px"
              class="form-control form-control-sm"
              type="file"
              id="formFile"
            />
          </div> -->
            <div class="action-buttons" v-if="this.role">
              <div>
                <GrayActionButton
                  @actionClick="showAlert = true"
                  :actionImage="actionDeleteImage"
                  :text="deleteText"
                  v-if="this.role == 'admin'"
                />
              </div>
              <div style="display: flex">
                <GrayActionButton
                  style="padding-inline: 20px"
                  @actionClick="dataPush()"
                  :actionImage="actionSaveImage"
                  :text="saveText"
                />
                <GrayActionButton
                  @actionClick="navigateBack"
                  :actionImage="actionCancel"
                  :text="cancelText"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    body="Biztosan törölni akarja ezt az elemet?"
    @confirm="handleDeleteClick"
    @cancel="showAlert = false"
  />
  <CustomAlert
    :show="showAlert2"
    :body="alertBody"
    @confirm="showAlert2 = false"
    :showCancelButton="false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "@/components/GoldCircleButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "@/components/CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
import DateInputField from "@/components/DateInputField.vue";
const dp = dataProvider();

export default {
  name: "EditLeg",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "",
      actionSpecImage: "fa-solid fa-chevron-down",
      actionSpecImageUp: "fa-solid fa-chevron-up",
      specText: "",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      legmap: "",
      backImage: "fa-solid fa-arrow-left",
      editLeg: [],
      configLegArray: [],
      editName: undefined,
      alertBody: undefined,
      editDate: undefined,
      leg_name: undefined,
      leg_date: undefined,
      editSnapRangeLeg: undefined,
      editDwellTime: undefined,
      config_snap_range: undefined,
      config_dwell_time: undefined,
      isConfigLeg: false,
      wordDocumentUrl: "../adatvedelem.docx",
      back: "",
      showAlert: false,
      showAlert2: false,
      isExtendDivVisible: false,
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      isSnapRangeLegOn: true,
      legsArray: [],
      eventId: undefined,
      map_name: undefined,
      map_File: undefined,
      map_Image: undefined,
    };
  },
  created() {
    this.back = this.$t("language.back");
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.specText = this.$t("language.specText");
    this.deleteText = this.$t("language.deleteText");
  },
  props: [],
  async mounted() {
    this.legId = localStorage.getItem("leg_id");
    this.editLeg = await dp.getLegById(this.legId);
    this.editName = this.editLeg.leg_name;
    this.editDate = this.editLeg.leg_date;
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------

    //Config leg get, backend requried
    this.configLegArray = await dp.getConfigLeg(this.legId);
    this.editSnapRangeLeg = this.configLegArray[0]
      ? this.configLegArray[0].config_snap_range
      : undefined;
    this.editDwellTime = this.configLegArray[0]
      ? this.configLegArray[0].config_dwell_time
      : undefined;
    this.config_snap_range = this.editSnapRangeLeg;
    this.config_dwell_time = this.editDwellTime;
    if (this.role == "clerk") {
      this.isExtendDivVisible = true;
    }
    //-----------------------
    const snapRangeKeyLeg = `isSnapRangeLegOn_${this.legId}`;
    let snapRangeLeg = localStorage.getItem(snapRangeKeyLeg);
    this.isSnapRangeLegOn = snapRangeLeg === null ? true : snapRangeLeg === "true";
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.isExtendDivVisible = false;
      this.isConfigLeg = false;
      this.device_serial_num = undefined;
    },

    async changeSnapRangeLeg(boolean) {
      const snapRangeKeyLeg = `isSnapRangeLegOn_${this.legId}`;
      localStorage.setItem(snapRangeKeyLeg, boolean.toString());
      this.isSnapRangeLegOn = boolean;
      if (!this.isSnapRangeLegOn) {
        this.config_snap_range = 0;
      } else {
        this.config_snap_range = 10;
      }
      this.editSnapRangeLeg = this.config_snap_range;
      await this.$nextTick();
      if (!this.isSnapRangeLegOn) {
        await dp.configLegEdit(
          this.legId,
          this.config_snap_range,
          this.config_dwell_time
        );
      } else {
        await dp.configLegEdit(
          this.legId,
          this.config_snap_range,
          this.config_dwell_time
        );
      }
    },
    changeSnapRangeLegSwitch(e) {
      this.changeSnapRangeLeg(e.target.checked);
    },
    async dataPush() {
      const snapRangeKeyLeg = `isSnapRangeLegOn_${this.legId}`;
      let snapRangeLeg = localStorage.getItem(snapRangeKeyLeg);
      if (snapRangeLeg === null || snapRangeLeg === "false") {
        this.isSnapRangeLegOn = true;
        localStorage.setItem(snapRangeKeyLeg, "true");
      } else {
        this.isSnapRangeLegOn = snapRangeLeg === "true";
      }
      try {
        await dp.updateLegById(
          this.leg_name,
          this.leg_date,
          undefined,
          this.legId
        );
      } catch (error) {
        console.error("Error during updateLegById:", error);
        this.showAlert2 = true;
        this.errorMessage = error.message;
        return;
      }
      try {
        await dp.configLegEdit(
          this.legId,
          this.config_snap_range,
          this.config_dwell_time
        );
      } catch (error) {
        console.error("Error during configLegEdit:", error);
        this.showAlert2 = true;
        this.errorMessage = error.message;
        return;
      }
      const formFile = document.getElementById("formFile")?.files[0];
      const formImg = document.getElementById("formImg")?.files[0];
      if (formFile || formImg) {
        if (formFile && formImg) {
          if (formFile && !formFile.name.endsWith(".gpx")) {
            this.alertBody = "A fájl nem .gpx kiterjesztésű!";
            this.showAlert2 = true;
            return;
          } else {
            this.map_File = formFile;
            this.map_Image = formImg;
            if (this.leg_name == undefined) {
              this.leg_name = this.editName;
            }
             if(this.map_name==undefined){
              this.map_name = 'map_'+ this.legId
            } 
            try {
              await dp.legEdit(
                this.legId,
                this.leg_name,
                this.map_name,
                this.map_File,
                this.map_Image
              );
            } catch (error) {
              console.error("Error during legEdit:", error);
              this.showAlert2 = true;
              this.errorMessage = error.message;
              return;
            }
          }
        } else {
          this.alertBody = "A térkép módosításához kép feltöltése is sükséges!";
          this.showAlert2 = true;
          return;
        }
      }
      this.$router.push("/legs");
    },
    toggleExtendDivWithDelay() {
      // Set isExtendDivVisible to true after a delay
      setTimeout(() => {
        this.isExtendDivVisible = true;
      }, 500); // 0.5 second delay
    },
    handleTransitionEnd(event) {
      // Check if the transition property is 'height' and the event target is the edit-block element
      if (event.propertyName === "height" && event.target.id === "edit-block") {
        // Call the toggleExtendDivWithDelay method
        this.toggleExtendDivWithDelay();
      }
    },
    handleConfigLeg() {
      this.isConfigLeg = !this.isConfigLeg;
      if (this.isConfigLeg) {
        this.toggleExtendDivWithDelay();
        document.getElementById("edit-block").style.height = "90vh";
        document.getElementById("edit-block").style.transition = "height 0.5s";
      } else {
        document.getElementById("edit-block").style.height = "82vh";
        this.isExtendDivVisible = false;
      }
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    navigateBack() {
      this.$router.push("/legs");
    },
    async handleDeleteClick() {
      await dp.deleteLegById(this.legId);
      this.$router.push("/legs");
    },
    handleSaveClick() {},
    handleCreateClick() {},
    setLocation(value) {
      this.legLocation = value;
    },
    setName(value) {
      this.leg_name = value;
    },
    setDate(value) {
      this.leg_date = value;
    },
    setDwellTime(value) {
      this.config_dwell_time = value;
    },
    setSnapRange(value) {
      this.editSnapRangeLeg = Number(value);
      this.config_snap_range = this.editSnapRangeLeg;
    },

    setMap(value) {
      this.map_name = value;
    },
    setFile(value) {
      this.map_File = value;
    },
    setImg(value) {
      this.map_Image = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    validateSnapRangeLeg(event) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, "");
      inputValue = inputValue.replace(/\D/g, "");
      let numericValue = Number(inputValue);
      const min = 0;
      const max = 1000;
      if (numericValue < min) {
        numericValue = min;
      }
      if (numericValue > max) {
        numericValue = max;
      }
      this.editSnapRangeLeg = numericValue;
      this.config_snap_range = numericValue;
    },
    validateDwellTime(event) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, "");
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = Number(inputValue);
      const min = 1;
      const max = 600000;
      if (inputValue < min) {
        inputValue = min;
      }
      if (inputValue > max) {
        inputValue = max;
      }
      event.target.value = inputValue;
      this.config_dwell_time = inputValue;
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    DateInputField,
    CustomAlert,
  },
};
</script>

<style scoped>
.settings-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  vertical-align: middle;
  color: white;
}
.settings-switch-text2 {
  margin: 0 10px;
}
.settings-switch-container {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  width: 50%;
  justify-content: flex-end;
}
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
  padding-bottom: 20px;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-block: 20px;
}

.leg-title {
  color: white;
  font-size: 32px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}

.leg-date {
  color: white;
  font-size: 20px;
  margin: 0;
  font-family: "Lexend";
  white-space: nowrap;
}
/* extendable modal fro edit leg */
.extendDiv {
  transition: 0.4s;
}

.input-custom {
  border: 1px solid white;
  border-radius: 10px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}
.form-control-sm {
  max-width: 300px;
  min-width: 200px;
}
.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
  height: 72vh;
  transition: height 0.5s ease 0s;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}
.name-date {
  width: 180px;
  font-size: 16px;
  text-align: left;
}

.edit-input {
  min-width: 150px; /* Vagy adj meg egy másik fix szélességet */
}

body {
  overflow-y: hidden;
}

/* Hide horizontal scrollbar */
.background {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .leg-title {
    font-size: 20px;
  }
  .leg-date {
    font-size: 16px;
  }
  .name-date {
    font-size: 14px;
    text-align: left;
  }
  .edit-block {
    width: 90vw;
  }
}

@media (max-width: 568px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 16px;
  }
  .edit-row {
    font-size: 14px;
  }
  .edit-block {
    min-width: 300px;
  }
}
@media (max-height: 550px) {
  .edit-block {
    height: 45vh;
    width: 92vw;
  }
}
</style>
