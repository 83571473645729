<template>
  <div class="modal-overlay" v-if="showReclaimDeviceModal">
    <div class="modal-container">
      <div class="header-title">
        <span class="modal-title">{{ $t("language.reclaimTitle") }}</span>
      </div>

      <div class="modal-content">
        <div class="inputfields">
          <div class="custom-input-div">
            <input
              list="freeDevices"
              aria-label="Default select example"
              :placeholder="$t('language.pilotDevSN')"
              id="device-selector"
              type="number"
              v-model="this.deviceSerial"
              @input="validateNumericInput($event)"
            />
          </div>
          <datalist id="freeDevices">
            <option
              v-for="item in this.filteredDeviceArray"
              :value="item.device_serial_num"
              :key="item.device_id"
            >
              {{ item.device_serial_num }}
            </option>
          </datalist>
        </div>
      </div>
      <div
        class="action-buttons"
        style="display: flex; flex-direction: row; padding-top: 20px"
      >
        <GrayActionButton
          style="padding-inline: 20px"
          @actionClick="handleSaveClick"
          :actionImage="actionSaveImage"
          :text="saveText"
        />
        <GrayActionButton
          @actionClick="closeModal"
          :actionImage="actionCancel"
          :text="cancelText"
        />
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="alertBody"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
</template>

<script>
//import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: "DeviceReclaim",
  emits: ["close"],
  data() {
    return {
      actionCancel: "fa-solid fa-xmark",
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      backImage: "fa-solid fa-arrow-left",
      deviceSerial: undefined,
      devSN: "",
      saveText: "",
      cancelText: "",
      showAlert: false,
      alertBody: "",
      deviceArray: [],
      filteredDeviceArray:[],
    };
  },
  created() {
    this.devSN = this.$t("language.devSN");
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
  },
  props: { showReclaimDeviceModal: Boolean },
  
  watch: {
    showReclaimDeviceModal: function () {
      // If "showAddRacerModal" ever changes, then we will console log its new value.
      this.getAvailableDevices();
    },
  },
  async mounted() {
    this.deviceArray = await dp.getDevice();
    await this.getAvailableDevices()
  },
  methods: {
    async getAvailableDevices() {
      this.deviceArray = await dp.getDevice();
      let devices = [...this.deviceArray];
      let deviceStatus = await dp.getLastDeviceLogs();
      this.reclaimedStatus = [];
      for (const status of deviceStatus) {
        if (status.device_status == "reclaimed") {
          this.reclaimedStatus.push(status.device_id);
        }
      }
      this.filteredDeviceArray = devices.filter((item) =>
        !this.reclaimedStatus.includes(item.device_id)
      );
      
    },
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    closeModal() {
      this.$emit("close");
    },
    async handleSaveClick() {
      console.log(this.deviceSerial);
      
      if (this.deviceSerial !== undefined && this.deviceSerial !== "") {
        if (
          this.deviceArray.find(
            (device) => device.device_serial_num == this.deviceSerial
          ) == undefined
        ) {
          this.showAlert = true;
          this.alertBody = this.$t("language.reclaimNotFound");
          return;
        } else {
          try {
            await dp.reclaimDevice(this.deviceSerial);
            this.deviceSerial = undefined;
            await this.getAvailableDevices()
            this.showAlert = true;
            this.alertBody = this.$t("language.reclaimOk");
          } catch (error) {
            this.showAlert = true;
            this.alertBody = this.$t("language.reclaimAllReady");
          }
        }
      } else {
        this.showAlert = true;
        this.alertBody = this.$t("language.reclaimError");
      }

      //   this.$router.push("/racerlist");
    },
    setSerialNumber(value) {
      this.deviceSerial = value;
    },
  },
  components: {
    //GrayInputField,
    GrayActionButton,
    CustomAlert,
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

#device-selector {
  background-color: #555556;
  font-family: "Lexend";
  color: white;
  border-radius: 10px;
  height: 5vh;
  padding: 5px;
  margin-bottom: 20px;
  width: 20vw;
  border-style: none;
}
.custom-input-div {
  padding-left: 5vw;
}

#device-selector:focus {
  outline-color: #dd9c44;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 25%;
  padding-left: 0vw;
}
.edit-row {
  padding-bottom: 20px;
}

.header-text {
  font-family: "Lexend";
  margin-bottom: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal-container {
  background: #202528;
  padding-inline: 20px;
  border-radius: 10px;
  width: 30%;
  height: 50%;
  z-index: 1002;
  box-shadow: #dd9c44 0px 0px 10px;
}

.modal-container::-webkit-scrollbar {
  width: 0px;
}
.modal-content {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-title {
  color: white;
  opacity: 0.6;
  font-size: 1.5rem;
  font-family: "Lexend";
}
.inputfields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 75px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

@media (max-width: 800px) {
  .modal-container {
    width: 90%;
    height: 50%;
  }
  .custom-input {
    width: 100%;
  }
  .inputfields {
    height: 60%;
    width: 80%;
    padding-right: 5vw;
  }
  .action-buttons {
    padding-right: 3vw;
  }
}
</style>
