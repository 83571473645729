<template>
  <div class="language-field" id="">
    <div v-show="toggled" class="language-selector-text-div">
      <span class="language-selector-text" @click="changeLanguage('en')">
        English
      </span>
      <span class="language-selector-text" @click="changeLanguage('hu')">
        Magyar
      </span>
    </div>
    <button
      type="button"
      style="padding-right: 3vw;"
      class=""
      id="language-button"
      @click="toggled = !toggled"
    >
      <i id="language-icon" class="fa-solid fa-globe"></i>
    </button>
    <!-- <div v-show="toggled" class="language-selector-text-div2">
      <span class="language-selector-text" @click="changeLanguage('en')">
        English
      </span>
      <span class="language-selector-text" @click="changeLanguage('hu')">
        Magyar
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "LanguageButton",
  data() {
    return {
      toggled: false,
    };
  },

  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("selectedLanguage", locale);
      window.location.reload(); // Frissítjük az oldalt a nyelvváltás után
    },
  },
  mounted() {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage) {
      this.$i18n.locale = selectedLanguage;
    }
  },
};
</script>

<style scoped>

.language-field {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.language-selector-text {
  color: #dd9c44;
  margin: 0 10px;
  cursor: pointer;
  font-family: "Lexend";
}
.language-selector-text:hover {
  color: white;
  transform: scale(1.1);
  transition: .3s;
}
.language-selector-text-div {
  background-color: #2c3133;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: right;
}
#language-button {
  background-color: transparent !important;
  border: 0;
  color: #dd9c44;
  display: flex;
  justify-content: right;
}

#language-icon:hover {
  box-shadow: #dd9c44 0px 0px 10px;
  border: 0px solid #dd9c44;
  transform: scale(1.1);
  border-radius: 50%;
}

#language-icon {
  font-size: 20px;
}

#language-container {
  position: absolute;
  vertical-align: center;
  right: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 999999999999;
}

#dropdown-content {
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  background-color: #2c3133;
}

#eng-btn {
  align-self: center;
  vertical-align: center;
  position: relative;
  bottom: 5px;
  color: white;
}

#eng-btn:hover {
  color: #2c3133 !important;
  background-color: #dd9c44 !important;
}

#hun-btn {
  align-self: center;
  vertical-align: center;
  position: relative;
  bottom: 5px;
  color: white;
}

#hun-btn:hover {
  background-color: #dd9c44 !important;
  color: #2c3133 !important;
}

.language-selector-text-div2 {
  display: none;
}

@media (max-width: 768px) {
  .language-selector-text-div {
    font-size: 12px;
    margin: 0;
    padding: 5px;
  }
}
</style>
