<template>
    <div style="padding-left: 20px;">
      <input
        class="custom-input"
        type="date"
        :placeholder="customPlaceholder"
        :value="value"
        @input="updateData"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'DateInputField',
    props: {
      customPlaceholder: {
        type: String,
      },
      value: {
        type: String,
      },
      setter: {
        type: Function,
      },
    },
    data() {
      return {
        internalValue: "",
      };
    },
    methods: {
        updateData(event) {
            this.setter(event.target.value);
        },
    },
  };
  </script>
  
  <style scoped>
  .custom-input {
    width: 20vw;
    height: 5vh;
    border: 1px;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    color: white;
    background-color: #555556;
    font-family: "Lexend";
  }
  
  .custom-input:focus {
    outline-color: #DD9C44;
  }

  @media (max-width: 768px) {
    
    
}
  </style>
  