<template>
  <header>
    <nav style="--bs-breadcrumb-divider: '/'; " aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color:#dd9c44 ;"><a href="/home">Home</a></li>
        <li class="breadcrumb-item " aria-current="page" style="color:#dd9c44 ;"><a href="/events">Events</a></li>
        <li class="breadcrumb-item " aria-current="page" style="color:#dd9c44 ;"><a href="/legs">Legs</a></li>
        <li class="breadcrumb-item active" aria-current="page" style="color:#dd9c44 ;"><a href="/stafflist">Staff list</a></li>
        <li class="breadcrumb-item active" aria-current="page" style="color:#dd9c44 ;">Staff info</li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row">
    <div class="center-alignment" style="padding-inline: 2vw;">
      <div>
        <GoldCircleButton circleText="Back" :circleImage="backImage" @circleClick="navigateBack()" title="Staff list"/>
      </div>
      <div class="title-container">
        <h1> Staff </h1>
      </div>
      <div v-if="screenWidth <= 600">
        <GoldCircleButton circleText="Edit" :circleImage="addImage" @circleClick="navigateEditRacer()" />
      </div>
    </div>
    <div class="page-title col-12">
      <div class="racer-info">
        <table v-if="screenWidth <= 768" class="header-table">
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Device Id</th>
            <td>{{ this.staffArray.staff_name }}</td>
            <td>{{ this.staffArray.staff_phonenum }}</td>
            <td>{{ this.staffArray.staff_email }}</td>
            <td>{{ this.staffArray.device_id }}</td>
          </tr>
          <tr>
            
            <th>Status</th>
            
            <td>{{ this.staffArray.staff_status }}</td>
          </tr>

        </table>
        <table v-else class="header-table">
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Device ID</th>
            <th>Status</th>
          </tr>
          <tr class="racer-info-data">
            <td>{{ this.staffArray.staff_name }}</td>
            <td>{{ this.staffArray.staff_phonenum }}</td>
            <td>{{ this.staffArray.staff_email }}</td>
            <td>{{ this.staffArray.device_id }}</td>
            <td>{{ this.staffArray.staff_status }}</td>
          </tr>

        </table>
      </div>
      <button class="edit-button" @click="navigateEditStaff()" title="Edit staff">
        <i style="font-size: 1.3rem;" class="fa-solid fa-pen-to-square"></i>
        <span style="padding-left: 5px; display: inline-flex; font-family: Lexend">Edit</span>
      </button>
    </div>
    <div class="diagrams">
      <div class="left-diagrams">

        <div class="gForce">
          <h1>G-Force</h1>
        </div>
        <div class="speed">
          <h1>Speed</h1>
        </div>
      </div>
      <div class="right-diagrams">
        <div class="outer-table">
          <table class="vehicle-table">
            <thead>

              <tr>
                <th colspan="4">Vehicle Data</th>
              </tr>
            </thead>
            <tbody>

              <tr class="racer-info-data">
                <td>Phone</td>
                <td>{{ phone }}</td>
                <td>X acceleration</td>
                <td>{{ xAcceleration }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Signal Strength</td>
                <td>{{ signalStrength }}</td>
                <td>Y acceleration</td>
                <td>{{ yAcceleration }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>IP 6 address</td>
                <td>{{ ip6Address }}</td>
                <td>Z acceleration</td>
                <td>{{ zAcceleration }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>IMEI</td>
                <td>{{ imei }}</td>
                <td>Speed km/h</td>
                <td>{{ speed }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Current stage</td>
                <td>{{ currentStage }}</td>
                <td>Recorded at</td>
                <td>{{ recorded }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Altitude</td>
                <td>{{ altitude }}</td>
                <td>ip 4 address</td>
                <td>{{ ip4Address }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Bearing</td>
                <td>{{ bearing }}</td>
                <td>MAC address</td>
                <td>{{ macAddress }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Car voltage</td>
                <td>{{ carVoltage }}</td>
                <td>Connection type</td>
                <td>{{ connectionType }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Device voltage</td>
                <td>{{ deviceVoltage }}</td>
                <td>Recieved at</td>
                <td>{{ recivedAt }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>External temp.</td>
                <td>{{ externalTemp }}</td>
                <td>Speeding</td>
                <td>{{ speeding }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Internal temp.</td>
                <td>{{ internalTemp }}</td>
                <td>SOS</td>
                <td>{{ sos }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Latitude</td>
                <td>{{ latitude }}</td>
                <td>Medic</td>
                <td>{{ medic }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Longitude</td>
                <td>{{ longitude }}</td>
                <td>OK</td>
                <td>{{ ok }}</td>
              </tr>
              <tr class="racer-info-data">
                <td>Stage distance</td>
                <td>{{ stageDistance }}</td>
                <td>G-force</td>
                <td>{{ gForce }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="follow-race">
          <div style="display: flex; flex-direction: row; align-items: center; margin: auto;">
            <span class="race-text" style="">Follow Active
              Race</span>
            <i class="fa-solid fa-arrow-right" style="color: #ffffff; font-weight: bold;"></i>
          </div>
          <img class="map-zoomed" src="mapImage.png" alt="map" />
        </div>
      </div>
    </div>
  </div>

</template>
  
<script>
import GoldCircleButton from './GoldCircleButton.vue';

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();

export default {
  name: 'RacerInfo',
  props: {
    pilot: {
      type: String,
      default: 'Pilot',
    },
    pilotNationality: {
      type: String,
      default: "HUN",
    },
    coPilot: {
      type: String,
      default: 'Co-Pilot',
    },
    coPilotNationality: {
      type: String,
      default: "HUN",
    },
    vehicle: {
      type: String,
      default: 'Vehicle',
    },
    startNum: {
      type: String,
      default: '1',
    },
    deviceId: {
      type: String,
      default: '13123123123',
    },
    status: {
      type: String,
      default: 'OK',
    },
    phone: {
      type: String,
      default: '+3620202020',
    },
    xAcceleration: {
      type: String,
      default: '0.0',
    },
    yAcceleration: {
      type: String,
      default: '0.0',
    },
    zAcceleration: {
      type: String,
      default: '0.0',
    },
    signalStrength: {
      type: String,
      default: '30',
    },
    ip6Address: {
      type: String,
      default: '0',
    },
    imei: {
      type: String,
      default: '212131310',
    },
    speed: {
      type: String,
      default: '30',
    },
    currentStage: {
      type: String,
      default: '3',
    },
    recorded: {
      type: String,
      default: '2023.10.08',
    },
    altitude: {
      type: String,
      default: '20',
    },
    ip4Address: {
      type: String,
      default: '0',
    },
    bearing: {
      type: String,
      default: '0',
    },
    macAddress: {
      type: String,
      default: '0',
    },
    carVoltage: {
      type: String,
      default: '0',
    },
    connectionType: {
      type: String,
      default: '0',
    },
    deviceVoltage: {
      type: String,
      default: '0',
    },
    recivedAt: {
      type: String,
      default: '0',
    },
    externalTemp: {
      type: String,
      default: '0',
    },
    internalTemp: {
      type: String,
      default: '0',
    },
    latitude: {
      type: String,
      default: '0',
    },
    longitude: {
      type: String,
      default: '0',
    },
    stageDistance: {
      type: String,
      default: '0',
    },
    gForce: {
      type: String,
      default: '0',
    },
    speeding: {
      type: String,
      default: '0',
    },
    sos: {
      type: String,
      default: '0',
    },
    medic: {
      type: String,
      default: '0',
    },
    ok: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {    
      wordDocumentUrl: '../adatvedelem.docx',
      backImage: 'fa-solid fa-arrow-left',
      addImage: 'fa-solid fa-pen-to-square',
      rows: [
        { id: 1, value1: 'User', value2: '+3630989898', value3: 'user@user.com', value4: '1' },
      ],
      screenWidth: window.innerWidth,
      staffArray: [],
      staff_id: undefined,
    };
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.staff_id = localStorage.getItem("staff_id");
    this.staffArray = await dp.getStaffById(this.staff_id);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  components: {
    GoldCircleButton,
  },
  methods: {
    navigateBack() {
      this.$router.push("/stafflist");
    },
    navigateEditStaff() {
      this.$router.push('/editStaff');
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    downloadWordFile() {
      const link = document.createElement('a');

      link.href = this.wordDocumentUrl; 
      link.download = 'adatedelem.docx'; 
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
  
<style scoped>
header{
position: fixed;
top: 0;
left: 20px;
}
footer{
  position: absolute;
  bottom: 0 ;
}
ol.breadcrumb li+li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
}

.vehicle-table th {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
}

.map-zoomed {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: flex;
  justify-content: flex-end;
}

.diagrams {
  display: flex;
  flex-direction: row;
  margin-top: 4vh;
  margin-left: 2vw;
}

.left-diagrams {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4vw;
}

.gForce {
  background-color: #2C3033;
  width: 40vw;
  height: 28vh;
  border-radius: 10px;
}

.speed {
  background-color: #2C3033;
  width: 40vw;
  height: 33vh;
  margin-top: 5vh;
  border-radius: 10px;
}

.page-title {
  width: 95%;
  background-color: #2C3033;
  height: 60px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* Adjusted to space between */
  margin: 0 auto;
  /* Center the title within its parent container */
}

.follow-race {
  background-color: #DD9C44;
  color: white;
  font-family: "Lexend";
  width: 50vw;
  height: 8vh;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.header-table {
  width: 85vw;
  border-collapse: collapse;
}

.vehicle-table {
  width: 50vw;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.outer-table {
  width: 100%;
  height: 58vh;
  overflow-y: auto;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow-x: hidden;
}

.vehicle-table th {
  background-color: #DD9C44;
  color: black;
  border-bottom: 2px solid white;
  padding: 8px;
  font-family: "Lexend";
}

.header-table th,
td {
  text-align: left;
}

.header-table th {
  color: #DD9C44;
  font-size: x-small;
  font-family: 'Lexend';
  white-space: nowrap;
}

.header-table td {
  color: white;
  font-size: 16px;
  font-family: 'Lexend';
  white-space: nowrap;
}

.vehicle-table td {
  border-bottom: 1px solid white;
  font-size: small;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
}

h1 {
  color: white;
  font-size: 50px;
  margin: 0;
  font-family: 'Lexend';
  white-space: nowrap;
}

h4 {
  color: #DD9C44;
  font-size: x-small;
  margin: 0;
  margin-right: 25%;
  font-family: 'Lexend';
  white-space: nowrap;
}

h3 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-family: 'Lexend';
  white-space: nowrap;
  margin-right: 15%;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3vh;
  padding: 0;
}

.title-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7%;
}

.edit-button {
  background: #DD9C44;
  border-radius: 10px;
  border: none;
  color: #202528;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  width: 12vh;
  height: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.race-text {
  font-size: large;
  font-weight: bold;
  margin-inline: 5px;
  text-align: center
}


.outer-table::-webkit-scrollbar {
  width: 0px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 30px;

  }

  .header-table {
    width: 90vw;
    margin-left: 10px;
  }

  .header-table th {
    font-size: 10px;
  }

  .header-table td {
    font-size: 10px;
  }

  .header-table tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .page-title {
    height: 80px;
  }

  .edit-button {
    transform: scale(0);
    margin-right: 0px;
    flex-direction: row;
    padding-right: 100px;
  }

  .race-text {
    font-size: 12px;
  }

  .edit-button {
    background: #DD9C44;
    border-radius: 10px;
    border: none;
    color: #202528;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    width: 12vh;
    height: 30px;
    font-weight: bold;
    margin-right: 20px;
  }

  /* Diagramm design change to Grid */
  .diagrams {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .gForce {
    width: 95vw;
    height: 15vh;
  }

  .speed {
    width: 95vw;
    height: 15vh;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .vehicle-table {
    width: 95vw;
    margin-bottom: 10px;
  }

  .follow-race {
    width: 95vw;
    margin-bottom: 10px;
  }

  .left-diagrams {
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title-container {
    margin-right: 0px;
  }

}
</style>