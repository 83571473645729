<template>
    <div class="costum-input-div">
      <input
        class="custom-input"
        :type="type"
        :placeholder="customPlaceholder"
        :value="value"
        @input="updateData"
      />
    </div>
  </template>

  <script>
  export default {
    name: 'GrayInputField',
    props: {
      customPlaceholder: {
        type: String,
      },
      value: {
        type: [String, Number],
      },
      setter: {
        type: Function,
        default: () => {},
      },
      type: {
        type: String,
        default: "text",
      },
    },
    data() {
      return {
        internalValue: "",
      };
    },
    methods: {
        updateData(event) {
            this.setter(event.target.value);
        },
    },
  };
  </script>

  <style scoped>
  .custom-input {
    width: 20vw;
    height: 5vh;
    border: 1px;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    color: white;
    background-color: #555556;
    font-family: "Lexend";
  }

  .costum-input-div {
    padding-left: 5vw;
  }

  .custom-input:focus {
    outline-color: #DD9C44;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

  @media (max-width: 768px) {
    .custom-input {
      height: 4vh;
      width: 100%;
      font-size: small;
    }

  }
  </style>
