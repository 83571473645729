<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.home") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="devicelist">{{ $t("language.devList") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
           {{ $t("language.addNewDev") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="editLeg-row">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="header-text">
        <div style="flex: 1">
          <GoldCircleButton
            :circleText="back"
            :circleImage="backImage"
            @circleClick="handleBackClick"
            title="Devices"
          />
        </div>
        <div class="header-title">
          <span class="title">{{ $t("language.addNewDev") }}</span>
        </div>
        <div style="flex: 1"></div>
      </div>
      <div class="edit-block">
        <div style="display: flex; flex-direction: column">
          <div>
            <div class="edit-row">
              {{ $t("language.devSN") }}
              <GrayInputField
                type="number"
                :setter="setPID"
                @input="validateNumericInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.devIMEI") }}
              <GrayInputField
                type="number"
                :setter="setIMEI"
                @input="validateNumericInput($event)"
              />
            </div>
            <!--             <div class="edit-row">
              {{ $t("language.devSimCard") }}
              <GrayInputField :setter="setSimCard" />
            </div> -->
            <div class="edit-row">
              {{ $t("language.devSimICC") }}
              <GrayInputField
                type="number"
                :setter="setICC"
                @input="validateNumericInput($event)"
              />
            </div>
            <div class="edit-row">
              {{ $t("language.devFrimewareVersion") }}
              <GrayInputField :setter="setFW" />
            </div>
            <div class="edit-row">
              {{ $t("language.devPhyID") }}
              <GrayInputField :setter="setPHI" />
            </div>
          </div>
          <div class="action-buttons" style="">
            <GrayActionButton
              style="padding-inline: 20px"
              @actionClick="handleCancelClick"
              :actionImage="actionCancel"
              :text="cancelText"
            />
            <GrayActionButton
              @actionClick="handleSaveClick"
              :actionImage="actionSaveImage"
              :text="saveText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomAlert
    :show="showAlertModal"
    :body="body"
    :showCancelButton="false"
    @confirm="showAlertModal = false"
  />
</template>

<script>
import GrayInputField from "@/components/GrayInputField.vue";
import GrayActionButton from "@/components/GrayActionButton.vue";
import GoldCircleButton from "./GoldCircleButton.vue";
import dataProvider from "../utils/dataProvider";
import CustomAlert from "@/components/CustomAlert.vue";

const dp = dataProvider();

export default {
  name: "AddDevice",
  data() {
    return {
      actionDeleteImage: "fa-solid fa-trash-can",
      deleteText: "Delete",
      actionSaveImage: "fa-solid fa-floppy-disk",
      saveText: "",
      actionCreateImage: "fa-solid fa-pen-to-square",
      createText: "Create",
      rallyName: "Nyíregyháza Rally",
      rallyLocation: "Nyíregyháza",
      userPhoneNumber: "+36 30 123 4567",
      deviceSorszam: "",
      devicePID: "",
      deviceIMEI: "",
      deviceICC: "",
      deviceMessageFrequency: "",
      deviceSound: "",
      backImage: "fa-solid fa-arrow-left",
      device_name: undefined,
      device_serial_num: undefined,
      device_imei: undefined,
      simcard: undefined,
      simcard_icc: undefined,
      firmware_version: undefined,
      device_physical_id: undefined,
      wordDocumentUrl: "../adatvedelem.docx",
      actionCancel: "fa-solid fa-xmark",
      cancelText: "",
      back: "",
      showAlertModal: false,
      body: "",
      deviceFillFields: "",
    };
  },
  created() {
    this.back = this.$t("language.back");
    this.saveText = this.$t("language.saveText");
    this.cancelText = this.$t("language.cancelText");
    this.deviceAlert = this.$t("language.deviceAlert");
    this.deviceFillFields = this.$t("language.deviceFillFields");
  },
  props: [],
  methods: {
    validateNumericInput(event) {
      let inputValue = event.target.value;
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
      // Replace any non-numeric characters with an empty string
      inputValue = inputValue.replace(/\D/g, "");
      // Update the input field value with the cleaned numeric value
      event.target.value = inputValue;
    },
    async dataPush() {
      if (
        this.device_serial_num &&
        this.device_imei &&
        this.simcard_icc &&
        this.firmware_version &&
        this.device_physical_id
      ) {
        try {
          await dp.addDevice(
            undefined,
            this.device_serial_num,
            this.device_imei,
            undefined,
            this.simcard_icc,
            this.firmware_version,
            this.device_physical_id
          );
          this.handleBackClick();
        } catch (error) {
          console.log(error);
          this.showAlertModal = true;
          this.body = error;
        }
      } else {
        this.showAlertModal = true;
        this.body = this.deviceFillFields;
      }
    },
    handleDeleteClick() {},
    async handleSaveClick() {
      await this.dataPush();
    },
    handleCreateClick() {},
    handleBackClick() {
      this.$router.push("/devicelist");
    },
    handleCancelClick() {
      this.$router.push("/devicelist");
    },
    setSorszam(value) {
      this.device_name = value;
    },
    setPID(value) {
      this.device_serial_num = value;
    },
    setSimCard(value) {
      this.simcard = value;
    },
    setICC(value) {
      this.simcard_icc = value;
    },
    setIMEI(value) {
      this.device_imei = value;
    },
    setFW(value) {
      this.firmware_version = value;
    },
    setPHI(value) {
      this.device_physical_id = value;
    },
    setMessageFrequency(value) {
      this.deviceMessageFrequency = value;
    },
    setSound(value) {
      this.deviceSound = value;
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  components: {
    GrayInputField,
    GrayActionButton,
    GoldCircleButton,
    CustomAlert,
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}
ol.breadcrumb li a {
  color: #dd9c44;
}
ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}
#editLeg-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-block: 20px;
}

.edit-row {
  padding-bottom: 20px;
  text-align: left;
}

.header-text {
  font-family: "Lexend";
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center vertically */
  width: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Occupy remaining space */
}

.edit-block {
  border: 1px solid white;
  background-color: #2c3033;
  border-radius: 10px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  margin-top: 30px;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}
.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Lexend";
}

.title {
  color: white;
  font-size: 45px;
  font-family: "Lexend";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
}

@media (max-width: 860px) {
  .title {
    font-size: 19px;
    padding-left: 25px;
  }
}
@media (max-width: 768px) {
  .edit-row {
    font-size: 12px;
  }

  .title {
    font-size: 20px;
    padding-left: 25px;
  }
  .edit-block {
    width: 90vw;
  }
}
@media (max-height: 550px) {
  .edit-row {
    font-size: 12px;
  }

  .title {
    font-size: 20px;
    padding-left: 25px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 60px;
    width: 100vw;
  }
  #editLeg-row {
    width: 100vw;
    height: 100vh;
    position: absolute !important;
    top: 0;
    left: 0 !important;
    overflow: hidden !important;
    padding: 0;
    z-index: 1000;
    margin: 0;
    top: 40px;
  }
}
</style>
